import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import {Box, TextField, Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  boxSectionInit: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  textfieldAreaContainer: {
    width: '80%',
    marginTop: '10px',
    marginBottom: '10px',
  },
  saveButtonSection: {
    width: '80%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  saveButtonStyle: {
    backgroundColor: 'rgba(41, 178, 239, 1)',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: '700',
    color: '#fff',
    padding: '10px',
  },
  sectorArea: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    marginTop: '10px',
    height: '150px',
    overflowY: 'scroll',
    padding: '20px',
  },
  mainItemBox: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'column',
    margin: '10px',
  },
  deleteSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  deleteIcon: {
    backgroundColor: 'rgba(217, 217, 217, 1)',
    width: '20px !important',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '2px',
    bottom: '-5px',
  },
  keywordContainer: {
    backgroundColor: 'rgba(222, 241, 251, 1)',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '50px',
    color: 'rgba(41, 33, 81, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 400,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
}));

export default function KeywordsTable({data, deletionHandler, keywordAddition}) {
  const classes = useStyles();
  const [addWord, setAddWord] = useState('');

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.boxSectionInit}>
        <Box className={classes.textfieldAreaContainer}>
          <TextField
            fullWidth
            value={addWord}
            onChange={(e) => setAddWord(e.target.value)}
            name="keywordAddition"
            type="text"
            label="Palabra Clave"
            variant="outlined"
            inputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Box>
        <Box className={classes.saveButtonSection}>
          <Button className={classes.saveButtonStyle} onClick={() => keywordAddition(addWord)}>
            GUARDAR
          </Button>
        </Box>
      </Box>
      <Box className={classes.sectorArea}>
        {data.map((index) => (
          <Box className={classes.mainItemBox}>
            <Box className={classes.deleteSection}>
              <Box component="button" className={classes.deleteIcon} onClick={() => deletionHandler(index._id)}>
                x
              </Box>
            </Box>
            <Box className={classes.keywordContainer}>
              {index.word}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
