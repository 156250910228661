/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  getOneProject, listTeam,
} from 'api-lofty';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import Card from '../components/CardUser';
import SearchUserModal from '../components/SearchUserModal';
import RemoveTeamDialog from '../components/RemoveTeamDialog';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const TeamProjectDashboard = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [idDoc, setIdDoc] = useState('');
  const [userIdDoc, setUserIdDoc] = useState('');
  const [open, setOpen] = useState(false);
  const {params} = match;
  const [labelUrl, setLabelUrl] = useState('');
  const {idProject} = params;
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resTeam = await listTeam({token, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Equipo`);
      setData(resTeam.data);
      setProject(resProject.project);
    } catch (error) {
      // code
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const handleUpdateData = async () => {
    setOpenDialogDelete(false);
    const resTeam = await listTeam({token, idProject});
    setData(resTeam.data);
  };

  const handleUndoDelete = () => {
    setOpenDialogDelete(false);
  };

  const setDialogExecution = (valX, valY) => {
    setIdDoc(valX);
    setUserIdDoc(valY);
    setOpenDialogDelete(true);
  };

  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push('/dashboard')}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Equipo
          </Typography>
        </Box>
        <Button
          onClick={() => setOpen(!open)}
          color="primary"
          variant="contained"
          size="medium"
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          className={classes.textButton}
        >
          Agregar
        </Button>
      </Box>
      <SearchUserModal
        open={open}
        handleClose={() => setOpen(!open)}
        token={token}
        idProject={idProject}
        handleSuccess={handleUpdateData}
      />
      <Box>
        <Box
          component="div"
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            height: 'auto',
            p: 1,
            m: 1,
          }}
        >
          {
          data.length === 0 ? (
            <Grid item xs={12}>
              <h3 className="text-center">
                No hay datos
              </h3>
            </Grid>
          ) : (
            <Grid container className="mt-2">
              {data.map((doc) => (
                <Grid key={doc._id} item xl={2} lg={3} md={4} sm={4} xs={12}>
                  <Card
                    isAdmin
                    name={`${doc.dataUser.firstName} ${doc.dataUser.lastName}`}
                    text={`${doc.dataUser.firstName[0]} ${doc.dataUser.lastName[0]}`}
                    status={doc.status}
                    permission={doc.permission}
                    handleRemove={() => setDialogExecution(doc._id, doc.userId)}
                    // handleRemove={async () => {
                    //   try {
                    //     await removeTeam({
                    //       token,
                    //       _id: doc._id,
                    //       userId: doc.userId,
                    //     });
                    //     handleUpdateData();
                    //     Swal.fire({
                    //       position: 'top-end',
                    //       icon: 'success',
                    //       title: 'Se cancelo el colaborador',
                    //       showConfirmButton: false,
                    //       timer: 1500,
                    //     });
                    //   } catch (error) {
                    //     if (error.info) {
                    //       Swal.fire({
                    //         position: 'top-end',
                    //         icon: 'error',
                    //         title: error.info,
                    //         showConfirmButton: false,
                    //         timer: 1500,
                    //       });
                    //     } else {
                    //       Swal.fire({
                    //         position: 'top-end',
                    //         icon: 'error',
                    //         title: 'Error no se pudo eliminar',
                    //         showConfirmButton: false,
                    //         timer: 1500,
                    //       });
                    //     }
                    //   }
                    // }}
                  />
                </Grid>
              ))}
            </Grid>
          )
        }
          <Box>
            <RemoveTeamDialog
              open={openDialogDelete}
              userId={userIdDoc}
              _id={idDoc}
              token={token}
              onClose={() => {
                setOpenDialogDelete(false);
              }}
              undoDelete={handleUndoDelete}
              successFunction={handleUpdateData}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(TeamProjectDashboard);
