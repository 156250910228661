import React from 'react';
import {
  Dialog, Box, IconButton,
  Button,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  textName: {
    color: 'rgba(41, 33, 81, 1)',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  textType: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '13px',
  },
  formControlArea: {
    padding: '5px',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
  },
  textAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(243, 243, 243, 1)',
    padding: '20px',
  },
  textSection: {
    padding: '10px',
    textAlign: 'center',
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

const VerifySuccess = ({
  open, handleClose,
}) => {
  const classes = useStyle();

  return (
    <Dialog open={open} handleClose={handleClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Verificacion de Dominio
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { handleClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.textAreaContainer}>
          <Box className={classes.textSection}>
            Su dominio ha sido verificado exitosamente
          </Box>
          <CheckIcon style={{color: 'green', width: '125px', height: '100px'}} />
        </Box>
        <Box className={classes.formControlArea}>
          <Button onClick={() => handleClose()} variant="contained" color="primary" className={classes.buttonStyle}>
            OK
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VerifySuccess;
