import React from 'react';
import {connect} from 'react-redux';
import Layout from '../layouts/PrincipalDasboard';
import Working from '../components/Working';
import {deletetoken} from '../actions/auth.actions';

const WorkingPrincipalPage = ({history, token}) => (
  <Layout history={history} token={token}>
    <Working />
  </Layout>
);

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkingPrincipalPage);
