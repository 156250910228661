/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
  addPluginsProject, getOneProject,
  listPluginsProject, listPlugins,
} from 'api-lofty';
import toastr from 'toastr';
import {Box, Typography, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
// import CardProject from '../components/CardProject';
// import PluginCard from '../components/PluginCard';
// import AddPluginsDialog from '../components/AddPluginsDialog';
// import {haveDoc, haveEvent} from '../util/addPlugins';
import CardAddSelectedPlugin from '../components/CardAddSelectedPlugin';
import pluginImage from '../assets/plugins/plugin.png';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const AddPluginPage = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pluginsProject, setPluginProject] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Plugins/Agregar`);
      setProject(resProject.project);
      const resUserProject = await listPluginsProject({token, idProject});
      setData(resUserProject.data.map(({type}) => type));
      const res = await listPlugins({token});
      setPluginProject(res.data.map((item) => ({
        type: item.name,
        name: item.label,
        description: item.description,
        image: pluginImage,
      })));
    } catch (error) {
      // C
    }
    setLoading(false);
  }, []);
  const updateData = async () => {
    try {
      const resUserProject = await listPluginsProject({token, idProject});
      setData(resUserProject.data.map(({type}) => type));
      const res = await listPlugins({token});
      setPluginProject(res.data.map((item) => ({
        type: item.name,
        name: item.label,
        description: item.description,
        image: pluginImage,
      })));
    } catch (error) {
      // C
    }
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}/plugins`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Regresar a Plugins
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          p: 1,
          m: 1,
        }}
      >
        {
          pluginsProject.map((item) => {
            if (!data.find((type) => type === item.type)) {
              return (
                <Box key={item.type} style={{width: '100%', padding: '10px'}}>
                  <CardAddSelectedPlugin
                    name={item.name}
                    idProject={idProject}
                    token={token}
                    description={item.description}
                    image={item.image}
                    onClick={async () => {
                      try {
                        await addPluginsProject({
                          idProject,
                          token,
                          type: item.type,
                        });
                        toastr.options = {
                          positionClass: 'toast-top-right',
                          hideDuration: 300,
                          timeOut: 6000,
                        };
                        toastr.clear();
                        setTimeout(() => toastr.success('Se agrego el plugin'), 300);
                        updateData();
                      } catch (error) {
                        toastr.options = {
                          positionClass: 'toast-top-right',
                          hideDuration: 300,
                          timeOut: 6000,
                        };
                        toastr.clear();
                        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
                      }
                    }}
                  />
                </Box>
              );
            }
            return undefined;
          })
        }
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(AddPluginPage);
