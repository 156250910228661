import React, {useEffect, useState} from 'react';
import {
  TextField,
  MenuItem, Button,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import toastr from 'toastr';
import {addRequestProjectPayment, listRequestProjectPayment} from 'api-lofty';
import Tabular from '../Tabular';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
  boxMainFields: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boxRowContainers: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: '5px',
  },
  urlWithMultipleColumns: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'row',
  },
  urlSectionContainer: {
    display: 'flex',
    margin: '2px',
    flexDirection: 'column',
  },
  urlFragmentSection: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  urlFragmentLabel: {
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    color: 'rgba(196, 196, 196, 1)',
    fontSize: '15px',
    fontWeight: 500,
  },
  urlInputSection: {
    border: '1px solid rgba(233, 231, 234, 1)',
    borderRadius: '15px',
  },
}));

const FormPayment = ({
  token, idProject,
}) => {
  const classes = useStyle();
  const [data, setData] = useState([]);
  const handleData = async () => {
    try {
      const res = await listRequestProjectPayment({project: idProject, token});
      setData(res.data);
    } catch (error) {
      if (error.info) {
        toastr.error(error.info);
      } else {
        toastr.error('Error al obtener los datos');
      }
    }
  };
  useEffect(() => {
    handleData();
  }, []);
  return (
    <div>
      {
        data.length === 0 ? (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                await addRequestProjectPayment({
                  currency: e.target.currency.value,
                  name: e.target.name.value,
                  project: idProject,
                  rtn: e.target.rtn.value,
                  status: 'waiting',
                  token,
                  accountNumber: e.target.accountNumber.value,
                  bank: e.target.bank.value,
                });
                toastr.success('Se solicitud de pasarela de pago se envio de manera exitosa. Espere el correo de confirmaciòn o rechazo de la solicitud.');
                handleData();
              } catch (error) {
                if (error.info) {
                  toastr.error(error.info);
                } else {
                  toastr.error('Error al procesar la petición');
                }
              }
            }}
          >
            <div className="mt-4">
              <TextField
                fullWidth
                name="rtn"
                type="text"
                label="RTN"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <TextField
                fullWidth
                name="name"
                type="text"
                label="Nombre legal de la empresa"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <TextField select fullWidth name="currency" type="text" label="Moneda" variant="outlined">
                <MenuItem value="HNL" key="HNL">
                  Lempiras
                </MenuItem>
              </TextField>
            </div>
            <div className="mt-4">
              <TextField select fullWidth name="bank" type="text" label="Banco" variant="outlined">
                <MenuItem value="bancoatlantida">
                  Banco Atlantida
                </MenuItem>
                <MenuItem value="baccredomatic">
                  Bac Credomatic
                </MenuItem>
              </TextField>
            </div>
            <div className="mt-4">
              <TextField
                fullWidth
                name="accountNumber"
                type="text"
                label="Número de Cuenta"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4 text-center">
              <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle}>
                Enviar
              </Button>
            </div>
          </form>
        ) : (
          <Tabular
            data={data}
            title="Solicitudes de método de pago"
            columns={[
              {
                title: 'Nombre de la empresa',
                field: 'name',
              },
              {
                title: 'RTN',
                field: 'rtn',
              },
              {
                title: 'Estatus del proceso',
                field: 'status',
              },
            ]}
          />
        )
      }
    </div>
  );
};

export default FormPayment;
