import {ADD_TOKEN, ADD_USER, DELETE_TOKEN} from '../types/auth.types';

const initialState = {
  token: localStorage.getItem('token'),
  user: undefined,
};

const reducerAuth = (state = initialState, {type, payload}) => {
  switch (type) {
    case ADD_TOKEN:
      return {
        ...state,
        token: payload?.token,
        user: payload?.user,
      };
    case DELETE_TOKEN:
      return {
        ...state,
        token: undefined,
        user: undefined,
      };
    case ADD_USER:
      return {
        ...state,
        user: payload?.user,
      };
    default:
      return state;
  }
};

export default reducerAuth;
