import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import {addPluginCalendar} from 'api-lofty';
import 'toastr/build/toastr.min.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 100,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const DialogAddCalendar = ({
  open, onClose, successFunction, errorFunction,
  idPluginProject, idProject, token,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Calendario
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await addPluginCalendar({
                idPluginProject,
                idProject,
                token,
                description: e.target.description.value,
                label: e.target.label.value,
                type: e.target.type.value,
                slug: e.target.slug.value,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              name="slug"
              type="text"
              label="Slug"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField select fullWidth name="type" type="text" label="Tipo" variant="standard">
              <MenuItem value="publicCalendar">
                Calendario Público
              </MenuItem>
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              multiline
              rows={4}
              name="description"
              type="text"
              label="Descripción"
              variant="standard"
            />
          </div>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogAddCalendar;
