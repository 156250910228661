import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import {
  Typography, IconButton, Grid,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  listPluginNotificationDataAction, removePluginNotificationDataAction,
} from 'api-lofty';
import toastr from 'toastr';
import Tabular from '../Tabular';
import AddNotification from './AddNotification';
import 'toastr/build/toastr.min.css';

const Notifications = ({token, idProject, idPluginProject}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };
  const handleUpdateData = async () => {
    try {
      const resPluginFieldChange = await listPluginNotificationDataAction({
        token, idProject, idPluginProject,
      });
      setData(resPluginFieldChange.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const columns = [
    {
      title: 'Etiqueta',
      field: 'label',
    },
    {
      title: 'Acciones',
      field: 'id',
      render: (rowData) => {
        const {_id} = rowData;
        if (!_id) {
          return <></>;
        }
        return (
          <IconButton
            onClick={() => handleOpenDeleteDialog(_id)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];
  useEffect(() => {
    handleUpdateData();
  }, []);
  return (
    <div>
      <div className="text-right mb-4">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setOpen(true)}
        >
          Agregar
        </Button>
        <AddNotification
          token={token}
          idPluginProject={idPluginProject}
          idProject={idProject}
          open={open}
          onClose={() => setOpen(false)}
          successFunction={() => {
            toastr.options = {
              positionClass: 'toast-top-right',
              hideDuration: 300,
              timeOut: 6000,
            };
            toastr.clear();
            setTimeout(() => toastr.success('Se agrego de manera exitosa el evento'), 300);
            handleUpdateData();
          }}
          errorFunction={(error) => {
            if (error.info) {
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              toastr.clear();
              setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
            } else {
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              toastr.clear();
              setTimeout(() => toastr.error('Error no se pudo agregar el evento'), 300);
            }
          }}
        />
      </div>
      <div>
        <Dialog open={openDialog}>
          <DialogTitle>
            Eliminar
          </DialogTitle>
          <DialogContent>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              ¿Estás seguro de eliminar este campo?
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    try {
                      await removePluginNotificationDataAction({token, _id: idDeletion});
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Se elimino el dato',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setOpenDialog(false);
                      handleUpdateData();
                    } catch (error) {
                      if (error.info) {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: error.info,
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: 'Error no se pudo eliminar',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    }
                  }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
      <Tabular
        title="Notificaciones"
        columns={columns}
        data={data}
        ready={loading}
      />
    </div>
  );
};

export default Notifications;
