/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
  Box, IconButton,
} from '@material-ui/core';
import {deleteEndpointPayment, listEndpointPayment} from 'api-lofty';
import toastr from 'toastr';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusOneIcon from '@material-ui/icons/Add';
import Tabular from '../Tabular';
import LoadingPage from '../../pages/LoadingPage';
import AddEndpointPayment from './AddEndpointPayment';
import 'toastr/build/toastr.min.css';

const EndpointPayment = ({idProject, token, history}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const handleData = async () => {
    try {
      const resMethod = await listEndpointPayment({
        idProject, token,
      });
      setData(resMethod.data);
    } catch (error) {
      if (error.info) {
        toastr.error(error.info);
      } else {
        toastr.error('Error al procesar la petición');
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    handleData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  const colums = [
    {
      title: 'Nombre',
      field: 'label',
    },
    {
      title: 'Tipo',
      field: 'typeFunction',
    },
    {
      title: 'Acciones',
      field: '_id',
      render: (rowData) => {
        const {_id} = rowData;
        if (!_id) {
          return <></>;
        }
        return (
          <>
            <IconButton
              onClick={async () => {
                const data = confirm('Esta seguro de eliminar este dato');
                if (data) {
                  await deleteEndpointPayment({
                    _id, token, idProject,
                  });
                  handleData();
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<PlusOneIcon />}
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          Agregar
        </Button>
      </Box>
      <div className="text-right mb-4">
        {/* Modal para agregar */}
        {/* Modal para actualizar */}
        <div>
          {/* Modal para eliminar */}
        </div>
      </div>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Tabular
          title="Servicios de obtención de datos"
          columns={colums}
          data={data}
          ready={loading}
        />
      </Box>
      <AddEndpointPayment
        idProject={idProject}
        open={open}
        token={token}
        onClose={() => {
          setOpen(false);
        }}
        errorFunction={(error) => {
          if (error?.info) {
            toastr.error(error.info);
          } else {
            toastr.error('Error al procesar la petición');
          }
        }}
        successFunction={() => {
          handleData();
          toastr.success('Se agrego el dato de manera exitosa');
          setOpen(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(EndpointPayment);
