/* eslint-disable prefer-template */
import React, {useState} from 'react';
import {isInvalidNameProject} from 'api-lofty/lib/utils/slugValidation';
import {isInvalidSlug} from 'api-lofty';
import TextField from '@material-ui/core/TextField';
import {
  Dialog, Box, IconButton, Grid,
  Button, FormControlLabel, Switch,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {Alert} from '@material-ui/lab';
import TemplateUserList from './TemplateUserList';
import {generateSlugSampleProject} from '../util/generateSuggestionSlug';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
}));

const AddProjectModal = ({
  handleClose, open, handleForm, templates, setSelectedTemplate,
  projectData,
}) => {
  const classes = useStyle();
  const [authUser, setAuthUser] = useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isEditingLabel, setIsEditingLabel] = React.useState(false);
  const [suggestionSlug, setSuggestionSlug] = React.useState('');
  const verifyCase = (e) => {
    const newValue = e.target.value;
    setSuggestionSlug(e.target.value);
    if (isInvalidSlug(newValue)) {
      setErrorText('Solo letras minusculas. Evitar palabras reservadas');
      setHasError(true);
    } else if (isInvalidNameProject(newValue)) {
      setErrorText('Solo letras minusculas');
      setHasError(true);
    } else {
      setErrorText('');
      setHasError(false);
    }
  };

  const verifySlugValue = (value) => {
    if (isInvalidSlug(value)) {
      setErrorText('Verifique que no tenga espacios/palabras reservadas');
      setHasError(true);
    } else {
      setHasError(false);
      setErrorText('');
    }
  };

  function generateSuggestionSlug(value) {
    if (isEditingLabel) {
      const res = generateSlugSampleProject(projectData, value);
      setSuggestionSlug(res);
      setIsEditingLabel(false);
    }
  }

  function onCloseRefresh() {
    setSuggestionSlug('');
    handleClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="lg" handleClose={() => onCloseRefresh()}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Crea tu Aplicación
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { onCloseRefresh(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={6}>
            <Box component="div" style={{padding: '10px', margin: '5px'}}>
              <form onSubmit={handleForm}>
                <div className="mt-4">
                  <TextField
                    fullWidth
                    name="name"
                    type="text"
                    label="Nombre"
                    variant="outlined"
                    inputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    onFocus={() => setIsEditingLabel(true)}
                    onBlur={(e) => generateSuggestionSlug(e.target.value)}
                  />
                </div>
                <div className="mt-4">
                  <TextField
                    fullWidth
                    name="slug"
                    type="text"
                    label="Slug"
                    variant="outlined"
                    helperText={errorText}
                    onChange={verifyCase}
                    error={hasError}
                    inputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                    value={suggestionSlug}
                    onBlur={(e) => verifySlugValue(e.target.value)}
                  />
                </div>
                <div className="mt-4">
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name="description"
                    type="text"
                    label="Descripción"
                    variant="outlined"
                    inputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                </div>
                <div className="mt-4">
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={authUser}
                        onChange={() => setAuthUser(!authUser)}
                        name="userSystem"
                        color="primary"
                      />
                    )}
                    label="Sistema de Usuario"
                    value={authUser}
                    name="userSystem"
                  />
                </div>
                <div className="mt-4 text-center">
                  <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle}>
                    Guardar
                  </Button>
                </div>
              </form>
            </Box>
          </Grid>
          <Grid className="container-carousel-template-suggestion" item md={6}>
            <Alert severity="info">
              Esta sección es para seleccionar una plantilla para crear el proyecto
            </Alert>
            <br />
            <TemplateUserList
              arrayList={templates?.map((item) => ({
                type: 'template',
                value: item._doc.idTemplate,
                label: item.datGenerator.project.name,
                url: 'https://' + item.datGenerator.project.slug + '.loftyapps.website',
              }))}
              onChange={(value) => {
                setSelectedTemplate(value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddProjectModal;
