import React, {useEffect, useState} from 'react';
import {Container, Grid} from '@material-ui/core';
import DialogFullScreen from '../../components/DialogFullScreen';
import DatabaseContainer from './DatabaseContainer';
import EditorJs from '../../components/EditorJs';

const OneDatabaseModal = ({
  open, handleClose, backoffice, doc, database, token,
}) => {
  const [fieldsLocal, setFieldsLocal] = useState([]);
  const [databaseRelation, setDatabaseRelation] = useState([]);
  const [fieldsRelation, setFieldsRelation] = useState([]);
  useEffect(() => {
    // adquiriendo los campos de la base de datos principal
    const fields = backoffice.fieldDatabases?.filter((field) => field.idDatabase === database._id);
    // obtener las base de datos que esta relacionadas con la base de datos principal
    const fieldsRelation2 = backoffice.fieldDatabases?.filter((field) => (
      field.relationDatabase === database._id
    ));
    const databaseRelation2 = backoffice.databases?.filter((databaseItem) => (
      fieldsRelation2.find((field) => field.idDatabase === databaseItem._id)
    ));
    setFieldsLocal(fields);
    setFieldsRelation(fieldsRelation2);
    setDatabaseRelation(databaseRelation2);
  }, [doc]);
  return (
    <DialogFullScreen open={open} handleClose={handleClose} title={database?.label}>
      <Container>
        <div className="mt-5">
          <h3 className="text-center">
            <b>Información</b>
          </h3>
          <Grid container className="mt-4">
            {
              fieldsLocal.map((field) => (
                <Grid key={field._id} item md={6}>
                  <div>
                    <b>
                      {field.label}
                      :
                    </b>
                  </div>
                  <div className="mt-2">
                    {
                      field.type === 'textrich' ? (
                        <EditorJs
                          backoffice
                          idProject={backoffice.project._id}
                          defaultValue={JSON.parse(doc[field.name])}
                        />
                      ) : (
                        <p>
                          {doc[field.name]}
                        </p>
                      )
                    }
                  </div>
                </Grid>
              ))
            }
          </Grid>
        </div>
        <div className="mt-4">
          <h3 className="text-center">
            <b>
              Relaciones
            </b>
          </h3>
          <div className="mt-4">
            {
              databaseRelation.map((databaseItem) => {
                const field = fieldsRelation.find((field2) => (
                  field2.idDatabase === databaseItem._id
                ));
                if (field) {
                  return (
                    <div key={databaseItem._id} className="mt-4">
                      <div className="mt-4">
                        <DatabaseContainer
                          backoffice={backoffice}
                          database={databaseItem}
                          fieldName={field.name}
                          idDoc={doc._id}
                          token={token}
                          type="filter"
                          id
                        />
                      </div>
                    </div>
                  );
                }
                return <span key={databaseItem._id} />;
              })
            }
          </div>
        </div>
      </Container>
    </DialogFullScreen>
  );
};

export default OneDatabaseModal;
