import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: '10px',
    position: 'relative',
    width: '95%',
    height: '100px',
    boxShadow: '1px 4px #DCDCDC',
    border: '1px solid',
    borderColor: '#DCDCDC',
    borderRadius: '15px',
  },
  labelSection: {
    display: 'flex',
    width: '40%',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    margin: '10px',
    padding: '10px',
  },
  chartSection: {
    display: 'flex',
    width: '60%',
    marginLeft: '5px',
  },
  titleText: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '18px',
    marginLeft: '10px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Consumption',
    },
  },
  scales: {
    x: {
      title: {
        display: false,
        text: 'MB',
      },
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

export default function HardwareUsageChart({
  name, icon, usage, total,
}) {
  const classes = useStyles();

  const chartdata = {
    labels: ['MB Used', ' MB Available'],
    datasets: [
      {
        label: 'Used',
        data: [usage, 0],
        backgroundColor: 'rgba(31, 120, 180, 1)',
      },
      {
        label: 'Available',
        data: [0, (total - usage)],
        backgroundColor: '#6FCF97',
      },
    ],
  };

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.labelSection}>
        <Box component="img" src={icon} alt="icon" />
        <Typography component="h5" variant="h5" className={classes.titleText}>
          {name}
        </Typography>
      </Box>
      <Box component="div" className={classes.chartSection}>
        <Bar height="70" data={chartdata} options={options} />
      </Box>
    </Box>
  );
}
