import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {isInvalidSlug} from 'api-lofty';
import {
  Dialog, Box, IconButton,
  Button, FormControlLabel, Switch,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {generateSlugSample} from '../util/generateSuggestionSlug';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
}));

const AddDatabaseModal = ({
  handleClose, open, handleForm, databaseData,
}) => {
  const classes = useStyle();
  const [isBucket, setIsBucket] = useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isEditingLabel, setIsEditingLabel] = React.useState(false);
  const [suggestionSlug, setSuggestionSlug] = React.useState('');

  const verifyCase = (e) => {
    const newValue = e.target.value;
    setSuggestionSlug(e.target.value);
    if (isInvalidSlug(newValue)) {
      setErrorText('Solo letras minusculas y numeros son aceptados');
      setHasError(true);
    } else {
      setErrorText('');
      setHasError(false);
    }
  };

  const verifySlugValue = (value) => {
    if (isInvalidSlug(value)) {
      setErrorText('Verifique que no tenga espacios/palabras reservadas');
      setHasError(true);
    } else {
      setHasError(false);
      setErrorText('');
    }
  };

  function generateSuggestionSlug(value) {
    if (isEditingLabel) {
      const res = generateSlugSample(databaseData, value);
      setSuggestionSlug(res);
      setIsEditingLabel(false);
    }
  }

  function onCloseRefresh() {
    setHasError(false);
    setErrorText('');
    setSuggestionSlug('');
    handleClose();
  }

  return (
    <Dialog open={open} handleClose={() => onCloseRefresh()}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Crea tu Colección de Datos
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { onCloseRefresh(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{padding: '10px', margin: '5px', width: '500px'}}>
          <form onSubmit={handleForm}>
            <div className="mt-4">
              <TextField
                fullWidth
                name="label"
                type="text"
                label="Etiqueta"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                onFocus={() => setIsEditingLabel(true)}
                onBlur={(e) => generateSuggestionSlug(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <TextField
                fullWidth
                name="name"
                type="text"
                label="Slug"
                variant="outlined"
                helperText={errorText}
                onChange={verifyCase}
                error={hasError}
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                value={suggestionSlug}
                // onChange={(e) => setSuggestionSlug(e.target.value)}
                onBlur={(e) => verifySlugValue(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <TextField
                fullWidth
                multiline
                rows={4}
                name="description"
                type="text"
                label="Descripción"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <FormControlLabel
                control={(
                  <Switch
                    checked={isBucket}
                    onChange={() => setIsBucket((prev) => !prev)}
                  />
                )}
                label="Almacenador de Archivos"
                value={isBucket}
                name="isBucket"
              />
            </div>
            <div className="mt-4 text-center">
              <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle}>
                Guardar
              </Button>
            </div>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddDatabaseModal;
