import React, {useState} from 'react';
import Carousel from 'react-multi-carousel';
import Thumbnail from 'react-webpage-thumbnail';
import 'react-multi-carousel/lib/styles.css';

const TemplateUserList = ({
  onChange, arrayList,
}) => {
  const array = [
    {
      type: 'blank',
      id: 'blank',
    },
  ];
  let array2 = array;
  if (arrayList) {
    array2 = array.concat(arrayList);
  }
  const [listItems] = useState(array2);
  return (
    <div style={{minHeight: '650px', backgroundColor: '#EFF2F6'}}>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots
        responsive={{
          superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 0},
            items: 1,
          },
        }}
        keyBoardControl
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        afterChange={(_number, item) => {
          if (onChange) {
            onChange(listItems[item.currentSlide]?.value);
          }
        }}
        itemClass="carousel-item-padding-40-px"
      >
        {
          listItems.map((item) => {
            if (item.type === 'blank') {
              return (
                <div
                  style={{
                    height: 600, display: 'flex', justifyContent: 'center', alignItems: 'center',
                  }}
                  key={item.id}
                >
                  <div style={{width: '65%'}}>
                    <div style={{width: '100%', height: 500, backgroundColor: 'white'}} />
                    <h3 className="text-center">
                      <b>
                        En Blanco
                      </b>
                    </h3>
                  </div>
                </div>
              );
            }
            return (
              <div
                style={{
                  height: 600, display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                key={item.id}
              >
                <div style={{width: '65%'}}>
                  <div style={{width: '100%', height: 500}}>
                    <Thumbnail
                      url={item.url}
                      height={495}
                      iframeWidth={1920}
                      iframeHeight={1920}
                    />
                  </div>
                  <h3 className="text-center">
                    <b>
                      {item.label}
                    </b>
                  </h3>
                </div>
              </div>
            );
          })
        }
      </Carousel>
    </div>
  );
};

export default TemplateUserList;
