/* eslint-disable max-len */
// routesjs
import React, {useState, useEffect} from 'react';
import {getLoggedUser} from 'api-lofty';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
// import {getLoggedUser} from './api/user.api';
import {addUser, deletetoken} from './actions/auth.actions';
/* import PageEditor from './pages/PageEditor'; */
// Pages
import LoginPageMain from './pages/LoginPage';
import RegisterPageMain from './pages/RegisterPage';
import ForgotPageMain from './pages/ForgotPassword';
import ResetPasswordMain from './pages/ResetPasswordPage';
import LoadingPage from './pages/LoadingPage';
import DashboardHome from './pages/DashboardHome';
import WorkingPrincipalPage from './pages/WorkingPrincipalPage';
// import CreateProject from './pages/CreateProject';
import DatabaseProjectDashboard from './pages/DatabaseProjectDashboard';
import CreateDatabaseDashboard from './pages/DatabaseCreateDashboard';
import LisFieldsDatabase from './pages/ListFieldsDatabase';
import ListEndpointDatabase from './pages/ListEndpointsDatabase';
import Error404 from './pages/Error404';
import TeamProjectDashboard from './pages/TeamProjectDashboard';
import ActivitiesProject from './pages/ActivitiesProject';
import ActivitiesDatabase from './pages/ActivitiesDatabase';
import ConfigProjectDashboard from './pages/ConfigProjectDashboard';
import UpdateProfile from './pages/UpdateProfileUser';
import PageProjectDashboard from './pages/PageProjectDashboard';
import UsersProjectList from './pages/UsersProjectList';
import UserProjectFieldsList from './pages/UserProjectFieldsList';
import ListRolesUserProject from './pages/ListRolesUserProject';
// import ListPermissionDatabase from './pages/ListPermissionDatabase';
import DeploymentProject from './pages/DeploymentProject';
import ApiKeyProject from './pages/ApiKeyProject';
import DocDatabase from './pages/DocDatabase';
import DocumentationUserProject from './pages/DocumentationUserProject';
import UserProjectSettings from './pages/UserProjectSettings';
import InvitationPage from './pages/InvitationPage';
import SimulatorDataDatabasePage from './pages/SimulatorDataDatabasePage';
import SimulatorUsersDashboard from './pages/SimulatorUsersDashboard';
import PluginsProjectPage from './pages/PluginsProjectPage';
import DocPluginProject from './pages/DocPluginProject';
import SimulatorPluginPage from './pages/SimulatorPluginPage';
import SettingsPluginProjectPage from './pages/SettingsPluginProjectPage';
import EventPluginProject from './pages/EventPluginProject';
import MailPluginProject from './pages/MailPluginProject';
import PluginVersionPdf from './pages/PluginVersionPdf';
import PluginPdfField from './pages/PluginPdfField';
import PluginNotificationData from './pages/PluginNotificationData';
import PreDeploymentDashboard from './pages/PreDeploymentDashboard';
import MedDeployView from './pages/MedDeployView';
import ActivitiesUser from './pages/ActivitiesUser';
import ProjectAssets from './pages/ProjectAssets';
import CustomServices from './pages/CustomServices';
import ExportSourceCode from './pages/ExportSourceCode';
import Backoffice from './backoffice/Initial';
import ListTemplates from './pages/ListTemplates';
import CheckoutTemplate from './pages/CheckoutTemplate';
import PaymentLoading from './pages/PaymentLoading';
import LogPaymentProject from './pages/LogPaymentProject';
import AddPluginPage from './pages/AddPluginPage';
import VerifyEmail from './pages/VerifyEmail';
import MethodPayment from './pages/MethodPayment';
import IntroDeployedView from './pages/IntroDeployedView';
import LoadingProcess from './pages/LoadingProcess';
import RenderCustomEndpointBlockly from './pages/RenderCustomEndpointBlockly';
import CalendarPage from './pages/CalendarPage';
import RedeemCreditsPage from './pages/RedeemCreditsPage';

import PaymentTemplateSuccess from './pages/PaymentTemplateSuccess';
import Affiliates from './pages/Affiliates';
import AffiliateDashboard from './pages/AffiliateDashboard';
import StorageChart from './components/StorageChart';
import UserStatistics from './components/UserStatistics';
import NotificationManager from './backoffice/pages/NotificationManager';
import WeeklyVisitsChart from './components/WeeklyVisitsChart';
import CronJobDashboard from './pages/CronJobDashboard';
import CustomCronJob from './pages/CustomCronJob';

// import DeployDashboard from './pages/DeployDashboard';

// |-- New page -- |
// import MaintenancePage from './pages/Maintenance';
// comentario

const App = ({token, addUserApp, deletetokenApp}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getLoggedUser({token}).then((res) => {
      addUserApp(res.user);
      setLoading(false);
    }).catch(() => {
      deletetokenApp();
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginPageMain} />
        <Route exact path="/register" component={RegisterPageMain} />
        <Route exact path="/forgot" component={ForgotPageMain} />
        <Route exact path="/reset-password/:token" component={ResetPasswordMain} />
        <Route exact path="/verify-email/:idVerify" component={VerifyEmail} />
        {/* Dashboard principal */}
        <Route exact path="/dashboard" component={DashboardHome} />
        <Route exact path="/dashboard/templates" component={ListTemplates} />
        <Route exact path="/dashboard/templates/:idTemplate" component={CheckoutTemplate} />
        <Route exact path="/dashboard/invitations" component={InvitationPage} />
        <Route exact path="/dashboard/activities" component={WorkingPrincipalPage} />
        <Route path="/dashboard/redeem" component={RedeemCreditsPage} />
        <Route exact path="/dashboard/calendar" component={CalendarPage} />
        <Route exact path="/dashboard/affiliates" component={Affiliates} />
        <Route exact path="/dashboard/affiliates/main" component={AffiliateDashboard} />
        <Route exact path="/dashboard/template/buy/success" component={PaymentTemplateSuccess} />
        {/* <Route exact path="/dashboard/infocredit" component={CreditCardDashboard} /> */}
        {/* <Route exact path="/dashboard/create/project" component={CreateProject} /> */}
        <Route exact path="/dashboard/profile" component={UpdateProfile} />
        {/* End Dashboard */}
        {/* Dashboard proyectos */}
        <Route exact path="/dashboard/project/:idProject" component={PreDeploymentDashboard} />
        <Route exact path="/dashboard/project/:idProject/database" component={DatabaseProjectDashboard} />
        <Route exact path="/dashboard/project/:idProject/predeployment" component={PreDeploymentDashboard} />
        <Route exact path="/dashboard/project/:idProject/deployed" component={IntroDeployedView} />
        <Route exact path="/dashboard/project/:idProject/process/:id" component={LoadingProcess} />
        {/* INTERCHANGABLE DEPLOYED VIEWS */}
        <Route exact path="/dashboard/project/:idProject/active" component={MedDeployView} />
        <Route exact path="/dashboard/project/:idProject/team" component={TeamProjectDashboard} />
        <Route exact path="/dashboard/project/:idProject/methodpayment" component={MethodPayment} />
        <Route exact path="/dashboard/project/:idProject/logpayment" component={LogPaymentProject} />
        <Route exact path="/dashboard/project/:idProject/page" component={PageProjectDashboard} />
        <Route exact path="/dashboard/project/:idProject/users" component={UsersProjectList} />
        <Route exact path="/dashboard/project/:idProject/plugins" component={PluginsProjectPage} />
        <Route exact path="/dashboard/project/:idProject/plugins/addplugins" component={AddPluginPage} />
        <Route exact path="/dashboard/project/:idProject/activities" component={ActivitiesProject} />
        <Route exact path="/dashboard/project/:idProject/config" component={ConfigProjectDashboard} />
        <Route exact path="/dashboard/project/:idProject/deployment" component={DeploymentProject} />
        <Route exact path="/dashboard/project/:idProject/payment/:idPayment" component={PaymentLoading} />
        <Route exact path="/dashboard/:idProject/payment/:idPayment" component={PaymentLoading} />
        <Route exact path="/dashboard/project/:idProject/create/database" component={CreateDatabaseDashboard} />
        <Route exact path="/dashboard/project/:idProject/apikeytesting" component={ApiKeyProject} />
        <Route exact path="/dashboard/project/:idProject/assets" component={ProjectAssets} />
        <Route exact path="/dashboard/project/:idProject/exportcode" component={ExportSourceCode} />
        <Route exact path="/dashboard/project/:idProject/cronjobs" component={CronJobDashboard} />
        <Route exact path="/dashboard/project/:idProject/customcronjob" component={CustomCronJob} />
        {/* End del dashboard */}
        {/* Dashboard de la base de datos */}
        <Route exact path="/dashboard/project/:idProject/database/:idDatabase" component={LisFieldsDatabase} />
        <Route exact path="/dashboard/project/:idProject/database/:idDatabase/endpoints" component={ListEndpointDatabase} />
        {/* <Route exact path="/dashboard/project/:idProject/database/:idDatabase/permission" component={ListPermissionDatabase} /> */}
        <Route exact path="/dashboard/project/:idProject/database/:idDatabase/customservices" component={CustomServices} />
        <Route exact path="/dashboard/project/:idProject/database/:idDatabase/documentation" component={DocDatabase} />
        <Route exact path="/dashboard/project/:idProject/database/:idDatabase/data" component={SimulatorDataDatabasePage} />
        <Route exact path="/dashboard/project/:idProject/database/:idDatabase/activities" component={ActivitiesDatabase} />
        <Route exact path="/dashboard/project/:idProject/database/:idDatabase/customendpoint/:idEndpoint" component={RenderCustomEndpointBlockly} />
        {/* Fin del dashboard */}
        {/* Inicio del dashboard los Usuarios del Proyecto */}
        <Route exact path="/dashboard/project/:idProject/users/:idUserProject/fields" component={UserProjectFieldsList} />
        <Route exact path="/dashboard/project/:idProject/users/:idUserProject/roles" component={ListRolesUserProject} />
        <Route exact path="/dashboard/project/:idProject/users/:idUserProject/documentation" component={DocumentationUserProject} />
        <Route exact path="/dashboard/project/:idProject/users/:idUserProject/data" component={SimulatorUsersDashboard} />
        <Route exact path="/dashboard/project/:idProject/users/:idUserProject/settings" component={UserProjectSettings} />
        <Route exact path="/dashboard/project/:idProject/users/:idUserProject/activities" component={ActivitiesUser} />
        {/* Fin del dashboard de los Usuarios del proyecto */}
        {/* Inicio del Dashboard para Plugins */}
        <Route exact path="/dashboard/project/:idProject/plugins/:idPluginsProject/documentation" component={DocPluginProject} />
        <Route exact path="/dashboard/project/:idProject/plugins/:idPluginsProject/events" component={EventPluginProject} />
        <Route exact path="/dashboard/project/:idProject/plugins/:idPluginsProject/notificationsemail" component={PluginNotificationData} />
        <Route exact path="/dashboard/project/:idProject/plugins/:idPluginsProject/mail" component={MailPluginProject} />
        <Route exact path="/dashboard/project/:idProject/plugins/:idPluginsProject/data" component={SimulatorPluginPage} />
        <Route exact path="/dashboard/project/:idProject/plugins/:idPluginsProject/versionspdf" component={PluginVersionPdf} />
        <Route exact path="/dashboard/project/:idProject/plugins/:idPluginsProject/fieldspdf" component={PluginPdfField} />
        <Route exact path="/dashboard/project/:idProject/plugins/:idPluginsProject/settings" component={SettingsPluginProjectPage} />
        {/* Fin del Dashboard para Plugins */}
        {/* Backoffice */}
        <Route exact path="/dashboard/project/:idProject/backoffice" component={Backoffice} />
        <Route exact path="/dashboard/project/:idProject/backoffice/:database" component={Backoffice} />
        <Route exact path="/dashboard/project/:idProject/backoffice" component={StorageChart} />
        <Route exact path="/dashboard/project/:idProject/backoffice" component={UserStatistics} />
        <Route exact path="/dashboard/project/:idProject/backoffice" component={WeeklyVisitsChart} />
        <Route exact path="/dashboard/project/:idProject/backoffice/notifications" component={NotificationManager} />
        {/* Fin del dashboard editor */}
        {/* Pagina de 404 */}
        {/* <Route exact path="*" component={MaintenancePage} /> */}
        <Route exact path="*" component={Error404} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addUserApp: (user) => dispatch(addUser(user)),
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
