import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import workingImage from '../assets/incomming/wip.png';
import logo from '../assets/incomming/logo_w2.png';

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(32, 21, 73, 1)',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  titleStyle: {
    fontFamily: 'inherit',
    textAlign: 'center',
    fontWeight: 700,
    color: '#FFFFFF',
    fontSize: '88px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {fontSize: '80px'},
    [theme.breakpoints.down('md')]: {fontSize: '78px'},
    [theme.breakpoints.up('sm')]: {fontSize: '72px'},
    [theme.breakpoints.down('xs')]: {fontSize: '56px'},
  },
  wipStyle: {
    width: '60%',
  },
  imgStyle: {
    position: 'relative',
    width: 'auto',
    top: '-25px',
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]: {width: '50px'},
  },
}));

const Working = () => {
  const classes = useStyle();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.titleStyle}>
        Muy Pronto
      </Box>
      <Box component="img" className={classes.wipStyle} alt="muy pronto" src={workingImage} />
      <Box component="img" className={classes.imgStyle} alt="logo" src={logo} />
    </Box>
  );
};

export default Working;
