/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
  Box, Tabs, Tab,
} from '@material-ui/core';
import {
  getOneProject,
} from 'api-lofty';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import FormPayment from '../components/MethodPayments/FormPayment';
import ListMethodPayment from '../components/MethodPayments/ListMethodPayment';
import EndpointPayment from '../components/MethodPayments/EndpointPayment';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const MethodPayment = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject} = params;
  const [valueTab, setValueTab] = useState(0);
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      setProject(resProject.project);
    } catch (error) {
      // code
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Tabs value={valueTab} onChange={(e, newValue) => setValueTab(newValue)} aria-label="tabs pasarela de pago">
            <Tab label="Solicitar pasarela de pago" {...a11yProps(0)} />
            <Tab label="Metodo de pago" {...a11yProps(1)} />
            <Tab label="Servicios para obtener datos" {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Box>
      <Box>
        <Box
          component="div"
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            height: 'auto',
            p: 1,
            m: 1,
          }}
        >
          <TabPanel value={valueTab} index={0}>
            <FormPayment
              token={token}
              idProject={idProject}
            />
          </TabPanel>
          <TabPanel value={valueTab} index={1}>
            <ListMethodPayment
              history={history}
              idProject={idProject}
              token={token}
            />
          </TabPanel>
          <TabPanel value={valueTab} index={2}>
            <EndpointPayment
              history={history}
              idProject={idProject}
              token={token}
            />
          </TabPanel>
        </Box>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(MethodPayment);
