import React from 'react';
// import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box, Button} from '@material-ui/core';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import cardImage from '../assets/creditCard/cardplaceholder.png';
import CheckIcon from '../assets/creditCard/checkicon.svg';

const useStyles = makeStyles(() => ({
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '34%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  initialAreaSection: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px',
    paddingBottom: '15px',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  titleSectionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  initialAreaTypography: {
    fontWeight: 700,
    fontSize: '17px',
    color: 'rgba(41, 33, 81, 1)',
  },
  sectorItems: {
    width: '80%',
    border: '1px solid rgba(20, 86, 158, 1)',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleSectorItems: {
    fontWeight: 500,
    fontSize: '12px',
    color: 'rgba(41, 33, 81, 1)',
  },
  cardsContainer: {
    width: '100%',
    height: '150px',
    overflowY: 'scroll',
  },
  cardNumberStyle: {
    fontSize: '12px',
    color: 'rgba(153, 153, 153, 1)',
    fontWeight: 500,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  buttonSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  buttonStyle: {
    borderRadius: '10px',
    backgroundColor: 'rgba(217, 217, 217, 1)',
    color: 'rgba(41, 33, 81, 1)',
    fontWeight: 700,
    fontSize: '16px',
  },
  redirectContainer: {
    width: '100%',
    display: 'flex',
  },
  redirectButtonStyle: {
    backgroundColor: 'transparent',
    color: 'rgba(23, 145, 254, 1)',
    fontWeight: 500,
    fontSize: '12px',
  },
  icon: {
    borderRadius: '5px',
    width: 16,
    height: 16,
    border: '1px solid rgba(23, 145, 254, 1)',
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: `url(${CheckIcon})`,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: `url(${CheckIcon})`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

export default function ExportCodeCreditCard({handlePay}) {
  const classes = useStyles();
  /*
  const [valueHandler, setValueHandler] = useState('');
  const handleCheck = (event) => {
    setValueHandler(event.target.value);
  };
  */

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.initialAreaSection}>
        <Box className={classes.titleSectionContainer}>
          <Typography className={classes.initialAreaTypography}>
            Metodo de Pago
          </Typography>
        </Box>
      </Box>
      <Box className={classes.sectorItems}>
        {/* <Typography className={classes.titleSectorItems}>Tarjeta de Credito</Typography>
        <Box className={classes.cardsContainer}>
          <RadioGroup name="cardSelection" value={valueHandler} onChange={handleCheck}>
            {data.map((index) => (
              <Box className={classes.itemContainer}>
                <Radio
                  value={index._id}
                  checkedIcon={<span className={classes.checkedIcon} />}
                  icon={<span className={classes.icon} />}
                />
                <Box component="img" src={cardImage} alt="icon" width={30} height={30} />
                <Typography className={classes.cardNumberStyle}>
                  {index.lastNumber}
                </Typography>
              </Box>
            ))}
          </RadioGroup>
        </Box>
        <Box className={classes.redirectContainer}>
          <Button
            className={classes.redirectButtonStyle}
            component={Link}
            to="/dashboard/infocredit"
          >
            + Agregar Tarjeta
          </Button>
        </Box> */}
        <Box
          component="img"
          src="https://placetopay-static-prod-bucket.s3.us-east-2.amazonaws.com/bancatlan-hn/header.svg"
          alt="Banco Atlantida"
          style={{
            width: 200,
          }}
        />
      </Box>
      <Box className={classes.buttonSection}>
        <Button className={classes.buttonStyle} onClick={() => handlePay()}>
          PROCEDER A PAGAR
        </Button>
      </Box>
    </Box>
  );
}
