/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {
  Table, TableBody, TableCell, TableHead,
  TableContainer, TableRow, Paper,
} from '@material-ui/core';

const TablePaginated = ({
  data, fields, limit, totalRegister,
  /* handleDataCurrentPage, */ handleNext, handlePrevius,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [total, setTotal] = useState();
  useEffect(() => {
    const totalPage = totalRegister / limit;
    setTotal(parseFloat(totalPage.toFixed(0)));
    const newPages = [];
    for (let index = 0; index < totalPage; index += 1) {
      newPages.push({
        label: `${index + 1}`,
        number: index,
      });
    }
    setPages(newPages);
  }, [limit, totalRegister]);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table style={{minWidth: 650}}>
          <TableHead>
            <TableRow>
              {
                fields?.map(({title, field}) => (
                  <TableCell key={field}>
                    {title}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data?.map((item) => (
                <TableRow key={item._id}>
                  {
                    fields?.map((field) => {
                      if (field.render) {
                        return (
                          <TableCell key={field.field}>
                            {field.render(item)}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={field.field}>
                          {item[field.field]}
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <nav className="mt-5 flex justify-end">
        <ul className="inline-flex -space-x-px text-base h-10">
          <li>
            {
              currentPage > 0 ? (
                <button
                  type="button"
                  onClick={() => {
                    if (handlePrevius && currentPage > 0) {
                      handlePrevius(currentPage - 1);
                    }
                    setCurrentPage(currentPage - 1);
                  }}
                  className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Anterior
                </button>
              ) : <></>
            }
          </li>
          {
            /* pages.map((item) => {
              if (item.number === currentPage) {
                return (
                  <li>
                    <button
                      type="button"
                      aria-current="page"
                      className="flex items-center justify-center px-4 h-10 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    >
                      {item.label}
                    </button>
                  </li>
                );
              }
              return (
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      if (handleDataCurrentPage) {
                        handleDataCurrentPage(item.number);
                      }
                      setCurrentPage(item.number);
                    }}
                    className="flex items-center justify-center px-4 h-10 text-blue-600 border border-gray-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:text-white"
                  >
                    {item.label}
                  </button>
                </li>
              );
            }) */
          }
          <li>
            {
              currentPage < total - 1 ? (
                <button
                  type="button"
                  onClick={() => {
                    if (handleNext) {
                      handleNext(currentPage + 1);
                    }
                    setCurrentPage(currentPage + 1);
                  }}
                  className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Siguiente
                </button>
              ) : <></>
            }
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TablePaginated;
