import React from 'react';
import Swal from 'sweetalert2';
import {
  TextField, Box, Hidden, Button,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {resetPassword} from 'api-lofty';
import {Link} from 'react-router-dom';
// import {resetPassword} from '../api/reset.api';
import AuthLayout from '../layouts/AuthLayout';
import imgSubcontainer from '../img/bg_pw/pass_1.png';

const useStyle = makeStyles((theme) => ({
  header1: {
    fontWeight: 'bold',
    fontSize: '42px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    color: '#ffffff',
    position: 'absolute',
    right: '100px',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]: {fontSize: '24px', right: '80px'},
  },
  header2: {
    fontSize: '39px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    color: '#ffffff',
    position: 'absolute',
    right: '200px',
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]: {fontSize: '24px', right: '130px'},
  },
  textBasic1: {
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    marginTop: '30px',
    marginBottom: '40px',
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {flexDirection: 'column'},
  },
  boxContainerImage: {
    flex: 2,
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {height: 'auto'},
  },
  imgStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  imgStyle2: {
    width: '30%',
    zIndex: 1,
  },
  imgStyle3: {
    width: '50%',
    zIndex: 1,
  },
  textAreaImage: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {display: 'none'},
  },
}));

const ResetPasswordMain = ({match, history}) => {
  const classes = useStyle();
  return (
    <AuthLayout>
      <Box className={classes.containerMain}>
        <Box className={classes.boxContainerImage}>
          <Hidden smDown>
            <Box
              component="div"
              style={{
                position: 'absolute', zIndex: 1, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '30px',
              }}
            >
              <Box
                component="img"
                src={imgSubcontainer}
                alt="ESE"
                style={{
                  position: 'absolute', width: '60%', height: '60%', zIndex: 2, marginLeft: '15%', objectFit: 'contain',
                }}
              />
            </Box>
          </Hidden>
          <Hidden mdUp xsDown>
            <Box component="img" src={imgSubcontainer} alt="ESE" className={classes.imgStyle2} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={imgSubcontainer} alt="ESE" className={classes.imgStyle3} />
          </Hidden>
        </Box>
        <Box style={{height: '100%', flex: 1}}>
          <Box
            component="div"
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box component="div" className="lofty-container-centered-password">
              <h2
                style={{
                  fontSize: '2rem',
                  marginBottom: '20px',
                  fontWeight: 'bold',
                  fontFamily: ['Nunito', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
                }}
                className="text-center mb4"
              >
                Cambiar Contraseña
              </h2>
              <Box
                component="form"
                onSubmit={async (e) => {
                  const {params} = match;
                  const {token} = params;
                  e.preventDefault();
                  try {
                    await resetPassword({
                      password: e.target.password.value,
                      confirmPassword: e.target.confirmPassword.value,
                      token,
                    });
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Se reseteo la contraseña de manera exitosa.',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    history.push('/');
                  } catch (error) {
                    if (error.info) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: error.info,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Lo sentimos no se pudo resetear la contraseña',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  }
                }}
              >
                <Box component="div">
                  <TextField style={{marginBottom: '20px'}} fullWidth name="password" type="password" label="Contraseña" variant="standard" />
                </Box>
                <Box component="div" className="mt-4">
                  <TextField fullWidth name="confirmPassword" type="password" label="Confirmar Contraseña" variant="standard" />
                </Box>
                <Box component="div" style={{marginBottom: '40px', marginTop: '80px'}} className="text-center mt-4">
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Restablecer Contraseña
                  </Button>
                </Box>
              </Box>
              <Box component="div" className="text-center mt-4">
                <Box component="p" style={{marginBottom: '40px'}}>
                  <Link style={{color: '#201549'}} to="/">
                    Regresar a Ingresar
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ResetPasswordMain;
