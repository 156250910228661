/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import toastr from 'toastr';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import {getOneProject, verifyPaymentBancoAtlantida} from 'api-lofty';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const PaymentLoading = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject, idPayment} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const [error, setError] = useState(false);
  const [textLoading, setTextLoading] = useState('Verificando el pago');
  const classes = useStyle();

  const getData = async () => {
    try {
      const response = await getOneProject({token, idProject});
      setProject(response.project);
      setLabelUrl(`proyectos/${response.project.name}/Despliegue`);
      const resPayment = await verifyPaymentBancoAtlantida({
        idLogPayment: idPayment, token, idProject,
      });
      if (resPayment.data.deployment.type === 'wating') {
        setTextLoading('Iniciando configuración de despliegue');
        history.push(`/dashboard/project/${idProject}/process/${resPayment.data.deployment._id}`);
      } else if (resPayment.data.deployment.type === 'deployed') {
        toastr.success(`Se realizo el despliegue de manera exitosa del proyecto ${response.data.name}`);
        history.push(`/dashboard/${idProject}`);
      }
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Container>
        {
          error ? (
            <Alert severity="error">
              Error al procesar la verificación de pago,
              por favot contactar al correo argesware@gmail.com para verificar su caso.
            </Alert>
          ) : (
            <LoadingPage textLoading={textLoading} />
          )
        }
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PaymentLoading);
