/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-alert */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
// import PlusOneIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import {updateDeploymentProject} from 'api-lofty/lib/api/deploymentRequest.api';
import toastr from 'toastr';
import {listDatabases, getOneProject, getSizeStorageDeployment} from 'api-lofty';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
  CategoryScale, LinearScale, BarElement, Title,
} from 'chart.js';
import {Bar, Doughnut} from 'react-chartjs-2';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import storageImage from '../img/deploy/Storage.png';
import updateImage from '../img/deploy/deploy_update.png';
import upgradeImage from '../img/deploy/web_design.png';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
);

const barchartoptions = {
  responsive: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  historyDivContainer: {
    height: '400px',
    backgroundColor: '#fff',
    width: '100%',
    padding: '5px',
    margin: '10px',
  },
  historyTileSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '95%',
  },
  historyOptionsSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title1HistorySection: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  title2HistorySection: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  subtitleSection: {
    borderTop: '1px solid',
    borderColor: 'rgba(41, 33, 81, 1)',
    display: 'flex',
    justifyContent: 'center',
  },
  subtitleHistory: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
    [theme.breakpoints.down('xs')]: {fontSize: '16px'},
  },
  upgradePlanSection: {
    height: '200px',
    display: 'flex',
    marginLeft: '10px',
    backgroundColor: 'rgba(166, 206, 227, 1)',
    justifyContent: 'center',
    width: '95%',
  },
  doughnutSection: {
    width: '95%',
    padding: '20px',
    margin: '10px',
    backgroundColor: '#fff',
    height: '400px',
  },
  doughnutArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  doughnutTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  doughnutInnerArea: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  doughnutInnerText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '40px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  doughnutInnerSectionText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '20px',
  },
  doughnutInnerText2: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '24px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
    [theme.breakpoints.down('xs')]: {fontSize: '22px'},
  },
  title1DoughnutSection: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '21px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  chartsSection: {
    display: 'flex',
    width: '100%',
    margin: '10px',
    height: '200px',
  },
  deployArea: {
    width: '100%',
    height: '100%',
    padding: '15px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: 1,
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  textMiddleDoughnutArea: {
    position: 'absolute',
    width: '100%',
  },
}));

const IntroDeployView = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const [onUseStorage, setOnUseStorage] = useState(0);
  const [usePercentage, setUsePercentage] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const {params} = match;
  const {idProject} = params;
  const classes = useStyle();
  const chartRange = 'Semana del 1 al 7 de Agosto';
  const chartdata = {
    labels: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    datasets: [
      {
        label: 'Queries',
        data: [1, 2, 4, 8, 20, 14, 17],
        backgroundColor: 'rgba(41, 178, 239, 1)',
      },
    ],
  };

  const doughnutdata = {
    labels: ['En Uso (GB)', 'Disponible (GB) '],
    datasets: [
      {
        label: 'Almacenamiento',
        data: [onUseStorage, (totalStorage - onUseStorage)],
        backgroundColor: [
          'rgba(20, 73, 153, 0.7)',
          'rgba(217, 217, 217, 0.7)',
        ],
        borderColor: [
          'rgba(20, 73, 153, 1)',
          'rgba(217, 217, 217, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const getData = async () => {
    try {
      // FOR TESTING ONLY
      // setOnUseStorage(2.7);
      // setTotalStorage(5);
      // const totalPercent = (2.7 / 5) * 100;
      // setUsePercentage(totalPercent);
      const getProject = await getOneProject({token, idProject});
      setProject(getProject.project);
      const getDatabases = await listDatabases({token, idProject});
      setData(getDatabases.data);
      const getStorageData = await getSizeStorageDeployment({token, idProject});
      setOnUseStorage(getStorageData.data.storage);
      setTotalStorage(getStorageData.data.total);
      const totalPercent = (getStorageData.data.storage / getStorageData.data.total) * 100;
      setUsePercentage(totalPercent);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {`/proyectos/${project?.name}`}
      </Typography>
      <Box
        component="div"
        sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Grid container className="mt-6">
          <Grid item md={8}>
            <Box component="div" className={classes.doughnutSection}>
              <Box component="div" className={classes.doughnutTitle}>
                <Box component="img" src={storageImage} alt="storage" />
                <Typography component="h5" variant="h5" className={classes.title1DoughnutSection}>
                  Almacenamiento
                </Typography>
              </Box>
              {/* <h2 className="text-center">
                Muy Pronto
              </h2> */}
              <Box component="div" className={classes.doughnutArea}>
                <Box component="div" style={{width: '250px', height: '250px'}}>
                  <Doughnut
                    data={doughnutdata}
                    options={{
                      circumference: 90 * Math.PI,
                      rotation: 70 * Math.PI,
                      cutout: '80%',
                    }}
                  />
                </Box>
                <Box component="div" className={classes.textMiddleDoughnutArea}>
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <Typography component="h5" variant="h5" className={classes.doughnutInnerText}>
                      {`${usePercentage} %`}
                    </Typography>
                    <Typography component="h5" variant="h5" className={classes.doughnutInnerSectionText}>
                      {`${onUseStorage} GB de ${totalStorage} GB`}
                    </Typography>
                  </Box>
                </Box>
                <Box component="div" className={classes.doughnutInnerArea}>
                  <Typography component="h5" variant="h5" className={classes.doughnutInnerText2}>
                    En Uso
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box component="div" className={classes.upgradePlanSection}>
              <Box component="img" src={upgradeImage} alt="upgrade" />
              <Box
                component="div"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 'auto',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  style={{
                    color: '#fff',
                    fontWeight: 'bold',
                    backgroundColor: 'rgba(41, 33, 81, 1)',
                    fontFamily: [
                      'Nunito',
                      'Roboto',
                      'Helvetica Neue',
                      'Arial',
                      'sans-serif',
                    ].join(','),
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    alert('Muy Pronto');
                  }}
                >
                  Mejorar Plan
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box component="div" className={classes.historyDivContainer}>
              <Box component="div" className={classes.historyTileSection}>
                <Typography component="h5" variant="h5" className={classes.title2HistorySection}>
                  Actividad
                </Typography>
              </Box>
              {/* <Bar height="200" data={chartdata} options={barchartoptions} /> */}
              <h2 className="text-center">
                Muy Pronto
              </h2>
              <Box component="div" className={classes.historyOptionsSection}>
                {/* <Box component="div" className={classes.subtitleSection}>
                  <Typography variant="subtitle1" className={classes.subtitleHistory}>
                    {chartRange}
                  </Typography>
                </Box> */}
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'auto',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{
                      margin: '10px',
                      color: '#fff',
                      fontWeight: 'bold',
                      backgroundColor: 'rgba(41, 33, 81, 1)',
                      fontFamily: [
                        'Nunito',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                        'sans-serif',
                      ].join(','),
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      history.push(`/dashboard/project/${idProject}/deployment`);
                    }}
                  >
                    Cambiar Plan
                  </Button>
                </Box>

                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'auto',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{
                      margin: '10px',
                      color: '#fff',
                      fontWeight: 'bold',
                      backgroundColor: 'rgba(41, 178, 239, 1)',
                      fontFamily: [
                        'Nunito',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                        'sans-serif',
                      ].join(','),
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      history.push(`/dashboard/project/${project._id}/backoffice`);
                    }}
                  >
                    Backoffice
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box component="div" className={classes.chartsSection}>
              <Box component="div" className={classes.deployArea}>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Box component="img" src={updateImage} alt="update" />
                </Box>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'auto',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      backgroundColor: 'rgba(217, 217, 217, 1)',
                      fontFamily: [
                        'Nunito',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                        'sans-serif',
                      ].join(','),
                      textTransform: 'none',
                    }}
                    onClick={async () => {
                      try {
                        const res = await updateDeploymentProject({
                          token, idProject,
                        });
                        if (res.data) {
                          toastr.success('Se genero exitosamente el proceso para actualizar el sitio');
                          history.push(`/dashboard/project/${idProject}/process/${res.data._id}`);
                        } else {
                          alert('Lo sentimos no se pudo generar el proceso de actualización, por favor contactar al correo de argesware@gmail.com para solucionar el problema.');
                        }
                      } catch (error) {
                        // console.log(error);
                        if (error?.info) {
                          toastr.error(error.info);
                        } else {
                          toastr.error('Ocurrio un error inesperado por favor intente más tarde');
                        }
                      }
                    }}
                  >
                    Actualizar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(IntroDeployView);
