/* eslint-disable react/function-component-definition */
import React from 'react';
import {
  Dialog, Box,
  Button, Typography,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import emailIcon from '../img/asset_icons/mail_verify.png';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(243, 243, 243, 1)',
    margin: '10px',
    padding: '15px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '5px',
  },
  buttonAreaContainer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    width: '200px',
    fontWeight: 'bold',
  },
}));

const VerifyEmailModal = ({
  open, onClose, handleResendEmail, logoutSession,
}) => {
  const classes = useStyle();
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Verificar Correo
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{margin: '15px', width: 'auto', padding: '5px'}}>
          <Box className={classes.messageContainer}>
            <Box component="img" src={emailIcon} width="50px" />
            <Typography
              style={{
                position: 'relative',
                bottom: '5px',
                color: 'rgba(32, 21, 73, 1)',
                fontWeight: '600',
                fontSize: '16px',
              }}
            >
              Es Necesario que verifique su correo para utilizar la plataforma.
            </Typography>
            <Box
              style={{
                width: '100%',
                height: '1px',
                backgroundColor: 'rgba(41, 178, 239, 1)',
                margin: '10px',
              }}
            />
            <Typography
              style={{
                position: 'relative',
                color: 'rgba(32, 21, 73, 1)',
                fontWeight: '400',
                fontSize: '14px',
                fontStyle: 'italic',
              }}
            >
              En caso de estar verificado, dar click al boton Refrescar Pagina.
            </Typography>
          </Box>
          <Box className={classes.buttonAreaContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              onClick={handleResendEmail}
            >
              Reenviar Correo
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              onClick={() => window.location.reload(false)}
            >
              Refrescar Pagina
            </Button>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonStyle}
              onClick={() => logoutSession()}
            >
              Cerrar Sesion
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyEmailModal;
