import React, {useState, useEffect} from 'react';
import toastr from 'toastr';
import {
  Paper, IconButton, Button, TextField, Dialog,
  DialogContent, DialogTitle, MenuItem, Box,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from '@material-ui/core/styles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import {
  deleteMethodPaypalPlugin, listMethodPaypalPlugin,
  listDatabases,
  createMethodPaypalPlugin,
  listPage,
} from 'api-lofty';
import Tabular from '../../../components/Tabular';
// comentario de prueba
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 100,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const AddMethodPaypal = ({
  open, onClose, successFunction, errorFunction,
  idProject, token, idPluginProject, idPaypalPlugin,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [pages, setPages] = useState([]);
  useEffect(async () => {
    try {
      const resDatabase = await listDatabases({token, idProject});
      const resPages = await listPage({
        idProject, token,
      });
      setPages(resPages.data);
      setDatabases(resDatabase.data);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Método de Pago
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await createMethodPaypalPlugin({
                token,
                idProject,
                idPluginProject,
                idDatabase: e.target.idDatabase.value,
                type: e.target.type.value,
                label: e.target.label.value,
                slug: e.target.slug.value,
                idPaypalPlugin,
                cancelIdPage: e.target.cancelIdPage.value,
                successIdPage: e.target.successIdPage.value,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              name="slug"
              type="text"
              label="Slug"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField select fullWidth name="type" type="text" label="Tipo" variant="standard">
              <MenuItem value="general">
                Normal
              </MenuItem>
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="idDatabase"
              type="text"
              label="Colección de datos"
              variant="standard"
            >
              {
                databases.map(({_id, label}) => (
                  <MenuItem value={_id} key={_id}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="cancelIdPage"
              type="text"
              label="Página de Pago Exitoso"
              variant="standard"
            >
              {
                pages.map(({_id, label}) => (
                  <MenuItem value={_id} key={_id}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="successIdPage"
              type="text"
              label="Pagina de Pago Cancelado"
              variant="standard"
            >
              {
                pages.map(({_id, label}) => (
                  <MenuItem value={_id} key={_id}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const ListMethodPaypal = ({
  token, idProject, idPluginProject, idPaypalPlugin,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const handleData = async () => {
    try {
      if (idPaypalPlugin) {
        const resListMethodPaypal = await listMethodPaypalPlugin({
          idPaypalPlugin, idPluginProject, idProject, token,
        });
        setData(resListMethodPaypal.data);
      }
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleData();
  }, []);
  return (
    <Paper className="py-4 px-2">
      <div className="text-right my-4">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => setOpen(true)}
        >
          Agregar
        </Button>
      </div>
      <AddMethodPaypal
        idProject={idProject}
        token={token}
        open={open}
        onClose={() => setOpen(false)}
        successFunction={() => {
          toastr.success('Se agrego el dato de manera exitosa');
          handleData();
          setOpen(false);
        }}
        errorFunction={(error) => {
          if (error?.info) {
            toastr.error(error.info);
          } else {
            toastr.error('Error al procesar la solicitud, por favor contacte a soporte técnico');
          }
        }}
        idPluginProject={idPluginProject}
        idPaypalPlugin={idPaypalPlugin}
      />
      <Tabular
        columns={[
          {
            title: 'Etiqueta',
            field: 'label',
          },
          {
            title: 'Slug',
            field: 'slug',
          },
          {
            title: 'Acciones',
            render: (rowData) => (
              <IconButton
                onClick={async () => {
                  const {_id} = rowData;
                  try {
                    await deleteMethodPaypalPlugin({
                      _id, idProject, token,
                    });
                    handleData();
                    toastr.success('Se elimino el dato de manera exitosa');
                  } catch (error) {
                    if (error.info) {
                      toastr.error(error.info);
                    } else {
                      toastr.error('Por favor contacte con soporte técnico.');
                    }
                  }
                }}
              >
                <DeleteOutlineOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
              </IconButton>
            ),
          },
        ]}
        data={data}
        ready={loading}
        title="Metodods de Pago"
      />
    </Paper>
  );
};

export default ListMethodPaypal;
