import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
  listDatabases, getOneProject,
} from 'api-lofty';
import toastr from 'toastr';
import {oneDeploymentRequest} from 'api-lofty/lib/api/deploymentRequest.api';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';

const LoadingProcess = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [setData] = useState([]);
  const [project, setProject] = useState();
  const [textLoading, setTextLoading] = useState('Iniciando proceso de actualización');
  const {params} = match;
  const {idProject, id} = params;

  useEffect(() => {
    getOneProject({token, idProject}).then((response) => {
      setProject(response.project);
      listDatabases({token, idProject}).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }).catch(() => {
      setLoading(false);
    });
    const intervalProces = setInterval(async () => {
      try {
        const res = await oneDeploymentRequest({
          token, idProject, _id: id,
        });
        if (res.data.type === 'wating') {
          setTextLoading('Se esta ejecutando el proceso de actualización');
        } else if (res.data.type === 'deployed') {
          setTextLoading('El proceso ha finalido de maneta exitosa');
          toastr.success('El proceso a finalizado de manera exitosa');
          history.push(`/dashboard/project/${idProject}`);
          clearInterval(intervalProces);
        } else {
          toastr.error('Ocurrio un error con el proceso, te lo atenderemos de inmediato');
          history.push(`/dashboard/project/${idProject}`);
          clearInterval(intervalProces);
        }
      } catch (error) {
        toastr.error('Ocurrio un error con el proceso, te lo atenderemos de inmediato');
        history.push(`/dashboard/project/${idProject}`);
        clearInterval(intervalProces);
      }
    }, 5000);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Layout
      history={history}
      idProject={idProject}
      projectName={project?.name}
      // handleTutorial={() => handleRedoTutorial()}
    >
      <LoadingPage
        textLoading={textLoading}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(LoadingProcess);
