import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '1px 4px #DCDCDC',
    border: '1px solid',
    borderColor: '#DCDCDC',
    [theme.breakpoints.down('sm')]: {width: '85%'},
    [theme.breakpoints.down('xs')]: {width: '100%'},
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
  },
  cover: {
    marginTop: '5px',
    width: 100,
    [theme.breakpoints.down('lg')]: {width: 75},
    [theme.breakpoints.down('md')]: {width: 70},
    [theme.breakpoints.down('xs')]: {width: 90},
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  titleText: {
    color: '#201549',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  subtitleText: {
    fontSize: '14px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  buttonFormat: {
    fontSize: '16px',
    fontWeight: 'bold',
    width: '60%',
    maxHeight: '30px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px', fontWeight: 'normal'},
    [theme.breakpoints.down('xs')]: {fontSize: '17px'},
  },
}));

export default function CardComponent({
  name, slug, image, onClick,
  textButton, secondButton, onClickSecondButton, textSecondButton,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Box component="div" className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.titleText}>
            {name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className={classes.subtitleText}>
            {slug}
          </Typography>
        </CardContent>
        {
        image && (
          <CardMedia
            className={classes.cover}
            image={image}
            title="Live from space album cover"
          />
        )
      }
      </Box>
      <Box component="div" className={classes.controls}>
        <Button
          style={{
            margin: '5px',
            backgroundColor: '#292151',
            color: '#fff',
            textTransform: 'none',
          }}
          variant="contained"
          size="small"
          onClick={onClick}
          className={classes.buttonFormat}
        >
          {
              textButton || 'Ingresar'
            }
        </Button>
        {
            secondButton && (
              <Button style={{margin: '5px'}} onClick={onClickSecondButton}>
                {
                  textSecondButton || 'Otra funcion'
                }
              </Button>
            )
          }
      </Box>
    </Card>
  );
}
