import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box, Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '36%',
    backgroundColor: '#FCFCFC',
    border: '1px solid #E2E2E2',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginTop: '20px',
    },
  },
  titleSection: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px',
    marginTop: '5px',
    [theme.breakpoints.down('290')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  titleStyle: {
    color: '#292151',
    fontSize: '21px',
    width: '50%',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    [theme.breakpoints.down('350')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('290')]: {
      margin: '5px',
    },
  },
  titleStylePrice: {
    color: '#292151',
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    [theme.breakpoints.down('350')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('290')]: {
      margin: '5px',
    },
  },
  divisorStyle: {
    backgroundColor: '#E2E2E2',
    marginTop: '10px',
    marginBottom: '20px',
    width: '85%',
    height: '2px',
  },
  listContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItem: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px',
    marginBottom: '10px',
  },
  listItemStyle: {
    color: '#292151',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  totalListDivisor: {
    backgroundColor: '#292151',
    marginTop: '10px',
    marginBottom: '20px',
    width: '80%',
    height: '2px',
  },
  totalListStyle: {
    color: '#292151',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: 'normal',
  },
  buttonSection: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  purchaseButtonStyle: {
    backgroundColor: 'transparent',
    color: '#292151',
    fontWeight: 700,
    fontSize: '12px',
    border: '1px solid #292151',
    borderRadius: '10px',
    width: '150px',
    marginTop: '30px',
    marginBottom: '30px',
  },
  purchaseText: {
    marginTop: '5px',
    color: '#6E7A90',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));

export default function TemplateReceipt({data, proceedToPurchase}) {
  const classes = useStyles();
  function convertToLocale(info) {
    const retVal = info.toLocaleString('en-US', {minimumFractionDigits: 2});
    return retVal;
  }
  return (
    <Box className={classes.mainBox}>
      <Box component="div" className={classes.titleSection}>
        <Typography className={classes.titleStyle}>
          {data.template}
        </Typography>
        <Typography className={classes.titleStylePrice}>
          {`HNL ${convertToLocale(data.total)}`}
        </Typography>
      </Box>
      <Box component="div" className={classes.divisorStyle} />
      <Box component="div" className={classes.listContainer}>
        <Box
          component="img"
          src="https://placetopay-static-prod-bucket.s3.us-east-2.amazonaws.com/bancatlan-hn/header.svg"
          alt="Banco Atlantida"
          style={{
            width: 200,
          }}
        />
      </Box>
      <Box component="div" className={classes.buttonSection}>
        <Button
          className={classes.purchaseButtonStyle}
          onClick={proceedToPurchase}
        >
          REALIZAR PAGO
        </Button>
      </Box>
    </Box>
  );
}
