/* eslint-disable no-useless-escape */
import {verifyExists, verifyExistsCron, verifyExistsProject} from './verifyAvailability';

// REGEX WITH ALL SPECIAL CHARS: [ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]
// REGEX ONLY ALPHANUMERIC: ^[0-9]+|[^\w]

export const generateSlugSample = (dataInfo, value) => {
  let flagIsUnique = false;
  let returnValue = '';
  while (!flagIsUnique) {
    let generateUniqueValue = `${value.trim().toLowerCase().replace(/^[0-9]+|[^\w]/g, '')}${(Math.random() + 1).toString(36).substring(7)}`;
    generateUniqueValue = generateUniqueValue.replace('lofty', '');
    const verifyGenerate = verifyExists(dataInfo, generateUniqueValue);
    if (!verifyGenerate) {
      flagIsUnique = true;
      returnValue = generateUniqueValue;
    }
  }
  return returnValue;
};

export const generateSlugSampleCron = (dataInfo, value) => {
  let flagIsUnique = false;
  let returnValue = '';
  while (!flagIsUnique) {
    let generateUniqueValue = `${value.trim().toLowerCase().replace(/^[0-9]+|[^\w]/g, '')}${(Math.random() + 1).toString(36).substring(7)}`;
    generateUniqueValue = generateUniqueValue.replace('lofty', '');
    const verifyGenerate = verifyExistsCron(dataInfo, generateUniqueValue);
    if (!verifyGenerate) {
      flagIsUnique = true;
      returnValue = generateUniqueValue;
    }
  }
  return returnValue;
};

export const generateSlugSampleProject = (dataInfo, value) => {
  let flagIsUnique = false;
  let returnValue = '';
  while (!flagIsUnique) {
    let generateUniqueValue = `${value.trim().toLowerCase().replace(/^[0-9]+|[^\w]/g, '')}${(Math.random() + 1).toString(36).substring(7)}`;
    generateUniqueValue = generateUniqueValue.replace('lofty', '');
    const verifyGenerate = verifyExistsProject(dataInfo, generateUniqueValue);
    if (!verifyGenerate) {
      flagIsUnique = true;
      returnValue = generateUniqueValue;
    }
  }
  return returnValue;
};
