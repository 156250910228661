/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import {IconButton} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import {requestListBackoffice, requestPutBackoffice} from 'api-lofty';
import CheckboxUpdateBackoffice from './CheckboxUpdateBackoffice';
import EditorJs from './EditorJs';

const useStyles = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const UpdateFieldDialogBackoffice = ({
  open, onClose, successFunction, errorFunction,
  fields, database, token, docUpdate, backoffice,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [fieldsForm, setFieldsdForm] = useState([]);
  const handleShowField = async () => {
    const fieldFormShow = [];
    for (const field of fields) {
      if (field.type === 'relation') {
        const databaseRelation = backoffice.databases.find((database) => (
          database._id === field.relationDatabase
        ));
        if (databaseRelation) {
          const resRelation = await requestListBackoffice({
            token,
            url: `${backoffice.domain}/api/${databaseRelation.name}/list`,
          });
          const fieldLabel = backoffice.fieldDatabases.find((field) => (
            field.idDatabase === databaseRelation._id && field.isLabel
          ));
          fieldFormShow.push({
            ...field,
            ...{
              data: resRelation.data,
              labelFieldRelation: fieldLabel.name,
            },
          });
        }
      } else if (field.type === 'user') {
        const resRelationUser = await requestListBackoffice({
          token,
          url: `${backoffice.domain}/api/user/list`,
        });
        fieldFormShow.push({
          ...field,
          ...{
            data: resRelationUser.data,
          },
        });
      } else {
        fieldFormShow.push(field);
      }
    }
    setFieldsdForm(fieldFormShow);
  };
  useEffect(() => {
    if (backoffice) {
      handleShowField();
    }
  }, [backoffice, open]);
  return (
    <Dialog fullScreen fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Actualizar Archivo
            {' '}
            {database.label}
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogTitle />
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              const doc = {};
              fields.forEach((item) => {
                if (item.type === 'boolean') {
                  doc[item.name] = !!e.target[item.name].checked;
                } else if (item.type === 'number') {
                  doc[item.name] = parseFloat(e.target[item.name].value);
                } else if (item.type === 'date') {
                  doc[item.name] = new Date(e.target[item.name].value);
                } else {
                  doc[item.name] = e.target[item.name].value;
                }
              });
              await requestPutBackoffice({
                body: doc,
                token,
                url: `${backoffice.domain}/api/${database.name}/update/`,
                _id: docUpdate._id,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          {
            fieldsForm.map((field) => {
              if (field.type === 'string') {
                const valueString = backoffice.valueStringField?.filter((item) => (
                  item.idFieldDatabase === field._id
                ));
                if (valueString?.length > 0) {
                  return (
                    <div key={field._id} className="my-4">
                      <TextField
                        fullWidth
                        name={field.name}
                        select
                        type="text"
                        label={field.label}
                        variant="standard"
                        required={field.required}
                        defaultValue={docUpdate ? docUpdate[field.name] : ''}
                      >
                        {
                          valueString.map((item) => (
                            <MenuItem value={item.slug} key={item._id}>
                              {item.label}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  );
                }
                return (
                  <div key={field._id} className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      type="text"
                      label={field.label}
                      variant="standard"
                      required={field.required}
                      defaultValue={docUpdate ? docUpdate[field.name] : ''}
                    />
                  </div>
                );
              }
              if (field.type === 'number') {
                return (
                  <div key={field._id} className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      label={field.label}
                      variant="standard"
                      required={field.required}
                      InputProps={{
                        inputMode: 'decimal',
                      }}
                      defaultValue={docUpdate ? docUpdate[field.name] : ''}
                    />
                  </div>
                );
              }
              if (field.type === 'boolean') {
                return (
                  <CheckboxUpdateBackoffice
                    docUpdate={docUpdate}
                    field={field}
                  />
                );
              }
              if (field.type === 'date') {
                return (
                  <div key={field._id} className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      type="date"
                      label={field.label}
                      variant="standard"
                      required={field.required}
                      defaultValue={docUpdate ? moment(docUpdate[field.name]).format('YYYY-MM-DD') : ''}
                    />
                  </div>
                );
              }
              if (field.type === 'relation') {
                return (
                  <div key={field._id} className="my-4">
                    <TextField
                      select
                      fullWidth
                      name={field.name}
                      label={field.label}
                      variant="standard"
                      required={field.required}
                      defaultValue={docUpdate ? docUpdate[field.name] : ''}
                    >
                      {
                        field.data.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item[field.labelFieldRelation]}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                );
              }
              if (field.type === 'user') {
                return (
                  <div key={field._id} className="my-4">
                    <TextField
                      select
                      fullWidth
                      name={field.name}
                      label={field.label}
                      variant="standard"
                      required={field.required}
                      defaultValue={docUpdate ? docUpdate[field.name] : ''}
                    >
                      {
                        field.data.map((item) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.loftyEmail}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                );
              }
              if (field.type === 'textrich' && docUpdate) {
                return (
                  <div>
                    <EditorJs
                      label={field.label}
                      name={field.name}
                      token={token}
                      backoffice={backoffice}
                      defaultValue={JSON.parse(docUpdate[field.name])}
                    />
                  </div>
                );
              }
              return <></>;
            })
          }
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Actualizar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateFieldDialogBackoffice;
