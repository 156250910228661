/* eslint-disable camelcase */
export const main_block_children = [
  'main_block_children',
];

export const db_interactions_children = [
  'database_field',
];

export const db_interactions_skip = [
  'skip_input_block',
];

export const db_interactions_limit = [
  'limit_input_block',
];

export const db_interaction_assign = [
  'Variable',
];

export const general_number = [
  'number',
];

export const general_arithmetic = [
  'arithmetic',
];

export const general_string = [
  'strings',
];

export const general_boolean = [
  'strings',
];

export const general_param = [
  'param',
];

export const db_field = [
  'database_field',
];

export const db_field_inputs = [
  'comparison',
  ...general_param,
  ...general_boolean,
  ...general_number,
  ...general_string,
];

export const db_comparison_inputs = [
  'Variable',
  ...general_param,
  ...general_arithmetic,
  ...general_boolean,
  ...general_number,
  ...general_string,
];

export const db_skip_limit_inputs = [
  'Variable',
  ...general_param,
  ...general_arithmetic,
  ...general_number,
];
