import {ADD_DATA_BACKOFFICE, DELETE_DATA_BACKOFFICE} from '../types/backoffice.types';

export const addDataBackoffice = (data) => ({
  type: ADD_DATA_BACKOFFICE,
  payload: data,
});

export const deleteDataBackoffice = () => ({
  type: DELETE_DATA_BACKOFFICE,
});
