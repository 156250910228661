/* eslint-disable arrow-body-style */
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import {Box, TextField, Button} from '@material-ui/core';
import ListProjectCredentials from './ListCredentialsProject';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: '5px',
    padding: '5px',
  },
  rootTitle: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid rgba(166, 206, 227, 1)',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  emailContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px',
    width: '60%',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px',
    width: '30%',
  },
  rowTitleStyle: {
    color: 'rgba(166, 206, 227, 1)',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#CFD6E6',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageControllerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

export default function TableProjectCredentialList({
  data, deleteCredential, addCredential,
}) {
  const classes = useStyles();
  const [nameFilter, setNameFilter] = useState('');
  const temp = [];
  data.map((index) => {
    const accents = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
    };
    const indexVal = index.email.toLowerCase();
    const strFilter = nameFilter.toLowerCase();
    const reg = /[áíóúé]/g;
    const newStr = indexVal.replace(reg, (matched) => {
      return accents[matched];
    });
    const newFilter = strFilter.replace(reg, (matched) => {
      return accents[matched];
    });
    if (newStr.includes(newFilter)) {
      temp.push(index);
    }
    return 1;
  });
  const dataFiltered = temp;
  const [count, setCount] = useState(1);
  const [lowLimit, setLowLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(5);
  const limitPage = dataFiltered.length / 5;
  const dataInfo = dataFiltered.slice(lowLimit, upperLimit);
  const handleNextPage = () => {
    setLowLimit(lowLimit + 5);
    setUpperLimit(upperLimit + 5);
    setCount(count + 1);
  };

  const handlePreviousPage = () => {
    setLowLimit(lowLimit - 5);
    setUpperLimit(upperLimit - 5);
    setCount(count - 1);
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.searchContainer}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{
            color: '#fff',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          onClick={addCredential}
        >
          Agregar Credencial
        </Button>
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <SearchIcon />
          </Box>
          <TextField size="small" placeholder="Buscar.." classes={{root: classes.searchTextField}} onChange={(e) => setNameFilter(e.target.value)} />
        </Box>
      </Box>
      <Box className={classes.rootTitle}>
        <Box className={classes.emailContainer}>
          <Typography className={classes.rowTitleStyle}>
            E-Mail
          </Typography>
        </Box>
        <Box className={classes.optionsContainer}>
          <Typography className={classes.rowTitleStyle}>
            Acciones
          </Typography>
        </Box>
      </Box>
      <Box>
        {dataInfo.map((index) => (
          <ListProjectCredentials
            email={index.email}
            deleteCredential={() => deleteCredential(index._id)}
          />
        ))}
      </Box>
      <Box className={classes.pageControllerContainer}>
        <Box>
          {count > 1
            && (
            <Button onClick={() => handlePreviousPage()}>
              Prev
            </Button>
            )}
          {`Pag: ${count} of ${Math.ceil(limitPage)}` }
          {count < limitPage && limitPage > 1
          && (
            <Button onClick={() => handleNextPage()}>
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
