import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: '15px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    boxShadow: '1px 4px #DCDCDC',
    border: '1px solid',
    borderColor: '#DCDCDC',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
  },
  dateText: {
    color: 'rgba(20, 73, 153, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  titleText: {
    color: 'rgba(255, 119, 35, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  textTimeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  timeText: {
    color: 'rgba(204, 204, 204, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  subtitleText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
    [theme.breakpoints.down('xs')]: {fontSize: '16px'},
  },
}));

export default function TileProjectDep({
  date, title, description, time,
}) {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.mainBox}>
      <Typography component="h5" variant="h5" className={classes.dateText}>
        {date}
      </Typography>
      <Card className={classes.root}>
        <Box component="div" className={classes.details}>
          <CardContent className={classes.content}>
            <Box component="div" className={classes.textTimeContainer}>
              <Typography component="h5" variant="h5" className={classes.titleText}>
                {title}
              </Typography>
              <Typography component="h5" variant="h5" className={classes.timeText}>
                {time}
              </Typography>
            </Box>
            <Typography variant="subtitle1" color="textSecondary" className={classes.subtitleText}>
              {description}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}
