/* eslint-disable prefer-template */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import Blockly from 'blockly';
import * as Es from 'blockly/msg/es';
import '@blockly/field-dependent-dropdown'; // Import with side effects.
import {
  db_comparison_inputs,
  db_field,
  db_field_inputs,
  db_interactions_children,
  db_interactions_limit,
  db_interactions_skip,
  db_skip_limit_inputs,
  general_boolean,
  general_number,
  general_param,
  general_string,
  main_block_children,
} from './blockValidationChildren';

Blockly.setLocale(Es);

const userBlockDefinition = {
  type: 'user_auth',
  message0: 'Usuario',
  output: general_string,
  colour: 280,
  tooltip: 'Utilizar usuario logeado en peticiones',
  helpUrl: '',
};
Blockly.defineBlocksWithJsonArray([userBlockDefinition]);
const userAuthBlock = [{kind: 'block', type: 'user_auth'}];
const defineDBBlocks = ({collections, fields}) => {
  const collectionDropdown = collections.map((collection) => [collection.label, collection._id]);
  const selectCollection = ['Seleccionar Colección', 'selectCollection'];
  const fieldOptionMapping = {};
  if (fields?.length > 0) {
    fields.forEach((fieldXcollection) => {
      const collectionId = fieldXcollection.collection.id;
      fieldOptionMapping[collectionId] = fieldXcollection.fields.map((field) => [field.label, field._id]);
    });
  }
  const collectionOptions = [...fields.map((field) => [field.collection.label, field.collection.id])];
  // const collectionOptions = [];
  const blockList = [
    // main_block
    {
      type: 'main_block',
      message0: 'Bloque Principal %1',
      args0: [
        {
          type: 'input_statement',
          name: 'main_block',
          check: main_block_children,
        },
      ],
      colour: 195,
      tooltip: 'Este bloque contiene todas las instrucciones que serán tomadas en cuenta por el Endpoint. Solo puede haber uno de estos bloques en el pizarrón a la vez.',
      helpUrl: '',
    },
    // other
    {
      type: 'comparison',
      message0: '%1 %2',
      args0: [
        {
          type: 'field_dropdown',
          name: 'comparison_type_select',
          options: [
            [
              '=',
              'equals',
            ],
            [
              '>',
              'more than',
            ],
            [
              '<',
              'less than',
            ],
            [
              '≥',
              'more or equal than',
            ],
            [
              '≤',
              'less or equal than',
            ],
            [
              '≠',
              'different',
            ],
            [
              'Expresión Regular',
              'regex',
            ],
          ],
        },
        {
          type: 'input_value',
          name: 'ComparisonBlock',
          check: db_comparison_inputs,
        },
      ],
      output: db_field_inputs[0],
      colour: 65,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'if_condition',
      message0: '%1 %2 %3',
      args0: [
        {
          type: 'input_value',
          name: 'comparison_left_condition',
          check: db_comparison_inputs,
        },
        {
          type: 'field_dropdown',
          name: 'comparison_type_select',
          options: [
            [
              '=',
              'equals',
            ],
            [
              '>',
              'more than',
            ],
            [
              '<',
              'less than',
            ],
            [
              '≥',
              'more or equal than',
            ],
            [
              '≤',
              'less or equal than',
            ],
            [
              '≠',
              'different',
            ],
            [
              'Expresión Regular',
              'regex',
            ],
          ],
        },
        {
          type: 'input_value',
          name: 'comparison_right_condition',
          check: db_comparison_inputs,
        },
      ],
      inputsInline: true,
      output: db_field_inputs[0],
      colour: 65,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'mathblocks',
      message0: '%1 %2 %3',
      args0: [
        {
          type: 'input_value',
          name: 'left_value',
          check: general_number,
        },
        {
          type: 'field_dropdown',
          name: 'arithmetic',
          options: [
            [
              '+',
              'sum',
            ],
            [
              '-',
              'difference',
            ],
            [
              '*',
              'product',
            ],
            [
              '/',
              'division',
            ],
            [
              '^',
              'power',
            ],
            [
              '√',
              'square_root',
            ],
          ],
        },
        {
          type: 'input_value',
          name: 'arithmeticoptions',
          check: general_number,
        },
      ],
      inputsInline: true,
      output: 'arithmetic',
      colour: 230,
      tooltip: 'Bloque para procesos matematicos',
      helpUrl: '',
    },
    {
      type: 'string_input_block',
      message0: '" %1 " %2',
      args0: [
        {
          type: 'field_input',
          name: 'valueInput',
          text: '',
        },
        {
          type: 'input_dummy',
        },
      ],
      output: general_string[0],
      colour: 0,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'number_input_block',
      message0: '# %1 %2',
      args0: [
        {
          type: 'field_number',
          name: 'valueInputNumber',
          value: 0,
        },
        {
          type: 'input_dummy',
        },
      ],
      output: general_number[0],
      colour: 0,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'boolean_input_block',
      message0: 'VoF %1 %2',
      args0: [
        {
          type: 'field_dropdown',
          name: 'boolean_type_select',
          options: [
            [
              'V',
              'true',
            ],
            [
              'F',
              'false',
            ],
          ],
        },
        {
          type: 'input_dummy',
        },
      ],
      output: general_boolean[0],
      colour: 0,
      tooltip: 'Entrada de tipo booleano V para verdadero (true) y F para falso (false).',
      helpUrl: '',
    },
    {
      type: 'conditional_if_block',
      message0: 'Si %1 entonces %2',
      args0: [
        {
          type: 'input_value',
          name: 'comparacion',
          check: ['comparison'],
        },
        {
          type: 'input_statement',
          name: 'then',
        },
        // {
        //  type: 'input_statement',
        //  name: 'else_do',
        //  check: '',
        // },
      ],
      previousStatement: main_block_children,
      nextStatement: main_block_children,
      colour: 210,
      tooltip: 'Bloque condicional que ejecuta las instrucciones en el bloque "entonces" si la condición es verdadera',
      helpUrl: '',
    },
  ];
  const dbBlocksDef = [
    // database_field
    {
      type: 'database_field',
      message0: 'Campo: Colección %1 Campo %2 %3',
      args0: [
        {
          type: 'field_dropdown',
          name: 'Collection',
          options: collectionOptions,
        },
        {
          type: 'field_dependent_dropdown',
          name: 'CollectionField',
          parentName: 'Collection',
          optionMapping: fieldOptionMapping,
          defaultOptions: [['Seleccionar Colección', 'selectCollection']],
        },
        {
          type: 'input_value',
          name: 'Operation',
          check: db_field_inputs,
        },
      ],
      previousStatement: [...db_field, ...db_interactions_children],
      nextStatement: [...db_field],
    },
    // specialized_db_blocks
    {
      type: 'skip_input_block',
      message0: 'Salto de datos %1',
      args0: [
        {
          type: 'input_value',
          name: 'value_input_skip',
          check: db_skip_limit_inputs,
        },
      ],
      inputsInline: true,
      previousStatement: db_interactions_skip,
      colour: 20,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'limit_input_block',
      message0: 'Límite de datos %1',
      args0: [
        {
          type: 'input_value',
          name: 'value_input_limit',
          check: db_skip_limit_inputs,
        },
      ],
      inputsInline: true,
      previousStatement: db_interactions_limit,
      colour: 20,
      tooltip: '',
      helpUrl: '',
    },
    {
      type: 'query_response_block',
      message0: 'Respuesta: estado %1 datos %2',
      args0: [
        {
          type: 'field_dropdown',
          name: 'type_status',
          options: [
            [
              'Error',
              'error',
            ],
            [
              'Exitoso',
              'success',
            ],
          ],
        },
        {
          type: 'input_value',
          name: 'responsedata',
        },
      ],
      previousStatement: main_block_children,
      // nextStatement: null,
      colour: 30,
      tooltip: 'Bloque de respuesta de una petición a una colección de datos',
      helpUrl: '',
    },
    // db_interactions
    {
      type: 'add_database_block',
      message0: 'Agregar en %1 asignar a %2 Campos %3',
      args0: [
        {
          type: 'field_dropdown',
          name: 'addCollection',
          options: [selectCollection, ...collectionDropdown],
        },
        {
          type: 'input_value',
          name: 'listDatabase',
          check: ['variable'],
        },
        {
          type: 'input_statement',
          name: 'listFieldDatabaseAdd',
          check: db_interactions_children,
        },
      ],
      inputsInline: true,
      previousStatement: main_block_children,
      nextStatement: main_block_children,
      colour: 120,
      tooltip: 'Bloque para guardar en la colección de datos',
      helpUrl: 'Bloque para guardar en la colección de datos',
    },
    {
      type: 'list_database_block',
      message0: 'Obtener lista de %1 asignar a %2 Campos %3 saltando %4 con un límite de %5',
      args0: [
        {
          type: 'field_dropdown',
          name: 'listCollection',
          options: [selectCollection, ...collectionDropdown],
        },
        {
          type: 'input_value',
          name: 'listDatabaseList',
          check: ['variable'],
        },
        {
          type: 'input_statement',
          name: 'listFieldDatabaseAdd',
          check: db_interactions_children,
        },
        {
          type: 'input_statement',
          name: 'listSaltDatabase',
          check: db_interactions_skip,
        },
        {
          type: 'input_statement',
          name: 'listLimitDatabase',
          check: db_interactions_limit,
        },
      ],
      inputsInline: true,
      previousStatement: main_block_children,
      nextStatement: main_block_children,
      colour: 270,
      tooltip: 'Bloque para obtener una lista de datos en la colección de datos',
      helpUrl: 'Bloque para obtener una lista de datos en la colección de datos',
    },
    {
      type: 'object_database_block',
      message0: 'Obtener dato de %1 asignar a %2 Campos %3',
      args0: [
        {
          type: 'field_dropdown',
          name: 'getOne',
          options: [selectCollection, ...collectionDropdown],
        },
        {
          type: 'input_value',
          name: 'objectDatabaseList',
          check: 'variable',
        },
        {
          type: 'input_statement',
          name: 'objectFieldDatabaseAdd',
          check: db_interactions_children,
        },
      ],
      inputsInline: true,
      previousStatement: main_block_children,
      nextStatement: main_block_children,
      colour: 270,
      tooltip: 'Bloque para obtener dato en la colección de datos',
      helpUrl: 'Bloque para obtener dato en la colección de datos',
    },
    {
      type: 'update_database_block',
      message0: 'Actualizar en %1 %2 Filtrar por %3 Campos %4',
      args0: [
        {
          type: 'field_dropdown',
          name: 'dropdownListUpdate',
          options: [selectCollection, ...collectionDropdown],
        },
        {
          type: 'input_dummy',
        },
        {
          type: 'input_statement',
          name: 'updateFilterFieldDatabase',
          check: db_interactions_children,
        },
        {
          type: 'input_statement',
          name: 'updateFieldDatabase',
          check: db_interactions_children,
        },
      ],
      previousStatement: main_block_children,
      nextStatement: main_block_children,
      colour: 45,
      tooltip: 'Bloque para actualizar datos de una colección',
      helpUrl: 'Bloque para actualizar datos de una colección',
    },
    {
      type: 'delete_database_block',
      message0: 'Eliminar dato en %1 %2 Filtrar por %3',
      args0: [
        {
          type: 'field_dropdown',
          name: 'dropdownListDelete',
          options: collectionOptions,
        },
        {
          type: 'input_dummy',
        },
        {
          type: 'input_statement',
          name: 'deleteFilterFieldDatabase',
          check: db_interactions_children,
        },
      ],
      previousStatement: main_block_children,
      nextStatement: main_block_children,
      colour: 0,
      tooltip: 'Bloque para eliminar un dato de una colección',
      helpUrl: '',
    },
  ];
  const paramBlocksDef = [
    {
      type: 'param_endpoint',
      message0: 'Parámetro %1',
      args0: [
        {
          type: 'field_dropdown',
          name: 'param_name',
          options: [],
        },
      ],
      output: general_param[0],
      colour: 20,
      tooltip: '',
      helpUrl: '',
    },
  ];
  Blockly.defineBlocksWithJsonArray([...blockList, ...paramBlocksDef, ...dbBlocksDef]);
  const basicBlocks = blockList.map((block) => ({kind: 'block', type: block.type}));
  const dbBlocks = dbBlocksDef.map((block) => ({kind: 'block', type: block.type}));
  const paramBlocks = paramBlocksDef.map((block) => ({kind: 'block', type: block.type}));
  const variableBlocks = {
    kind: 'category',
    name: 'Variables',
    custom: 'VARIABLE',
    categorystyle: 'variable_category',
    tooltip: 'Declaración y manejo de variables',
  };
  const toolbox = {
    kind: 'categoryToolbox',
    contents: [
      {
        kind: 'category', name: 'Bloques Básicos', categorystyle: 'math_category', contents: basicBlocks,
      },
      {
        kind: 'category', name: 'Base de Datos', categorystyle: 'math_category', contents: dbBlocks,
      },
      variableBlocks,
      {
        kind: 'category', name: 'Parámetros', categorystyle: 'math_category', contents: paramBlocks,
      },
      // {
      //   kind: 'category', name: 'Variables Con Campos', categorystye: 'math_category', contents: [],
      // },
    ],
  };
  return toolbox;
};

export default defineDBBlocks;

export const addNewParamBlockBlockly = (blocklyWorkspace, oldToolbox, setToolbox, setOpenAdd, name, label, type, tbReact) => {
  // Blockly
  const blockType = 'param_endpoint';
  const blockDefinition = {
    type: 'param_endpoint',
    message0: 'Parámetro %1',
    args0: [
      {
        type: 'field_dropdown',
        name: 'param_name',
        options: [
          ...tbReact.map((param) => [
            `${param.label} - ${param.type}`,
            param.name,
          ]),
          [
            `${label} - ${type}`,
            name,
          ],
        ],
      },
    ],
    output: null,
    colour: 20,
    tooltip: '',
    helpUrl: '',
  };
  Blockly.defineBlocksWithJsonArray([blockDefinition]);
  const paramsArrayNew = [{kind: 'block', type: blockType}];
  const newToolbox = {...oldToolbox};
  newToolbox.contents[2].contents = paramsArrayNew;
  blocklyWorkspace.updateToolbox(newToolbox);
  // react
  setToolbox(newToolbox);
  setOpenAdd(false);
};

export const addNewVariableWithFieldData = (blocklyWorkspace, oldToolbox, setToolbox, variables, stateVars) => {
  if (variables?.length > 0) {
    const varFieldOptionMapping = {};
    const varInfoData = [];
    variables?.forEach((index) => {
      const getVarInfo = stateVars.find((vars) => (index.id === vars.id));
      if (getVarInfo) {
        varFieldOptionMapping[getVarInfo.id] = index.fieldinfo.map((field) => [field.label, field._id]);
        varInfoData.push(getVarInfo);
      }
    });
    const varOptions = [...varInfoData.map((index) => [index.name, index.id])];
    const blockDefinition = {
      type: 'variable_field_info',
      message0: 'Variable %1 Campo %2',
      args0: [
        {
          type: 'field_dropdown',
          name: 'VariableInfo',
          options: varOptions,
        },
        {
          type: 'field_dependent_dropdown',
          name: 'FieldFromVariable',
          parentName: 'VariableInfo',
          optionMapping: varFieldOptionMapping,
          // defaultOptions: [['Seleccionar Colección', 'selectCollection']],
        },
      ],
      output: null,
      colour: 127,
      tooltip: '',
      helpUrl: '',
    };
    const variableData = [blockDefinition];
    Blockly.defineBlocksWithJsonArray([blockDefinition]);
    const variableWithDbBlocks = {
      kind: 'category',
      name: 'Variables con Campo',
      contents: variableData.map((block) => ({kind: 'block', type: block.type})),
      categorystyle: 'math_category',
    };
    const newToolbox = {...oldToolbox};
    newToolbox.contents = [...newToolbox.contents, variableWithDbBlocks];
    blocklyWorkspace.updateToolbox(newToolbox);
    setToolbox(newToolbox);
  } else {
    const newToolbox = {...oldToolbox};
    const seekToolbox = newToolbox.contents.filter((index) => (index.name !== 'Variables con Campo'));
    newToolbox.contents = seekToolbox;
    blocklyWorkspace.updateToolbox(newToolbox);
    setToolbox(newToolbox);
  }
};

export const addUserAuthBlockBlockly = (blocklyWorkspace, oldToolbox, setToolbox, state) => {
  // Blockly
  if (state) {
    const authenticationBlock = {
      kind: 'category',
      name: 'Usar Autenticacion',
      contents: userAuthBlock,
      categorystyle: 'math_category',
    };
    const newToolbox = {...oldToolbox};
    newToolbox.contents = [...newToolbox.contents, authenticationBlock];
    blocklyWorkspace.updateToolbox(newToolbox);
    setToolbox(newToolbox);
  } else {
    const newToolbox = {...oldToolbox};
    const seekToolbox = newToolbox.contents.filter((index) => (index.name !== 'Usar Autenticacion'));
    newToolbox.contents = seekToolbox;
    blocklyWorkspace.updateToolbox(newToolbox);
    setToolbox(newToolbox);
  }
};

export const defineOnChangeSpawnDBFields = (blocklyWorkspace, listFieldsDatabase, token, idProject) => {
  blocklyWorkspace?.addChangeListener((e) => {
    if (!e.isUiEvent) {
      if (e.type === 'change') {
        // console.log(e);
        const idDatabase = e.newValue;
        if (idDatabase === 'selectCollection') { return; }
        // const block = blocklyWorkspace.getBlockById(e.blockId);

        const spawnCollectionBlocks = async () => {
          const resFields = await listFieldsDatabase({token, idDatabase, idProject});
          const dbFields = resFields.data;

          const blockList = dbFields.map((dbField) => ({
            type: dbField._id,
            // eslint-disable-next-line prefer-template
            message0: dbField.label + '%1',
            args0: [
              {
                type: 'input_value',
                name: 'CollectionFieldName',
              },
            ],
            previousStatement: null,
            nextStatement: null,
            colour: 330,
            tooltip: '',
            helpUrl: '',
          }));

          Blockly.defineBlocksWithJsonArray(blockList);
          const workspace = Blockly.getMainWorkspace();
          blockList.forEach((block) => {
            workspace.newBlock(block.type).initSvg();
          });

          workspace.render();
        };
        spawnCollectionBlocks();
      }
    }
  });
};
