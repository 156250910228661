import React from 'react';
import Grid from '@material-ui/core/Grid';
import {createEndpoint} from 'api-lofty';
import DialogFullScreen from './DialogFullScreen';
// import {createEndpoint} from '../api/endpoint.api';
import endpoints from '../util/addEndpoints';
import CardServices from './CardServices';

const AddEnpointDialog = ({
  open, handleClose, token, types, successFunction, errorFunction, idDatabase, idProject,
}) => (
  <DialogFullScreen open={open} handleClose={handleClose} title="Agregando Servicio">
    <Grid container>
      {
        endpoints.map((item) => {
          if (!types.find((type) => type === item.type)) {
            return (
              <Grid key={item.type} className="p-2" item md={4}>
                <CardServices
                  name={item.name}
                  idProject={idProject}
                  token={token}
                  description={item.description}
                  image={item.image}
                  textButton="Agregar"
                  onClick={async (isAuthentication) => {
                    try {
                      await createEndpoint({
                        token,
                        typeFunction: item.type,
                        idDatabase,
                        idProject,
                        isAuthentication,
                        url: item.url,
                      });
                      if (successFunction) {
                        successFunction();
                      }
                    } catch (error) {
                      if (errorFunction) {
                        errorFunction(error);
                      }
                    }
                  }}
                />
              </Grid>
            );
          }
          return undefined;
        })
      }
    </Grid>
  </DialogFullScreen>
);

export default AddEnpointDialog;
