/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import toastr from 'toastr';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  getOneProject, listFieldUserProject, getOneUserProject,
  listSimulatorUserProject, deleteSimulatorUserProject,
} from 'api-lofty';
import Tabular from '../components/Tabular';
import Layout from '../layouts/UserProjectDashboard';
import LoadingPage from './LoadingPage';
import {labelUserProject} from '../util/typeUserProject';
import RemoveDataUserModal from '../components/RemoveDataUserModal';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const UserProjetFieldsList = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idUserProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [data, setData] = useState([]);
  const [dataUserProject, setDataUserProject] = useState(undefined);
  const [colums, setColums] = useState([]);
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [labelUrl, setLabelUrl] = useState('');

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  const updateInfo = async () => {
    const resData = await listSimulatorUserProject({token, idProject});
    const resFields = await listFieldUserProject({token, idUserProject, idProject});
    const newData = [];
    resData.data.forEach((item) => {
      const doc = {
        _id: item._id,
        loftyEmail: item.data.loftyEmail,
        loftyUsername: item.data.loftyUsername,
        loftyName: item.data.loftyName,
        loftyTypeRegister: item.data.loftyTypeRegister,
      };
      resFields.data.forEach((field) => {
        doc[field.name] = item.data[field.name];
      });
      newData.push(doc);
    });
    setData(newData);
  };
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resUserProject = await getOneUserProject({token, _id: idUserProject});
      const resData = await listSimulatorUserProject({token, idProject});
      const resFields = await listFieldUserProject({token, idUserProject, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Usuarios/${resUserProject.data.type}/Datos`);
      setDataProject(resProject.project);
      setDataUserProject(resUserProject.data);
      // AGREGANDO COLUM
      const newColumn = [];
      newColumn.push({
        title: 'Correo',
        field: 'loftyEmail',
      });
      newColumn.push({
        title: 'Usuario',
        field: 'loftyUsername',
      });
      newColumn.push({
        title: 'Nombre',
        field: 'loftyName',
      });
      newColumn.push({
        title: 'Tipo de Registro',
        field: 'loftyTypeRegister',
      });
      resFields.data.forEach((item) => {
        const doc = {
          title: item.label,
          field: item.name,
        };
        newColumn.push(doc);
      });
      newColumn.push({
        title: 'Acciones',
        field: '_id',
        render: (rowData) => (
          <IconButton
            onClick={() => handleOpenDeleteDialog(rowData._id)}
          >
            <DeleteIcon />
          </IconButton>
        ),
      });
      const newData = [];
      resData.data.forEach((item) => {
        const doc = {
          _id: item._id,
          loftyEmail: item.data.loftyEmail,
          loftyUsername: item.data.loftyUsername,
          loftyName: item.data.loftyName,
          loftyTypeRegister: item.data.loftyTypeRegister,
        };
        resFields.data.forEach((field) => {
          doc[field.name] = item.data[field.name];
        });
        newData.push(doc);
      });
      setData(newData);
      setColums(newColumn);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idUserProject={idUserProject}
      userProjectType={labelUserProject(dataUserProject?.type)}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Datos Usuario
          </Typography>
        </Box>
        <Button onClick={updateInfo} variant="contained" color="secondary" className={classes.textButton}>
          Actualizar Datos
        </Button>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Tabular
          title="Campos"
          columns={colums}
          data={data}
          ready={loading}
        />
      </Box>
      <div>
        <RemoveDataUserModal
          open={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          undoDelete={() => setOpenDialog(!openDialog)}
          handleDeletion={async () => {
            try {
              await deleteSimulatorUserProject({
                _id: idDeletion,
                idProject,
                token,
              });
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              setOpenDialog(!openDialog);
              updateInfo();
              toastr.clear();
              setTimeout(() => toastr.success('Se elimino exitosamente el campo'), 300);
            } catch (error) {
              if (error.info) {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
              } else {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n No se elimino el campo'), 300);
              }
            }
          }}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(UserProjetFieldsList);
