import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import {addPluginVersionAPIPdf} from 'api-lofty';
// import {addPluginVersionPdf} from '../../api/pluginVersionPdf.api';

const AddVersionPdf = ({
  open, onClose, token, onSuccess, onError, idProject, idPluginProject,
}) => {
  const [isLogged, setIsLogged] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Agregar Version
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              await addPluginVersionAPIPdf({
                idPluginProject,
                idProject,
                isLogged,
                isSaved,
                label: e.target.label.value,
                name: e.target.name.value,
                token,
              });
              if (onSuccess) {
                onSuccess();
              }
            } catch (error) {
              if (onError) {
                onError(error);
              }
            }
          }}
        >
          <div className="my-4">
            <TextField variant="outlined" fullWidth label="Etiqueta" name="label" required />
          </div>
          <div className="my-4">
            <TextField variant="outlined" fullWidth label="Slug" name="name" required />
          </div>
          <Grid className="my-4" container>
            <Grid item md={6}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={isLogged}
                    onChange={() => setIsLogged((prev) => !prev)}
                  />
                )}
                label="Logueado"
              />
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={isSaved}
                    onChange={() => setIsSaved((prev) => !prev)}
                  />
                )}
                label="Almacenar archivo"
              />
            </Grid>
          </Grid>
          <div className="text-center my-4">
            <Button type="submit" variant="contained" color="primary">
              Guardar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddVersionPdf;
