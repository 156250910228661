/* eslint-disable no-alert */
import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  Dialog, Box, IconButton,
  Select, MenuItem, FormControl,
  InputLabel,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {searchUserTeam, addTeam} from 'api-lofty';
// import ModalFull from './DialogFullScreen';
import Card from './CardUserSearch';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  textName: {
    color: 'rgba(41, 33, 81, 1)',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  textType: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '13px',
  },
  formControlArea: {
    padding: '5px',
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
  },
  formControlStyle: {
    margin: theme.spacing(1),
  },
}));

const SearchUserModal = ({
  open, handleClose, token, idProject, handleSuccess,
}) => {
  const [data, setData] = useState([]);
  const classes = useStyle();
  const [valueSelect, setValueSelect] = useState('');

  const handleChangeSelect = (e) => {
    setValueSelect(e);
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Colaborador
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { handleClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{padding: '10px', margin: '5px'}}>
          <div className="mt-2 w-100">
            <TextField
              onChange={async (e) => {
                const {value} = e.target;
                if (value.length > 3) {
                  const response = await searchUserTeam({
                    token,
                    text: value,
                    idProject,
                  });
                  setData(response.data);
                }
              }}
              label="Buscar"
              variant="standard"
              fullWidth
            />
          </div>
        </Box>
        <Box className={classes.formControlArea}>
          <FormControl variant="outlined" fullWidth className={classes.formControlStyle}>
            <InputLabel>Rol</InputLabel>
            <Select
              value={valueSelect}
              onChange={(e) => handleChangeSelect(e.target.value)}
            >
              <MenuItem value="Developer">Developer</MenuItem>
              <MenuItem value="Designer">Designer</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box style={{height: '450px', width: '400px', backgroundColor: 'rgba(243, 243, 243, 1)'}}>
          <Grid className="mt-3" container>
            {
          data.map((doc) => (
            <Grid item xs={12}>
              <Card
                key={doc._id}
                name={`${doc.firstName} ${doc.lastName}`}
                text={`${doc.firstName[0]} ${doc.lastName[0]}`}
                permission="Developer"
                handleInvite={async () => {
                  try {
                    await addTeam({
                      idProject,
                      permission: 'admin',
                      token,
                      userId: doc._id,
                    });
                    if (handleSuccess) {
                      handleSuccess();
                    }
                    handleClose();
                  } catch (error) {
                    if (error.info) {
                      alert(error.info);
                    } else {
                      alert('Ocurrio un error al agregar colaborador');
                    }
                  }
                }}
              />
            </Grid>
          ))
        }
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SearchUserModal;
