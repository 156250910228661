/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import ImageIcon from '@material-ui/icons/Image';
import Swal from 'sweetalert2';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Typography, IconButton, Grid,
} from '@material-ui/core';
import {
  getOneProject, getOnePluginProject, listPluginVersionPdf,
  removePluginVersionPdf, updateAssetPluginVersionPdf,
} from 'api-lofty';
import toastr from 'toastr';
import Tabular from '../components/Tabular';
import Layout from '../layouts/PluginsProjectDashboard';
import LoadingPage from './LoadingPage';
import AddVersionPdf from '../components/PluginPdf/AddVersionPdf';
import ListAssetsModal from '../components/ListAssetsModal';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const PluginVersionPdf = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idPluginsProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [data, setData] = useState([]);
  const [colums, setColums] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataPluginProject, setDataPluginProject] = useState();
  const [openImage, setOpenImage] = useState(false);
  const [idVersionPdf, setIdVersionPdf] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };
  const updateInfo = async () => {
    const resPluginVersion = await listPluginVersionPdf({idProject, token});
    setData(resPluginVersion.data);
  };
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resPluginProject = await getOnePluginProject({token, _id: idPluginsProject, idProject});
      const resPluginVersion = await listPluginVersionPdf({idProject, token});
      setLabelUrl(`proyectos/${resProject.project.name}/Plugins/${resPluginProject.data.type}/versiones`);
      setDataProject(resProject.project);
      setDataPluginProject(resPluginProject.data);
      // AGREGANDO COLUM
      const newColumn = [];
      newColumn.push({
        title: 'Etiqueta',
        field: 'label',
      });
      newColumn.push({
        title: 'Slug',
        field: 'name',
      });
      newColumn.push({
        title: 'Acciones',
        field: '_id',
        render: (rowData) => (
          <>
            <IconButton
              onClick={() => {
                setOpenImage(true);
                setIdVersionPdf(rowData._id);
              }}
            >
              <ImageIcon />
            </IconButton>
            <IconButton
              onClick={() => handleOpenDeleteDialog(rowData._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      });
      setColums(newColumn);
      setData(resPluginVersion.data);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idPluginProject={idPluginsProject}
      pluginProjectType={dataPluginProject?.type}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <div className="text-right mb-2">
        <Button onClick={() => setOpen(true)} variant="contained" color="primary">
          Agregar version
        </Button>
      </div>
      <ListAssetsModal
        handleClose={() => setOpenImage(false)}
        idProject={idProject}
        open={openImage}
        token={token}
        handleImage={async (idAssets) => {
          toastr.options = {
            positionClass: 'toast-top-right',
            hideDuration: 300,
            timeOut: 6000,
          };
          try {
            await updateAssetPluginVersionPdf({token, _id: idVersionPdf, idAssets});
            // alert('Exito');
            toastr.clear();
            setTimeout(() => toastr.success('exito'), 300);
          } catch (error) {
            if (error.info) {
              // alert(error.info);
              toastr.clear();
              setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
            } else {
              // alert('Error al procesar la peticion');
              toastr.clear();
              setTimeout(() => toastr.error('Error al procesar la peticion'), 300);
            }
          }
        }}
      />
      <AddVersionPdf
        idProject={idProject}
        idPluginProject={idPluginsProject}
        token={token}
        open={open}
        onClose={() => setOpen(false)}
        onError={(error) => {
          if (error.info) {
            toastr.options = {
              positionClass: 'toast-top-right',
              hideDuration: 300,
              timeOut: 6000,
            };
            toastr.clear();
            setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
            /*
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'fatal error',
              showConfirmButton: false,
              timer: 1500,
            });
            */
          } else {
            toastr.options = {
              positionClass: 'toast-top-right',
              hideDuration: 300,
              timeOut: 6000,
            };
            toastr.clear();
            setTimeout(() => toastr.error('Error no se pudo eliminar'), 300);
            /*
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Error no se pudo eliminar',
              showConfirmButton: false,
              timer: 1500,
            });
            */
          }
        }}
        onSuccess={() => {
          toastr.options = {
            positionClass: 'toast-top-right',
            hideDuration: 300,
            timeOut: 6000,
          };
          toastr.clear();
          setTimeout(() => toastr.success('Se agrego de manera exitosa la version'), 300);
          /*
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Se agrego de manera exitosa la version',
            showConfirmButton: false,
            timer: 1500,
          });
          */
          updateInfo();
        }}
      />
      <Tabular
        title="Versiones"
        columns={colums}
        data={data}
        ready={loading}
      />
      <div>
        <Dialog open={openDialog}>
          <DialogTitle>
            Eliminar
          </DialogTitle>
          <DialogContent>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              ¿Estás seguro de eliminar este campo?
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    try {
                      await removePluginVersionPdf({
                        _id: idDeletion,
                        token,
                      });
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Se elimino el dato',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setOpenDialog(false);
                      updateInfo();
                    } catch (error) {
                      if (error.info) {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: error.info,
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: 'Error no se pudo eliminar',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    }
                  }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PluginVersionPdf);
