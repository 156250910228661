import React, {useState} from 'react';
import toastr from 'toastr';
import {Paper, TextField} from '@material-ui/core';
import {addUpdateApiKeyGoogle, getOneConfigGoogleAnalitycs} from 'api-lofty';
// import {addUpdatePaypalPlugin, getOnePaypalPlugin} from 'api-lofty';

const FormConfigGoogleAnalytics = ({
  token, idProject, idPluginProject, setGoogle,
  dataGoogleAnalytics,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Paper className="p-3">
      <h2 className="text-center my-2">
        <b>Configuración Google Analytics</b>
      </h2>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            const resAddUpdate = await addUpdateApiKeyGoogle({
              idPluginProject,
              idProject,
              token,
              apiKeyGoogle: e.target.apiKeyGoogleId.value,
            });
            if (setGoogle) {
              const res = await getOneConfigGoogleAnalitycs({
                idPluginProject, idProject, token,
              });
              setGoogle(res.data);
            }
            toastr.success(resAddUpdate.info);
          } catch (error) {
            if (error.info) {
              toastr.error(error.info);
            } else {
              toastr.error('Contacte con soporte técnico');
            }
          }
          setLoading(false);
        }}
      >
        <div className="my-4">
          <TextField
            fullWidth
            name="apiKeyGoogleId"
            type="text"
            label="Key de Google Analytics"
            variant="standard"
            defaultValue={dataGoogleAnalytics?.apiKeyGoogle}
          />
        </div>
        <div className="text-center my-4">
          <button
            style={{
              background: '#201549',
            }}
            type="submit"
            className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
            disabled={loading}
          >
            {
              loading ? (
                <>
                  Procesando ...
                </>
              ) : (
                <>
                  Guardar
                </>
              )
            }
          </button>
        </div>
      </form>
    </Paper>
  );
};

export default FormConfigGoogleAnalytics;
