import React, {useState, useEffect} from 'react';
import {CircularProgress, Grid} from '@material-ui/core';
import {listAssetsProject} from 'api-lofty';
import DialogFullScreen from './DialogFullScreen';
import CardImage from './CardImage';

const ListAssetsModal = ({
  open, handleClose, idProject, token, handleImage,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const handleData = async () => {
      try {
        const resAssets = await listAssetsProject({token, idProject});
        setData(resAssets.data);
      } catch (error) {
        //
      }
      setLoading(false);
    };
    handleData();
  }, []);
  return (
    <DialogFullScreen open={open} handleClose={handleClose} title="Imagenes del Proyecto">
      {
        loading && (
          <div className="text-center mt-5">
            <CircularProgress color="primary" />
          </div>
        )
      }
      {
        !loading && data.length === 0 && (
          <div className="text-center">
            <p>No hay imagenes</p>
          </div>
        )
      }
      {
        !loading && data.length > 0 && (
          <Grid spacing={2} container>
            {
              data.map(({src, _id, name}) => (
                <Grid key={_id} item md={3}>
                  <CardImage
                    name={name}
                    url={src}
                    onClick={() => {
                      if (handleImage) {
                        handleImage(_id);
                      }
                    }}
                  />
                </Grid>
              ))
            }
          </Grid>
        )
      }
    </DialogFullScreen>
  );
};

export default ListAssetsModal;
