/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import toastr from 'toastr';
import {
  getOneEndpoint, getCustomEndpointData, getOneProject,
  getOneDatabase, listDatabases, listFieldsDatabase, listParamsEndpoint,
} from 'api-lofty';
import Blockly from 'blockly';
import Layout from '../layouts/DatabaseDashboard';
import LoadingPage from './LoadingPage';
import 'toastr/build/toastr.min.css';
import defineDBBlocks, {addNewParamBlockBlockly} from '../util/customServicesBlockDefinitions';
// import AddParamModal from '../components/AddParamModal';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  urlWithMultipleColumns: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'row',
  },
  urlSectionContainer: {
    display: 'flex',
    margin: '2px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  urlFragmentSection: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  urlFragmentLabel: {
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    color: 'rgba(196, 196, 196, 1)',
    fontSize: '15px',
    fontWeight: 500,
  },
  urlInputSection: {
    border: '1px solid rgba(233, 231, 234, 1)',
    borderRadius: '15px',
  },
}));

const RenderCustomServicesBlockly = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFields, setDataFields] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject, idEndpoint, idDatabase} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const [database, setDatabase] = useState();
  const [infoEndpointData, setInfoEndpointData] = useState();
  const [variableInfoData, setVariableInfoData] = useState();
  const [urlEndpoint, setUrlEndpoint] = useState();
  const [paramData, setParamData] = useState([]);

  // blockly
  const blocklyRef = useRef();
  let blocklyWorkspace = null;
  const [renderHelper, setRenderHelper] = useState(0);
  const [toolbox, setToolbox] = useState();
  // const [blocklyWorkspaceReact, setBlocklyWorkspaceReact] = useState();
  const [toolboxReact, setToolboxReact] = React.useState([]);
  // end blockly

  const classes = useStyle();

  const handleParamData = (blocklyData, toolboxData) => {
    try {
      paramData.forEach((index) => {
        const newBlock = {
          type: index.type,
          label: index.label,
          name: index.name,
          typeParam: 'temp',
          required: index.required,
        };
        addNewParamBlockBlockly(
          blocklyData,
          toolboxData,
          setToolbox,
          null,
          newBlock.name,
          newBlock.label,
          newBlock.type,
          toolboxReact,
        );
        setToolboxReact(((oldTBReact) => ([...oldTBReact, newBlock])));
      });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleLoad = async () => {
    const mainWorkspace = Blockly.getMainWorkspace();
    const serializer = new Blockly.serialization.blocks.BlockSerializer();
    const variableSerializer = new Blockly.serialization.variables.VariableSerializer();
    // console.log(variableInfoData, infoEndpointData);
    variableSerializer.load(variableInfoData, mainWorkspace);
    // const serialBlock = {blocks: infoEndpointData};
    serializer.load(infoEndpointData, mainWorkspace);
  };

  const getCollectionFields = async () => {
    const collectionXfields = [];
    const resDatabases = await listDatabases({token, idProject});
    const asyncFunctions = resDatabases.data.map((collection) => async () => {
      const fields = await listFieldsDatabase({token, idDatabase: collection._id, idProject});
      const mappedFields = fields
        ?.data?.map?.((field) => ({_id: field._id, label: field.label})) ?? [];
      return {collection: {id: collection.name, label: collection.label}, fields: mappedFields};
    });
    const runAsyncFunctionsInSequence = async () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const asyncFn of asyncFunctions) {
        // eslint-disable-next-line no-await-in-loop
        const collectionXfield = await asyncFn();
        collectionXfields.push(collectionXfield);
      }
    };
    await runAsyncFunctionsInSequence();
    setDataFields(collectionXfields);
  };

  const handleListData = async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resEndpoint = await getOneEndpoint({token, _id: idEndpoint});
      const resOneDatabase = await getOneDatabase({_id: idDatabase, token});
      const resDatabases = await listDatabases({token, idProject});
      const resParams = await listParamsEndpoint({token, idProject, idEndpoint});
      setParamData(resParams.data);
      setUrlEndpoint(resEndpoint.data.url);
      setLabelUrl(`proyectos/${resProject.project.name}/Servicio Personalizado`);
      setProject(resProject.project);
      setDatabase(resOneDatabase.data);
      setData(resDatabases.data);
      const resInfoCustom = await getCustomEndpointData({token, idProject, _id: idEndpoint});
      setInfoEndpointData(resInfoCustom.data.logicInfo.arrayLogic[0]);
      setVariableInfoData(resInfoCustom.data.logicInfo.variables);
      // console.log(resInfoCustom);
    } catch (error) {
      if (error.status === 'succes') {
        // console.log(error);
        const blockData = error.data.logicInfo.arrayLogic[0];
        setInfoEndpointData(blockData);
        setVariableInfoData(error.data.logicInfo.variables);
        // console.log(blockData);
        // const mainWorkspace = Blockly.getMainWorkspace();
        // const serializer = new Blockly.serialization.blocks.BlockSerializer();
        // serializer.load(blockData, mainWorkspace);
        // const infoVal = jsonToXml(blockData.blocks);
        // setData(infoVal);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('No se logro cargar el servicio'), 300);
      }
    }
  };

  useEffect(() => {
    if (!blocklyRef.current) {
      setRenderHelper(renderHelper + 1);
      return;
    }
    const toolbox1 = defineDBBlocks({
      collections: data,
      fields: dataFields,
    });
    blocklyWorkspace = Blockly.inject(
      'blocklyDiv',
      {
        toolbox,
        readOnly: true,
        maxInstances: {main_block: 1},
        scrollbars: true,
      },
    );
    // const xmlData = jsonToXml(data.blocks);
    // const varXML = Blockly.JavaScript.workspaceToCode(data);
    // const varDom = Blockly.Xml.textToDom(data);
    // Blockly.Xml.domToWorkspace(varDom, blocklyWorkspace);
    // defineOnChangeSpawnDBFields(blocklyWorkspace, listFieldsDatabase, token, idProject);
    // setBlocklyWorkspaceReact(blocklyWorkspace);
    setToolbox(toolbox1);
    const blankDiv = document.querySelector('.injectionDiv');
    if (blankDiv) {
      blankDiv.style.display = 'none';
    }
    handleParamData(blocklyWorkspace, toolbox1);
    handleLoad();
  }, [blocklyRef.current, renderHelper]);

  useEffect(() => {
    const loadAll = async () => {
      await handleListData();
      await getCollectionFields();
      // handleLoad();
      setLoading(false);
    };
    loadAll();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      databaseName={database?.label}
      history={history}
      idProject={idProject}
      projectName={project?.name}
      idDatabase={idDatabase}
      token={token}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/endpoints`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Servicio Personalizado
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          /* display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center', */
          p: 1,
          m: 1,
        }}
      >
        <Box>
          <Box component="div" className={classes.urlSectionContainer}>
            URL:
            {' '}
            {urlEndpoint}
          </Box>
        </Box>
        <div ref={blocklyRef} id="blocklyDiv" style={{height: '480px', width: '100%'}} />
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(RenderCustomServicesBlockly);
