import React, {useState, useEffect} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {getSizeStorageDeployment} from 'api-lofty';
import serversIcon from '../img/servers_icon.png';

const StorageChart = ({token, idProject, totalStorage}) => {
  const [onUseStorage, setOnUseStorage] = useState(0);
  const [, setLoading] = useState(true);
  const [usePercentage, setUsePercentage] = useState(0);

  const getData = async () => {
    try {
      const getStorageData = await getSizeStorageDeployment({token, idProject});
      const usedStorage = getStorageData.data.storage / 1000;
      setOnUseStorage(usedStorage);
      const totalPercent = (getStorageData.data.storage / getStorageData.data.total) * 100;
      setUsePercentage(totalPercent);
    } catch (error) {
      // No error logging
      // console.error('storage data error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [token, idProject]);

  const doughnutData = {
    labels: ['En Uso (GB)', 'Disponible (GB)'],
    datasets: [
      {
        label: 'Almacenamiento',
        data: [onUseStorage, (totalStorage - onUseStorage)],
        backgroundColor: [
          'rgba(20, 73, 153, 0.7)',
          'rgba(217, 217, 217, 0.7)',
        ],
        borderColor: [
          'rgba(20, 73, 153, 1)',
          'rgba(217, 217, 217, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: '80%',
    rotation: -Math.PI / 4, // Adjust rotation as needed
    circumference: Math.PI * 1.5, // Adjust circumference as needed
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    layout: {
      padding: 20,
    },
  };

  return (
    <div style={{
      backgroundColor: 'white',
      width: '700px',
      height: '400px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      padding: '20px',
      boxSizing: 'border-box',
    }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        top: '20px',
        left: '20px',
        right: '20px',
        width: 'calc(100% - 40px)',
        boxSizing: 'border-box',
      }}
      >
        <img src={serversIcon} alt="Icono de Servidores" style={{width: '30px', height: '30px'}} />
        <h3 style={{
          margin: 0,
          color: '#292151',
          fontWeight: 'bold',
          textAlign: 'right',
          flex: 1,
        }}
        >
          Almacenamiento
        </h3>
      </div>
      <div style={{
        width: '100%', height: '60%', position: 'relative', marginTop: '80px',
      }}
      >
        <Doughnut data={doughnutData} options={options} />
      </div>
      <div style={{
        position: 'absolute',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
      }}
      >
        <h2 style={{
          margin: 0,
          color: '#758095',
          fontWeight: 'normal',
          fontSize: '4rem',
        }}
        >
          {usePercentage.toFixed(0)}
          %
        </h2>
        <p style={{
          margin: '10px 0 0 0',
          color: '#758095',
          fontWeight: 'normal',
          fontSize: '1.5rem',
        }}
        >
          En Uso
        </p>
      </div>
    </div>
  );
};

export default StorageChart;
