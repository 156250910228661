import React from 'react';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const DocPluginExpo = ({
  idProject, url,
}) => (
  <Paper className="py-2">
    <Container>
      {/* Registrar token Plugin Push Expo */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Notificaciones Push Expo para Moviles
          </b>
          {' '}
          |
          {' '}
          Crear
        </h3>
        <p className="my-4">
          Registrar Token
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/notification/expo/add/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  AuthorizationApp
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Token de la autenticación del usuario
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  loftyTokenExpo
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Token de Expo
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de registrar token expo push */}
      {/* Eliminar expo token  */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Notificaciones Push Expo para Moviles
          </b>
          {' '}
          |
          {' '}
          Eliminar
        </h3>
        <p className="my-4">
          Eliminar Expo Token
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/notification/expo/remove/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  AuthorizationApp
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Token de la autenticación del usuario
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  loftyTokenExpo
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Expo Token
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de eliminar expo token */}
      {/* Enviar Push Notification */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Notificaciones Push Expo para Moviles
          </b>
          {' '}
          |
          {' '}
          Enviar Notificacion
        </h3>
        <p className="my-4">
          Enviar Notificacion
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/notification/expo/send/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  AuthorizationApp
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Token de la autenticación del usuario
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  message
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Mensaje que contendra el Push
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  userId
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Identificador del usuario
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de enviar notificacion */}
    </Container>
  </Paper>
);

export default DocPluginExpo;
