import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {listUserProject} from 'api-lofty';
// import {listUserProject} from '../api/user.project.api';

const limitText = (text) => {
  const limit = 25;
  if (text.length > 25) {
    return `${text.substr(0, limit)}...`;
  }
  return text;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function CardServices({
  name, description, image, onClick,
  textButton, idProject, token,
}) {
  const classes = useStyles();
  const [isAuthentication, setIsAuthentication] = useState(false);
  const [validUserProject, setValidUserProject] = useState(false);
  useEffect(async () => {
    try {
      const res = listUserProject({idProject, token});
      if (res.data.length > 0) {
        validUserProject(true);
      }
    } catch (error) {
      setValidUserProject(false);
    }
  }, []);
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {limitText(description)}
          </Typography>
          <div>
            <FormControlLabel
              control={(
                <Switch
                  checked={isAuthentication}
                  onChange={() => setIsAuthentication((prev) => !prev)}
                />
                )}
              label="Autenticacion"
            />
          </div>
        </CardContent>
        <div className={classes.controls}>
          <Button
            onClick={() => {
              if (onClick) {
                onClick(isAuthentication);
              }
            }}
          >
            {
              textButton || 'Ingresar'
            }
          </Button>
        </div>
      </div>
      <CardMedia
        className={classes.cover}
        image={image}
        title="Live from space album cover"
      />
    </Card>
  );
}
