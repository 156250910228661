import React, {useState, useEffect} from 'react';
import {getOnePaypalPlugin} from 'api-lofty';
import {Grid} from '@material-ui/core';
import ListMethodPaypal from './settings/ListMethodPaypal';
import FormConfigPaypal from './settings/FormConfigPaypal';

const SettingsPayPal = ({
  token, idPluginProject, idProject,
}) => {
  const [paypalConfig, setPaypalPlugin] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handle = async () => {
      try {
        const res = await getOnePaypalPlugin({
          idPluginProject, idProject, token,
        });
        setPaypalPlugin(res.data);
      } catch (error) {
        // error
      }
      setLoading(false);
    };
    handle();
  }, []);
  if (loading) {
    return (
      <div className="text-center">
        <h1 className="text-black">Cargando ...</h1>
      </div>
    );
  }
  return (
    <Grid className="my-4 mx-2" container>
      <Grid className="p-2" item md={6} xs={12}>
        <ListMethodPaypal
          idPaypalPlugin={paypalConfig?._id}
          idPluginProject={idPluginProject}
          idProject={idProject}
          token={token}
          paypalConfig={paypalConfig}
        />
      </Grid>
      <Grid className="p-2" item md={6} xs={12}>
        <FormConfigPaypal
          idPluginProject={idPluginProject}
          idProject={idProject}
          setPaypal={(value) => setPaypalPlugin(value)}
          token={token}
          dataPaypal={paypalConfig}
        />
      </Grid>
    </Grid>
  );
};

export default SettingsPayPal;
