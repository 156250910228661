import expoImage from '../assets/plugins/expo.png';
import changeImage from '../assets/plugins/change.png';
import pdfImage from '../assets/plugins/pdf.png';
import calendar from '../assets/plugins/calendar.png';
import paypal from '../assets/plugins/paypal.png';
import google from '../assets/plugins/google.jpg';

export default [
  {
    type: 'pushNotificationExpo',
    name: 'Notificaciones Push Moviles',
    description: 'Notificaciones para aplicaciones hechas con React Native Expo',
    image: expoImage,
  },
  {
    type: 'changeFieldDatabase',
    name: 'Notificaciones de Correo',
    description: 'Notificaciones de correo cuando se ejecuta un cambio en el administador del sistema',
    image: changeImage,
  },
  {
    type: 'pdfFile',
    name: 'Generador de PDF',
    description: 'Generador de archivos PDF',
    image: pdfImage,
  },
  {
    type: 'calendar',
    name: 'Calendario',
    description: 'Calendario para tu proyecto',
    image: calendar,
  },
  {
    type: 'paypal',
    name: 'Paypal',
    description: 'Método de pago paypal.',
    image: paypal,
  },
  {
    type: 'googleanalytics',
    name: 'Google Analytics',
    description: 'Servicio de Google Analytics',
    image: google,
  },
];

export const labelTypePlugin = (text) => {
  if (text === 'pushNotificationExpo') {
    return 'Notificacions Push Expo';
  }
  if (text === 'changeFieldDatabase') {
    return 'Notificaciones de Cambios de Datos';
  }
  if (text === 'pdfFile') {
    return 'Generador de PDF';
  }
  return '';
};

export const haveDoc = (text) => {
  if (text === 'pushNotificationExpo') {
    return true;
  }
  if (text === 'pdfFile') {
    return true;
  }
  return false;
};

export const haveData = (text) => {
  if (text === 'pushNotificationExpo') {
    return true;
  }
  if (text === 'pdfFile') {
    return true;
  }
  return false;
};

export const haveEmail = (text) => {
  if (text === 'changeFieldDatabase') {
    return true;
  }
  return false;
};

export const haveNotification = (text) => {
  if (text === 'changeFieldDatabase') {
    return true;
  }
  return false;
};

export const haveEvent = (text) => {
  if (text === 'changeFieldDatabase') {
    return true;
  }
  if (text === 'calendar') {
    return true;
  }
  return false;
};

export const isCalendar = (text) => text === 'calendar';

export const haveActions = (text) => {
  if (text === 'changeFieldDatabase') {
    return true;
  }
  return false;
};

export const havePdfFile = (text) => {
  if (text === 'pdfFile') {
    return true;
  }
  return false;
};
