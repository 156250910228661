/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import toastr from 'toastr';
import {Typography, Box, Button} from '@material-ui/core';
import {connect} from 'react-redux';
import {
  getOneProject, listAssetsProject, addAssetsProject,
  removeAssetProject,
} from 'api-lofty';
import {makeStyles} from '@material-ui/core/styles';
// import Working from '../components/Working';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import TableAssetsList from '../components/TableAssetsList';
import icon1 from '../assets/assetDashboard/addIcon.png';
import icon2 from '../assets/assetDashboard/videoIcon.png';
import icon3 from '../assets/assetDashboard/volumeIcon.png';
import 'toastr/build/toastr.min.css';
import RemoveAssetModal from '../components/RemoveAssetModal';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  formContainer: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderWidth: '2px',
    borderRadius: '1rem',
    borderStyle: 'dashed',
    borderColor: 'rgba(82, 82, 82, 1)',
    backgroundColor: 'rgba(244, 251, 255, 1)',
  },
  uploadLabelTypography: {
    color: 'rgba(41, 33, 81, 1)',
    fontWeight: '400',
    fontSize: '23px',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    justifyContent: 'space-between',
  },
  noDataFoundContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '23px',
    height: '200px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
  dragElement: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '1rem',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

const ProjectAssets = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const inputRef = React.useRef(null);
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  const [dragActive, setDragActive] = React.useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');

  // -- FOR TESTING --
  // const imageObject = {
  //   loftyFileName: 'https://placehold.co/600x400',
  //   name: 'sampleName',
  //   height: '400',
  //   width: '600',
  //   src: 'https://placehold.co/600x400',
  //   type: 'image',
  // };

  const handleInfoData = async () => {
    getOneProject({token, idProject}).then((response) => {
      setProject(response.project);
      setLabelUrl(`proyectos/${response.project.name}/Biblioteca de Medios`);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
    const res = await listAssetsProject({token, idProject});
    // const arrayTemp = [];
    // for (let index = 0; index < 15; index++) {
    //   arrayTemp.push(imageObject);
    // }
    setData(res.data);
  };

  useEffect(async () => {
    handleInfoData();
  }, []);

  async function toUpload(files) {
    // console.log(files[0]);
    try {
      await addAssetsProject({token, idProject, files: files[0]});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se subieron los archivos exitosamente'), 300);
      handleInfoData();
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se logro agregar los datos'), 300);
      }
    }
  }

  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      toUpload(e.dataTransfer.files);
    }
  }

  function handleChange(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      toUpload(e.target.files);
    }
  }

  const clickUpload = () => {
    inputRef.current.click();
  };

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  const handleUndoDelete = () => {
    setOpenDialog(false);
  };

  const handleUpdateDeletion = async () => {
    setOpenDialog(false);
    const res = await listAssetsProject({token, idProject});
    setData(res.data);
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box>
        <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input ref={inputRef} type="file" style={{display: 'none'}} multiple={false} onChange={handleChange} />
          <Box component="div" className={classes.formContainer}>
            <Typography className={classes.uploadLabelTypography}>
              Arrastra los archivos para subirlos
            </Typography>
            <Box className={classes.iconContainer}>
              <Box component="img" src={icon1} alt="image" />
              <Box component="img" src={icon2} alt="image" />
              <Box component="img" src={icon3} alt="image" />
            </Box>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonStyle}
              onClick={clickUpload}
            >
              Subir Archivo
            </Button>
          </Box>
          { dragActive
            && (
            <div
              className={classes.dragElement}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
            )}
        </form>
      </Box>
      {data && data.length > 0
        ? (
          <TableAssetsList
            data={data}
            handleDeletion={(idData) => handleOpenDeleteDialog(idData)}
          />
        )
        : (
          <Box className={classes.noDataFoundContainer}>
            <Typography>Aún no has subido ningún archivo</Typography>
          </Box>
        )}
      <Box>
        <RemoveAssetModal
          open={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          undoDelete={handleUndoDelete}
          handleDeletion={async () => {
            try {
              await removeAssetProject({token, _id: idDeletion, idProject});
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              handleUpdateDeletion();
              toastr.clear();
              setTimeout(() => toastr.success('Se elimino exitosamente la página'), 300);
            } catch (error) {
              if (error.info) {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
              } else {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n No se elimino la página'), 300);
              }
            }
          }}
        />
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ProjectAssets);
