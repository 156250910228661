import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import {Box, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  mainBox: {
    margin: '10px',
    border: '1px solid',
    borderColor: 'rgba(200, 210, 237, 1)',
    borderRadius: '10px',
  },
  avatarSection: {
    margin: '10px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  textNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
  textName: {
    color: 'rgba(41, 33, 81, 1)',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  textType: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '13px',
  },
  iconButtonArea: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  iconButtonStyle: {
    backgroundColor: 'rgba(217, 217, 217, 1)',
    height: '35px',
    width: '45px',
    borderRadius: '10px',
  },
}));

const CardUser = ({
  text, name, permission, handleInvite,
}) => {
  const classes = useStyle();
  return (
    <Box className={classes.mainBox}>
      <Paper elevation={2} style={{borderRadius: '10px'}}>
        <Container className="py-3">
          <Grid container>
            <Grid item xs={3}>
              <Box className={classes.avatarSection}>
                <Avatar>
                  {text}
                </Avatar>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.textNameContainer}>
                <Box component="div" className={classes.textName}>
                  {name}
                </Box>
                <Box component="div" className={classes.textType}>
                  {permission}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.iconButtonArea}>
                <IconButton onClick={handleInvite} className={classes.iconButtonStyle}>
                  <AddIcon style={{width: '20px', height: '20px', color: 'rgba(41, 33, 81, 1)'}} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Box>
  );
};

export default CardUser;
