import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {
  Grid, MenuItem,
  ListItemIcon, Typography,
  MenuList, // Button, IconButton,
  Box,
  Hidden,
} from '@material-ui/core';
// import Swal from 'sweetalert2';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MailIcon from '@material-ui/icons/Mail';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Layout from './Dashboard';
import {
  haveData, haveEvent, haveDoc, // labelTypePlugin,
  havePdfFile,
  haveEmail,
  haveNotification,
} from '../util/addPlugins';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import exitImg from '../img/icons/salir.svg';
import imgDoc1 from '../img/icons/documentacion_w.svg';
import imgDoc2 from '../img/icons/documentacion_b.svg';
import imgActivity1 from '../img/icons/actividad_w.svg';
import imgActivity2 from '../img/icons/actividad_b.svg';
import imgField1 from '../img/icons/campos_w.svg';
import imgField2 from '../img/icons/campos_b.svg';
import imgConfig1 from '../img/icons/configuracion_w.svg';
import imgConfig2 from '../img/icons/configuracion_b.svg';
import imgData1 from '../img/icons/datos_w.svg';
import imgData2 from '../img/icons/datos_b.svg';

const useStyle = makeStyles((theme) => ({
  exitItemTypo: {
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '18px',
    marginLeft: '7%',
    marginTop: '10%',
    [theme.breakpoints.down('lg')]: {fontSize: '13px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  fontItemsStyle: {
    fontSize: '21px',
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('1280')]: {fontSize: '11px'},
    [theme.breakpoints.down('1100')]: {fontSize: '9px'},
    [theme.breakpoints.down('950')]: {fontSize: '12px'},
  },
  exitIconStyle: {
    zIndex: 1,
    marginTop: '40px',
    marginBottom: '10%',
    marginLeft: '15%',
    width: '50px',
    height: 'auto',
    [theme.breakpoints.down('lg')]: {width: '30px'},
  },
}));

const StyledMenuField = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgField1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgField2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: theme.palette.common.white,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgField2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
      '&:hover': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
      '&:focus': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
}))(MenuItem);

const StyledMenuDoc = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgDoc1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgDoc2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: theme.palette.common.white,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgDoc2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
      '&:hover': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
      '&:focus': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
}))(MenuItem);

const StyledMenuData = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgData1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgData2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: theme.palette.common.white,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgData2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
      '&:hover': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
      '&:focus': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
}))(MenuItem);

const StyledMenuActivity = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgActivity1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgActivity2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: theme.palette.common.white,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgActivity2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
      '&:hover': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
      '&:focus': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
}))(MenuItem);

const StyledMenuConfig = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgConfig1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgConfig2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: theme.palette.common.white,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgConfig2})`,
        width: '30px',
        height: '30px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
      '&:hover': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
      '&:focus': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
}))(MenuItem);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        color: '#3F51B5',
      },
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&:focus': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      backgroundColor: theme.palette.common.white,
      color: 'black',
      '& .MuiListItemIcon-root': {
        color: '#3F51B5',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
      '&:hover': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
      '&:focus': {
        '& .MuiListItemIcon-root': {
          width: '17px !important',
          height: '17px !important',
        },
      },
    },
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
}))(MenuItem);

const PluginsProjectDashboard = ({
  children, history, pluginProjectType, idProject,
  idPluginProject, // projectName, token,
}) => {
  const DrawerMenu = (
    <Box>
      <Box
        component="img"
        src={imgLogo}
        alt="logo"
        style={{
          zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
        }}
      />
      <MenuList
        autoFocusItem
        style={{
          marginLeft: '4%',
        }}
      >
        {
            haveDoc(pluginProjectType) && (
              <StyledMenuDoc
                selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/documentation`}
                onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/documentation`)}
              >
                <ListItemIcon />
                <Typography className={useStyle().fontItemsStyle}>
                  Documentación
                </Typography>
              </StyledMenuDoc>
            )
          }
        {
            havePdfFile(pluginProjectType) && (
              <>
                <StyledMenuItem
                  selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/versionspdf`}
                  onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/versionspdf`)}
                >
                  <ListItemIcon>
                    <PictureAsPdfIcon />
                  </ListItemIcon>
                  <Typography className={useStyle().fontItemsStyle}>
                    Versiones
                  </Typography>
                </StyledMenuItem>
                <StyledMenuField
                  selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/fieldspdf`}
                  onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/fieldspdf`)}
                >
                  <ListItemIcon />
                  <Typography className={useStyle().fontItemsStyle}>
                    Campos de PDF
                  </Typography>
                </StyledMenuField>
              </>
            )
          }
        {
            haveData(pluginProjectType) && (
              <StyledMenuData
                selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/data`}
                onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/data`)}
              >
                <ListItemIcon />
                <Typography className={useStyle().fontItemsStyle}>
                  Datos
                </Typography>
              </StyledMenuData>
            )
          }
        {
          haveEvent(pluginProjectType) && (
            <StyledMenuItem
              selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/events`}
              onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/events`)}
            >
              <ListItemIcon>
                <EventSeatIcon />
              </ListItemIcon>
              <Typography className={useStyle().fontItemsStyle}>
                Eventos
              </Typography>
            </StyledMenuItem>
          )
        }
        {
          haveNotification(pluginProjectType) && (
            <StyledMenuItem
              selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/notificationsemail`}
              onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/notificationsemail`)}
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <Typography className={useStyle().fontItemsStyle}>
                Notifications
              </Typography>
            </StyledMenuItem>
          )
        }
        {
            haveEmail(pluginProjectType) && (
              <StyledMenuItem
                selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/mail`}
                onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/mail`)}
              >
                <ListItemIcon>
                  <MailIcon />
                </ListItemIcon>
                <Typography className={useStyle().fontItemsStyle}>
                  Correos
                </Typography>
              </StyledMenuItem>
            )
          }
        <StyledMenuConfig
          selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/settings`}
          onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/settings`)}
        >
          <ListItemIcon />
          <Typography className={useStyle().fontItemsStyle}>
            Configuración
          </Typography>
        </StyledMenuConfig>
        <StyledMenuActivity
          selected={window.location.pathname === `/dashboard/project/${idProject}/plugins/${idPluginProject}/activities`}
          onClick={() => history.push(`/dashboard/project/${idProject}/plugins/${idPluginProject}/activities`)}
        >
          <ListItemIcon />
          <Typography className={useStyle().fontItemsStyle}>
            Actividad
          </Typography>
        </StyledMenuActivity>
      </MenuList>
      <Box
        component="div"
        sx={{
          width: '100%', display: 'flex', justifyContent: 'flex-start', position: 'absolute', alignItems: 'center', bottom: 1, p: 1, m: 1,
        }}
      >
        <Link to={`/dashboard/project/${idProject}`}>
          <Box
            component="img"
            src={exitImg}
            alt="logo"
            className={useStyle().exitIconStyle}
          />
        </Link>
        <Typography variant="inherit" className={useStyle().exitItemTypo}>
          Salir de Plugins
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Grid container>
        <Hidden smDown>
          <Grid item md={2} style={{backgroundColor: '#144999', position: 'relative'}}>
            {DrawerMenu}
          </Grid>
        </Hidden>
        { /* Revisar Resoluciones */ }
        <Grid className="p-3" item sm={12} md={10} xs={12} style={{backgroundColor: '#F5F5F5'}}>
          <Layout data={DrawerMenu}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PluginsProjectDashboard);
