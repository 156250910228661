import React from 'react';
import {
  Dialog, Box, IconButton,
  Button, Typography,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(243, 243, 243, 1)',
    margin: '10px',
    padding: '15px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '5px',
  },
  buttonAreaContainer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    width: '100px',
    fontWeight: 'bold',
  },
}));

const RemoveSimDataDatabaseModal = ({
  open, onClose, handleDeletion, undoDelete,
}) => {
  const classes = useStyle();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Eliminar Dato de la Base de Datos
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{margin: '15px', width: '300px', padding: '5px'}}>
          <Box className={classes.messageContainer}>
            <Box component="div" style={{margin: '15px', padding: '5px'}}>
              <WarningOutlinedIcon style={{width: '50px', height: '50px', color: 'rgba(234, 232, 178, 1)'}} />
            </Box>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              ¿Estás seguro de eliminar el dato?
            </Typography>
          </Box>
          <Box className={classes.buttonAreaContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { if (undoDelete) { undoDelete(); } }}
              className={classes.buttonStyle}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              onClick={handleDeletion}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveSimDataDatabaseModal;
