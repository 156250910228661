import React, {useState} from 'react';
import {createReactEditorJS} from 'react-editor-js';
import {addAssetsProject} from 'api-lofty';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
/* import LinkTool from '@editorjs/link'; */
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';
import {getBase64} from '../util/base64';

const ReactEditorJS = createReactEditorJS();

const EditorJs = ({
  defaultValue, label,
  name, idProject, token,
  backoffice,
}) => {
  const [value, setValue] = useState('');
  const EDITOR_JS_TOOLS = {
    paragraph: Paragraph,
    table: Table,
    list: List,
    image: {
      class: ImageTool,
      config: {
        uploader: {
          uploadByFile(file) {
            if (!backoffice) {
              return addAssetsProject({
                idProject,
                token,
                files: file,
              }).then((res) => ({
                success: 1,
                file: {
                  url: res.data[0]?.src,
                },
              }));
            }
            return getBase64(file).then((res) => ({
              success: 1,
              file: {
                url: res,
              },
            }));
          },
        },
        additionalRequestHeaders: {
          Authorization: token,
        },
      },
    },
  };
  return (
    <div
      style={{
        borderColor: 'black',
        borderWidth: 1,
        padding: 10,
      }}
    >
      <p>
        {label}
      </p>
      <ReactEditorJS
        style={{
          width: '100%',
        }}
        defaultValue={{
          time: new Date(),
          blocks: defaultValue,
        }}
        tools={EDITOR_JS_TOOLS}
        onChange={async (api) => {
          const output = await api.saver.save();
          setValue(JSON.stringify(output.blocks));
        }}
      />
      <input value={value} type="text" name={name} style={{display: 'none'}} />
    </div>
  );
};

export default EditorJs;
