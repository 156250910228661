import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {updateEndpointAuth} from 'api-lofty';
// import {updateEndpointAuth} from '../api/endpoint.api';

const UpdateEndpointModal = ({
  open, onClose, successFunction, errorFunction,
  idProject, token, selectedDoc, isAuth,
}) => {
  const [loading, setLoading] = useState(false);
  const [isAuthentication, setIsAuthentication] = useState(selectedDoc?.isAuthentication);
  useEffect(() => {
    setIsAuthentication(selectedDoc?.isAuthentication);
  }, [isAuth]);
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Agregar API Key
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await updateEndpointAuth({
                idProject,
                _id: selectedDoc._id,
                isAuthentication,
                token,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <FormControlLabel
              control={(
                <Switch
                  checked={isAuthentication}
                  onChange={() => setIsAuthentication((prev) => !prev)}
                />
                )}
              label="Autenticación"
            />
          </div>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateEndpointModal;
