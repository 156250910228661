import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import {Box} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {addFieldUserProject, isInvalidSlug, listDatabases} from 'api-lofty';
// import {addFieldUserProject} from '../api/field.user.project.api';
import {fieldsLabelValue} from '../util/typesFields';
import {generateSlugSample} from '../util/generateSuggestionSlug';
// import imageCreate from '../assets/fields/create.svg';
// import {listDatabases} from '../api/database.api';

const useStyles = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));
const AddUserProjectField = ({
  open, onClose, successFunction, errorFunction,
  idProject, token, fieldData,
}) => {
  const [checkedUnique, setCheckedUnique] = useState(false);
  const [checkedIndex, setCheckedIndex] = useState(false);
  const [checkedRequired, setCheckedRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [typeField, setTypeField] = useState('');
  const [databases, setDatabases] = useState([]);
  const [isEditingLabel, setIsEditingLabel] = React.useState(false);
  const [suggestionSlug, setSuggestionSlug] = React.useState('');
  const [errorInSlug, setErrorInSlug] = useState(false);
  const [errorMessageSlug, setErrorMessageSlug] = useState('');

  useEffect(async () => {
    try {
      const resDatabases = await listDatabases(({token, idProject}));
      setDatabases(resDatabases.data);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const verifySlugValue = (value) => {
    if (isInvalidSlug(value)) {
      setErrorMessageSlug('Verifique que no tenga espacios/palabras reservadas');
      setErrorInSlug(true);
    } else {
      setErrorInSlug(false);
      setErrorMessageSlug('');
    }
  };

  function generateSuggestionSlug(value) {
    if (isEditingLabel) {
      const res = generateSlugSample(fieldData, value);
      setSuggestionSlug(res);
      setIsEditingLabel(false);
    }
  }

  function onCloseRefresh() {
    setSuggestionSlug('');
    setErrorInSlug(false);
    setErrorMessageSlug('');
    onClose();
  }
  return (
    <Dialog fullWidth open={open} onClose={() => onCloseRefresh()}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Campo
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onCloseRefresh(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <div className="text-center">
          <img alt="Crear" src={imageCreate} className="mx-auto w-75" />
        </div> */}
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await addFieldUserProject({
                token,
                idProject,
                description: e.target.description.value,
                name: e.target.name.value,
                type: e.target.type.value,
                index: checkedIndex,
                required: checkedRequired,
                unique: checkedUnique,
                relationDatabase: e.target.relation?.value,
                label: e.target.label.value,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              // console.log(error);
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="standard"
              onFocus={() => setIsEditingLabel(true)}
              onBlur={(e) => generateSuggestionSlug(e.target.value)}
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              name="name"
              type="text"
              label="Slug (Identificador Unico)"
              variant="standard"
              value={suggestionSlug}
              onChange={(e) => setSuggestionSlug(e.target.value)}
              helperText={errorMessageSlug}
              error={errorInSlug}
              onBlur={(e) => verifySlugValue(e.target.value)}
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              multiline
              rows={4}
              name="description"
              type="text"
              label="Descripción"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField
              onChange={(e) => {
                setTypeField(e.target.value);
              }}
              select
              fullWidth
              name="type"
              type="text"
              label="Tipo"
              variant="standard"
            >
              {
                fieldsLabelValue.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <Grid container className="my-4">
            <Grid item md={4}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedIndex}
                    onChange={() => setCheckedIndex((prev) => !prev)}
                  />
                )}
                label="Indexar"
              />
            </Grid>
            <Grid item md={4}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedUnique}
                    onChange={() => setCheckedUnique((prev) => !prev)}
                  />
                )}
                label="Unico"
              />
            </Grid>
            <Grid item md={4}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedRequired}
                    onChange={() => setCheckedRequired((prev) => !prev)}
                  />
                )}
                label="Requerido"
              />
            </Grid>
          </Grid>
          {
            typeField === 'relation' && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="relation"
                  type="text"
                  label="Relación de Base de Datos"
                  variant="standard"
                >
                  {
                    databases.map(({_id, label}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddUserProjectField;
