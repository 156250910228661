import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton, Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import toastr from 'toastr';
import Tabular from '../../components/Tabular';
import Layout from '../../layouts/BackofficeDashboard';
import AddNotificationDialog from '../../components/AddNotificationDialog';
import RemoveNotificationModal from '../../components/RemoveNotificationModal';
import 'toastr/build/toastr.min.css';

// const getNotifications = async ({ token }) => [];

// const sendNotification = async ({ token, notificationData }) => {};

// const deleteNotification = async ({ token, notificationId }) => { };

const useStyles = makeStyles((theme) => ({

  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '18px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  buttonSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  notificationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  notificationTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    backgroundColor: '#00bfff',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00a1e0',
    },
  },
}));

const NotificationManager = ({history, token}) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const [openAddNotification, setOpenAddNotification] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        // eslint-disable-next-line no-undef
        const data = await getNotifications({token});
        setNotifications(data);
      } catch (error) {
        toastr.error('Error al cargar las notificaciones');
      }
    };
    fetchNotifications();
  }, [token]);

  const handleOpenAddNotification = () => {
    setOpenAddNotification(true);
  };

  const handleCloseAddNotification = () => {
    setOpenAddNotification(false);
  };

  const handleSendNotification = async (type, message) => {
    const newNotification = {
      type: type === 'all' ? 'Todos' : 'Usuario Específico',
      message,
      recipient: type === 'specific' ? 'usuario123' : null,
      date: new Date().toISOString(),
      status: 'Enviado',
    };

    try {
      // eslint-disable-next-line no-undef
      await sendNotification({token, notificationData: newNotification});
      setNotifications([...notifications, newNotification]);
      toastr.success('Notificación enviada');
    } catch (error) {
      toastr.error('Error al enviar la notificación');
    }
  };

  const handleOpenRemoveModal = (notification) => {
    setNotificationToDelete(notification);
    setOpenRemoveModal(true);
  };

  const handleCloseRemoveModal = () => {
    setOpenRemoveModal(false);
    setNotificationToDelete(null);
  };

  const handleDeleteNotification = async () => {
    try {
      // eslint-disable-next-line no-undef
      await deleteNotification({token, notificationId: notificationToDelete.id});
      setNotifications(notifications.filter((n) => n !== notificationToDelete));
      toastr.success('Notificación eliminada');
    } catch (error) {
      toastr.error('Error al eliminar la notificación');
    }
    handleCloseRemoveModal();
  };

  const columns = [
    {
      title: 'Tipo de Notificación',
      field: 'type',
    },
    {
      title: 'Mensaje',
      field: 'message',
    },
    {
      title: 'Destinatario',
      field: 'recipient',
      render: (rowData) => rowData.recipient || 'Todos',
    },
    {
      title: 'Fecha',
      field: 'date',
    },
    {
      title: 'Estado',
      field: 'status',
    },
    {
      title: 'Acciones',
      field: 'actions',
      render: (rowData) => (
        <div>
          <IconButton onClick={() => handleOpenRemoveModal(rowData)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <Layout history={history}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        Gestionar Notificaciones
      </Typography>
      <Box className={classes.descriptionSection}>
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          Aquí puedes enviar notificaciones a todos los usuarios o a un usuario en específico.
        </Typography>
      </Box>

      <Box className={classes.notificationHeader}>
        <Box className={classes.notificationTitle}>
          <ArrowBackIcon style={{marginRight: '10px'}} />
          <Typography variant="h6" style={{fontWeight: 'bold'}}>
            Notificaciones
          </Typography>
        </Box>
        <Button
          variant="contained"
          className={classes.addButton}
          startIcon={<AddIcon />}
          onClick={handleOpenAddNotification}
        >
          Crear Notificación
        </Button>
      </Box>

      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Tabular
          title="Historial de Notificaciones"
          columns={columns}
          data={notifications}
        />
      </Box>
      <AddNotificationDialog
        open={openAddNotification}
        onClose={handleCloseAddNotification}
        onSend={handleSendNotification}
      />
      <RemoveNotificationModal
        open={openRemoveModal}
        onClose={handleCloseRemoveModal}
        handleDeletion={handleDeleteNotification}
      />
    </Layout>
  );
};

export default NotificationManager;
