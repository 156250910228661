import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {Box} from '@material-ui/core';
import CardPlan from './CardPlan';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    margin: '20px',
    display: 'flex',
    padding: '5px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
    margin: '10px',
    color: 'rgba(32, 21, 73, 1)',
    backgroundColor: '#fff',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#fff',
      backgroundColor: 'rgba(32, 21, 73, 1)',
    },
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderTopWidth: 20,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: 'rgba(32, 21, 73, 1)',
    marginTop: '-10px',
  },
  triangleInv: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderBottomWidth: 20,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#fff',
    marginTop: '-10px',
  },
  buttonAndShapeContainer: {
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  lowerTextContainer: {
    display: 'flex',
    margin: '10px',
    padding: '5px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  lowerTextArea: {
    width: '400px',
    fontSize: '14px',
    textAlign: 'center',
    color: 'rgba(32, 21, 73, 1)',
  },
}));

const ListMembership = ({handleNext, items}) => {
  const [planActual, setPlanActual] = useState('annual');
  const [selectedMonth, setSelectedMonth] = useState(false);
  const [selectedYear, setSelectedYear] = useState(true);
  const itemData = items.sort((a, b) => a.price - b.price);

  const handleToggle = () => {
    if (selectedMonth) {
      setSelectedMonth(false);
      setSelectedYear(true);
      setPlanActual('annual');
    } else {
      setSelectedMonth(true);
      setSelectedYear(false);
      setPlanActual('monthly');
    }
  };

  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.buttonsContainer}>
        <Box className={classes.buttonAndShapeContainer}>
          {/* <ToggleButton
              selected={selectedMonth}
              onClick={() => handleToggle()}
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
            >
            Mensual
          </ToggleButton> */}
          {selectedMonth
            ? (
              <Box className={classes.triangle} />
            )
            : (
              <Box className={classes.triangleInv} />
            )}
        </Box>
        <Box className={classes.buttonAndShapeContainer}>
          <ToggleButton
            selected={selectedYear}
            onClick={() => handleToggle()}
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
          >
            Anual
          </ToggleButton>
          {selectedYear
            ? (
              <Box className={classes.triangle} />
            )
            : (
              <Box className={classes.triangleInv} />
            )}
        </Box>
      </Box>
      {/* <Box className={classes.lowerTextContainer}>
        <Box className={classes.lowerTextArea}>
          Ahorra hasta un 20% en suscripciones anuales.
          Cambia de plan en cualquier momento. Se aplican condiciones.
        </Box>
      </Box> */}
      <Grid spacing={2} container>
        {
          itemData.map((doc) => {
            if (doc.typePayment === planActual) {
              return (
                <Grid item md={4}>
                  <CardPlan
                    type={doc.type}
                    price={doc.price}
                    collabs={doc.limitColaborators}
                    dataTest={doc.limitDatatest}
                    verPage={doc.limitVersionPage}
                    assetStorage={doc.assetStorage}
                    appweb={doc.appWeb}
                    backoffice={doc.backoffice}
                    database={doc.database}
                    descuentoEC={doc.descuentoEC}
                    onClick={() => handleNext(doc)}
                  />
                </Grid>
              );
            }
            return true;
          })
        }
      </Grid>
    </Box>
  );
};

export default ListMembership;
