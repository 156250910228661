import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import reducers from './reducer/index';
import './styles/index.css';

const store = createStore(reducers, applyMiddleware(thunk));

const generalTheme = createMuiTheme({
  palette: {
    primary: {main: '#29B2EF', contrastText: '#201549'},
    secondary: {main: '#201549', contrastText: '#ffffff'},
    error: {main: '#EF6629'},
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={generalTheme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
