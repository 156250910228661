export const mainDashboardTutorial = [
  {
    content: 'Bienvenido a Lofty Apps',
    placement: 'center',
    target: 'body',
  },
  {
    title: 'Sobre Proyectos',
    content: 'En esta pestaña, encontraras los proyectos creados o donde colaboras en nuestra plataforma',
    placement: 'right',
    target: '#projects_platform',
  },
  {
    title: 'Sobre Plantillas',
    content: 'En esta pestaña, encontraras las distintas plantillas para crear tus paginas en nuestra plataforma.',
    placement: 'right',
    target: '#templates_platform',
  },
  {
    title: 'Sobre Invitaciones',
    content: 'Aqui encontraras todas las invitaciones que recibes para colaborar en otros proyectos',
    placement: 'right',
    target: '#invites_platform',
  },
  {
    title: 'Sobre Mi Perfil',
    content: 'En esta pestaña, encontraras toda la informacion sobre tu usuario',
    placement: 'right',
    target: '#profile_platform',
  },

  {
    title: 'Agendar Reunión',
    content: 'En esta pestaña, puedes programar reuniones con Lofty Apps',
    placement: 'right',
    target: '#schedule_meeting',
  },
  {
    title: 'Afiliados',
    content: 'Aqui puedes enviar tu petición de afiliación',
    placement: 'right',
    target: '#affiliates_info',
  },

  {
    title: 'Actividad',
    content: 'En esta pestaña, puedes revisar y seguir el historial de tus actividades.',
    placement: 'right',
    target: '#activity',
  },

];

export const projectDashboardTutorial = [
  {
    content: 'Bienvenido al Dashboard de Lofty',
    placement: 'center',
    target: 'body',
  },
  {
    title: 'Sobre el Dashboard',
    content: 'En esta pestaña encontraras la informacion basica de tu proyecto.',
    placement: 'right',
    target: '#dashboard_platform',
  },
  {
    title: 'Sobre Método de Pago',
    content: 'En esta pestaña encontraras la sección para solicitar una pasarela de pago para tu producto digital',
    placement: 'right',
    target: '#payment_platform',
  },
  {
    title: 'Sobre Coleccion de Datos',
    content: 'Aqui encontraras encontraras las distintas colecciones de datos para almacenar toda tu informacion.',
    placement: 'right',
    target: '#database_platform',
  },
  {
    title: 'Sobre Paginas',
    content: 'Aqui encontraras todas las paginas creadas para tu proyecto.',
    placement: 'right',
    target: '#pages_platform',
  },
  {
    title: 'Sobre Usuarios',
    content: 'En esta pestaña encontraras la informacion de los usuarios que se han autenticado en tu proyecto.',
    placement: 'right',
    target: '#users_platform',
  },
  {
    title: 'Sobre Equipo',
    content: 'En esta pestaña encontraras a los miembros de tu equipo colaborando en tu proyecto.',
    placement: 'right',
    target: '#teams_platform',
  },
  {
    title: 'Sobre Plugins',
    content: 'En esta pestaña encontraras los distintos plugins que puedes usar en tu proyecto como Calendarios, metodos de pago, etc.',
    placement: 'right',
    target: '#plugins_platform',
  },
  {
    title: 'Sobre API Key test',
    content: 'En esta pestaña puedes generar codigos o llaves para un API.',
    placement: 'right',
    target: '#api_platform',
  },
  {
    title: 'Sobre Multimedia',
    content: 'Aqui encontraras cualquier tipo de multimedia que has guardado en tu proyecto.',
    placement: 'right',
    target: '#media_platform',
  },
  {
    title: 'Sobre Actividad',
    content: 'Aqui encontraras la bitacora de las actividades realizadas dentro de tu proyecto.',
    placement: 'right',
    target: '#logsproject_platform',
  },
  {
    title: 'Sobre Configuracion',
    content: 'En esta pestaña se encuentran los ajustes de tu proyecto, credenciales, y otros datos importantes.',
    placement: 'right',
    target: '#config_platform',
  },
  {
    title: 'Historial de Pago',
    content: 'En esta sección puedes revisar y gestionar el historial de pagos asociados a tu proyecto.',
    placement: 'right',
    target: '#log_payment',
  },

  {
    title: 'Sobre Despliegue',
    content: 'Aqui realizas el proceso para desplegar tu proyecto.',
    placement: 'right',
    target: '#deploy_platform',
  },
];
