import React, {useState, useEffect} from 'react';
import toastr from 'toastr';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Box, Typography} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import {
  updateFieldDatabase, listValueStringField, addValueStringField,
  deleteValueString,
} from 'api-lofty';
import 'toastr/build/toastr.min.css';
import {stringTypeLabelValue} from '../util/typesFields';
// import {updateFieldDatabase} from '../api/fields.database.api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    // overflow: 'auto',
    // maxHeight: 100,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const UpdateFieldDialog = ({
  open, onClose, successFunction, errorFunction,
  idDatabase, idProject, token, docUpdate,
}) => {
  const classes = useStyles();
  const [checkedUnique, setCheckedUnique] = useState(docUpdate?.unique);
  const [checkedIndex, setCheckedIndex] = useState(docUpdate?.index);
  const [checkedRequired, setCheckedRequired] = useState(docUpdate?.required);
  const [checkedIsLabel, setCheckedIsLabel] = useState(docUpdate?.isLabel);
  const [listDefaultValues, setListDefaultValues] = useState([]);
  const [defaultValueString, setDefaultValueString] = useState('');
  const [defaultValueName, setDefaultValueName] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [checkedDefaultValues, setCheckedDefaultValues] = useState(false);
  const [typeString, setTypeString] = useState(docUpdate?.typeString);

  const fetchList = async () => {
    if (docUpdate) {
      const idFieldDatabase = docUpdate._id;
      const res = await listValueStringField({idFieldDatabase, idProject, token});
      if (res.data.length > 0 === 'string') {
        setCheckedDefaultValues(true);
      }
      setListDefaultValues(res.data);
    }
  };

  function findData() {
    let retVal = false;
    if (listDefaultValues.find((element) => element.slug === defaultValueString)) {
      retVal = true;
    }
    return retVal;
  }

  const addDefaultValueChecker = async () => {
    if (!findData()) {
      // const obj = {label: defaultValueName, slug: defaultValueString};
      // se((prevArray) => [...prevArray, obj]);
      try {
        const label = defaultValueName;
        const slug = defaultValueString;
        const idFieldDatabase = docUpdate._id;
        await addValueStringField({
          token, slug, label, idFieldDatabase, idDatabase, idProject,
        });
        setDefaultValueString('');
        setDefaultValueName('');
        fetchList();
      } catch (error) {
        // console.log(error);
      }
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('error: \n Ya existe la variable. \n Verifique el slug'), 300);
    }
  };

  const removeDefaultValue = (value) => {
    setIdDeletion(value._id);
    setOpenDialog(true);
    // setDefaultValueData((current) => current.filter((data) => data.value !== value));
  };

  useEffect(() => {
    fetchList();
    setCheckedUnique(docUpdate?.unique);
    setCheckedRequired(docUpdate?.required);
    setCheckedIndex(docUpdate?.index);
    setCheckedIsLabel(docUpdate?.isLabel);
  }, [docUpdate]);
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Actualizar Campo
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <div className="text-center">
          <img alt="Crear" src={imageCreate} className="mx-auto w-75" />
        </div> */}
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await updateFieldDatabase({
                token,
                idDatabase,
                idProject,
                description: e.target.description.value,
                index: checkedIndex,
                required: checkedRequired,
                unique: checkedUnique,
                relationDatabase: e.target.relation?.value,
                label: e.target.label.value,
                _id: docUpdate._id,
                isLabel: checkedIsLabel,
                typeString,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              //
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="standard"
              defaultValue={docUpdate?.label}
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              multiline
              rows={4}
              name="description"
              type="text"
              label="Descripción"
              variant="standard"
              defaultValue={docUpdate?.description}
            />
          </div>
          <Grid container className="my-4">
            <Grid item md={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedIndex}
                    onChange={() => setCheckedIndex((prev) => !prev)}
                  />
                )}
                label="Indexar"
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedUnique}
                    onChange={() => setCheckedUnique((prev) => !prev)}
                  />
                )}
                label="Unico"
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedRequired}
                    onChange={() => setCheckedRequired((prev) => !prev)}
                  />
                )}
                label="Requerido"
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedIsLabel}
                    onChange={() => setCheckedIsLabel((prev) => !prev)}
                  />
                )}
                label="Etiqueta"
              />
            </Grid>
          </Grid>
          {docUpdate?.type === 'string' && (
            <div className="my-4">
              <FormControlLabel
                control={
                  <Checkbox checked={checkedDefaultValues} onClick={() => setCheckedDefaultValues(!checkedDefaultValues)} name="checkedB" color="primary" />
                }
                label="Valores Por defecto"
              />
            </div>
          )}
          {docUpdate?.typeString && (
            <div className="my-4">
              <TextField
                onChange={(e) => setTypeString(e.target.value)}
                defaultValue={docUpdate.typeString}
                select
                fullWidth
                name="typeString"
                type="text"
                label="Tipo de String"
                variant="standard"
              >
                {
                stringTypeLabelValue.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
              </TextField>
            </div>
          )}
          {(listDefaultValues.length > 0 || checkedDefaultValues) && (
            <div className="my-4">
              <div className="my-4">
                <b>Campos por defecto</b>
              </div>
              <div className="my-4">
                <TextField
                  fullWidth
                  type="text"
                  label="Etiqueta"
                  variant="standard"
                  value={defaultValueName}
                  onChange={(e) => setDefaultValueName(e.target.value)}
                />
              </div>
              <div className="my-4">
                <TextField
                  fullWidth
                  type="text"
                  label="Slug"
                  variant="standard"
                  value={defaultValueString}
                  onChange={(e) => setDefaultValueString(e.target.value)}
                />
              </div>
              <Box component="div" className="my-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addDefaultValueChecker}
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: '#29B2EF',
                    fontFamily: [
                      'Nunito',
                      'Roboto',
                      'Helvetica Neue',
                      'Arial',
                      'sans-serif',
                    ].join(','),
                    textTransform: 'none',
                  }}
                >
                  Agregar Valor Por defecto
                </Button>
              </Box>
              <Box component="div" className="my-4">
                <List className={classes.root}>
                  {listDefaultValues.map((option) => (
                    <ListItem>
                      <ListItemText
                        primary={option.label}
                        secondary={option.slug}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => removeDefaultValue(option)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </div>
          )}
          <div>
            <Dialog open={openDialog}>
              <DialogTitle>
                Eliminar
              </DialogTitle>
              <DialogContent>
                <Typography
                  style={{
                    position: 'relative',
                    bottom: '10px',
                  }}
                >
                  ¿Estás seguro de eliminar la variable?
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        try {
                          await deleteValueString({token, _id: idDeletion});
                          toastr.options = {
                            positionClass: 'toast-top-right',
                            hideDuration: 300,
                            timeOut: 6000,
                          };
                          toastr.clear();
                          setTimeout(() => toastr.success('Se elimino exitosamente la variable'), 300);
                          setIdDeletion('');
                          setOpenDialog(false);
                          fetchList();
                        } catch (error) {
                          if (error.info) {
                            toastr.options = {
                              positionClass: 'toast-top-right',
                              hideDuration: 300,
                              timeOut: 6000,
                            };
                            toastr.clear();
                            setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
                          } else {
                            toastr.options = {
                              positionClass: 'toast-top-right',
                              hideDuration: 300,
                              timeOut: 6000,
                            };
                            toastr.clear();
                            setTimeout(() => toastr.error('error: \n No se elimino el dato'), 300);
                          }
                        }
                      }}
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </div>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateFieldDialog;
