import React, {useState, useEffect} from 'react';
import toastr from 'toastr';
import TodayIcon from '@material-ui/icons/Today';
import DeleteIcon from '@material-ui/icons/Delete';
import {listPluginCalendar, listPluginCalendarSchedule, removePluginCalendar} from 'api-lofty';
import {Button, Paper, IconButton} from '@material-ui/core';
import Tabular from '../../components/Tabular';
import DialogAddCalendar from './DialogAddCalendar';
import AddSchedule from './AddSchedule';

const ConfigCalendar = ({
  token, idProject, idPluginProject,
}) => {
  const [calendars, setCalendars] = useState([]);
  const [schedule, setSchedule] = useState();
  const [openAddCalendar, setOpenAddCalendar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState();
  const handleData = async () => {
    try {
      const resCalendars = await listPluginCalendar({
        idPluginProject, idProject, token,
      });
      setCalendars(resCalendars.data);
    } catch (error) {
      // error
    }
    setLoading(false);
  };
  useEffect(() => {
    handleData();
  }, []);
  return (
    <div className="my-3">
      <Paper className="p-3">
        <div className="text-right my-4">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => {
              setOpenAddCalendar(true);
            }}
          >
            Agregar
          </Button>
        </div>
        <Tabular
          title="Calendarios"
          ready={loading}
          data={calendars}
          columns={[
            {
              title: 'Etiqueta',
              field: 'label',
            },
            {
              title: 'Slug',
              field: 'slug',
            },
            {
              title: 'Acciones',
              field: '_id',
              render: (rowData) => {
                const {_id} = rowData;
                if (!_id) {
                  return <></>;
                }
                return (
                  <>
                    <IconButton
                      onClick={async () => {
                        try {
                          const resSchedule = await listPluginCalendarSchedule({
                            token, idPluginCalendar: _id, idProject,
                          });
                          setSelectedCalendar(_id);
                          if (resSchedule.data.length > 0) {
                            setSchedule(resSchedule.data[0]);
                          } else {
                            setSchedule({});
                          }
                          setOpenSchedule(true);
                        } catch (error) {
                          if (error.info) {
                            toastr.error(error.info);
                          } else {
                            toastr.error('Error al obtener el dato');
                          }
                        }
                      }}
                    >
                      <TodayIcon />
                    </IconButton>
                    <IconButton
                      onClick={async () => {
                        try {
                          await removePluginCalendar({
                            _id, token,
                          });
                          toastr.success('Se elimino el dato de manera exitosa');
                        } catch (error) {
                          if (error.info) {
                            toastr.error(error.info);
                          } else {
                            toastr.error('Ocurrio un problema, intente más tarde');
                          }
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                );
              },
            },
          ]}
        />
      </Paper>
      <DialogAddCalendar
        idPluginProject={idPluginProject}
        idProject={idProject}
        onClose={() => setOpenAddCalendar(false)}
        open={openAddCalendar}
        token={token}
        successFunction={() => {
          toastr.success('Se aagrego de manera exitosa el calendario');
          handleData();
        }}
        errorFunction={(error) => {
          if (error.info) {
            toastr.error(error.info);
          } else {
            toastr.error('Error por favor comunicarse con soporte técnico');
          }
        }}
      />
      <AddSchedule
        open={openSchedule}
        onClose={() => setOpenSchedule(false)}
        schedule={schedule}
        errorFunction={(error) => {
          if (error.info) {
            toastr.error(error.info);
          } else {
            toastr.error('Error por favor intente más tarde');
          }
        }}
        idPluginCalendar={selectedCalendar}
        idProject={idProject}
        token={token}
        idPluginProject={idPluginProject}
        successFunction={() => {
          setSelectedCalendar(undefined);
          setOpenSchedule(false);
          toastr.success('Se agrego los horarios de manera exitosa');
        }}
      />
    </div>
  );
};

export default ConfigCalendar;
