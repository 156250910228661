import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const limitText = (text) => {
  const limit = 25;
  if (text.length > 25) {
    return `${text.substr(0, limit)}...`;
  }
  return text;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  titleText: {
    color: '#201549',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
}));

export default function CardProject({
  name, description, image, onClick,
  textButton, secondButton, onClickSecondButton, textSecondButton,
  redirectToVisualizer, typeItem,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.titleText}>
            {name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {limitText(description)}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          <Button
            style={{
              margin: '5px',
              backgroundColor: '#292151',
              color: '#fff',
            }}
            variant="contained"
            onClick={onClick}
          >
            {
              textButton || 'Ingresar'
            }
          </Button>
          {
            secondButton && (
              <Button style={{margin: '5px'}} onClick={onClickSecondButton}>
                {
                  textSecondButton || 'Otra funcion'
                }
              </Button>
            )
          }
        </div>
        <div className={classes.controls}>
          {
            typeItem && typeItem.includes('custom') && (
              <Button style={{margin: '5px'}} onClick={redirectToVisualizer}>
                Visualizar Personalizado
              </Button>
            )
          }
        </div>
      </div>
      {
        image && (
          <CardMedia
            className={classes.cover}
            image={image}
            title="Live from space album cover"
          />
        )
      }
    </Card>
  );
}
