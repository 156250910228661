/* eslint-disable no-underscore-dangle */
import React, {useState} from 'react';
// import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import JoyRide from 'react-joyride';
import {InlineWidget} from 'react-calendly';
import {
  Box,
} from '@material-ui/core';
import {
  tutorialStatus,
} from 'api-lofty';
import {deletetoken} from '../actions/auth.actions';
import DashboardLayout from '../layouts/PrincipalDasboard';
import ModalShowTutorial from '../components/ModalShowTutorial';
import {mainDashboardTutorial} from '../util/tutorialData';
import 'toastr/build/toastr.min.css';

const CalendarPage = ({history, token, user}) => {
  const [openNewInPlatform, setOpenNewInPlatform] = useState(false);
  const [withTutorial, setWithTutorial] = useState(false);

  async function handleModalRes() {
    setOpenNewInPlatform(false);
    setWithTutorial(true);
    await tutorialStatus({token, typeTutorialUpdate: 'init'});
    window.localStorage.setItem('newInPlatformValue', 'true');
  }

  async function handleNoTutorial() {
    setOpenNewInPlatform(false);
    await tutorialStatus({token, typeTutorialUpdate: 'init'});
    window.localStorage.setItem('newInPlatformValue', 'true');
  }

  function handleRedoTutorial() {
    setWithTutorial(true);
  }

  return (
    <DashboardLayout history={history} handleTutorial={() => handleRedoTutorial()} token={token}>
      <ModalShowTutorial
        open={openNewInPlatform}
        handleClose={() => setOpenNewInPlatform(false)}
        handleOpenTutorial={() => handleModalRes()}
        handleWithoutTutorial={() => handleNoTutorial()}
        textinfo="Desea ver un tutorial corto del inicio de la plataforma?"
      />
      <Box>
        {withTutorial
       && (
       <JoyRide
         continuous
         hideCloseButton
         scrollToFirstStep
         showProgress
         showSkipButton
         steps={mainDashboardTutorial}
         locale={{
           back: 'Anterior', close: 'Cerrar', last: 'Ultimo', next: 'Siguiente', open: 'Abrir dialogo', skip: 'Saltar',
         }}
         styles={{
           buttonNext: {
             backgroundColor: 'rgba(41, 33, 81, 1)',
           },
           buttonBack: {
             backgroundColor: 'rgba(41, 178, 239, 1)',
             borderRadius: 4,
             color: '#fff',
           },
         }}
       />
       )}
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', minHeight: 500, p: 1, m: 1,
        }}
      >
        <InlineWidget
          prefill={{
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          }}
          url="https://calendly.com/argesware"
        />
      </Box>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
