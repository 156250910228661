/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import toastr from 'toastr';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';
import {getProjectTemplates, createPaymentCheckoutTemplate} from 'api-lofty/lib';
// import toastr from 'toastr';
import Layout from '../layouts/PrincipalDasboard';
import LoadingPage from './LoadingPage';
import TemplateCartInfo from '../components/TemplateCartInfo';
import TemplateReceipt from '../components/TemplateReceipt';
// import sample from '../assets/sample.png';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  titleBox: {
    width: 'auto',
    marginTop: '5px',
    marginBottom: '15px',
  },
  mainTitle: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '22px',
    fontWeight: '700',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  boxMainContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

const CheckoutTemplate = ({history, match, token}) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  // const {params} = match;
  // const {idProject} = params;
  // const [project, setProject] = useState(undefined);
  const {params} = match;
  const {idTemplate} = params;
  const [data, setData] = useState();

  const handlePreview = () => {
    //
  };

  const handleContinueShopping = () => {
    //
  };

  const handlePurchase = async () => {
    // console.log('-- PURCHASE ENDPOINT --');
    try {
      const data = await createPaymentCheckoutTemplate({
        token, idTemplateProject: idTemplate,
      });
      window.location.href = data.data.processUrl;
    } catch (error) {
      if (error.info) {
        toastr.error(error.info);
      } else {
        toastr.error('Ocurrio un error al procesar el pago, intente nuevamente');
      }
    }
  };

  useEffect(async () => {
    try {
      const templateData = await getProjectTemplates({token});
      const findTemplate = templateData.data.find((index) => (index._id === idTemplate));
      const temp = {
        template: findTemplate.name,
        description: findTemplate.description,
        image: findTemplate.url,
        total: findTemplate.price,
        slug: findTemplate.slug,
        // items: [
        //   {item: 'Lorem Ipsum', itemCost: '20'},
        //   {item: 'Lorem Ipsum', itemCost: '20'},
        //   {item: 'Lorem Ipsum', itemCost: '20'},
        //   {item: 'Lorem Ipsum', itemCost: '20'},
        //   {item: 'Lorem Ipsum', itemCost: '20'},
        // ],
      };
      setData(temp);
      setLoading(false);
      if (localStorage.getItem('fromTemplatePurchase')) {
        localStorage.removeItem('fromTemplatePurchase');
      }
    } catch (error) {
      // console.log(error);
      if (!token) {
        // console.log(history.location.pathname);
        localStorage.setItem('fromTemplatePurchase', history.location.pathname);
        history.push('/');
      }
    }
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} token={token}>
      <Box>
        <Box className={classes.titleBox}>
          <Typography className={classes.mainTitle}>
            Resumen de compra de plantilla
          </Typography>
        </Box>
        <Box className={classes.boxMainContent}>
          <TemplateCartInfo
            data={data}
            continueShopping={() => handleContinueShopping()}
            preview={() => handlePreview}
          />
          <TemplateReceipt data={data} proceedToPurchase={handlePurchase} />
        </Box>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(CheckoutTemplate);
