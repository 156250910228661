import React from 'react';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const DocApiEmail = ({
  idProject, loading, fields, url, data,
}) => (
  <Paper className="py-2">
    <Container>
      {/* Crear usuario */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Usuario Correo y Contraseña
          </b>
          {' '}
          |
          {' '}
          Crear
        </h3>
        <p className="my-4">
          Crear nuevo usuario
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/user/create/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              loading ? (
                <TableRow>
                  <TableCell colSpan="4" align="center">Cargando ...</TableCell>
                </TableRow>
              ) : (
                <>
                  {
                    fields.length === 0 ? (
                      <>
                        <TableRow>
                          <TableCell>
                            loftyName
                          </TableCell>
                          <TableCell>
                            string
                          </TableCell>
                          <TableCell>
                            Si
                          </TableCell>
                          <TableCell>
                            Nombre del usuario
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            loftyEmail
                          </TableCell>
                          <TableCell>
                            string
                          </TableCell>
                          <TableCell>
                            Si
                          </TableCell>
                          <TableCell>
                            Correo del usuario
                          </TableCell>
                        </TableRow>
                        {
                          data.haveUserName && (
                            <TableRow>
                              <TableCell>
                                loftyUsername
                              </TableCell>
                              <TableCell>
                                string
                              </TableCell>
                              <TableCell>
                                Si
                              </TableCell>
                              <TableCell>
                                Usuario
                              </TableCell>
                            </TableRow>
                          )
                        }
                        <TableRow>
                          <TableCell>
                            loftyPassword
                          </TableCell>
                          <TableCell>
                            string
                          </TableCell>
                          <TableCell>
                            Si
                          </TableCell>
                          <TableCell>
                            Contraseña del usuario
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell>
                            loftyName
                          </TableCell>
                          <TableCell>
                            string
                          </TableCell>
                          <TableCell>
                            Si
                          </TableCell>
                          <TableCell>
                            Nombre del usuario
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            loftyEmail
                          </TableCell>
                          <TableCell>
                            string
                          </TableCell>
                          <TableCell>
                            Si
                          </TableCell>
                          <TableCell>
                            Correo del usuario
                          </TableCell>
                        </TableRow>
                        {
                          data.haveUserName && (
                            <TableRow>
                              <TableCell>
                                loftyUsername
                              </TableCell>
                              <TableCell>
                                string
                              </TableCell>
                              <TableCell>
                                Si
                              </TableCell>
                              <TableCell>
                                Usuario
                              </TableCell>
                            </TableRow>
                          )
                        }
                        <TableRow>
                          <TableCell>
                            loftyPassword
                          </TableCell>
                          <TableCell>
                            string
                          </TableCell>
                          <TableCell>
                            Si
                          </TableCell>
                          <TableCell>
                            Contraseña del usuario
                          </TableCell>
                        </TableRow>
                        {
                          fields.map((field) => (
                            <TableRow>
                              <TableCell>
                                {field.name}
                              </TableCell>
                              <TableCell>
                                {field.type}
                              </TableCell>
                              <TableCell>
                                {
                                  field.required ? 'Si' : 'No'
                                }
                              </TableCell>
                              <TableCell>
                                {
                                  field.description
                                }
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </>
                    )
                  }
                </>
              )
            }
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de crear */}
      {/* Actualizar usuario */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Usuario Correo y Contraseña
          </b>
          {' '}
          |
          {' '}
          Actualizar
        </h3>
        <p className="my-4">
          Actualizar información del usuario
        </p>
        <p className="my-4">
          <Chip label="PUT" style={{backgroundColor: 'burlywood', color: 'white'}} />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/user/update/info/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  AuthorizationApp
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Token de la autenticación del usuario
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              loading ? (
                <TableRow>
                  <TableCell colSpan="4" align="center">Cargando ...</TableCell>
                </TableRow>
              ) : (
                <>
                  {
                    fields.length === 0 ? (
                      <TableRow>
                        <TableCell>
                          loftyName
                        </TableCell>
                        <TableCell>
                          string
                        </TableCell>
                        <TableCell>
                          Si
                        </TableCell>
                        <TableCell>
                          Nombre del usuario
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        <TableRow>
                          <TableCell>
                            loftyName
                          </TableCell>
                          <TableCell>
                            string
                          </TableCell>
                          <TableCell>
                            Si
                          </TableCell>
                          <TableCell>
                            Nombre del usuario
                          </TableCell>
                        </TableRow>
                        {
                          fields.map((field) => (
                            <TableRow>
                              <TableCell>
                                {field.name}
                              </TableCell>
                              <TableCell>
                                {field.type}
                              </TableCell>
                              <TableCell>
                                {
                                  field.required ? 'Si' : 'No'
                                }
                              </TableCell>
                              <TableCell>
                                {
                                  field.description
                                }
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </>
                    )
                  }
                </>
              )
            }
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de actualizar */}
      {/* iniciar sesion */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Usuario Correo y Contraseña
          </b>
          {' '}
          |
          {' '}
          Iniciar sesión
        </h3>
        <p className="my-4">
          Iniciar sesión
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/user/login/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  loftyEmail
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Correo del usuario
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  loftyPassword
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Contraseña del usuario
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de iniciar sesion */}
      {/* Cambiar la contraseña */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Usuario Correo y Contraseña
          </b>
          {' '}
          |
          {' '}
          Cambiar Contraseña
        </h3>
        <p className="my-4">
          Cambiar contraseña
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/user/change/password/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  AuthorizationApp
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Token de la autenticación del usuario
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  newPassword
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Nueva contraseña
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  confirmNewPassword
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Confirmar contraseña
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de cambiar contraseña */}
      {/* Cerrar Sesion */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Usuario Correo y Contraseña
          </b>
          {' '}
          |
          {' '}
          Cerrar sesión
        </h3>
        <p className="my-4">
          Cerrar sesión
        </p>
        <p className="my-4">
          <Chip label="GET" color="default" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/user/logout/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  AuthorizationApp
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Token de la autenticación del usuario
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de Cerrar Sesion */}
      {/* Obtener Usuario */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Usuario Correo y Contraseña
          </b>
          {' '}
          |
          {' '}
          Obtener Usuario
        </h3>
        <p className="my-4">
          Obtener información del usuario autenticado
        </p>
        <p className="my-4">
          <Chip label="GET" color="default" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/user/logged/user/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  AuthorizationApp
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Token de la autenticación del usuario
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de Obtener usuario */}
      {/* crear token para contraseña */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Olvidar contraseña
          </b>
          {' '}
          |
          {' '}
          Crear Token
        </h3>
        <p className="my-4">
          Servicio que crea token para resetear contraseña
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/reset/create/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  email
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Correo del usuario para resetear contraseña
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de crear token para contraseña */}
      {/* verificar token para contraseña */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Olvidar contraseña
          </b>
          {' '}
          |
          {' '}
          Verificar Token
        </h3>
        <p className="my-4">
          Servicio que crea token para resetear contraseña
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/reset/verify/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  token
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  token para verificar la contraseña
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  password
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Nueva contraseña
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  confirmPassword
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  Confirmar nueva contraseña
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de verificar token para contraseña */}
    </Container>
  </Paper>
);

export default DocApiEmail;
