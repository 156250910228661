/* eslint-disable arrow-body-style */
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import {Box, TextField} from '@material-ui/core';
import ListPageProject from './ListPageCard';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: '5px',
    padding: '5px',
    height: '650px',
    overflowY: 'scroll',
  },
  rootTitle: {
    display: 'flex',
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  previewContainer: {
    display: 'flex',
    margin: '10px',
    width: '25%',
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px',
    width: '25%',
  },
  urlContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px',
    width: '20%',
  },
  optionsContainer: {
    display: 'flex',
    margin: '10px',
    width: '30%',
  },
  rowTitleStyle: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '19px',
    fontWeight: 'bold',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#CFD6E6',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageControllerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

export default function TablePageList({
  data, viewEditor, clonePage, editPage, deletePage,
}) {
  const classes = useStyles();
  const [nameFilter, setNameFilter] = useState('');
  const temp = [];
  data.map((index) => {
    const accents = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
    };
    const indexVal = index.label.toLowerCase();
    const strFilter = nameFilter.toLowerCase();
    const reg = /[áíóúé]/g;
    const newStr = indexVal.replace(reg, (matched) => {
      return accents[matched];
    });
    const newFilter = strFilter.replace(reg, (matched) => {
      return accents[matched];
    });
    if (newStr.includes(newFilter)) {
      temp.push(index);
    }
    return 1;
  });
  const dataFiltered = temp;
  const [count, setCount] = useState(1);
  // const [lowLimit, setLowLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(15);
  const limitPage = dataFiltered.length / 15;
  const dataInfo = dataFiltered.slice(0, upperLimit);
  // const handleNextPage = () => {
  //   setLowLimit(lowLimit + 5);
  //   setUpperLimit(upperLimit + 5);
  //   setCount(count + 1);
  // };

  // const handlePreviousPage = () => {
  //   setLowLimit(lowLimit - 5);
  //   setUpperLimit(upperLimit - 5);
  //   setCount(count - 1);
  // };

  const handleInfiniteScroll = () => {
    setTimeout(() => {
      let canScroll = true;
      if (count >= limitPage) {
        window.removeEventListener('scroll', handleInfiniteScroll);
        canScroll = false;
      }
      const endOfPage = window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
      if (endOfPage && canScroll) {
        setUpperLimit(upperLimit + 15);
        setCount(count + 1);
      }
    }, 1000);
  };

  return (
    <Box className={classes.mainBox} onScroll={handleInfiniteScroll}>
      <Box className={classes.searchContainer}>
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <SearchIcon />
          </Box>
          <TextField size="small" placeholder="Buscar.." classes={{root: classes.searchTextField}} onChange={(e) => setNameFilter(e.target.value)} />
        </Box>
      </Box>
      <Box className={classes.rootTitle}>
        <Box className={classes.previewContainer}>
          <Typography className={classes.rowTitleStyle}>
            Preview
          </Typography>
        </Box>
        <Box className={classes.nameContainer}>
          <Typography className={classes.rowTitleStyle}>
            Nombre
          </Typography>
        </Box>
        <Box className={classes.urlContainer}>
          <Typography className={classes.rowTitleStyle}>
            URL
          </Typography>
        </Box>
        <Box className={classes.optionsContainer}>
          <Typography className={classes.rowTitleStyle}>
            Acciones
          </Typography>
        </Box>
      </Box>
      <Box>
        {dataInfo.map((index) => (
          <ListPageProject
            preview={`https://preview-lofty-apps.web.app/page/${index._id}`}
            name={index.label}
            url={index.url}
            editorSelect={() => viewEditor(index._id)}
            clonePage={() => clonePage(index._id)}
            deletePage={() => deletePage(index._id)}
            modifyPage={() => editPage(index)}
          />
        ))}
      </Box>
      {/* <Box className={classes.pageControllerContainer}>
        <Box>
          {count > 1
            && (
            <Button onClick={() => handlePreviousPage()}>
              Prev
            </Button>
            )}
          {`Pag: ${count} of ${Math.ceil(limitPage)}` }
          {count < limitPage && limitPage > 1
          && (
            <Button onClick={() => handleNextPage()}>
              Next
            </Button>
          )}
        </Box>
      </Box> */}
    </Box>
  );
}
