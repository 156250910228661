/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {
  getOneProject, resumeProject,
} from 'api-lofty';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';
// import toastr from 'toastr';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import TableProjectSummaryExport from '../components/ProjectExportSummary';
import ExportCodeCreditCard from '../components/ExportCodeCreditCard';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles(() => ({
  titleBox: {
    width: 'auto',
    marginTop: '5px',
    marginBottom: '15px',
  },
  mainTitle: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '22px',
    fontWeight: '700',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  boxMainContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const ExportSourceCode = ({history, match, token}) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  const {params} = match;
  const {idProject} = params;
  const [project, setProject] = useState(undefined);
  const [data, setData] = useState();
  // const [creditCardData, setCreditCardData] = useState();

  const handlePayment = () => {
    // console.log('Payment endpoint');
  };

  useEffect(async () => {
    try {
      const dataProject = await getOneProject(({token, idProject}));
      // const creditCardRes = await getCreditCard({token});
      setProject(dataProject.project);
      const response = await resumeProject({token, _id: idProject});
      // setCreditCardData(creditCardRes.data.map(({
      //   lastNumberCreditCard, _id, type,
      // }) => ({
      //   lastNumber: `xxxx xxxx xxxx x${lastNumberCreditCard}`,
      //   _id,
      //   type,
      // })));
      const databases = response.data.amountDatabase;
      const pages = response.data.amountPage;
      const teams = response.data.amountTeams;
      const temp = [
        {name: 'Colección de Datos', quantity: databases, perUnit: '250.00'},
        {name: 'Páginas', quantity: pages, perUnit: '250.00'},
        {name: 'Sistema de Autenticacion', quantity: teams, perUnit: '350.00'},
        {name: 'Servicios (Endpoints)', quantity: pages, perUnit: '125.00'},
      ];
      setData(temp);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Box>
        <Box className={classes.titleBox}>
          <Typography className={classes.mainTitle}>
            Obtener Código Fuente
          </Typography>
        </Box>
        <Box className={classes.boxMainContent}>
          <TableProjectSummaryExport data={data} />
          <ExportCodeCreditCard handlePay={() => handlePayment()} />
        </Box>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ExportSourceCode);
