import React, {useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Swal from 'sweetalert2';
import Avatar from '@material-ui/core/Avatar';
// import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import {
  changePassword, updateInfo,
  // getCreditCard, deleteCreditCard,
} from 'api-lofty';
import Layout from '../layouts/PrincipalDasboard';
import {isValidName} from '../util/verifyRegex';
// import ModalCreditCard from '../components/ModalCreditCard';
// import Tabular from '../components/Tabular';

const useStyle = makeStyles(() => ({
  containerUserInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  containerPasswordSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid rgba(229, 229, 229, 1)',
    padding: '10px',
    marginTop: '10px',
  },
  userProfilePictureContainer: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid rgba(229, 229, 229, 1)',
    padding: '10px',
  },
  userCredentialInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '68%',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid rgba(229, 229, 229, 1)',
    padding: '10px',
  },
  titleProfilePic: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '21px',
    fontWeight: 700,
  },
  nameProfilePic: {
    color: 'rgba(19, 69, 166, 1)',
    fontSize: '14px',
    fontWeight: 500,
  },
  userSectionTitle: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '21px',
    fontWeight: 700,
  },
  userFieldsContainer: {
    width: '100%',
    display: 'flex',
    padding: '5px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  passwordSectionTitle: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '20px',
    fontWeight: 700,
  },
  passwordFields: {
    width: '35%',
    display: 'flex',
    padding: '5px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const UserFieldTextfield = withStyles({
  root: {
    '& label': {
      color: 'rgba(19, 69, 166, 1)',
      fontWeight: 600,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(19, 69, 166, 1)',
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(229, 229, 229, 1)',
      },
    },
  },
})(TextField);

const PasswordTextField = withStyles({
  root: {
    '& label': {
      color: 'rgba(19, 69, 166, 1)',
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(19, 69, 166, 1)',
        fontSize: '15px',
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(229, 229, 229, 1)',
      },
    },
  },
})(TextField);

const UpdateProfile = ({user, token, history}) => {
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  // const [openCredit, setOpenCredit] = useState(false);
  // const [dataCredit, setDataCredit] = useState([]);
  const classes = useStyle();
  // const handleDataCredit = async () => {
  //   const res = await getCreditCard({token});
  //   setDataCredit(res.data.map(({lastNumberCreditCard, name, _id}) => ({
  //     lastNumber: `xxxx xxxx xxxx x${lastNumberCreditCard}`,
  //     name,
  //     _id,
  //   })));
  // };
  // useEffect(() => {
  //   handleDataCredit();
  // }, []);
  // const colums = [
  //   {
  //     title: 'Número',
  //     field: 'lastNumber',
  //   },
  //   {
  //     title: 'Nombre',
  //     field: 'name',
  //   },
  //   {
  //     title: 'Acciones',
  //     field: 'id',
  //     render: (rowData) => {
  //       const {_id} = rowData;
  //       return (
  //         <IconButton
  //           onClick={async () => {
  //             try {
  //               await deleteCreditCard({token, _id});
  //               Swal.fire({
  //                 position: 'top-end',
  //                 icon: 'success',
  //                 title: 'Se elimino el dato',
  //                 showConfirmButton: false,
  //                 timer: 1500,
  //               });
  //               handleDataCredit();
  //             } catch (error) {
  //               if (error.info) {
  //                 Swal.fire({
  //                   position: 'top-end',
  //                   icon: 'error',
  //                   title: error.info,
  //                   showConfirmButton: false,
  //                   timer: 1500,
  //                 });
  //               } else {
  //                 Swal.fire({
  //                   position: 'top-end',
  //                   icon: 'error',
  //                   title: 'Error no se pudo eliminar',
  //                   showConfirmButton: false,
  //                   timer: 1500,
  //                 });
  //               }
  //             }
  //           }}
  //         >
  //           <DeleteIcon />
  //         </IconButton>
  //       );
  //     },
  //   },
  // ];
  return (
    <Layout history={history} token={token}>
      <Box component="div" className={classes.containerUserInfo}>
        <Box component="div" className={classes.userProfilePictureContainer}>
          <Box component="div" className={classes.titleProfilePic}>
            Cambiar foto de Perfil
          </Box>
          <Avatar style={{width: '100px', height: '100px'}} className="mx-auto">
            {`${user?.firstName[0]} ${user?.lastName[0]}`}
          </Avatar>
          <Box component="div" className={classes.nameProfilePic}>
            {`${user?.firstName} ${user?.lastName}`}
          </Box>
          <Box>
            {user?.email}
          </Box>
        </Box>
        <Box component="div" className={classes.userCredentialInfo}>
          <Box component="div" className={classes.userSectionTitle}>
            Actualizar Perfil
          </Box>
          <form
            className="mt-4"
            onSubmit={async (e) => {
              e.preventDefault();
              setLoadingInfo(true);
              try {
                if (!isValidName(e.target.firstName.value)) {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Lo sentimos no se pudo actualizar la informacion',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setLoadingInfo(false);
                  return;
                }
                if (!isValidName(e.target.lastName.value)) {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Lo sentimos no se pudo actualizar la informacion',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setLoadingInfo(false);
                  return;
                }
                await updateInfo({
                  firstName: e.target.firstName.value,
                  lastName: e.target.lastName.value,
                  gender: e.target.gender.value,
                  company: e.target.company.value,
                  token,
                });
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Se actualizo la informacion de manera exitosa',
                  showConfirmButton: false,
                  timer: 1500,
                });
                setTimeout(() => window.location.reload(), 1800);
              } catch (error) {
                if (error.info) {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error.info,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Lo sentimos no se pudo actualizar la informacion',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              }
              setLoadingInfo(false);
            }}
          >
            <Box component="div" className={classes.userFieldsContainer}>
              <UserFieldTextfield fullWidth name="firstName" type="text" label="Nombre" variant="outlined" defaultValue={user?.firstName} />
            </Box>
            <Box component="div" className={classes.userFieldsContainer}>
              <UserFieldTextfield fullWidth name="lastName" type="text" label="Apellido" variant="outlined" defaultValue={user?.lastName} />
            </Box>
            <Box component="div" className={classes.userFieldsContainer}>
              <UserFieldTextfield select fullWidth name="gender" type="text" label="Genero" variant="outlined" defaultValue={user?.gender}>
                <MenuItem value="female">
                  Femenino
                </MenuItem>
                <MenuItem value="male">
                  Masculino
                </MenuItem>
              </UserFieldTextfield>
            </Box>
            <Box component="div" className={classes.userFieldsContainer}>
              <UserFieldTextfield fullWidth name="company" type="text" label="Empresa (Opcional)" variant="outlined" defaultValue={user?.company} />
            </Box>
            <div className="text-center mt-4">
              <button
                style={{
                  background: '#201549',
                }}
                type="submit"
                className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                disabled={loadingInfo}
              >
                {
                    loadingInfo ? 'Procesando ...' : 'Guardar'
                  }
              </button>
            </div>
          </form>
        </Box>
      </Box>
      <Box component="div" className={classes.containerPasswordSection}>
        <Box component="div" className={classes.passwordSectionTitle}>
          Cambiar contraseña
        </Box>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoadingPassword(true);
            try {
              await changePassword({
                newPassword: e.target.password.value,
                confirmNewPassword: e.target.confirmPassword.value,
                token,
              });
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Se actualizo tu contraseña',
                showConfirmButton: false,
                timer: 1500,
              });
            } catch (error) {
              if (error.info) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: error.info,
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                // CR
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Lo sentimos no se pudo actualizar la informacion',
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            }
            setLoadingPassword(false);
          }}
        >
          <Box component="div" className={classes.passwordFields}>
            <PasswordTextField fullWidth name="password" type="password" label="Nueva Contraseña" variant="outlined" />
          </Box>
          <Box component="div" className={classes.passwordFields}>
            <PasswordTextField fullWidth name="confirmPassword" type="password" label="Confirmar Nueva Contraseña" variant="outlined" />
          </Box>
          <Box component="div">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loadingPassword}
            >
              {
                    loadingPassword ? 'Procesando ...' : 'Cambiar'
                  }
            </button>
          </Box>
        </form>
      </Box>
      {/* <Grid container>
        <Grid className="p-2" item md={12} xs={12}>
          <Paper className="p-4">
            <h3 className="text-center text-4xl mb-4">
              <b>
                Tarjeta de Crédito
              </b>
            </h3>
            <div className="text-right mb-2">
              <Button onClick={() => setOpenCredit(true)} variant="contained" color="primary">
                Agregar
              </Button>
              <ModalCreditCard
                token={token}
                open={openCredit}
                handleSuccess={handleDataCredit}
                onClose={() => setOpenCredit(!openCredit)}
              />
            </div>
            <Tabular
              columns={colums}
              data={dataCredit}
              title=""
              ready={false}
            />
          </Paper>
        </Grid>
      </Grid> */}
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(UpdateProfile);
