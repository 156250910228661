import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Typography} from '@material-ui/core';
import {getOneUserProject, getOneProject, listActions} from 'api-lofty';
import Layout from '../layouts/UserProjectDashboard';
import LoadingPage from './LoadingPage';
import {labelUserProject} from '../util/typeUserProject';
import ListActivitiesUserSection from '../components/ListActivitiesUserSection';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const ActivitiesUser = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idUserProject} = params;
  const [loading, setLoading] = useState(true);
  const [pageActual, setPageActual] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [limitPage] = useState(20);
  const [dataProject, setDataProject] = useState({});
  const [dataUserProject, setDataUserProject] = useState(undefined);
  const [logUser, setLogUser] = useState([]);
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();

  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resUserProject = await getOneUserProject({token, _id: idUserProject});
      const resLogs = await listActions({
        token,
        idProject,
        limit: limitPage,
        page: pageActual,
        idDatabase: 'none',
        idPage: 'none',
        idFieldDatabase: 'none',
        idFieldUserProject: 'none',
        typeFilter: 'fieldUserProject',
      });
      setLabelUrl(`proyectos/${resProject.project.name}/Usuarios/${resUserProject.data.type}/Actividades`);
      setLogUser(resLogs.data);
      setDataProject(resProject.project);
      setDataUserProject(resUserProject.data);
      // TODO agregar el listado de actividades
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  const handleNextPage = async () => {
    const resLogs = await listActions({
      token,
      idProject,
      limit: limitPage,
      page: pageActual + limitPage,
      idDatabase: 'none',
      idPage: 'none',
      idFieldDatabase: 'none',
      idFieldUserProject: 'none',
      typeFilter: 'fieldUserProject',
    });
    setLogUser(resLogs.data);
    setPageActual(pageActual + limitPage);
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = async () => {
    const resLogs = await listActions({
      token,
      idProject,
      limit: limitPage,
      page: pageActual - limitPage,
      idDatabase: 'none',
      idPage: 'none',
      idFieldDatabase: 'none',
      idFieldUserProject: 'none',
      typeFilter: 'fieldUserProject',
    });
    setLogUser(resLogs.data);
    setPageActual(pageActual - limitPage);
    setPageNumber(pageNumber - 1);
  };

  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idUserProject={idUserProject}
      userProjectType={labelUserProject(dataUserProject?.type)}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <ListActivitiesUserSection
        data={logUser.logs}
        actualPage={pageNumber}
        total={logUser.total}
        limitNumber={limitPage}
        previousPage={handlePreviousPage}
        nextPage={handleNextPage}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ActivitiesUser);
