import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  listDatabases, getOneProject,
  // getSizeStorageDeployment,
} from 'api-lofty';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
  CategoryScale, LinearScale, BarElement, Title,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import storageImage from '../img/deploy/Storage.png';
import updateImage from '../img/deploy/deploy_update.png';
import memoryImage from '../img/deploy/MemorySlot.png';
import HardwareUsageChart from '../components/HardwareUsageChart';
// import PercentageChart from '../components/PercentageChart';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
);

const barchartoptions = {
  responsive: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  historyDivContainer: {
    height: '400px',
    backgroundColor: '#fff',
    width: '100%',
    padding: '10px',
    margin: '10px',
  },
  historyTileSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '95%',
  },
  historyOptionsSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title1HistorySection: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  title2HistorySection: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '19px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  subtitleSection: {
    borderTop: '1px solid',
    borderColor: 'rgba(41, 33, 81, 1)',
    display: 'flex',
    justifyContent: 'center',
  },
  subtitleHistory: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
    [theme.breakpoints.down('xs')]: {fontSize: '16px'},
  },
  dbSection: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    backgroundColor: '#fff',
    justifyContent: 'center',
    width: '95%',
  },
  dbTitleSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dbSubtitleDataSection: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
    [theme.breakpoints.down('xs')]: {fontSize: '16px'},
  },
  dbSubtitleLabel: {
    color: 'rgba(110, 122, 144, 1)',
    fontSize: '12px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '10px'},
    [theme.breakpoints.down('md')]: {fontSize: '9px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  dbFragmentData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  doughnutSection: {
    width: '95%',
    padding: '20px',
    margin: '10px',
    backgroundColor: '#fff',
    height: '400px',
  },
  doughnutArea: {
    position: 'relative',
    paddingLeft: '20%',
    paddingRight: '20%',
    display: 'flex',
    justifyContent: 'center',
  },
  doughnutInnerArea: {
    background: 'rgba(229, 244, 251, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    position: 'absolute',
    marginLeft: '20%',
    marginRight: '20%',
    width: '20%',
    height: '70%',
    top: '22%',
    right: '20%',
    bottom: '20%',
    left: '20%',
    borderRadius: '50%',
  },
  doughnutInnerText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  doughnutTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title1DoughnutSection: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '21px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  memoryDataSection: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  chartsSection: {
    display: 'flex',
    width: '100%',
    margin: '10px',
    height: '300px',
  },
  deployArea: {
    width: '100%',
    height: '100%',
    padding: '15px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: 1,
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const MedDeployView = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [setData] = useState([]);
  const [project, setProject] = useState();
  // const [onUseStorage, setOnUseStorage] = useState(0);
  // const [usePercentage, setUsePercentage] = useState(0);
  // const [totalStorage, setTotalStorage] = useState(0);
  const {params} = match;
  const {idProject} = params;
  const classes = useStyle();
  const chartRange = 'Semana del 1 al 7 de Agosto';
  const chartdata = {
    labels: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    datasets: [
      {
        label: 'First set',
        data: [1, 2, 4, 8, 20, 14, 17],
        backgroundColor: 'rgba(41, 178, 239, 1)',
      },
    ],
  };

  // const doughnutdata = {
  //   labels: ['En Uso (GB)', 'Disponible (GB) '],
  //   datasets: [
  //     {
  //       label: 'Almacenamiento',
  //       data: [onUseStorage, (totalStorage - onUseStorage)],
  //       backgroundColor: [
  //         'rgba(20, 73, 153, 0.7)',
  //         'rgba(217, 217, 217, 0.7)',
  //       ],
  //       borderColor: [
  //         'rgba(20, 73, 153, 1)',
  //         'rgba(217, 217, 217, 1)',
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // const doughnutOptions = {
  //   cutout: '90%',
  //   maintainAspectRatio: false,
  // };

  const dbSampleData = [
    {
      name: 'RAM',
      icon: memoryImage,
      usage: 400,
      total: 700,
    },
    {
      name: 'Almacenamiento',
      icon: storageImage,
      usage: 500,
      total: 700,
    },
  ];

  const getData = async () => {
    try {
      // FOR TESTING ONLY
      // setOnUseStorage(2.7);
      // setTotalStorage(5);
      // const totalPercent = (2.7 / 5) * 100;
      // setUsePercentage(totalPercent);
      const getProject = await getOneProject({token, idProject});
      setProject(getProject.project);
      const getDatabases = await listDatabases({token, idProject});
      setData(getDatabases.data);
      // const getStorageData = await getSizeStorageDeployment({token, idProject});
      // setOnUseStorage(getStorageData.data.storage);
      // setTotalStorage(getStorageData.data.total);
      // const totalPercent = (getStorageData.data.storage / getStorageData.data.total) * 100;
      // setUsePercentage(totalPercent);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {`/proyectos/${project.name}`}
      </Typography>
      <Box
        component="div"
        sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Grid container className="mt-6">
          <Grid item md={8}>
            <Box component="div" className={classes.doughnutSection}>
              <Box component="div" className={classes.doughnutTitle}>
                <Box component="img" src={memoryImage} alt="storage" />
                <Typography component="h5" variant="h5" className={classes.title1DoughnutSection}>
                  Memoria RAM (App Web)
                </Typography>
              </Box>
              {/* <Box component="div" className={classes.doughnutArea}>
                <Doughnut
                  data={doughnutdata}
                  options={doughnutOptions}
                  height="30%"
                  width="30%"
                />
                <Box component="div" className={classes.doughnutInnerArea}>
                  <Typography component="h5" variant="h5" className={classes.doughnutInnerText}>
                    70 %
                  </Typography>
                </Box>
                <Box component="div" style={{height: '190px', width: '190px'}}>
                  <PercentageChart usage={70} />
                </Box>
              </Box> */}
              <Box
                component="div"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                }}
              >
                <Box component="div" className={classes.memoryDataSection}>
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      borderBottom: '1px solid',
                      borderColor: 'rgba(41, 33, 81, 1)',
                    }}
                  >
                    <Box component="div" className={classes.dbFragmentData}>
                      <Typography variant="subtitle1" color="textSecondary" className={classes.dbSubtitleLabel}>
                        En Uso
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" className={classes.dbSubtitleDataSection}>
                        450 MB
                      </Typography>
                    </Box>
                    <Box component="div" className={classes.dbFragmentData}>
                      <Typography variant="subtitle1" color="textSecondary" className={classes.dbSubtitleLabel}>
                        Libres
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary" className={classes.dbSubtitleDataSection}>
                        150 MB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    component="div"
                    style={{
                      margin: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle1" color="textSecondary" className={classes.dbSubtitleDataSection}>
                      600 MB
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.dbSubtitleLabel}>
                      Disponibles
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box component="div" className={classes.dbSection}>
              <Box component="div" className={classes.dbTitleSection}>
                <Typography component="h5" variant="h5" className={classes.title2HistorySection} style={{marginRight: '15px'}}>
                  Base de Datos
                </Typography>
              </Box>
              {dbSampleData.map((value) => (
                <HardwareUsageChart
                  name={value.name}
                  icon={value.icon}
                  usage={value.usage}
                  total={value.total}
                />
              ))}
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box component="div" className={classes.historyDivContainer}>
              <Box component="div" className={classes.historyTileSection}>
                <Typography component="h5" variant="h5" className={classes.title2HistorySection}>
                  Actividad
                </Typography>
              </Box>
              <Bar height="200" data={chartdata} options={barchartoptions} />
              <Box component="div" className={classes.historyOptionsSection}>
                <Box component="div" className={classes.subtitleSection}>
                  <Typography variant="subtitle1" className={classes.subtitleHistory}>
                    {chartRange}
                  </Typography>
                </Box>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'auto',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{
                      margin: '10px',
                      color: '#fff',
                      fontWeight: 'bold',
                      backgroundColor: 'rgba(41, 33, 81, 1)',
                      fontFamily: [
                        'Nunito',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                        'sans-serif',
                      ].join(','),
                      textTransform: 'none',
                    }}
                  >
                    Cambiar Plan
                  </Button>
                </Box>

                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'auto',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{
                      margin: '10px',
                      color: '#fff',
                      fontWeight: 'bold',
                      backgroundColor: 'rgba(41, 178, 239, 1)',
                      fontFamily: [
                        'Nunito',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                        'sans-serif',
                      ].join(','),
                      textTransform: 'none',
                    }}
                  >
                    Backoffice
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box component="div" className={classes.chartsSection}>
              <Box component="div" className={classes.deployArea}>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '50%',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Box component="img" src={updateImage} alt="update" width="auto" height="150px" />
                </Box>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'auto',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      backgroundColor: 'rgba(217, 217, 217, 1)',
                      fontFamily: [
                        'Nunito',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                        'sans-serif',
                      ].join(','),
                      textTransform: 'none',
                    }}
                  >
                    Actualizar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(MedDeployView);
