import React, {useState} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import {
  TextField, Button, Box, Typography, Hidden,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link, Redirect} from 'react-router-dom';
import {loginWithEmail} from 'api-lofty';
import {addToken} from '../actions/auth.actions';
import containedImage from '../img/bg_login/bg-login_1.png';
import imgLogo from '../img/logo.png';
import containedImage2 from '../img/bg_login/tablet_1.png';
import containedImage3 from '../img/bg_login/mobile-login-bg_1.png';

import AuthLayout from '../layouts/AuthLayout';

const useStyle = makeStyles((theme) => ({
  header1: {
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    color: '#ffffff',
    marginLeft: '20%',
    [theme.breakpoints.down('lg')]: {fontSize: '76px'},
  },
  header2: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    color: '#ffffff',
    [theme.breakpoints.down('lg')]: {fontSize: '32px'},
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {flexDirection: 'column-reverse'},
  },
  boxContainerImage: {
    flex: 2,
    position: 'relative',
    height: '100%',
  },
  imgStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  imgStyle2: {
    width: '100%',
    height: 'auto',
    zIndex: 1,
  },
  imgStyle3: {
    width: '100%',
    height: 'auto',
    zIndex: 1,
  },
  textAreaImage: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '30px',
    [theme.breakpoints.down('sm')]: {display: 'none'},
  },
  textComponents: {
    fontWeight: 'bold',
  },
  logoStyle: {
    width: '50%',
    position: 'absolute',
    marginLeft: '5%',
    bottom: 1,
    marginBottom: '2%',
    zIndex: 1,
  },
}));

const LoginPageMain = ({token, history, addTokenApp}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyle();
  // console.log(localStorage.getItem('fromTemplatePurchase'));
  if (token) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <AuthLayout>
      <Box className={classes.containerMain}>
        <Box style={{height: '100%', flex: 1}}>
          <Box component="div" className="lofty-container-centered-login">
            <Box
              component="div"
              style={{
                textAlign: 'left',
                fontFamily: ['Nunito', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
                fontSize: '24px',
                marginBottom: '40px',
                marginTop: '70px',
                fontWeight: 'bold',
              }}
            >
              Login
            </Box>
            <Box
              component="form"
              className="wpcf7-form init demo"
              onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                try {
                  const res = await loginWithEmail({
                    email: e.target.email.value,
                    password: e.target.password.value,
                  });
                  addTokenApp({
                    token: res.token,
                    user: res.user,
                  });
                  if (localStorage.getItem('fromTemplatePurchase')) {
                    // console.log('must be here');
                    history.push(localStorage.getItem('fromTemplatePurchase'));
                  } else {
                    // console.log('wat');
                    history.push('/');
                  }
                } catch (error) {
                  if (error.info) {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: error.info,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: 'Lo sentimos no se pudo inicar sesion',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                }
                setLoading(false);
              }}
            >
              <Box component="div">
                <TextField fullWidth name="email" type="email" label="Correo" variant="standard" />
              </Box>
              <Box component="div" className="my-4">
                <TextField fullWidth name="password" type="password" label="Contraseña" variant="standard" />
              </Box>
              <Box component="div">
                <p className="mb-0">
                  <Link style={{color: '#201549', fontSize: '10px', fontFamily: ['Nunito', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(',')}} to="/forgot">
                    ¿Has olvidado tu contraseña?
                  </Link>
                </p>
              </Box>
              <Box component="div" className="text-center mt-4">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  className={classes.textComponents}
                >
                  {
                      loading ? 'Procesando' : 'Iniciar Sesion'
                    }
                </Button>
              </Box>
              <Box component="div" style={{marginTop: '80px', marginBottom: '20px', textAlign: 'center'}}>
                <p>
                  <Box
                    component="span"
                    style={{
                      color: '#201549',
                      fontSize: '14px',
                      fontFamily: ['Nunito', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
                      marginRight: 10,
                    }}
                  >
                    No te has registrado aun?
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    component={Link}
                    to="/register"
                    className={classes.textComponents}
                  >
                    Registrate
                  </Button>
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.boxContainerImage}>
          <Hidden smDown>
            <Box component="img" src={containedImage} alt="ESE" className={classes.imgStyle} />
            <Box component="img" src={imgLogo} alt="ESE" className={classes.logoStyle} />
          </Hidden>
          <Hidden mdUp xsDown>
            <Box component="img" src={containedImage2} alt="ESE" className={classes.imgStyle2} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={containedImage3} alt="ESE" className={classes.imgStyle3} />
          </Hidden>
          <Box component="div" className={classes.textAreaImage}>
            <Typography variant="h2" className={classes.header2}>
              Crea & Personaliza
            </Typography>
            <Typography variant="h1" className={classes.header1}>
              tu app
            </Typography>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addTokenApp: (doc) => dispatch(addToken(doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageMain);
