import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Box, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {addUpdatePluginCalendarSchedule} from 'api-lofty';
import {minutosInterval15, minutosInterval30, minutosInterval60} from '../../util/calendars';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 100,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const BlockTime = ({time, onClick, active}) => (
  <Paper
    onClick={onClick}
    style={{cursor: 'pointer', backgroundColor: !active ? 'white' : '#d9d4d4'}}
    className="p-3 m-3 text-center"
  >
    {time}
  </Paper>
);

const AddSchedule = ({
  open, onClose, successFunction, errorFunction,
  idPluginProject, idPluginCalendar, idProject, token,
  schedule,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [intervalSelected, setIntervalSelected] = useState(15);
  const [arrayMonday, setArrayMonday] = useState(
    schedule ? schedule.arrayDaysAvailable?.filter((doc) => doc.nameDay === 'monday')
      : [],
  );
  const [arrayTuesday, setArrayTuesday] = useState(
    schedule ? schedule.arrayDaysAvailable?.filter((doc) => doc.nameDay === 'tuesday')
      : [],
  );
  const [arrayWednesday, setArrayWenesday] = useState(
    schedule ? schedule.arrayDaysAvailable?.filter((doc) => doc.nameDay === 'wednesday')
      : [],
  );
  const [arrayThursday, setArrayThursday] = useState(
    schedule ? schedule.arrayDaysAvailable?.filter((doc) => doc.nameDay === 'thursday')
      : [],
  );
  const [arrayFriday, setArrayFriday] = useState(
    schedule ? schedule.arrayDaysAvailable?.filter((doc) => doc.nameDay === 'friday')
      : [],
  );
  const [arraySaturday, setArraySaturday] = useState(
    schedule ? schedule.arrayDaysAvailable?.filter((doc) => doc.nameDay === 'saturday')
      : [],
  );
  const [arraySunday, setArraySunday] = useState(
    schedule ? schedule.arrayDaysAvailable?.filter((doc) => doc.nameDay === 'sunday')
      : [],
  );
  function handleInterval() {
    if (intervalSelected === 15) {
      return minutosInterval15();
    } if (intervalSelected === 30) {
      return minutosInterval30();
    } if (intervalSelected === 60) {
      return minutosInterval60();
    }
    return {};
  }
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Horario
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Container>
          <div className="my-4 text-center">
            <TextField
              defaultValue={`${intervalSelected}`}
              onChange={(e) => {
                setIntervalSelected(parseInt(e.target.value));
                setArrayMonday([]);
                setArrayTuesday([]);
                setArrayWenesday([]);
                setArrayThursday([]);
                setArrayFriday([]);
                setArraySaturday([]);
                setArraySunday([]);
              }}
              select
              name="type"
              type="text"
              label="Intervalo de tiempo"
              variant="standard"
              style={{width: 400}}
            >
              <MenuItem value="15">
                15 minutos
              </MenuItem>
              <MenuItem value="30">
                30 minutos
              </MenuItem>
              <MenuItem value="60">
                60 minutos
              </MenuItem>
            </TextField>
          </div>
          <div>
            <h2 style={{fontSize: 30, textAlign: 'center'}}>
              <b>Horarios</b>
            </h2>
          </div>
          <Grid className="mb-4" justifyContent="center" container>
            <Grid item md={1}>
              {/* Lunes */}
              <h3 className="text-center">Lunes</h3>
              {
                handleInterval().monday?.map((time) => (
                  <BlockTime
                    time={time}
                    key={time}
                    onClick={() => {
                      if (arrayMonday.find((item) => item === time)) {
                        const newArray = [];
                        arrayMonday.forEach((element) => {
                          if (element !== time) {
                            newArray.push(element);
                          }
                        });
                        setArrayMonday(newArray);
                      } else {
                        arrayMonday.push(time);
                        setArrayMonday(arrayMonday.map((item) => item));
                      }
                    }}
                    active={arrayMonday.find((item) => item === time)}
                  />
                ))
              }
            </Grid>
            <Grid item md={1}>
              {/* martes */}
              <h3 className="text-center">Martes</h3>
              {
                handleInterval().tuesday?.map((time) => (
                  <BlockTime
                    time={time}
                    key={time}
                    onClick={() => {
                      if (arrayTuesday.find((item) => item === time)) {
                        const newArray = [];
                        arrayTuesday.forEach((element) => {
                          if (element !== time) {
                            newArray.push(element);
                          }
                        });
                        setArrayTuesday(newArray);
                      } else {
                        arrayTuesday.push(time);
                        setArrayTuesday(arrayTuesday.map((item) => item));
                      }
                    }}
                    active={arrayTuesday.find((item) => item === time)}
                  />
                ))
              }
            </Grid>
            <Grid item md={1}>
              {/* Miercoles */}
              <h3 className="text-center">Miércoles</h3>
              {
                handleInterval().wednesday?.map((time) => (
                  <BlockTime
                    time={time}
                    key={time}
                    onClick={() => {
                      if (arrayWednesday.find((item) => item === time)) {
                        const newArray = [];
                        arrayWednesday.forEach((element) => {
                          if (element !== time) {
                            newArray.push(time);
                          }
                        });
                        setArrayWenesday(newArray);
                      } else {
                        arrayWednesday.push(time);
                        setArrayWenesday(arrayWednesday.map((item) => item));
                      }
                    }}
                    active={arrayWednesday.find((item) => item === time)}
                  />
                ))
              }
            </Grid>
            <Grid item md={1}>
              {/* Jueves */}
              <h3 className="text-center">Jueves</h3>
              {
                handleInterval().thursday?.map((time) => (
                  <BlockTime
                    time={time}
                    key={time}
                    onClick={() => {
                      if (arrayThursday.find((item) => item === time)) {
                        const newArray = [];
                        arrayThursday.forEach((element) => {
                          if (element !== time) {
                            newArray.push(time);
                          }
                        });
                        setArrayThursday(newArray);
                      } else {
                        arrayThursday.push(time);
                        setArrayThursday(arrayThursday.map((item) => item));
                      }
                    }}
                    active={arrayThursday.find((item) => item === time)}
                  />
                ))
              }
            </Grid>
            <Grid item md={1}>
              {/* Viernes */}
              <h3 className="text-center">Viernes</h3>
              {
                handleInterval().friday?.map((time) => (
                  <BlockTime
                    onClick={() => {
                      if (arrayFriday.find((item) => item === time)) {
                        const newArray = [];
                        arrayFriday.forEach((element) => {
                          if (element !== time) {
                            newArray.push(element);
                          }
                        });
                        setArrayFriday(newArray);
                      } else {
                        arrayFriday.push(time);
                        setArrayFriday(arrayFriday.map((item) => item));
                      }
                    }}
                    time={time}
                    key={time}
                    active={arrayFriday.find((item) => item === time)}
                  />
                ))
              }
            </Grid>
            <Grid item md={1}>
              {/* Sabado */}
              <h3 className="text-center">Sábado</h3>
              {
                handleInterval().saturday?.map((time) => (
                  <BlockTime
                    time={time}
                    key={time}
                    onClick={() => {
                      if (arraySaturday.find((item) => item === time)) {
                        const newArray = [];
                        arraySaturday.forEach((element) => {
                          if (element !== time) {
                            newArray.push(element);
                          }
                        });
                        setArraySaturday(newArray);
                      } else {
                        arraySaturday.push(time);
                        setArraySaturday(arraySaturday.map((item) => item));
                      }
                    }}
                    active={arraySaturday.find((item) => item === time)}
                  />
                ))
              }
            </Grid>
            <Grid item md={1}>
              {/* Domingo */}
              <h3 className="text-center">Domingo</h3>
              {
                handleInterval().saturday?.map((time) => (
                  <BlockTime
                    time={time}
                    key={time}
                    onClick={() => {
                      if (arraySunday.find((item) => item === time)) {
                        const newArray = [];
                        arraySunday.forEach((element) => {
                          if (element !== time) {
                            newArray.push(element);
                          }
                        });
                        setArraySunday(newArray);
                      } else {
                        arraySunday.push(time);
                        setArraySunday(arraySunday.map((item) => item));
                      }
                    }}
                    active={arraySunday.find((item) => item === time)}
                  />
                ))
              }
            </Grid>
          </Grid>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="button"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
              onClick={async () => {
                try {
                  setLoading(true);
                  const res = await addUpdatePluginCalendarSchedule({
                    idPluginCalendar,
                    idPluginProject,
                    idProject,
                    rangeMinutes: intervalSelected,
                    token,
                    arrayDaysAvailable: [
                      {
                        nameDay: 'monday',
                        numberDay: 1,
                        rangeHours: arrayMonday,
                      },
                      {
                        nameDay: 'tuesday',
                        numberDay: 2,
                        rangeHours: arrayTuesday,
                      },
                      {
                        nameDay: 'wednesday',
                        numberDay: 3,
                        rangeHours: arrayWednesday,
                      },
                      {
                        nameDay: 'thursday',
                        numberDay: 4,
                        rangeHours: arrayThursday,
                      },
                      {
                        nameDay: 'friday',
                        numberDay: 5,
                        rangeHours: arrayFriday,
                      },
                      {
                        nameDay: 'saturday',
                        numberDay: 6,
                        rangeHours: arrayFriday,
                      },
                      {
                        nameDay: 'sunday',
                        numberDay: 0,
                        rangeHours: arraySunday,
                      },
                    ],
                  });
                  if (successFunction) {
                    successFunction(res.data);
                  }
                } catch (error) {
                  if (errorFunction) {
                    errorFunction(error);
                  }
                }
                setLoading(false);
              }}
            >
              {
                loading ? (
                  <>
                    Procesando ...
                  </>
                ) : (
                  <>
                    Guardar
                  </>
                )
              }
            </button>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default AddSchedule;
