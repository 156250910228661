/* eslint-disable consistent-return */

const blocklyAnalyzer = (block, listDatabases, fieldData, originPoint, variablesWithFields) => {
  if (block.type === 'main_block') {
    if (block.inputs?.main_block?.block) {
      const response = blocklyAnalyzer(
        block.inputs.main_block.block,
        listDatabases,
        fieldData,
        originPoint,
        variablesWithFields,
      );
      return response;
    }
  }
  if (block.type === 'variable_field_info') {
    const getVariableSelected = block?.fields?.VariableInfo;
    if (!variablesWithFields) {
      return {status: 'error', info: 'No hay variables con campos existentes'};
    }
    if (variablesWithFields?.length > 0) {
      const seekExistInArray = variablesWithFields.findIndex(
        (index) => (index.id === getVariableSelected),
      );
      if (seekExistInArray === -1) {
        return {status: 'error', info: 'La variable no contiene campos validos actuales'};
      }
    }
  }
  if (block.type === 'list_database_block') {
    const getSelectedCollection = block?.fields?.listCollection;
    if (block?.inputs?.listFieldDatabaseAdd?.block) {
      const seekIssue = blocklyAnalyzer(
        block?.inputs?.listFieldDatabaseAdd.block,
        listDatabases,
        fieldData,
        getSelectedCollection,
        variablesWithFields,
      );
      if (seekIssue) {
        return seekIssue;
      }
    }
  }
  if (block.type === 'add_database_block') {
    const getSelectedCollection = block?.fields?.addCollection;
    if (block?.inputs?.listFieldDatabaseAdd?.block) {
      const seekIssue = blocklyAnalyzer(
        block?.inputs?.listFieldDatabaseAdd.block,
        listDatabases,
        fieldData,
        getSelectedCollection,
        variablesWithFields,
      );
      if (seekIssue) {
        return seekIssue;
      }
    }
  }
  if (block.type === 'object_database_block') {
    const getSelectedCollection = block?.fields?.getOne;
    if (block?.inputs?.objectFieldDatabaseAdd?.block) {
      const seekIssue = blocklyAnalyzer(
        block?.inputs?.objectFieldDatabaseAdd.block,
        listDatabases,
        fieldData,
        getSelectedCollection,
        variablesWithFields,
      );
      if (seekIssue) {
        return seekIssue;
      }
    }
  }
  if (block.type === 'update_database_block') {
    const getSelectedCollection = block?.fields?.dropdownListUpdate;
    if (block?.inputs?.updateFieldDatabase?.block) {
      const seekIssue = blocklyAnalyzer(
        block?.inputs?.updateFieldDatabase.block,
        listDatabases,
        fieldData,
        getSelectedCollection,
      );
      if (seekIssue) {
        return seekIssue;
      }
    }
    if (block?.inputs?.updateFilterFieldDatabase?.block) {
      const seekIssue = blocklyAnalyzer(
        block?.inputs?.updateFilterFieldDatabase.block,
        listDatabases,
        fieldData,
        getSelectedCollection,
        variablesWithFields,
      );
      if (seekIssue) {
        return seekIssue;
      }
    }
  }
  if (block.type === 'delete_database_block') {
    const caseDelete = block?.fields?.dropdownListDelete;
    const getSelectedCollection = listDatabases.find((index) => (index.name === caseDelete));
    if (block?.inputs?.deleteFilterFieldDatabase?.block) {
      const seekIssue = blocklyAnalyzer(
        block?.inputs?.deleteFilterFieldDatabase.block,
        listDatabases,
        fieldData,
        getSelectedCollection._id,
        variablesWithFields,
      );
      if (seekIssue) {
        return seekIssue;
      }
    }
  }
  if (block.type === 'database_field') {
    const getSelectedField = block?.fields?.CollectionField;
    const getReferenceDatabase = listDatabases.find((index) => (index._id === originPoint));
    if (!getReferenceDatabase) {
      return {status: 'error', info: 'No hay referencia en Coleccion de Datos'};
    }
    const seekCurrentFields = fieldData.find(
      (index) => (index.collection.id === getReferenceDatabase.name),
    );
    const searchExistsInFields = seekCurrentFields?.fields?.findIndex(
      (index) => (index._id === getSelectedField),
    );
    if (searchExistsInFields === -1) {
      return {status: 'error', info: 'Campo de Coleccion de Datos Incorrecto'};
    }
  }

  if (block.inputs?.then) {
    return blocklyAnalyzer(
      block.inputs?.then.block,
      listDatabases,
      fieldData,
      originPoint,
      variablesWithFields,
    );
  }

  if (block.next?.block) {
    return blocklyAnalyzer(
      block.next.block,
      listDatabases,
      fieldData,
      originPoint,
      variablesWithFields,
    );
  }

  if (block?.responsedata) {
    return blocklyAnalyzer(
      block?.responsedata.block,
      listDatabases,
      fieldData,
      originPoint,
      variablesWithFields,
    );
  }

  if (block?.inputs?.Operation) {
    return blocklyAnalyzer(
      block?.inputs?.Operation.block,
      listDatabases,
      fieldData,
      originPoint,
      variablesWithFields,
    );
  }
  if (block?.inputs?.comparison_left_condition) {
    return blocklyAnalyzer(
      block?.inputs?.comparison_left_condition.block,
      listDatabases,
      fieldData,
      originPoint,
      variablesWithFields,
    );
  }
  if (block?.inputs?.comparison_right_condition) {
    return blocklyAnalyzer(
      block?.inputs?.comparison_right_condition.block,
      listDatabases,
      fieldData,
      originPoint,
      variablesWithFields,
    );
  }
  if (block?.inputs?.ComparisonBlock) {
    return blocklyAnalyzer(
      block?.inputs?.ComparisonBlock.block,
      listDatabases,
      fieldData,
      originPoint,
      variablesWithFields,
    );
  }
};

export default blocklyAnalyzer;
