import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {listPluginVersionPdf, listPluginFieldPdf} from 'api-lofty';

const DocPluginPdf = ({
  idProject, url, token,
}) => {
  const [dataVersions, setDataVersions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handleData = async () => {
      try {
        const resVersions = await listPluginVersionPdf({token, idProject});
        const resFieldVersions = await listPluginFieldPdf({token, idProject});
        const newData = [];
        resVersions.data.forEach((item) => {
          const dataFieldPdf = [];
          resFieldVersions.data.forEach((field) => {
            if (item._id === field.idPluginVersionPdf) {
              dataFieldPdf.push(field);
            }
          });
          newData.push({...item, ...{dataFieldPdf}});
        });
        setDataVersions(newData);
      } catch (error) {
        // code
      }
      setLoading(false);
    };
    handleData();
  }, []);
  if (loading) {
    return <p>Cargando ...</p>;
  }
  return (
    <Paper className="py-2">
      <Container>
        {/* Registrar token Plugin Push Expo */}
        {
          dataVersions.length === 0 ? (
            <div className="text-center">
              No hay documentacion
            </div>
          ) : dataVersions.map((item) => (
            <>
              <div className="mb-5">
                <hr />
                <h3>
                  <b>
                    {item.name}
                  </b>
                  {' '}
                  |
                  {' '}
                  Crear PDF
                </h3>
                <p className="my-4">
                  {item.label}
                </p>
                <p className="my-4">
                  <Chip label="POST" color="primary" />
                </p>
                <p className="my-4">
                  {`${url}/api/simulator/generate/pdf/add/${idProject}/${item._id}`}
                </p>
                <h3 className="my-4">
                  Cuerpo de Encabezado
                </h3>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead style={{backgroundColor: '#201549'}}>
                      <TableRow>
                        <TableCell style={{color: 'white'}}>
                          Campo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Tipo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Requerido
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Descripción
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        item.isLogged && (
                          <TableRow>
                            <TableCell>
                              AuthorizationApp
                            </TableCell>
                            <TableCell>
                              string
                            </TableCell>
                            <TableCell>
                              Si
                            </TableCell>
                            <TableCell>
                              Token de la autenticación del usuario
                            </TableCell>
                          </TableRow>
                        )
                      }
                      <TableRow>
                        <TableCell>
                          apiKeyLoftyApps
                        </TableCell>
                        <TableCell>
                          string
                        </TableCell>
                        <TableCell>
                          Si
                        </TableCell>
                        <TableCell>
                          API key para aplicaciones externas
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <h3 className="my-4">
                  Cuerpo de solicitud
                </h3>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead style={{backgroundColor: '#201549'}}>
                      <TableRow>
                        <TableCell style={{color: 'white'}}>
                          Campo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Tipo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Requerido
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Descripción
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          fields
                        </TableCell>
                        <TableCell>
                          Array
                        </TableCell>
                        <TableCell>
                          Si
                        </TableCell>
                        <TableCell>
                          La estructura debe de ser de esta manera
                          {' '}
                          {'{ name: string, value: any } '}
                          con los siguientes campos:
                          {' '}
                          {item.dataFieldPdf.map((field) => `${field.name},`)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <hr />
              </div>
              <div className="mb-5">
                <hr />
                <h3>
                  <b>
                    {item.name}
                  </b>
                  {' '}
                  |
                  {' '}
                  Listado PDF
                </h3>
                <p className="my-4">
                  {item.label}
                </p>
                <p className="my-4">
                  <Chip label="GET" color="default" />
                </p>
                <p className="my-4">
                  {`${url}/api/simulator/generate/pdf/list/${idProject}/${item._id}`}
                </p>
                <h3 className="my-4">
                  Cuerpo de Encabezado
                </h3>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead style={{backgroundColor: '#201549'}}>
                      <TableRow>
                        <TableCell style={{color: 'white'}}>
                          Campo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Tipo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Requerido
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Descripción
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        item.isLogged && (
                          <TableRow>
                            <TableCell>
                              AuthorizationApp
                            </TableCell>
                            <TableCell>
                              string
                            </TableCell>
                            <TableCell>
                              Si
                            </TableCell>
                            <TableCell>
                              Token de la autenticación del usuario
                            </TableCell>
                          </TableRow>
                        )
                      }
                      <TableRow>
                        <TableCell>
                          apiKeyLoftyApps
                        </TableCell>
                        <TableCell>
                          string
                        </TableCell>
                        <TableCell>
                          Si
                        </TableCell>
                        <TableCell>
                          API key para aplicaciones externas
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <hr />
              </div>
              <div className="mb-5">
                <hr />
                <h3>
                  <b>
                    {item.name}
                  </b>
                  {' '}
                  |
                  {' '}
                  Obtener PDF
                </h3>
                <p className="my-4">
                  {item.label}
                </p>
                <p className="my-4">
                  <Chip label="GET" color="default" />
                </p>
                <p className="my-4">
                  {`${url}/api/simulator/generate/pdf/one/${idProject}/${item._id}/:_id`}
                </p>
                <h3 className="my-4">
                  Cuerpo de Encabezado
                </h3>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead style={{backgroundColor: '#201549'}}>
                      <TableRow>
                        <TableCell style={{color: 'white'}}>
                          Campo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Tipo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Requerido
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Descripción
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        item.isLogged && (
                          <TableRow>
                            <TableCell>
                              AuthorizationApp
                            </TableCell>
                            <TableCell>
                              string
                            </TableCell>
                            <TableCell>
                              Si
                            </TableCell>
                            <TableCell>
                              Token de la autenticación del usuario
                            </TableCell>
                          </TableRow>
                        )
                      }
                      <TableRow>
                        <TableCell>
                          apiKeyLoftyApps
                        </TableCell>
                        <TableCell>
                          string
                        </TableCell>
                        <TableCell>
                          Si
                        </TableCell>
                        <TableCell>
                          API key para aplicaciones externas
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <hr />
              </div>
              <div className="mb-5">
                <hr />
                <h3>
                  <b>
                    {item.name}
                  </b>
                  {' '}
                  |
                  {' '}
                  Obtener PDF
                </h3>
                <p className="my-4">
                  {item.label}
                </p>
                <p className="my-4">
                  <Chip label="DELETE" color="secondary" />
                </p>
                <p className="my-4">
                  {`${url}/api/simulator/generate/pdf/remove/${idProject}/${item._id}/:_id`}
                </p>
                <h3 className="my-4">
                  Cuerpo de Encabezado
                </h3>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead style={{backgroundColor: '#201549'}}>
                      <TableRow>
                        <TableCell style={{color: 'white'}}>
                          Campo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Tipo
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Requerido
                        </TableCell>
                        <TableCell style={{color: 'white'}}>
                          Descripción
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        item.isLogged && (
                          <TableRow>
                            <TableCell>
                              AuthorizationApp
                            </TableCell>
                            <TableCell>
                              string
                            </TableCell>
                            <TableCell>
                              Si
                            </TableCell>
                            <TableCell>
                              Token de la autenticación del usuario
                            </TableCell>
                          </TableRow>
                        )
                      }
                      <TableRow>
                        <TableCell>
                          apiKeyLoftyApps
                        </TableCell>
                        <TableCell>
                          string
                        </TableCell>
                        <TableCell>
                          Si
                        </TableCell>
                        <TableCell>
                          API key para aplicaciones externas
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <hr />
              </div>
            </>
          ))
        }
      </Container>
    </Paper>
  );
};

export default DocPluginPdf;
