import React from 'react';
import toastr from 'toastr';
import {Typography, Grid} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {removeTeam} from 'api-lofty';
import 'toastr/build/toastr.min.css';

const RemoveTeamDialog = ({
  open, onClose, successFunction, undoDelete,
  userId, _id, token,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      Eliminar Colaborador
    </DialogTitle>
    <DialogContent>
      <Typography
        style={{
          position: 'relative',
          bottom: '10px',
        }}
      >
        ¿Estás seguro de eliminar al colaborador?
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <Button variant="contained" color="secondary" onClick={() => { if (undoDelete) { undoDelete(); } }}>
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              try {
                await removeTeam({
                  token,
                  _id,
                  userId,
                });
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.success('Se elimino exitosamente el colaborador'), 300);
                if (successFunction) {
                  successFunction();
                }
              } catch (error) {
                if (error.info) {
                  toastr.options = {
                    positionClass: 'toast-top-right',
                    hideDuration: 300,
                    timeOut: 6000,
                  };
                  toastr.clear();
                  setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
                } else {
                  toastr.options = {
                    positionClass: 'toast-top-right',
                    hideDuration: 300,
                    timeOut: 6000,
                  };
                  toastr.clear();
                  setTimeout(() => toastr.error('error: \n No se elimino el colaborador'), 300);
                }
              }
            }}
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
);

export default RemoveTeamDialog;
