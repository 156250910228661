import React from 'react';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const DocApiFacebook = ({
  idProject, loading, fields, url,
}) => (
  <Paper className="py-2">
    <Container>
      {/* Crear usuario o iniciar sesion */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Google
          </b>
          {' '}
          |
          {' '}
          Iniciar sesión o crear usuario
        </h3>
        <p className="my-4">
          Iniciar sesión o crear usuario
        </p>
        <p className="my-4">
          <Chip label="POST" color="primary" />
        </p>
        <p className="my-4">
          {`${url}/api/simulator/user/login/facebook/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              loading ? (
                <TableRow>
                  <TableCell colSpan="4" align="center">Cargando ...</TableCell>
                </TableRow>
              ) : (
                <>
                  <TableRow>
                    <TableCell>
                      tokenId
                    </TableCell>
                    <TableCell>
                      string
                    </TableCell>
                    <TableCell>
                      Si
                    </TableCell>
                    <TableCell>
                      Token de acceso de Google Accounts
                    </TableCell>
                  </TableRow>
                </>
              )
            }
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion de crear usuario o iniciar sesion */}
      {/* Procesamiento del pre registro de google */}
      <div className="mb-5">
        <hr />
        <h3>
          <b>
            Google
          </b>
          {' '}
          |
          {' '}
          Pre Registro
        </h3>
        <p className="my-4">
          Servicio para procesar los campos que son requeridos para el registro
          del usuario en tu aplicacion
        </p>
        <p className="my-4">
          <Chip label="PUT" style={{backgroundColor: 'burlywood', color: 'white'}} />
        </p>
        <p className="my-4">
          {`${url}/login/preregister/facebook/${idProject}`}
        </p>
        <h3 className="my-4">
          Cuerpo de Encabezado
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  apiKeyLoftyApps
                </TableCell>
                <TableCell>
                  string
                </TableCell>
                <TableCell>
                  Si
                </TableCell>
                <TableCell>
                  API key para aplicaciones externas
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h3 className="my-4">
          Cuerpo de solicitud
        </h3>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead style={{backgroundColor: '#201549'}}>
              <TableRow>
                <TableCell style={{color: 'white'}}>
                  Campo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Tipo
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Requerido
                </TableCell>
                <TableCell style={{color: 'white'}}>
                  Descripción
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              loading ? (
                <TableRow>
                  <TableCell colSpan="4" align="center">Cargando ...</TableCell>
                </TableRow>
              ) : (
                <>
                  {
                    fields.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan="4" align="center">Cargando ...</TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {
                          fields.map((field) => (
                            <TableRow>
                              <TableCell>
                                {field.name}
                              </TableCell>
                              <TableCell>
                                {field.type}
                              </TableCell>
                              <TableCell>
                                {
                                  field.required ? 'Si' : 'No'
                                }
                              </TableCell>
                              <TableCell>
                                {
                                  field.description
                                }
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </>
                    )
                  }
                </>
              )
            }
            </TableBody>
          </Table>
        </TableContainer>
        <hr />
      </div>
      {/* Fin de la documentacion del proceso del pre registro de google */}
    </Container>
  </Paper>
);

export default DocApiFacebook;
