import imageCreate from '../assets/endpoint/create.svg';
import imageList from '../assets/endpoint/list.svg';
import imageOne from '../assets/endpoint/one.svg';
import imageDelete from '../assets/endpoint/delete.svg';
import imageUpdate from '../assets/endpoint/update.svg';

export default [
  {
    type: 'create',
    url: '/create',
    name: 'Crear',
    description: 'Servicio para crear',
    image: imageCreate,
  },
  {
    type: 'update',
    url: '/update',
    name: 'Actualizar',
    description: 'Servicio para actualizar',
    image: imageUpdate,
  },
  {
    type: 'one',
    url: '/one',
    name: 'Obtener',
    description: 'Servicio para obtener un dato',
    image: imageOne,
  },
  {
    type: 'list',
    url: '/list',
    name: 'Obtener Todo',
    description: 'Servicio para obtener todos los datos',
    image: imageList,
  },
  {
    type: 'delete',
    url: '/delete',
    name: 'Eliminar',
    description: 'Servicio para eliminar',
    image: imageDelete,
  },
];
