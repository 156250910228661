import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {
  addTemplateEmailPluginsDatabaseFieldChangeController,
  listPluginNotificationDataAction, listPluginDatabaseFieldChange,
  listFieldsDatabase,
} from 'api-lofty';
import DialogFullScreen from '../DialogFullScreen';

const AddMailChangeField = ({
  open, onClose, successFunction, errorFunction,
  idProject, token, idPluginProject,
}) => {
  const [loading, setLoading] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [type, setType] = useState('');
  const [dataEvent, setDataEvent] = useState();
  const [staticEmail, setStaticEmail] = useState(true);
  const [dataFieldUser, setDataFieldUser] = useState();
  const [fields, setFields] = useState([]);
  const [getAdminChange, setGetAdminChange] = useState(true);
  const [isNotificationData, setIsNotificationData] = useState(false);
  const [dataPluginNotification, setDataPluginNotification] = useState([]);
  const [havePdfFile, setHavePdfFile] = useState(false);
  useEffect(async () => {
    try {
      const resEvents = await listPluginDatabaseFieldChange({token, idProject, idPluginProject});
      const resNotification = await listPluginNotificationDataAction({
        token, idProject, idPluginProject,
      });
      setEventsData(resEvents.data);
      setDataPluginNotification(resNotification.data);
    } catch (error) {
      //
    }
  }, []);
  useEffect(() => {
    const handleAsync = async () => {
      try {
        if (dataEvent && !staticEmail) {
          const resField = await listFieldsDatabase({
            token,
            idDatabase: dataEvent.idDatabase,
            idProject,
          });
          setFields(resField.data);
        }
      } catch (error) {
        // code
      }
    };
    handleAsync();
  }, [dataEvent, staticEmail]);
  return (
    <DialogFullScreen title="Agregar Acción" open={open} handleClose={onClose}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            await addTemplateEmailPluginsDatabaseFieldChangeController({
              idProject,
              idPluginProject,
              label: e.target.label.value,
              idDatabase: dataEvent.idDatabase,
              idFieldDatabase: !isNotificationData ? dataEvent.idFieldDatabase : undefined,
              token,
              idPluginDatabaseFieldChange: !isNotificationData ? dataEvent._id : undefined,
              type,
              value: e.target.value.value,
              idFieldUserDatabase: staticEmail ? undefined : dataFieldUser?._id,
              staticEmail,
              textStaticEmail: staticEmail ? e.target.textStaticEmail.value : undefined,
              getAdminChange,
              isNotificationData,
              idPluginNotificationDataAction: isNotificationData ? dataEvent._id : undefined,
              havePdfFile,
              contentHtml: e.target.contentHtml.value,
            });
            if (successFunction) {
              successFunction();
            }
          } catch (error) {
            if (errorFunction) {
              errorFunction(error);
            }
          }
          setLoading(false);
        }}
      >
        <Grid spacing={3} container>
          <Grid item md={6}>
            <div className="my-4">
              <TextField
                fullWidth
                name="label"
                type="text"
                label="Etiqueta"
                variant="outlined"
              />
            </div>
            <div className="my-4">
              <TextField
                select
                fullWidth
                name="type"
                type="text"
                label="Tipo"
                variant="outlined"
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <MenuItem value="text">
                  Correo Texto Plano
                </MenuItem>
              </TextField>
            </div>
            <div className="my-4">
              <FormControlLabel
                control={(
                  <Switch
                    checked={isNotificationData}
                    onChange={() => setIsNotificationData(!isNotificationData)}
                  />
                  )}
                label="Notificación de datos"
              />
            </div>
            <div className="my-4">
              {
              !isNotificationData ? (
                <TextField
                  select
                  fullWidth
                  name="idDatabase"
                  type="text"
                  label="Evento"
                  variant="outlined"
                  onChange={(e) => {
                    const data = eventsData.find(({_id}) => _id === e.target.value);
                    setDataEvent(data);
                  }}
                >
                  {
                    eventsData.map(({_id, label}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              ) : (
                <TextField
                  select
                  fullWidth
                  type="text"
                  label="Notificaciones"
                  variant="outlined"
                  onChange={(e) => {
                    const data = dataPluginNotification.find(({_id}) => _id === e.target.value);
                    setDataEvent(data);
                  }}
                >
                  {
                    dataPluginNotification.map(({_id, label}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              )
            }
            </div>
            <div className="my-4">
              <FormControlLabel
                control={(
                  <Switch
                    checked={staticEmail}
                    onChange={() => setStaticEmail(!staticEmail)}
                  />
                  )}
                label="Correo Estatico"
              />
            </div>
            {
              staticEmail ? (
                <div className="my-4">
                  <TextField
                    fullWidth
                    name="textStaticEmail"
                    type="text"
                    label="Correo"
                    variant="outlined"
                  />
                </div>
              ) : (
                <div className="my-4">
                  <TextField
                    select
                    fullWidth
                    name="idFieldUserDatabase"
                    type="text"
                    label="Campo de usuario"
                    variant="outlined"
                    onChange={(e) => {
                      const data = fields.find(({_id}) => _id === e.target.value);
                      setDataFieldUser(data);
                    }}
                  >
                    {
                      fields.map(({_id, label, type}) => {
                        if (type === 'user') {
                          return (
                            <MenuItem value={_id} key={_id}>
                              {label}
                            </MenuItem>
                          );
                        }
                        return <></>;
                      })
                    }
                  </TextField>
                </div>
              )
            }
            <div className="my-4">
              <TextField
                fullWidth
                name="value"
                type="text"
                label="Contenido del Correo"
                multiline
                rows={3}
                variant="outlined"
              />
            </div>
            {
              !isNotificationData && (
                <div className="my-4">
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={getAdminChange}
                        onChange={() => setGetAdminChange(!getAdminChange)}
                      />
                  )}
                    label="Aplica Administracion"
                  />
                </div>
              )
            }
          </Grid>
          <Grid item md={6}>
            <div className="my-4">
              <FormControlLabel
                control={(
                  <Switch
                    checked={havePdfFile}
                    onChange={() => setHavePdfFile(!havePdfFile)}
                  />
                  )}
                label="Agregar archivo PDF"
              />
            </div>
            {
              havePdfFile && (
                <TextField
                  fullWidth
                  name="contentHtml"
                  type="text"
                  label="Contenido en HTML"
                  multiline
                  rows={10}
                  variant="outlined"
                />
              )
            }
          </Grid>
          <Grid item md={12}>
            <div className="text-center my-4">
              <button
                style={{
                  background: '#201549',
                }}
                type="submit"
                className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                disabled={loading}
              >
                {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
              </button>
            </div>
          </Grid>
        </Grid>
      </form>
    </DialogFullScreen>
  );
};

export default AddMailChangeField;
