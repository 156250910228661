const intervalMinutosHoutsStringArray = (interval) => {
  const array = [];
  let hourstring = '00:00';
  const containMinutes = 60 / interval;
  array.push(hourstring);
  // eslint-disable-next-line no-plusplus
  for (let index = 1; index < 24 * containMinutes; index++) {
    const arraySchedule = hourstring.split(':');
    const minutes = parseInt(arraySchedule[1]);
    const hour = parseInt(arraySchedule[0]);
    const newMinutes = minutes + interval;
    const newhour = hour + 1;
    if (newMinutes < 60) {
      hourstring = `${arraySchedule[0]}:${newMinutes}`;
      array.push(hourstring);
    } else if (newhour < 10) {
      hourstring = `0${newhour}:00`;
      array.push(hourstring);
    } else {
      hourstring = `${newhour}:00`;
      array.push(hourstring);
    }
  }
  return array;
};

export const minutosInterval15 = () => ({
  monday: intervalMinutosHoutsStringArray(15),
  tuesday: intervalMinutosHoutsStringArray(15),
  wednesday: intervalMinutosHoutsStringArray(15),
  thursday: intervalMinutosHoutsStringArray(15),
  friday: intervalMinutosHoutsStringArray(15),
  saturday: intervalMinutosHoutsStringArray(15),
  sunday: intervalMinutosHoutsStringArray(15),
});

export const minutosInterval30 = () => ({
  monday: intervalMinutosHoutsStringArray(30),
  tuesday: intervalMinutosHoutsStringArray(30),
  wednesday: intervalMinutosHoutsStringArray(30),
  thursday: intervalMinutosHoutsStringArray(30),
  friday: intervalMinutosHoutsStringArray(30),
  saturday: intervalMinutosHoutsStringArray(30),
  sunday: intervalMinutosHoutsStringArray(30),
});

export const minutosInterval60 = () => ({
  monday: intervalMinutosHoutsStringArray(60),
  tuesday: intervalMinutosHoutsStringArray(60),
  wednesday: intervalMinutosHoutsStringArray(60),
  thursday: intervalMinutosHoutsStringArray(60),
  friday: intervalMinutosHoutsStringArray(60),
  saturday: intervalMinutosHoutsStringArray(60),
  sunday: intervalMinutosHoutsStringArray(60),
});
