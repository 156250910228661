import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {
  addPluginNotificationDataAction,
  listDatabases,
} from 'api-lofty';
import typesNotificationEmail from '../../util/typesNotificationEmail';

const AddEventChangeField = ({
  open, onClose, successFunction, errorFunction,
  idProject, token, idPluginProject,
}) => {
  const [loading, setLoading] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [type, setType] = useState('');
  const [dataDatabase, setDataDatabase] = useState();
  useEffect(async () => {
    try {
      const resDatabase = await listDatabases({token, idProject});
      //
      setDatabases(resDatabase.data);
    } catch (error) {
      //
    }
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Agregar Evento
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await addPluginNotificationDataAction({
                token,
                idDatabase: dataDatabase._id,
                idPluginProject,
                idProject,
                label: e.target.label.value,
                type,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="outlined"
            />
          </div>
          <div className="my-4">
            <TextField onChange={(e) => setType(e.target.value)} select fullWidth name="type" type="text" label="Tipo" variant="outlined">
              {
                typesNotificationEmail.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="idDatabase"
              type="text"
              label="Base de Datos"
              variant="outlined"
              onChange={(e) => {
                const data = databases.find(({_id}) => _id === e.target.value);
                setDataDatabase(data);
              }}
            >
              {
                databases.map(({_id, label}) => (
                  <MenuItem value={_id} key={_id}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddEventChangeField;
