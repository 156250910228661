import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Box, IconButton} from '@material-ui/core';
import toastr from 'toastr';

const useStyles = makeStyles(() => ({
  mainBox: {
    padding: '15px',
    width: '100%',
    height: '175px',
    overflowY: 'scroll',
  },
  headerSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid rgba(217, 217, 217, 1)',
    borderBottom: '1px solid rgba(217, 217, 217, 1)',
  },
  contentSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid rgba(217, 217, 217, 1)',
    borderBottom: '1px solid rgba(217, 217, 217, 1)',
  },
  typeSection: {
    width: '20%',
    padding: '5px',
  },
  hostSection: {
    width: '40%',
    padding: '5px',
  },
  valueSection: {
    width: '40%',
    padding: '5px',
  },
  iconStyleColor: {
    '&:hover': {
      color: '#D9D9D9',
    },
  },
}));

export default function ListVerifyDomain({
  data,
}) {
  const classes = useStyles();
  async function copyInfoToClipBoard(infodata) {
    try {
      await navigator.clipboard.writeText(infodata);
      toastr.success('Copiado!');
    } catch (error) {
      //
    }
  }
  return (
    <Box component="div" className={classes.mainBox}>
      <Box component="div" className={classes.headerSection}>
        <Box component="div" className={classes.typeSection}>
          Tipo de Registro
        </Box>
        <Box component="div" className={classes.hostSection}>
          Host
        </Box>
        <Box component="div" className={classes.valueSection}>
          Valor
        </Box>
      </Box>
      {data.map((index) => (
        <Box component="div" className={classes.contentSection}>
          <Box component="div" className={classes.typeSection}>
            {index.typeRegister ? 'A' : 'B'}
          </Box>
          <Box component="div" className={classes.hostSection}>
            {index.domainsite}
          </Box>
          <Box component="div" className={classes.valueSection}>
            {index.ipdomain}
            {' '}
            <IconButton
              className={classes.iconStyleColor}
              onClick={() => copyInfoToClipBoard(index.ipdomain)}
            >
              <FileCopyIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
