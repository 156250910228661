import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {addPluginVersionPdf} from 'api-lofty';
// import {addPluginVersionPdf} from '../../api/pluginFieldPdf.api';
import {basicFieldsLabelValue} from '../../util/typesFields';

const AddFieldVersionPdf = ({
  open, onClose, successFunction, errorFunction,
  idPluginProject, idProject, token, versions,
}) => {
  const [loading, setLoading] = useState(false);
  const [typeField, setTypeField] = useState('');
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Agregar Campo
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await addPluginVersionPdf({
                token,
                idPluginProject,
                idPluginVersionPdf: e.target.idPluginVersionPdf.value,
                idProject,
                label: e.target.label.value,
                name: e.target.name.value,
                type: typeField,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="outlined"
              required
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              name="name"
              type="text"
              label="Slug"
              variant="outlined"
              required
            />
          </div>
          <div className="my-4">
            <TextField
              required
              onChange={(e) => setTypeField(e.target.value)}
              select
              fullWidth
              name="type"
              type="text"
              label="Tipo"
              variant="outlined"
            >
              {
                basicFieldsLabelValue.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              required
              name="idPluginVersionPdf"
              type="text"
              label="Version"
              variant="outlined"
            >
              {
                    versions.map(({_id, label}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
            </TextField>
          </div>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddFieldVersionPdf;
