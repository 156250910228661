import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '65%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  initialAreaSection: {
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5px',
    paddingBottom: '15px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderBottom: '1px solid rgba(41, 33, 81, 1)',
  },
  titleSectionContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  quantitySectionContainer: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalSectionContainer: {
    width: '35%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  initialAreaTypography: {
    fontWeight: 400,
    fontSize: '16px',
    color: 'rgba(41, 33, 81, 1)',
  },
  sectorItems: {
    width: '95%',
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderBottom: '1px solid rgba(166, 206, 227, 1)',
  },
  lastSection: {
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '5px',
    paddingBottom: '15px',
    paddingLeft: '5px',
    paddingRight: '5px',
    borderTop: '3px solid rgba(41, 33, 81, 1)',
  },
  totalAreaTypography: {
    fontWeight: 900,
    fontSize: '16px',
    color: 'rgba(41, 33, 81, 1)',
  },
}));

export default function TableProjectSummaryExport({data}) {
  const classes = useStyles();
  let temp = 0;
  data.forEach((element) => {
    temp += element.quantity * element.perUnit;
  });

  function convertToLocale(info) {
    const retVal = info.toLocaleString('en-US', {minimumFractionDigits: 2});
    return retVal;
  }
  return (
    <Box className={classes.mainBox}>
      <Box className={classes.initialAreaSection}>
        <Box className={classes.titleSectionContainer}>
          <Typography className={classes.initialAreaTypography}>
            Data
          </Typography>
        </Box>
        <Box className={classes.quantitySectionContainer}>
          <Typography className={classes.initialAreaTypography}>
            Cantidad
          </Typography>
        </Box>
        <Box className={classes.totalSectionContainer}>
          <Typography className={classes.initialAreaTypography}>
            Total
          </Typography>
        </Box>
      </Box>
      <Box className={classes.sectorItems}>
        {data.map((index) => (
          <Box className={classes.itemContainer}>
            <Box className={classes.titleSectionContainer}>
              <Typography className={classes.initialAreaTypography}>
                {index.name}
              </Typography>
            </Box>
            <Box className={classes.quantitySectionContainer}>
              <Typography className={classes.initialAreaTypography}>
                {index.quantity}
              </Typography>
            </Box>
            <Box className={classes.totalSectionContainer}>
              <Typography className={classes.initialAreaTypography}>
                {`Lps. ${convertToLocale((index.quantity * index.perUnit))}`}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box className={classes.lastSection}>
        <Typography className={classes.totalAreaTypography}>
          Total Exportacion
        </Typography>
        <Typography className={classes.totalAreaTypography}>
          {`Lps. ${convertToLocale(temp)}`}
        </Typography>
      </Box>
    </Box>
  );
}
