/* eslint-disable no-underscore-dangle */
import React, {useEffect, useState} from 'react';
// import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import JoyRide from 'react-joyride';
import {
  Box,
  Button,
} from '@material-ui/core';
import {
  tutorialStatus, addPartner,
  listPartner,
} from 'api-lofty';
import toastr from 'toastr';
import {deletetoken} from '../actions/auth.actions';
import DashboardLayout from '../layouts/PrincipalDasboard';
import ModalShowTutorial from '../components/ModalShowTutorial';
import {mainDashboardTutorial} from '../util/tutorialData';
import 'toastr/build/toastr.min.css';

const Affiliates = ({history, token, user}) => {
  const [openNewInPlatform, setOpenNewInPlatform] = useState(false);
  const [withTutorial, setWithTutorial] = useState(false);
  const [nameInfo, setNameInfo] = useState('');
  const [cityInfo, setCityInfo] = useState('');

  async function handleModalRes() {
    setOpenNewInPlatform(false);
    setWithTutorial(true);
    await tutorialStatus({token, typeTutorialUpdate: 'init'});
    window.localStorage.setItem('newInPlatformValue', 'true');
  }

  async function handleNoTutorial() {
    setOpenNewInPlatform(false);
    await tutorialStatus({token, typeTutorialUpdate: 'init'});
    window.localStorage.setItem('newInPlatformValue', 'true');
  }

  function handleRedoTutorial() {
    setWithTutorial(true);
  }

  async function handleAddPartner() {
    try {
      await addPartner({
        dni: cityInfo,
        name: nameInfo,
        token,
      });
      toastr.success('Solicitud Enviada');
      setCityInfo('');
      setNameInfo('');
    } catch (error) {
      toastr.error(`Error \n ${error}`);
    }
  }

  async function isCurrentPartner() {
    try {
      const getAllPartnerList = await listPartner({token});
      const isPartner = getAllPartnerList.data.find((index) => (
        index.userid === user._id && index.active));
      if (isPartner) {
        history.push('/dashboard/affiliates/main');
      }
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    isCurrentPartner();
  }, []);

  return (
    <DashboardLayout history={history} handleTutorial={() => handleRedoTutorial()} token={token}>
      <ModalShowTutorial
        open={openNewInPlatform}
        handleClose={() => setOpenNewInPlatform(false)}
        handleOpenTutorial={() => handleModalRes()}
        handleWithoutTutorial={() => handleNoTutorial()}
        textinfo="Desea ver un tutorial corto del inicio de la plataforma?"
      />
      <Box>
        {withTutorial
          && (
            <JoyRide
              continuous
              hideCloseButton
              scrollToFirstStep
              showProgress
              showSkipButton
              steps={mainDashboardTutorial}
              locale={{
                back: 'Anterior', close: 'Cerrar', last: 'Ultimo', next: 'Siguiente', open: 'Abrir dialogo', skip: 'Saltar',
              }}
              styles={{
                buttonNext: {
                  backgroundColor: 'rgba(41, 33, 81, 1)',
                },
                buttonBack: {
                  backgroundColor: 'rgba(41, 178, 239, 1)',
                  borderRadius: 4,
                  color: '#fff',
                },
              }}
            />
          )}
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: '#00000000', width: '100%', minHeight: 500, p: 1, m: 1,
        }}
      >
        <Box width="60%">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{backgroundColor: '#E8F4FD'}}
            paddingX="2rem"
            paddingY="1rem"
            marginBottom="2rem"
            width="100%"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src="/icons/important.svg" alt="exclamation mark" style={{marginRight: '1rem'}} />
              <span sx={{color: '#363636'}}>
                Para enviar la petición de afiliación  necesitas llenar la
                información en el formulario
              </span>
            </Box>
          </Box>
          <Box
            sx={{width: '100%'}}
          >
            <Box display="flex" justifyContent="center" sx={{color: '#fff', backgroundColor: '#201549', fontSize: '1.2rem'}}>
              FORMULARIO DE AFILIACIÓN
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              paddingX="5rem"
              paddingY="1.2rem"
              sx={{backgroundColor: '#fff'}}
            >
              <Box display="flex" flexDirection="column" width="100%" marginBottom="0.5rem">
                <Box className="affiliate-input-label" sx={{width: '100%', marginBottom: '0.4rem'}}>
                  Nombre Completo
                </Box>
                <input className="affiliate-form-input" name="fullname" value={nameInfo} onChange={(e) => setNameInfo(e.target.value)} maxLength={100} />
              </Box>
              <Box display="flex" flexDirection="column" width="100%" marginBottom="0.5rem">
                <Box className="affiliate-input-label" sx={{width: '100%', marginBottom: '0.4rem'}}>
                  DNI
                </Box>
                <input className="affiliate-form-input" name="city" value={cityInfo} onChange={(e) => setCityInfo(e.target.value)} maxLength={100} />
              </Box>
              <Button
                variant="contained"
                style={{
                  color: 'black',
                  fontSize: '13px',
                  backgroundColor: '#29B2EF',
                  fontWeight: 'bold',
                  fontFamily: [
                    'Nunito',
                    'Roboto',
                    'Helvetica Neue',
                    'Arial',
                    'sans-serif',
                  ].join(','),
                  textTransform: 'none',
                  marginTop: '2rem',
                }}
                onClick={() => handleAddPartner()}
              >
                Afiliarse Ahora
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Affiliates);
