/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
/*
import {
  getOneProject,
} from 'api-lofty';
*/
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
/* import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails'; */
import {getProjectTemplates} from 'api-lofty';
import Typography from '@material-ui/core/Typography';
import {Box, Hidden} from '@material-ui/core';
// import toastr from 'toastr';
/* import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; */
import Layout from '../layouts/PrincipalDasboard';
import LoadingPage from './LoadingPage';
/* import TemplateCategories from '../components/TemplateCategories'; */
import TemplateListData from '../components/TemplateListData';
/* import sample from '../assets/placeholderTemplate.png'; */
import 'toastr/build/toastr.min.css';
/* import Working from '../components/Working'; */

const useStyle = makeStyles((theme) => ({
  titleBox: {
    width: 'auto',
    marginTop: '5px',
    marginBottom: '15px',
  },
  mainTitle: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '22px',
    fontWeight: '700',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  boxMainContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('600')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  categoryTitle: {
    color: '#292151',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    marginTop: '20px',
  },
}));

const ListTemplates = ({history, token}) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  // const {params} = match;
  // const {idProject} = params;
  // const [project, setProject] = useState(undefined);
  // const [data, setData] = useState();
  const [dataCategories, setDataCategories] = useState([]);
  const [dataTemplates, setDataTemplates] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const handlePreview = (slug) => {
    window.open(`https://${slug}.loftyapps.website`);
  };

  const handlePurchase = (idTemplate) => {
    history.push(`/dashboard/templates/${idTemplate}`);
  };

  const handleCategory = (e) => {
    const temp = dataTemplates;
    if (e.name === 'All') {
      setFilteredData(temp);
      return;
    }
    const toFilter = temp.filter((index) => (index.category === e.name));
    // console.log(toFilter);
    setFilteredData(toFilter);
  };

  useEffect(async () => {
    try {
      // const simData = [{
      //   name: 'Sample 1',
      //   category: 'Bufete',
      //   image: sample,
      //   price: 12,
      //   description: 'Lorem Ipsum',
      // },
      // {
      //   name: 'Sample 2',
      //   category: 'Marketing',
      //   image: sample,
      //   price: 12,
      //   description: 'Lorem Ipsum',
      // },
      // {
      //   name: 'Sample 3',
      //   category: 'E-Commerce',
      //   image: sample,
      //   price: 12,
      //   description: 'Lorem Ipsum',
      // },
      // {
      //   name: 'Sample 4',
      //   category: 'E-Commerce',
      //   image: sample,
      //   price: 15,
      //   description: 'Lorem Ipsum',
      // },
      // {
      //   name: 'Sample 5',
      //   category: 'E-Commerce',
      //   image: sample,
      //   price: 15,
      //   description: 'Lorem Ipsum',
      // },
      // ];
      // const countCategories = [];
      // simData.forEach((index) => {
      //   const getCategory = index.category;
      //   const found = countCategories.some((el) => el.name === getCategory);
      //   if (found) {
      //     // eslint-disable-next-line array-callback-return
      //     countCategories.map((selector) => {
      //       if (selector.name === getCategory) {
      //         const tempCounter = selector.count;
      //         selector.count = tempCounter + 1;
      //       }
      //     });
      //   } else {
      //     countCategories.push({name: index.category, count: 1});
      //   }
      // });
      // countCategories.push({name: 'All', count: simData.length});
      // setDataCategories(countCategories);
      // setDataTemplates(simData);
      // setFilteredData(simData);
      const templateData = await getProjectTemplates({token});
      setDataTemplates(templateData.data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout token={token} history={history}>
      <Box>
        <Box className={classes.titleBox}>
          <Typography className={classes.mainTitle}>
            Plantillas
          </Typography>
        </Box>
        <Box className={classes.boxMainContent}>
          {/* <Hidden xsDown>
            <TemplateCategories
              data={dataCategories}
              handleSelection={(e) => handleCategory(e)}
            />
          </Hidden> */}
          {/* <Hidden smUp>
            <Accordion style={{marginBottom: '20px', width: '100%'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.categoryTitle}>Categorias</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TemplateCategories
                  data={dataCategories}
                  handleSelection={(e) => handleCategory(e)}
                />
              </AccordionDetails>
            </Accordion>
          </Hidden> */}

          <TemplateListData
            data={dataTemplates}
            handlePurchase={handlePurchase}
            handlePreview={handlePreview}
          />
        </Box>
      </Box>
      {/* <Working /> */}
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ListTemplates);
