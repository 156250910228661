/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {getOneProject, listActions} from 'api-lofty';
import {makeStyles} from '@material-ui/core/styles';
// import Working from '../components/Working';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import ListActivitesProjectSection from '../components/ListActivitiesProjectSection';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const ActivitiesProject = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  // const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const [pageActual, setPageActual] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [limitPage] = useState(20);
  const [logsProject, setLogsProject] = useState([]);
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();

  useEffect(async () => {
    /*
      CHECK RES DATA TOTAL
    */
    const resLogs = await listActions({
      token,
      idProject,
      limit: limitPage,
      page: pageActual,
      idDatabase: 'none',
      idPage: 'none',
      idFieldDatabase: 'none',
      idFieldUserProject: 'none',
      typeFilter: 'project',
    });
    // console.log(resLogs.data);
    setLogsProject(resLogs.data);
    getOneProject({token, idProject}).then((response) => {
      setProject(response.project);
      setLabelUrl(`proyectos/${response.project.name}/Actividades`);
      // TODO seccion para obtener el listado del equipo
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  const handleNextPage = async () => {
    const resLogs = await listActions({
      token,
      idProject,
      limit: limitPage,
      page: pageActual,
      idDatabase: 'none',
      idPage: 'none',
      idFieldDatabase: 'none',
      idFieldUserProject: 'none',
      typeFilter: 'project',
    });
    setLogsProject(resLogs.data);
    setPageActual(pageActual + limitPage);
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = async () => {
    const resLogs = await listActions({
      token,
      idProject,
      limit: limitPage,
      page: pageActual,
      idDatabase: 'none',
      idPage: 'none',
      idFieldDatabase: 'none',
      idFieldUserProject: 'none',
      typeFilter: 'project',
    });
    setLogsProject(resLogs.data);
    setPageActual(pageActual - limitPage);
    setPageNumber(pageNumber - 1);
  };

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <ListActivitesProjectSection
        data={logsProject.logs}
        actualPage={pageNumber}
        total={logsProject.total}
        limitNumber={limitPage}
        previousPage={handlePreviousPage}
        nextPage={handleNextPage}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ActivitiesProject);
