import React, {useState, useEffect} from 'react';
import {listPluginCalendarMeeting, listPluginCalendar, listPluginCalendarSchedule} from 'api-lofty';
import {Paper, TextField, MenuItem} from '@material-ui/core';
import moment from 'moment';
import {Calendar, momentLocalizer} from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const EventsCalendar = ({
  token, idPluginProject, idProject,
}) => {
  const [calendar, setCalendar] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState({});
  const handleData = async () => {
    try {
      const resCalendar = await listPluginCalendar({
        idPluginProject, idProject, token,
      });
      setCalendar(resCalendar.data);
      if (resCalendar.data.length > 0) {
        const resSchedule = await listPluginCalendarSchedule({
          idProject, idPluginCalendar: resCalendar.data[0]._id, token,
        });
        setSelectedCalendar(resCalendar.data[0]._id);
        const resMeeting = await listPluginCalendarMeeting({
          token, idPluginCalendar: resCalendar.data[0]._id, idProject,
        });
        if (resSchedule.data?.length > 0) {
          setMeetings(resMeeting.data.map((item) => {
            const meetingDate = new Date(item.loftyDate);
            const dateInitial = new Date(
              `${meetingDate.getFullYear()}-${meetingDate.getMonth() + 1}-${meetingDate.getDate()}`,
            );
            const dateEnd = new Date(
              `${meetingDate.getFullYear()}-${meetingDate.getMonth() + 1}-${meetingDate.getDate()}`,
            );
            dateEnd.setMinutes(dateEnd.getMinutes() + resSchedule.data[0].rangeMinutes);
            return {
              start: dateInitial,
              end: dateEnd,
              title: item.loftyReason,
            };
          }));
        }
      }
    } catch (error) {
      // error
    }
  };
  useEffect(() => {
    handleData();
  }, []);
  return (
    <Paper className="p-3">
      <div className="my-4">
        <TextField
          nChange={async (e) => {
            setSelectedCalendar(e.target.value);
            const resMeeting = await listPluginCalendarMeeting({
              token, idPluginCalendar: e.target.value, idProject,
            });
            setMeetings(resMeeting.data);
          }}
          select
          style={{
            width: '400px',
          }}
          name="type"
          type="text"
          label="Calendario"
          variant="standard"
          value={selectedCalendar}
        >
          {
            calendar.map(({label, _id}) => (
              <MenuItem value={_id} key={_id}>
                {label}
              </MenuItem>
            ))
          }
        </TextField>
      </div>
      <Calendar
        localizer={localizer}
        events={meetings.map((item) => item)}
        startAccessor="start"
        endAccessor="end"
        style={{height: 500}}
      />
    </Paper>
  );
};

export default EventsCalendar;
