/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {getOneProject, getOnePluginProject} from 'api-lofty';
import Layout from '../layouts/PluginsProjectDashboard';
import LoadingPage from './LoadingPage';
import Notifications from '../components/EventPluginProject/Notifications';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const PluginNotificationData = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idPluginsProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataPluginProject, setDataPluginProject] = useState();
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resPluginProject = await getOnePluginProject({token, _id: idPluginsProject, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Plugins/${resPluginProject.data.type}/Notificaciones`);
      setDataPluginProject(resPluginProject.data);
      setDataProject(resProject.project);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idPluginProject={idPluginsProject}
      pluginProjectType={dataPluginProject?.type}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      {
        dataPluginProject?.type === 'changeFieldDatabase' && (
          <Notifications
            token={token}
            idPluginProject={idPluginsProject}
            idProject={idProject}
          />
        )
      }
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PluginNotificationData);
