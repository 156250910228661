import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress';
import imgBackground1 from '../img/loading/bg-spinner-3_1.png';
import imgBackground2 from '../img/loading/bg-spinner-movil_1.png';
import loadingLogo from '../img/loading/LOGO.png';
import '../styles/loadingCube.scss';

const useStyle = makeStyles((theme) => ({
  containerMain: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: '100vh',
    width: '100%',
    alignItems: 'center',
    backgroundImage: `url(${imgBackground1})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {backgroundImage: `url(${imgBackground2})`},
  },
  textLogoArea: {
    position: 'absolute',
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'block',
    color: '#fff',
    height: '100px',
    marginBottom: '200px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: '50%',
      justifyContent: 'center',
    },
  },
  containerGrid: {
    [theme.breakpoints.down('sm')]: {display: 'block'},
  },
}));

const LoadingPage = ({textLoading}) => {
  const classes = useStyle();
  return (
    <Box component="div" className={classes.containerMain}>
      <Box component="div" className={classes.textLogoArea}>
        <Grid container spacing={2} className={classes.containerGrid}>
          <Grid item md={6} sm={12}>
            <div style={{padding: '25px', marginTop: '15px'}}>
              Gracias por Utilizar
            </div>
          </Grid>
          <Grid item md={6} sm={12}>
            <Box component="img" src={loadingLogo} alt="icon" />
          </Grid>
        </Grid>
      </Box>
      <Box component="div" className="cube-wrapper">
        <div className="cube-folding">
          <span className="leaf1" />
          <span className="leaf2" />
          <span className="leaf3" />
          <span className="leaf4" />
        </div>
        <span className="loading" data-name="Loading">
          {textLoading || 'Cargando'}
        </span>
      </Box>
    </Box>
  );
};

export default LoadingPage;
