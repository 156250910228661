/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, {useState, useEffect} from 'react';
import toastr from 'toastr';
import moment from 'moment';
import {Box, Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import {listValueStringField} from 'api-lofty';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import {typeFormField} from '../util/typesFields';
import EditorJs from './EditorJs';

const useStyles = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
}));

const handleLabelRelation = (data, fields) => {
  let label = '';
  fields.forEach((field) => {
    if (field.isLabel) {
      label += `${data[field.name]}`;
    }
  });
  return label;
};

const handleDefaultData = (docUpdate, field) => {
  if (docUpdate) {
    return docUpdate[field];
  }
  return '';
};

const handleDefaultDateData = (docUpdate, field) => {
  if (docUpdate) {
    return moment(docUpdate[field]).format('YYYY-MM-DD');
  }
  return '';
};

const handleDefaultCoordinate = (coordinate, type) => {
  if (type === 'latitude' && coordinate) {
    return coordinate[0];
  } if (type === 'longitude' && coordinate) {
    return coordinate[0];
  }
  return 0;
};

const BucketDataForm = ({
  open, onClose, loading, handleSubmit, fields, relation, docUpdate, arrayTestUser,
  idProject, token,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState();
  const [valueString, setValueString] = useState([]);
  let coordinateLatitude = [];
  let coordinateLongitude = [];
  if (docUpdate !== undefined && docUpdate.coordinates !== undefined) {
    const latitudeString = docUpdate.coordinates.match(/Latitude: [0-9.-]+/g);
    const longitudeString = docUpdate.coordinates.match(/Longitude: [0-9.-]+/g);
    if (latitudeString) {
      coordinateLatitude = latitudeString[0].match(/[0-9.-]+/g);
    }
    if (longitudeString) {
      coordinateLongitude = longitudeString[0].match(/[0-9.-]+/g);
    }
  }
  const handleFileChange = (event) => {
    if (!event.target.files) {
      return;
    }

    setFile(event.target.files[0]);
  };
  const handleValueString = async () => {
    try {
      const newValueString = [];
      for (const field of fields) {
        if (field.type === 'string') {
          const res = await listValueStringField({
            idFieldDatabase: field._id, idProject, token,
          });
          newValueString.push({
            field,
            valueString: res.data,
          });
        }
      }
      setValueString(newValueString);
    } catch (error) {
      toastr.error('Error al obtener los valores permitidos de los campos');
    }
  };
  useEffect(() => {
    handleValueString();
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            {`${!docUpdate ? 'Agregar' : 'Actualizar'}`}
            {' '}
            Dato
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => handleSubmit(e, file)}
        >
          {
          fields.map((field) => {
            if (field.type === 'boolean') {
              return (
                <FormControl key={field._id} className="my-4">
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name={field.name}
                        color="primary"
                      />
                    )}
                    label={field.label}
                    defaultChecked={handleDefaultData(docUpdate, field.name)}
                  />
                </FormControl>
              );
            }
            if (field.type === 'relation') {
              return (
                <div key={field._id} className="my-4">
                  <TextField
                    fullWidth
                    name={field.name}
                    select
                    label={field.label}
                    variant="standard"
                    defaultValue={handleDefaultData(docUpdate, field.name)}
                  >
                    {
                      relation[field.name]?.data?.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {handleLabelRelation(data.data, relation[field.name].fields)}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </div>
              );
            }
            if (field.type === 'date') {
              return (
                <div key={field._id} className="my-4">
                  <TextField
                    fullWidth
                    name={field.name}
                    type={typeFormField(field.type)}
                    label={field.label}
                    variant="standard"
                    defaultValue={handleDefaultDateData(docUpdate, field.name)}
                  />
                </div>
              );
            }
            if (field.type === 'user') {
              return (
                <div key={field._id} className="my-4">
                  <TextField
                    fullWidth
                    name={field.name}
                    select
                    label={field.label}
                    variant="standard"
                    defaultValue={handleDefaultData(docUpdate, field.name)}
                  >
                    {
                      arrayTestUser?.map((data) => (
                        <MenuItem key={data._id} value={data._id}>
                          {data.data.loftyEmail}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </div>
              );
            }
            if (field.type === 'hour') {
              return (
                <Box component="div" className="my-4">
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100px',
                    }}
                  >
                    <Box component="label" style={{fontSize: '16px', color: 'gray', fontFamily: 'Segoe UI'}}>
                      {field.label}
                    </Box>
                    <Box component="input" type="time" name={field.name} label={field.label} />
                  </Box>
                </Box>
              );
            }
            if (field.type === 'coordinates') {
              return (
                <Box component="div" className="my-4">
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100px',
                    }}
                  >
                    <Box component="label" style={{fontSize: '16px', color: 'gray', fontFamily: 'Segoe UI'}}>
                      {field.label}
                    </Box>
                    <TextField
                      fullWidth
                      name="latitude"
                      type="number"
                      inputProps={{min: -90, max: 90, step: '.0000001'}}
                      label="Latitude"
                      variant="standard"
                      defaultValue={handleDefaultCoordinate(coordinateLatitude, 'latitude')}
                    />
                    <TextField
                      fullWidth
                      name="longitude"
                      type="number"
                      inputProps={{min: -180, max: 180, step: '.0000001'}}
                      label="Longitude"
                      variant="standard"
                      defaultValue={handleDefaultCoordinate(coordinateLongitude, 'longitude')}
                    />
                  </Box>
                </Box>
              );
            } if (field.type === 'textrich') {
              return (
                <EditorJs
                  defaultValue={handleDefaultData(docUpdate, field.name)}
                  label={field.label}
                  name={field.name}
                  idProject={idProject}
                  token={token}
                />
              );
            }
            if (field.type === 'string') {
              const values = valueString.find((item) => item.field._id === field._id);
              if (values && values.valueString?.length > 0) {
                return (
                  <>
                    <div key={field._id} className="my-4">
                      <TextField
                        fullWidth
                        name={field.name}
                        select
                        type="text"
                        label={field.label}
                        variant="standard"
                        defaultValue={handleDefaultData(docUpdate, field.name)}
                      >
                        {
                        values.valueString.map((item) => (
                          <MenuItem key={item._id} value={item.slug}>
                            {item.label}
                          </MenuItem>
                        ))
                      }
                      </TextField>
                    </div>
                  </>
                );
              }
              return (
                <>
                  <div key={field._id} className="my-4">
                    <TextField
                      fullWidth
                      name={field.name}
                      type="text"
                      label={field.label}
                      variant="standard"
                      defaultValue={handleDefaultData(docUpdate, field.name)}
                    />
                  </div>
                </>
              );
            }
            return (
              <>
                <div key={field._id} className="my-4">
                  <TextField
                    fullWidth
                    name={field.name}
                    type={typeFormField(field.type)}
                    label={field.label}
                    variant="standard"
                    defaultValue={handleDefaultData(docUpdate, field.name)}
                  />
                </div>

              </>
            );
          })
        }
          <div key="file" className="my-4">
            <Button
              variant="contained"
              component="label"
              onChange={handleFileChange}
            >
              {file ? `${file.name}` : 'Upload File'}
              <input
                type="file"
                hidden
                required
              />
            </Button>
          </div>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
              loading ? (
                <>
                  Procesando ...
                </>
              ) : (
                <>
                  Guardar
                </>
              )
            }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default BucketDataForm;
