/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
// import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import JoyRide from 'react-joyride';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Typography, Grid, Box, Button,
} from '@material-ui/core';
import {
  listProject, addUserProject, addProject, tutorialStatus,
  getTutorialStatus,
  getLoggedUser,
  reSendEmailVerify,
  logout,
} from 'api-lofty';
import {getTemplateProject} from 'api-lofty/lib/api/project.api';
import {makeStyles} from '@material-ui/core/styles';
import toastr from 'toastr';
import {deletetoken} from '../actions/auth.actions';
import DashboardLayout from '../layouts/PrincipalDasboard';
import CardComponent from '../components/CardComponent';
// import {listProject} from '../api/project.api';
import projectImage from '../assets/project/sample_icon.png';
import AddProjectModal from '../components/AddProjectModal';
import ModalShowTutorial from '../components/ModalShowTutorial';
import {mainDashboardTutorial} from '../util/tutorialData';
import 'toastr/build/toastr.min.css';
import VerifyEmailModal from '../components/VerifyEmailModal';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
}));

const DashboardHome = ({deletetokenApp, history, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openNewInPlatform, setOpenNewInPlatform] = useState(false);
  const [withTutorial, setWithTutorial] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const [idTemplate, setIdTemplate] = useState('');
  const [templates, setTemplates] = useState([]);
  const classes = useStyle();
  useEffect(() => {
    getLoggedUser({token}).then((res) => {
      if (!res.user.verified) {
        setIsVerified(false);
      } else {
        // eslint-disable-next-line no-useless-return
        return;
      }
    });
    getTutorialStatus({token, typeTutorialUpdate: 'init'}).then((res) => {
      if ((res.user.doneTutorialInitPlatform === undefined)) {
        setOpenNewInPlatform(true);
      }
      if (!res.user.doneTutorialInitPlatform) {
        setOpenNewInPlatform(true);
      }
    });
    listProject({token}).then((res) => {
      setData(res.projects);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
    getTemplateProject({token}).then((res) => {
      setTemplates(res.data);
    });
  }, []);

  const updateInfo = async () => {
    const dataProject = await listProject({token});
    setData(dataProject.projects);
  };

  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    try {
      const restProject = await addProject({
        token,
        name: e.target.name.value,
        slug: e.target.slug.value,
        description: e.target.description.value,
        idTemplate,
      });
      const {data} = restProject;
      if (e.target.userSystem.value === 'true') {
        await addUserProject({
          type: 'email',
          idProject: data._id,
          token,
        });
      }
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se agrego el proyecto'), 300);
      updateInfo();
      setOpenAdd(false);
    } catch (error) {
      // console.log(error);
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se pudo agregar el proyecto'), 300);
      }
    }
  };

  async function handleModalRes() {
    setOpenNewInPlatform(false);
    setWithTutorial(true);
    await tutorialStatus({token, typeTutorialUpdate: 'init'});
    window.localStorage.setItem('newInPlatformValue', 'true');
  }

  async function handleNoTutorial() {
    setOpenNewInPlatform(false);
    await tutorialStatus({token, typeTutorialUpdate: 'init'});
    window.localStorage.setItem('newInPlatformValue', 'true');
  }

  function handleRedoTutorial() {
    setWithTutorial(true);
  }

  async function handleResendEmail() {
    try {
      await reSendEmailVerify({token});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se envio el correo'), 300);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('No se logro enviar el correo.'), 300);
    }
  }

  const handleLogout = async () => {
    try {
      await logout({token});
      deletetokenApp();
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('No se logro enviar el correo.'), 300);
      }
    }
  };

  return (
    <DashboardLayout history={history} handleTutorial={() => handleRedoTutorial()} token={token}>
      <VerifyEmailModal
        open={!isVerified}
        onClose={null}
        handleResendEmail={() => handleResendEmail()}
        logoutSession={() => handleLogout()}
      />
      <ModalShowTutorial
        open={openNewInPlatform}
        handleClose={() => setOpenNewInPlatform(false)}
        handleOpenTutorial={() => handleModalRes()}
        handleWithoutTutorial={() => handleNoTutorial()}
        textinfo="Desea ver un tutorial corto del inicio de la plataforma?"
      />
      <Box>
        {withTutorial
       && (
       <JoyRide
         continuous
         hideCloseButton
         scrollToFirstStep
         showProgress
         showSkipButton
         steps={mainDashboardTutorial}
         locale={{
           back: 'Anterior', close: 'Cerrar', last: 'Ultimo', next: 'Siguiente', open: 'Abrir dialogo', skip: 'Saltar',
         }}
         styles={{
           buttonNext: {
             backgroundColor: 'rgba(41, 33, 81, 1)',
           },
           buttonBack: {
             backgroundColor: 'rgba(41, 178, 239, 1)',
             borderRadius: 4,
             color: '#fff',
           },
         }}
       />
       )}
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Typography className={classes.miniNavbarMenuTitle}>
          Proyectos
        </Typography>
        <Button
          variant="contained"
          style={{
            color: 'black',
            fontSize: '13px',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          onClick={() => setOpenAdd(true)}
        >
          Crear Proyecto
        </Button>
        <AddProjectModal
          open={openAdd}
          handleClose={() => setOpenAdd(false)}
          handleForm={(e) => handleSubmitAdd(e)}
          setSelectedTemplate={(value) => setIdTemplate(value)}
          templates={templates}
          projectData={data}
        />
      </Box>
      {
        loading ? (
          <div className="text-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box
              component="div"
              sx={{
                backgroundColor: '#fff',
                width: '100%',
                height: 'auto',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                p: 1,
                m: 1,
              }}
            >
              {
              data.length === 0 ? (
                <h3 className="text-center">
                  <b>
                    No hay Proyectos
                  </b>
                </h3>
              ) : (
                <Grid container>

                  {
                    data.map((item) => (
                      <Grid key={item._id} item md={4} sm={6} xs={12}>
                        <div className="mt-4" style={{width: '90%'}}>
                          <Box className={classes.contentAlign}>
                            <CardComponent
                              name={item.name}
                              slug={item.slug}
                              image={projectImage}
                              onClick={() => history.push(`/dashboard/project/${item._id}`)}
                            />
                          </Box>
                        </div>
                      </Grid>
                    ))
                  }

                </Grid>
              )
            }
            </Box>
          </>
        )
      }
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome);
