import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Thumbnail from 'react-webpage-thumbnail';
import Typography from '@material-ui/core/Typography';
import {Box, Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60%',
    backgroundColor: '#FCFCFC',
    border: '1px solid #E2E2E2',
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  buttonSection: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('350')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  textContainer: {
    width: '70%',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  textStyle: {
    color: '#6E7A90',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('600')]: {
      fontSize: '12px',
      textAlign: 'center',
    },
  },
  previewButtonStyle: {
    backgroundColor: '#D9D9D9',
    color: '#292151',
    fontWeight: 700,
    fontSize: '13px',
    borderRadius: '10px',
    width: '150px',
    [theme.breakpoints.down('600')]: {
      width: '100px',
      fontSize: '11px',
    },
    [theme.breakpoints.down('350')]: {
      width: '120px',
      fontSize: '12px',
    },
  },
  continueBuyingButtonStyle: {
    backgroundColor: '#292151',
    color: '#FFF',
    fontWeight: 700,
    fontSize: '11px',
    borderRadius: '10px',
    width: '150px',
    [theme.breakpoints.down('600')]: {
      width: '110px',
      fontSize: '8px',
    },
    [theme.breakpoints.down('350')]: {
      width: '120px',
      fontSize: '9px',
      marginTop: '10px',
    },
  },
}));

export default function TemplateCartInfo({data, preview, continueShopping}) {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
      <Box component="div" src={data.image}>
        <Thumbnail
          url={`https://${data.slug}.loftyapps.website`}
          width={600}
          height={450}
        />
      </Box>
      <Box component="div" className={classes.buttonSection}>
        <Button
          className={classes.previewButtonStyle}
          onClick={preview}
        >
          Previsualizar
        </Button>
        <Button
          className={classes.continueBuyingButtonStyle}
          onClick={continueShopping}
        >
          Seguir Comprando
        </Button>
      </Box>
      <Box component="div" className={classes.textContainer}>
        <Typography className={classes.textStyle}>
          {data.description}
        </Typography>
      </Box>
    </Box>
  );
}
