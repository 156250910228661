/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Button,
  TextField, Checkbox, FormControlLabel,
  Menu, Select, MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: '5px',
    padding: '5px',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  textFilterTitle: {
    fontSize: '21px',
    fontWeight: 'normal',
  },
  paperMenu: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  filterMenu: {
    width: '500px',
    height: '500px',
    padding: '20px 15px',
    borderRadius: '15px',
    backgroundColor: 'rgba(246, 250, 253, 1)',
  },
  filterFields: {
    height: '400px',
    overflowY: 'scroll',
  },
  textButton: {
    fontSize: '14px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  inputField: {
    padding: '10px',
  },
  inputTitle: {
    fontSize: '16px',
    color: 'rgba(9, 44, 76, 1)',
    fontFamily: 'Segoe UI',
    fontWeight: 700,
  },
  buttonArea: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  },
  basicStyleInput: {
    border: '1px solid rgba(234, 238, 244, 1)',
    borderRadius: '8px',
    padding: '8px',
    height: '45px',
  },
  inputStyle: {
    '& .MuiOutlinedInput-root': {
      border: '1px solid rgba(234, 238, 244, 1)',
      borderRadius: '8px',
      padding: '1px',
      height: '45px',
    },
  },
}));

export default function FilterListData({
  data, fieldsComponent, dataRelation, dataUsers,
  handleParamUpdate, isBackoffice,
  // paramFromUrl,
}) {
  // console.log(data, dataRelation, dataUsers);
  const classes = useStyles();
  const [anchorFilters, setAnchorFilters] = useState(null);
  const [paramData, setParamData] = useState([]);
  const [orderBy, setOrderBy] = useState('asc');
  const isFiltersOpen = Boolean(anchorFilters);
  const filterMenuId = 'primary-filter-data-menu';
  const handleMenuOpen = (event) => {
    setAnchorFilters(event.currentTarget);
  };
  const handleAnchorClose = () => {
    setAnchorFilters(null);
  };

  function resetData() {
    setParamData([]);
    setOrderBy('asc');
    handleParamUpdate([], 'asc');
  }

  function saveParamToData(e, index) {
    const indexOfParam = paramData.findIndex((obj) => obj.index.name === index.name);
    setParamData((prevState) => {
      if (indexOfParam === -1) {
        if (index.type === 'boolean') {
          return [...prevState, {index, value: true}];
        } else {
          // Object doesn't exist in the array, so add it
          return [...prevState, {index, value: e}];
        }
      } else {
        // Object already exists, so update it
        const updatedArray = [...prevState];
        if (index.type === 'boolean') {
          const {value} = updatedArray[indexOfParam];
          updatedArray[indexOfParam] = {index, value: !value};
        } else {
          updatedArray[indexOfParam] = {index, value: e};
        }
        return updatedArray;
      }
    });
  }

  /**
   * --- Get Saved Data ---
   * ====================================
   *
   * Search in array for the value of the field.
   * Returns the value if found, else returns [].
  */
  function getSavedData(index) {
    // console.log(index);
    try {
      // console.log('flag eins');
      const indexOfParam = paramData.findIndex((obj) => obj.index.name === index.name);
      // console.log(`flag 一番 ${indexOfParam}`);
      if (indexOfParam !== -1) {
        // console.log(`flag 二番 ${paramData[indexOfParam].value}`);
        return paramData[indexOfParam].value;
      } else {
        if (index.type === 'boolean') {
          return false;
        }
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  /**
   * --- Group Data by String ---
   * ====================================
   *
   * Only for fields with String type.
   * Allows grouping of strings by letter.
   * Saves letters for search, the first letter
   * for grouping and value to save it in filter.
  */
  const GroupDataString = (arrayData) => {
    const optionData = [];
    arrayData.forEach((option) => {
      if (option !== 'No definido' && option) {
        const firstLetter = option[0].toUpperCase();
        optionData.push({
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
          value: option,
        });
      }
      // const firstLetter = option[0].toUpperCase();
      // return {
      //   firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      //   ...option,
      // };
    });
    // const retval = arrayData?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));
    // console.log(retval);
    // console.log(optionData);
    return optionData;
  };

  /**
   * --- SEARCH FOR TYPE FIELD STRING ---
   * ====================================
   *
   * Only for fields with Relation type.
   * Search for the specific field to render
   * a string value, otherwise returns NO Data.
  */

  function SearchForFieldTypeString(metadata, prop) {
    try {
      let retVal = '-- NO DATA --';
      // Search for the field that is Label
      const fieldLabelRelation = metadata.fieldMeta.find((relationField) => (
        relationField.isLabel
      ));
      if (fieldLabelRelation && !isBackoffice) {
        retVal = prop.data[fieldLabelRelation.name];
      }
      if (fieldLabelRelation && isBackoffice) {
        retVal = prop[fieldLabelRelation.name];
      }
      return retVal;
    } catch (error) {
      return 'No definido';
    }
  }

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.rootTitle}>
        <Button
          onClick={handleMenuOpen}
          variant="contained"
          color="primary"
          size="small"
          className={classes.textButton}
          style={{
            color: 'rgba(32, 21, 73, 1)',
            fontWeight: 'bold',
            backgroundColor: '#fff',
            border: '1px solid rgba(32, 21, 73, 1)',
            borderRadius: '10px',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
        >
          Filtros
        </Button>
      </Box>
      <Menu
        anchorEl={anchorFilters}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        id={filterMenuId}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={isFiltersOpen}
        onClose={handleAnchorClose}
        className={classes.paperMenu}
      >
        <Box className={classes.filterMenu}>
          <Box className={classes.filterFields}>
            {fieldsComponent.map((index) => {
            /*
              'string', 'number', 'boolean', 'date', 'relation', 'user',
              'hour', 'coordinates'
            */
              switch (index.type) {
                case 'string': {
                  const filteredData = data.map((iterator) => (iterator[index.name]));
                  // console.log(dataSearch);
                  const dataSearch = GroupDataString(filteredData);
                  return (
                    <Box className={classes.inputField}>
                      <Box component="label" className={classes.inputTitle}>
                        {index.label}
                      </Box>
                      <Autocomplete
                        multiple
                        id={`id_${index.name}`}
                        options={dataSearch.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => (option.value.toString())}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      // onChange={(e) => saveParamToData(e, index)}
                        onChange={(e, value) => {
                          saveParamToData(value, index);
                        }}
                        className={classes.inputStyle}
                        value={getSavedData(index)}
                      />
                    </Box>
                  );
                }
                case 'number': {
                  return (
                    <Box className={classes.inputField}>
                      <Box component="label" className={classes.inputTitle}>
                        {index.label}
                      </Box>
                      <TextField
                        type="number"
                        name={`${index.name}`}
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          saveParamToData(e.target.value, index);
                        }}
                        className={classes.inputStyle}
                        value={getSavedData(index)}
                      />
                    </Box>
                  );
                }
                case 'boolean': {
                  return (
                    <Box className={classes.inputField}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            name={`id_${index.name}`}
                            color="primary"
                            onChange={() => {
                              saveParamToData('', index);
                            }}
                            checked={getSavedData(index) || false}
                          />
                      )}
                        label={index.label}
                      />
                    </Box>
                  );
                }
                case 'date': {
                  return (
                    <Box className={classes.inputField}>
                      <Box component="label" className={classes.inputTitle}>
                        {index.label}
                      </Box>
                      <TextField
                        fullWidth
                        name={index.name}
                        type="date"
                        variant="standard"
                        onChange={(e) => {
                          saveParamToData(e.target.value, index);
                        }}
                        className={classes.inputStyle}
                        value={getSavedData(index)}
                      />
                    </Box>
                  );
                }
                case 'relation': {
                  // console.log(dataRelation);
                  if (dataRelation?.length > 0) {
                    if (dataRelation?.length > 0 === undefined) {
                      return null;
                    }
                    const selectedData = dataRelation?.find((key) => {
                      // console.log(key);
                      if (key.fieldInfo === index.name) {
                        return key;
                      }
                    });
                    return (
                      <Box className={classes.inputField}>
                        <Box component="label" className={classes.inputTitle}>
                          {index.label}
                        </Box>
                        <Autocomplete
                          multiple
                          id={`id_${index.name}`}
                          options={selectedData?.dataSimDatabase || []}
                          getOptionLabel={(option) => (SearchForFieldTypeString(selectedData, option) || '')}
                          isOptionEqualToValue={(option, value) => (option?.dataSimDatabase._id === value)}
                          renderInput={(params) => <TextField {...params} variant="outlined" />}
                          renderOption={(props) => (
                            <li {...props}>
                              {SearchForFieldTypeString(selectedData, props)}
                            </li>
                          )}
                        // onChange={(e) => saveParamToData(e, index)}
                          onChange={(e, value) => {
                            saveParamToData(value, index);
                          }}
                          className={classes.inputStyle}
                          value={getSavedData(index)}
                        />
                      </Box>
                    );
                  }
                  break;
                }
                case 'user': {
                  return (
                    <Box className={classes.inputField}>
                      <Box component="label" className={classes.inputTitle}>
                        {index.label}
                      </Box>
                      <Autocomplete
                        multiple
                        id={`id_${index.name}`}
                        options={dataUsers || []}
                        getOptionLabel={(option) => (option.data.loftyEmail || '')}
                        isOptionEqualToValue={(option, value) => (option._id === value)}
                      // getOptionLabel={(option) => (option[index])}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        renderOption={(props) => (
                          <li {...props}>
                            {`${props.data?.loftyEmail} ${props.data?.loftyName}`}
                          </li>
                        )}
                      // onChange={(e) => saveParamToData(e, index)}
                        onChange={(e, value) => {
                          saveParamToData(value, index);
                        }}
                        className={classes.inputStyle}
                        value={getSavedData(index)}
                      />
                    </Box>
                  );
                }
                case 'hour': {
                  return (
                    <Box className={classes.inputField}>
                      <Box
                        component="div"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100px',
                        }}
                      >
                        <Box component="label" className={classes.inputTitle}>
                          {index.label}
                        </Box>
                        <Box
                          component="input"
                          type="time"
                          name={index.name}
                          label={index.label}
                          onChange={(e) => {
                            saveParamToData(e.target.value, index);
                          }}
                          value={getSavedData(index)}
                          className={classes.basicStyleInput}
                        />
                      </Box>
                    </Box>
                  );
                }
                // case 'coordinates': {
                //   return (
                //     <Box className={classes.inputField}>
                //       <Box
                //         component="div"
                //         style={{
                //           display: 'flex',
                //           flexDirection: 'column',
                //           width: '75%',
                //         }}
                //       >
                //         <Box component="label" className={classes.inputTitle}>
                //           {index.label}
                //         </Box>
                //         <TextField
                //           fullWidth
                //           name="latitude"
                //           type="number"
                //           inputProps={{min: -90, max: 90, step: '.0000001'}}
                //           label="Latitude"
                //           variant="standard"
                //         />
                //         <TextField
                //           fullWidth
                //           name="longitude"
                //           type="number"
                //           inputProps={{min: -180, max: 180, step: '.0000001'}}
                //           label="Longitude"
                //           variant="standard"
                //         />
                //       </Box>
                //     </Box>
                //   );
                // }
                default: {
                  break;
                }
              }
            })}
          </Box>
          <Box>
            <Box component="label" className={classes.inputTitle}>
              Ordernar por
            </Box>
            <Select
              labelId="select-order-of-table"
              id="select-order-of-table"
              value={orderBy}
              label="order"
              onChange={(e) => setOrderBy(e.target.value)}
              style={{marginLeft: '10px'}}
            >
              <MenuItem value="desc">Descendente</MenuItem>
              <MenuItem value="asc">Ascendente</MenuItem>
            </Select>
          </Box>
          <Box className={classes.buttonArea}>
            <Button
              onClick={() => handleParamUpdate(paramData, orderBy)}
              variant="contained"
              color="primary"
              size="small"
              className={classes.textButton}
              style={{
                color: '#fff',
                fontWeight: 'normal',
                backgroundColor: 'rgba(81, 78, 243, 1)',
                fontFamily: [
                  'Nunito',
                  'Roboto',
                  'Helvetica Neue',
                  'Arial',
                  'sans-serif',
                ].join(','),
                textTransform: 'none',
              }}
            >
              Filtrar
            </Button>
            <Button
              onClick={() => resetData()}
              variant="contained"
              color="secondary"
              size="small"
              className={classes.textButton}
              style={{
                color: '#fff',
                fontWeight: 'normal',
                backgroundColor: 'rgba(255, 0, 0, 1)',
                fontFamily: [
                  'Nunito',
                  'Roboto',
                  'Helvetica Neue',
                  'Arial',
                  'sans-serif',
                ].join(','),
                textTransform: 'none',
              }}
            >
              Resetear
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}
