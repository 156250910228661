import {ADD_DATA_BACKOFFICE, DELETE_DATA_BACKOFFICE} from '../types/backoffice.types';

const initialState = {
  data: undefined,
};

const reducerBackoffice = (state = initialState, {type, payload}) => {
  switch (type) {
    case ADD_DATA_BACKOFFICE:
      return {
        ...state,
        data: payload,
      };
    case DELETE_DATA_BACKOFFICE:
      return {
        ...state,
        data: undefined,
      };
    default:
      return state;
  }
};

export default reducerBackoffice;
