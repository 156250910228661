/* eslint-disable prefer-template */
/* eslint-disable default-case */
import React, {useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import {isInvalidSlug} from 'api-lofty';
import {
  Dialog, Box, IconButton,
  Button, MenuItem,
  Menu, Grid,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '@material-ui/lab/Alert';
import TemplateUserList from './TemplateUserList';
import {generateSlugSample} from '../util/generateSuggestionSlug';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
  boxMainFields: {
    display: 'block',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boxRowContainers: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    padding: '5px',
  },
  urlWithMultipleColumns: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'row',
  },
  urlSectionContainer: {
    display: 'flex',
    margin: '2px',
    flexDirection: 'column',
  },
  urlFragmentSection: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  urlFragmentLabel: {
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    color: 'rgba(196, 196, 196, 1)',
    fontSize: '15px',
    fontWeight: 500,
  },
  urlInputSection: {
    border: '1px solid rgba(233, 231, 234, 1)',
    borderRadius: '15px',
  },
}));

const AgregarPageModal = ({
  handleClose, open, handleForm, error, hasUserProject,
  suggestion, setSelectedTemplate, pageData,
}) => {
  const classes = useStyle();
  // const [authProtocol, setAuthProtocol] = React.useState('');
  const [urlInfo, setUrlInfo] = React.useState([]);
  const [urlLabel, setUrlLabel] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [counts, setCounts] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isEditingLabel, setIsEditingLabel] = React.useState(false);
  const [suggestionSlug, setSuggestionSlug] = React.useState('');
  const [typeOfPage, setTypeOfPage] = React.useState('');
  const typeSelectPage = [
    {
      name: 'Personalizada',
      value: 'custom',
    },
    {
      name: 'Principal',
      value: 'main',
    },
    {
      name: 'Error 404',
      value: 'error404',
    },
  ];
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleItemSelection = (value) => {
    if (value === 'param') {
      const toAdd = {idNumber: counts, preceding: '/:', value: ''};
      const temp = urlInfo;
      temp.push(toAdd);
      setUrlInfo(temp);
      setCounts(counts + 1);
      setAnchorEl(null);
    }
    if (value === 'route') {
      const toAdd = {idNumber: counts, preceding: '/', value: ''};
      const temp = urlInfo;
      temp.push(toAdd);
      setUrlInfo(temp);
      setCounts(counts + 1);
      setAnchorEl(null);
    }
  };

  const updateValueData = (index) => (e) => {
    const tempArray = [...urlInfo];
    tempArray[index].value = e.target.value;
    setUrlInfo(tempArray);
  };

  const eliminateValueFrom = (location) => {
    setUrlInfo((urlInfo) => urlInfo.filter((index) => index.idNumber !== location));
  };

  function changeLayout(value) {
    setTypeOfPage(value);
    if (value === 'error404') {
      setUrlLabel('*');
    } else if (value === 'main') {
      setUrlLabel('/');
    } else {
      setUrlLabel('');
    }
  }

  const verifyCase = (e) => {
    const newValue = e.target.value;
    setSuggestionSlug(e.target.value);
    if (isInvalidSlug(newValue)) {
      setErrorText('Solo letras minusculas y numeros son aceptados');
      setHasError(true);
    } else {
      setErrorText('');
      setHasError(false);
    }
  };

  const verifySlugValue = (value) => {
    if (isInvalidSlug(value)) {
      setErrorText('Verifique que no tenga espacios/palabras reservadas');
      setHasError(true);
    } else {
      setHasError(false);
      setErrorText('');
    }
  };

  function generateSuggestionSlug(value) {
    if (isEditingLabel) {
      const res = generateSlugSample(pageData, value);
      setSuggestionSlug(res);
      setIsEditingLabel(false);
    }
  }

  function onCloseRefresh() {
    setSuggestionSlug('');
    setHasError(false);
    setErrorText('');
    handleClose();
  }

  useEffect(() => {
    setSuggestionSlug('');
  }, [open]);

  return (
    <Dialog
      open={open}
      handleClose={() => onCloseRefresh()}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Página
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { onCloseRefresh(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid md={6} item>
            <Box component="div" style={{padding: '10px', margin: '5px', width: '650px'}}>
              <form onSubmit={(e) => handleForm(e, typeOfPage, urlInfo)}>
                {
                  error && (
                    <Alert severity="error">
                      {error}
                    </Alert>
                  )
                }
                <Box component="div" className={classes.boxMainFields}>
                  <Box component="div" className={classes.boxRowContainers}>
                    <div className="mt-4">
                      <TextField select fullWidth name="typePage" type="text" label="Tipo de pagina" variant="outlined" onChange={(e) => changeLayout(e.target.value)}>
                        {
                          typeSelectPage?.map((index) => (
                            <MenuItem value={index.value} key={index.value}>{index.name}</MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                    <div className="mt-4">
                      <TextField
                        fullWidth
                        name="label"
                        type="text"
                        label="Etiqueta"
                        variant="outlined"
                        inputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        onFocus={() => setIsEditingLabel(true)}
                        onBlur={(e) => generateSuggestionSlug(e.target.value)}
                      />
                    </div>
                    {hasUserProject
                      && (
                        <div className="mt-4">
                          <TextField select fullWidth name="authProtocol" type="text" label="Autenticacion de Usuario" variant="outlined">
                            <MenuItem value="active" key="active">
                              Activo
                            </MenuItem>
                            <MenuItem value="inactive" key="inactive">
                              Inactivo
                            </MenuItem>
                            <MenuItem value="not required" key="not required">
                              No requerido
                            </MenuItem>
                          </TextField>
                        </div>
                      )}
                    <div className="mt-4">
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        name="description"
                        type="text"
                        label="Descripción"
                        variant="outlined"
                        inputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </div>
                  </Box>
                  <Box component="div" className={classes.boxRowContainers}>
                    <div className="mt-4">
                      <TextField
                        fullWidth
                        name="name"
                        type="text"
                        label="Slug"
                        error={hasError}
                        variant="outlined"
                        helperText={errorText}
                        onChange={verifyCase}
                        inputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        value={suggestionSlug}
                        // onChange={(e) => setSuggestionSlug(e.target.value)}
                        onBlur={(e) => verifySlugValue(e.target.value)}
                      />
                    </div>
                    <div className="mt-4">
                      {typeOfPage === 'custom' && (
                        <Box component="div" className={classes.urlWithMultipleColumns}>
                          <Box component="div" className={classes.urlSectionContainer}>
                            URL:
                          </Box>
                          <Box component="div" className={classes.urlSectionContainer}>
                            {urlInfo.map((index, key) => (
                              <Box component="div" className={classes.urlFragmentSection}>
                                <Box className={classes.urlFragmentLabel}>
                                  {index.preceding}
                                </Box>
                                <TextField
                                  fullWidth
                                  type="text"
                                  label="seccion de url"
                                  name={`section${index.idNumber}`}
                                  value={index.value}
                                  variant="outlined"
                                  onChange={updateValueData(key)}
                                  defaultValue={urlLabel}
                                />
                                <IconButton
                                  onClick={() => eliminateValueFrom(index.idNumber)}
                                  className={classes.iconButtonStyle}
                                >
                                  <DeleteOutlineOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
                                </IconButton>
                              </Box>
                            ))}
                          </Box>
                          <Box component="div" className={classes.urlSectionContainer}>
                            <IconButton
                              onClick={handleOpenMenu}
                              className={classes.iconButtonStyle}
                            >
                              <AddIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleCloseMenu}
                            >
                              <MenuItem onClick={() => handleItemSelection('param')}>Parametro</MenuItem>
                              <MenuItem onClick={() => handleItemSelection('route')}>Ruta</MenuItem>
                            </Menu>
                          </Box>
                        </Box>
                      )}
                    </div>
                  </Box>
                </Box>
                <div className="mt-4 text-center">
                  <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle}>
                    Guardar
                  </Button>
                </div>
              </form>
            </Box>
          </Grid>
          <Grid className="container-carousel-template-suggestion" item md={6}>
            <Alert severity="info">
              Puedes seleccionar una estructura de página para que no inicies desde cero.
            </Alert>
            <br />
            <TemplateUserList
              arrayList={suggestion?.map((item) => ({
                type: 'suggestion',
                value: item._id,
                label: item.name,
                url: 'https://preview-lofty-apps.web.app/suggestion/' + item._id,
              }))}
              onChange={(value) => {
                setSelectedTemplate(value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AgregarPageModal;
