/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton, Grid,
} from '@material-ui/core';
import {
  requestListBackoffice,
  requestDeleteBackoffice,
} from 'api-lofty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {CSVLink} from 'react-csv';
import toastr from 'toastr';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttachmentIcon from '@material-ui/icons/Attachment';
import PlusOneIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabular from '../../components/Tabular';
import LoadingPage from '../../pages/LoadingPage';
import AddFieldDialog from '../../components/AddFieldDialogBackoffice';
import UpdateFieldDialog from '../../components/UpdateFieldDialogBackoffice';
import UpdateFileBackofficeDialog from '../../components/updateFileBackofficeDialog';
import FilterListData from '../../components/FilterListsComponent';
import 'toastr/build/toastr.min.css';
import OneDatabaseModal from './OneDatabaseModal';
import TablePaginated from '../../components/TablePaginated';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '18px'},
  },
}));

const DatabaseContainer = ({
  token, database, backoffice, type, fieldName, idDoc,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openUpdateFile, setOpenUpdateFile] = useState(false);
  const [docUpdate, setDocUpdate] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [colums, setColums] = useState([]);
  const [fields, setFields] = useState([]);
  const [openOneDatabase, setOpenOneDatabase] = useState(false);
  const [dataCSV, setDataCSV] = useState([]);
  const classes = useStyle();
  const [skip, setSkip] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [errorFilterList, setErrorFilterList] = useState(false);

  // FOR FILTER
  const [backofficeData, setBackofficeData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [relationData, setRelationData] = useState([]);
  const [fieldDataBackoffice, setFieldDataBackoffice] = useState([]);

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  const handleTotaldatafilter = async (paramFilter) => {
    try {
      const res = await requestListBackoffice({
        token,
        url: `${backoffice.domain}/api/${database.name}/loftycounter`,
        paramFilter,
      });
      setTotal(res.data.total);
    } catch (error) {
      toastr.error('Error al obtener el dato');
    }
  };

  const handleDataFilter = async (paramFilter, orderBy) => {
    if (paramFilter) {
      handleTotaldatafilter(paramFilter);
    }
    // tener mapeado los campos de la base de datos
    const fieldsDatabase = [];
    backoffice.fieldDatabases.forEach((field) => {
      if (field.idDatabase === database._id) {
        fieldsDatabase.push(field);
      }
    });
    setFieldDataBackoffice(fieldsDatabase);
    setFields(fieldsDatabase);
    // creando las columnas
    const columnData = [];
    columnData.push({
      title: 'Identificador',
      field: '_id',
    });
    fieldsDatabase.forEach((field) => {
      if (field.type === 'user') {
        columnData.push({
          title: field.label,
          field: `lofty_${field.name}`,
        });
      } else if (field.type === 'textrich') {
        columnData.push({
          title: field.label,
          field: field.name,
          render: (rowData) => (
            <>
              {
                rowData[field.name] ? (
                  <p>Hay Datos</p>
                ) : (
                  <p>
                    No hay Datos
                  </p>
                )
              }
            </>
          ),
        });
      } else {
        columnData.push({
          title: field.label,
          field: field.name,
        });
      }
    });
    if (database?.isBucket) {
      columnData.push({
        title: 'Nombre Original',
        field: 'loftyOriginalName',
      });
      columnData.push({
        title: 'Url',
        field: 'loftyUrl',
      });
    }
    // agregando el campo de fecha de creación
    columnData.push(({
      title: 'Fecha de creación',
      field: 'loftyCreatedAt',
      render: (rowData) => (
        <span>
          {moment(rowData.loftyCreatedAt).format('DD/MM/YYYY')}
        </span>
      ),
    }));
    // agregando los botones
    if (type !== 'filter') {
      columnData.push({
        title: 'Acciones',
        field: '_id',
        render: (rowData) => {
          const {_id} = rowData;
          if (!_id) {
            return <></>;
          }
          return (
            <>
              <IconButton
                onClick={async () => {
                  try {
                    setDocUpdate(rowData);
                    setOpenOneDatabase(true);
                  } catch (error) {
                    if (error.info) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: error.info,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Ocurrio un error',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  }
                }}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                onClick={async () => {
                  try {
                    setDocUpdate(rowData);
                    setOpenUpdate(true);
                  } catch (error) {
                    if (error.info) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: error.info,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Ocurrio un error',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  }
                }}
              >
                <EditIcon />
              </IconButton>
              {
                database.isBucket ? (
                  <IconButton
                    onClick={async () => {
                      try {
                        setDocUpdate(rowData);
                        setOpenUpdateFile(true);
                      } catch (error) {
                        if (error.info) {
                          Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: error.info,
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        } else {
                          Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Ocurrio un error',
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        }
                      }
                    }}
                  >
                    <AttachmentIcon />
                  </IconButton>
                ) : <></>
              }
              <IconButton
                onClick={() => handleOpenDeleteDialog(_id)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      });
    }
    setColums(columnData);
    // obteniendo los datos
    try {
      setData([]);
      const resDatabaseBackoffice = await requestListBackoffice({
        token,
        url: `${backoffice.domain}/api/${database.name}/filter`,
        paramFilter,
        orderBy,
        skipNumber: skip,
        limitNumber: limit,
      });
      for (const dataPro of resDatabaseBackoffice.data) {
        if (database.isBucket) {
          dataPro.loftyUrl = `${backoffice.domain}/api/${database.name}/file/lofty/local/${dataPro.loftyFileName}`;
        }
      }
      const relationFieldInfo = [];
      for (const fieldData of fieldsDatabase) {
        if (fieldData.type === 'user') {
          for (const dataPro of resDatabaseBackoffice.data) {
            try {
              const res = await requestListBackoffice({
                token,
                url: `${backoffice.domain}/api/user/one/${dataPro[fieldData.name]}`,
              });
              dataPro[`lofty_${fieldData.name}`] = res.data?.loftyEmail;
              setUserData(res.data);
            } catch (error) {
              // error
            }
          }
        }
        if (fieldData.type === 'relation') {
          const databaseRelation = backoffice.databases.find((item) => (
            fieldData.relationDatabase === item._id
          ));
          if (database) {
            const datafieldLabel = backoffice.fieldDatabases.find((item) => (
              item.idDatabase === database._id && item.isLabel
            ));
            if (datafieldLabel) {
              for (const dataPro of resDatabaseBackoffice.data) {
                try {
                  const res = await requestListBackoffice({
                    token,
                    url: `${backoffice.domain}/api/${databaseRelation.name}/one/${dataPro[fieldData.name]}`,
                  });
                  if (res.data) {
                    dataPro[fieldData.name] = res.data[datafieldLabel.name];
                  }
                } catch (error) {
                  // error
                }
              }
            }
          }
          relationFieldInfo.push({name: fieldData.name, idDatabase: fieldData.relationDatabase});
        }
      }
      if (type === 'filter') {
        setData(resDatabaseBackoffice.data.filter((doc) => (
          doc[fieldName] === idDoc
        )));
        setLoading(false);
      } else {
        setData(resDatabaseBackoffice.data);
        setLoading(false);
        const dataSimFromForeignDB = [];
        relationFieldInfo.forEach(async (item) => {
          // search with backoffice.database.find
          const getDatabaseInfo = backoffice.databases.find((index) => (
            item.idDatabase === index._id
          ));
          if (getDatabaseInfo) {
            const fieldsFromForeignDB = [];
            backoffice.fieldDatabases.forEach((field) => {
              if (field.idDatabase === item.idDatabase) {
                fieldsFromForeignDB.push(field);
              }
            });
            const resDataRelationDatabase = await requestListBackoffice({
              token,
              url: `${backoffice.domain}/api/${getDatabaseInfo.name}/list`,
            });
            // Gather all main data (data in foreign db sim, fields of givendb...)
            dataSimFromForeignDB.push({
              relationInfo: item,
              dataSimDatabase: resDataRelationDatabase.data,
              idDatabase: item.idDatabase,
              fieldInfo: item.name,
              fieldMeta: fieldsFromForeignDB,
            });
          }
        });
        setRelationData(dataSimFromForeignDB);
        const resFitlerData = await requestListBackoffice({
          token,
          url: `${backoffice.domain}/api/${database.name}/list`,
        });
        setBackofficeData(resFitlerData.data);
        if (!database.data?.isBucket) {
          const tempArrayFields = ['_id'];
          const tempArrayNames = ['_id'];
          fieldsDatabase.forEach((index) => {
            tempArrayFields.push(index.name);
            tempArrayNames.push(index.label);
          });
          const csvData = [
            tempArrayNames,
            ...resDatabaseBackoffice.data.map((index) => {
              const tempValue = [];
              tempArrayFields.forEach((seeker) => {
                tempValue.push((index[seeker]));
              });
              return tempValue;
            }),
          ];
          setDataCSV(csvData);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const handleDatabaseDataList = async (paramFilter, orderBy) => {
    // tener mapeado los campos de la base de datos
    const fieldsDatabase = [];
    backoffice.fieldDatabases.forEach((field) => {
      if (field.idDatabase === database._id) {
        fieldsDatabase.push(field);
      }
    });
    setFieldDataBackoffice(fieldsDatabase);
    setFields(fieldsDatabase);
    // creando las columnas
    const columnData = [];
    columnData.push({
      title: 'Identificador',
      field: '_id',
    });
    fieldsDatabase.forEach((field) => {
      if (field.type === 'user') {
        columnData.push({
          title: field.label,
          field: `lofty_${field.name}`,
        });
      } else {
        columnData.push({
          title: field.label,
          field: field.name,
        });
      }
    });
    if (database?.isBucket) {
      columnData.push({
        title: 'Nombre Original',
        field: 'loftyOriginalName',
      });
      columnData.push({
        title: 'Url',
        field: 'loftyUrl',
      });
    }
    // agregando el campo de fecha de creación
    columnData.push(({
      title: 'Fecha de creación',
      field: 'loftyCreatedAt',
      render: (rowData) => (
        <span>
          {moment(rowData.loftyCreatedAt).format('DD/MM/YYYY')}
        </span>
      ),
    }));
    // agregando los botones
    if (type !== 'filter') {
      columnData.push({
        title: 'Acciones',
        field: '_id',
        render: (rowData) => {
          const {_id} = rowData;
          if (!_id) {
            return <></>;
          }
          return (
            <>
              <IconButton
                onClick={async () => {
                  try {
                    setDocUpdate(rowData);
                    setOpenOneDatabase(true);
                  } catch (error) {
                    if (error.info) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: error.info,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Ocurrio un error',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  }
                }}
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                onClick={async () => {
                  try {
                    setDocUpdate(rowData);
                    setOpenUpdate(true);
                  } catch (error) {
                    if (error.info) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: error.info,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Ocurrio un error',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  }
                }}
              >
                <EditIcon />
              </IconButton>
              {
                database.isBucket ? (
                  <IconButton
                    onClick={async () => {
                      try {
                        setDocUpdate(rowData);
                        setOpenUpdateFile(true);
                      } catch (error) {
                        if (error.info) {
                          Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: error.info,
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        } else {
                          Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Ocurrio un error',
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        }
                      }
                    }}
                  >
                    <AttachmentIcon />
                  </IconButton>
                ) : <></>
              }
              <IconButton
                onClick={() => handleOpenDeleteDialog(_id)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      });
    }
    setColums(columnData);
    // obteniendo los datos
    try {
      setData([]);
      const resDatabaseBackoffice = await requestListBackoffice({
        token,
        url: `${backoffice.domain}/api/${database.name}/list`,
        paramFilter,
        orderBy,
      });
      for (const dataPro of resDatabaseBackoffice.data) {
        if (database.isBucket) {
          dataPro.loftyUrl = `${backoffice.domain}/api/${database.name}/file/lofty/local/${dataPro.loftyFileName}`;
        }
      }
      const relationFieldInfo = [];
      for (const fieldData of fieldsDatabase) {
        if (fieldData.type === 'user') {
          for (const dataPro of resDatabaseBackoffice.data) {
            const res = await requestListBackoffice({
              token,
              url: `${backoffice.domain}/api/user/one/${dataPro[fieldData.name]}`,
            });
            dataPro[`lofty_${fieldData.name}`] = res.data?.loftyEmail;
            setUserData(res.data);
          }
        }
        if (fieldData.type === 'relation') {
          relationFieldInfo.push({name: fieldData.name, idDatabase: fieldData.relationDatabase});
        }
      }
      if (type === 'filter') {
        setData(resDatabaseBackoffice.data.filter((doc) => (
          doc[fieldName] === idDoc
        )));
      } else {
        setData(resDatabaseBackoffice.data);
        const dataSimFromForeignDB = [];
        relationFieldInfo.forEach(async (item) => {
          // search with backoffice.database.find
          const getDatabaseInfo = backoffice.databases.find((index) => (
            item.idDatabase === index._id
          ));
          if (getDatabaseInfo) {
            const fieldsFromForeignDB = [];
            backoffice.fieldDatabases.forEach((field) => {
              if (field.idDatabase === item.idDatabase) {
                fieldsFromForeignDB.push(field);
              }
            });
            const resDataRelationDatabase = await requestListBackoffice({
              token,
              url: `${backoffice.domain}/api/${getDatabaseInfo.name}/list`,
            });
            // Gather all main data (data in foreign db sim, fields of givendb...)
            dataSimFromForeignDB.push({
              relationInfo: item,
              dataSimDatabase: resDataRelationDatabase.data,
              idDatabase: item.idDatabase,
              fieldInfo: item.name,
              fieldMeta: fieldsFromForeignDB,
            });
          }
        });
        setRelationData(dataSimFromForeignDB);

        setBackofficeData(resDatabaseBackoffice.data);
        if (!database.data?.isBucket) {
          const tempArrayFields = ['_id'];
          const tempArrayNames = ['_id'];
          fieldsDatabase.data.forEach((index) => {
            tempArrayFields.push(index.name);
            tempArrayNames.push(index.label);
          });
          const csvData = [
            tempArrayNames,
            ...resDatabaseBackoffice.data.map((index) => {
              const tempValue = [];
              tempArrayFields.forEach((seeker) => {
                tempValue.push((index[seeker]));
              });
              return tempValue;
            }),
          ];
          setDataCSV(csvData);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDatabaseData = async (paramFilter, orderBy) => {
    let hasError = false;
    try {
      await handleDataFilter(paramFilter, orderBy);
    } catch (error) {
      hasError = true;
      setErrorFilterList(true);
    }
    if (hasError) {
      try {
        await handleDatabaseDataList(paramFilter, orderBy);
      } catch (error) {
        toastr.error('Error al obtener los datos');
      }
    }
  };

  useEffect(() => {
    handleDatabaseData();
  }, [database, skip]);

  useEffect(() => {
    handleTotaldatafilter();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }
  const handleOpenAdd = () => {
    setOpenAdd((prev) => !prev);
  };
  // function cuando es success
  const handleAddSuccess = () => {
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 6000,
    };
    toastr.clear();
    setTimeout(() => toastr.success('Se agrego el dato de manera exitosa'), 300);
    handleOpenAdd();
    handleDatabaseData();
  };
  const handleAddError = (error) => {
    if (error.info) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Lo sentimos no se pudo agregar el campo'), 300);
    }
  };
  const handleUpdateSuccess = () => {
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 6000,
    };
    toastr.clear();
    setTimeout(() => toastr.success('Se actualizo el campo'), 300);
    setOpenUpdate(false);
    handleDatabaseData();
  };
  const hadnleUpdateError = (error) => {
    if (error.info) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Lo sentimos no se pudo actualizar el campo'), 300);
    }
  };

  const handleParamAnchor = async (params, orderBy) => {
    const paramsCapture = [];
    const cleanedFromNoise = params.filter((index) => (
      index.value.length > 0 || index.value === 0
    ));
    cleanedFromNoise.forEach((obj) => {
      if (obj.index?.type === 'string') {
        const filteredString = obj.value.map((valdata) => (valdata.value));
        paramsCapture.push({param: obj.index.name, value: filteredString});
      } else if (obj.index?.type === 'relation' || obj.index?.type === 'user') {
        const filteredID = obj.value.map((valdata) => (valdata._id));
        paramsCapture.push({param: obj.index.name, value: filteredID});
      } else {
        paramsCapture.push({param: obj.index.name, value: obj.value});
      }
    });
    if (paramsCapture.length > 0) {
      // const currentParams = new URLSearchParams(location.search);
      // paramsCapture.forEach((key) => {
      //   const {param, value} = key;
      //   // Check if the value is an array
      //   if (Array.isArray(value)) {
      //     // Serialize the array using a delimiter (e.g., comma) and set the parameter
      //     currentParams.set(param, value.join(','));
      //   } else {
      //     // For non-array values, simply set the parameter
      //     currentParams.set(param, value);
      //   }
      // });
      // history.replace({
      //   pathname: location.pathname,
      //   search: `?${currentParams.toString()}`,
      // });
      const paramFilter = JSON.stringify(paramsCapture);
      // const getSomeInfo = await listDataSimulatorDatabaseAdmin({
      //   token, idDatabase, idProject, paramFilter,
      // });
      handleDatabaseData(paramFilter, orderBy);
    } else {
      // history.replace({
      //   pathname: location.pathname,
      //   search: '',
      // });
      handleDatabaseData();
    }
    // setSearchParams(params);
  };

  return (
    <div>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography className={classes.miniNavbarMenuTitle}>
            {database.label}
          </Typography>
        </Box>
        {
          type !== 'filter' ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<PlusOneIcon />}
              style={{
                color: 'black',
                fontWeight: 'bold',
                backgroundColor: '#29B2EF',
                fontFamily: [
                  'Nunito',
                  'Roboto',
                  'Helvetica Neue',
                  'Arial',
                  'sans-serif',
                ].join(','),
                textTransform: 'none',
              }}
              onClick={() => handleOpenAdd()}
            >
              Agregar
            </Button>
          ) : (
            <></>
          )
        }
        {!database?.isBucket && type !== 'filter' && (
        <CSVLink
          className="MuiButtonBase-root MuiButton-root MuiButton-contained makeStyles-textButton-180 MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall"
          style={{
            color: '#fff',
            marginLeft: '5px',
            marginRight: '5px',
            fontWeight: 'bold',
            backgroundColor: 'rgba(47, 129, 50, 1)',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          filename="backoffice-data.csv"
          data={dataCSV}
        >
          Exportar a CSV
        </CSVLink>
        )}
      </Box>
      <div className="text-right mb-4">
        {
          type !== 'filter' ? (
            <AddFieldDialog
              token={token}
              open={openAdd}
              backoffice={backoffice}
              database={database}
              fields={fields}
              onClose={handleOpenAdd}
              successFunction={handleAddSuccess}
              errorFunction={handleAddError}
            />
          ) : (
            <></>
          )
        }
        {
          type !== 'filter' ? (
            <UpdateFieldDialog
              docUpdate={docUpdate}
              errorFunction={hadnleUpdateError}
              onClose={() => {
                setOpenUpdate(false);
                setDocUpdate();
              }}
              open={openUpdate}
              token={token}
              successFunction={handleUpdateSuccess}
              database={database}
              fields={fields}
              backoffice={backoffice}
            />
          ) : (
            <></>
          )
        }
        {
          (type !== 'filter' && database.isBucket) ? (
            <UpdateFileBackofficeDialog
              docUpdate={docUpdate}
              errorFunction={hadnleUpdateError}
              token={token}
              successFunction={handleUpdateSuccess}
              database={database}
              backoffice={backoffice}
              open={openUpdateFile}
              onClose={() => {
                setOpenUpdateFile(false);
                setDocUpdate();
              }}
            />
          ) : <></>
        }
        {
          type !== 'filter' ? (
            <div>
              {
                openOneDatabase && (
                  <OneDatabaseModal
                    open={openOneDatabase}
                    backoffice={backoffice}
                    database={database}
                    doc={docUpdate}
                    handleClose={() => setOpenOneDatabase(!openOneDatabase)}
                    token={token}
                  />
                )
              }
            </div>
          ) : (
            <></>
          )
        }
        <div>
          {
            type !== 'filter' ? (
              <Dialog open={openDialog}>
                <DialogTitle>
                  Eliminar
                </DialogTitle>
                <DialogContent>
                  <Typography
                    style={{
                      position: 'relative',
                      bottom: '10px',
                    }}
                  >
                    ¿Estás seguro de eliminar este campo?
                  </Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          try {
                            await requestDeleteBackoffice({
                              _id: idDeletion,
                              token,
                              url: `${backoffice.domain}/api/${database.name}/delete/`,
                            });
                            Swal.fire({
                              position: 'top-end',
                              icon: 'success',
                              title: 'Se elimino el dato',
                              showConfirmButton: false,
                              timer: 1500,
                            });
                            setOpenDialog(false);
                            handleDatabaseData();
                          } catch (error) {
                            if (error.info) {
                              Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: error.info,
                                showConfirmButton: false,
                                timer: 1500,
                              });
                            } else {
                              Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'Error no se pudo eliminar',
                                showConfirmButton: false,
                                timer: 1500,
                              });
                            }
                          }
                        }}
                      >
                        Confirmar
                      </Button>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Dialog>
            ) : (
              <></>
            )
          }
        </div>
      </div>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Box
          style={{
            padding: '10px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <FilterListData
            fieldsComponent={fieldDataBackoffice}
            data={backofficeData}
            dataRelation={relationData}
            dataUsers={userData}
            handleParamUpdate={(e, orderBy) => handleParamAnchor(e, orderBy)}
            isBackoffice
            // paramFromUrl={queryParams}
          />
        </Box>
        {
          errorFilterList ? (
            <Tabular
              title={`${database.label}`}
              columns={colums}
              data={data}
              ready={loading}
            />
          ) : (
            <TablePaginated
              data={data}
              fields={colums}
              limit={limit}
              totalRegister={total}
              handleDataCurrentPage={(number) => {
                setSkip(number);
              }}
              handleNext={(number) => {
                setSkip(number);
              }}
              handlePrevius={(number) => {
                setSkip(number);
              }}
            />
          )
        }
      </Box>
    </div>
  );
};

export default DatabaseContainer;
