import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';

const useStyle = makeStyles(() => ({
  mainTitleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  iconTitle: {
    border: '1px solid rgba(204, 51, 51, 1)',
    borderRadius: '15px',
    height: '30px',
    width: '60px',
    backgroundColor: '#fff',
    color: 'rgba(204, 51, 51, 1)',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '15px',
  },
  headerStyle: {
    color: 'rgba(41, 33, 81, 1)',
    fontWeight: 400,
    fontSize: '15px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  sectionHeaderContainer: {
    backgroundColor: 'rgba(32, 21, 73, 1)',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '13px',
    height: '35px',
  },
  sectionDataContainer: {
    border: '1px solid rgba(32, 21, 73, 1)',
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  sectionAlignmentAdjuster: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px',
    alignContent: 'center',
    alignItems: 'center',
  },
  sectionRowDataContainer: {
    borderBottom: '1px solid rgba(196, 196, 196, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px',
    height: '50px',
    alignContent: 'center',
    alignItems: 'center',
  },
  stringContainer: {
    textAlign: 'left',
    fontSize: '13px',
    fontColor: 'rgba(100, 100, 100, 1)',
    fontWeight: '400',
    width: '25%',
  },
  queryAnswerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
  },
  querySampleContainer: {
    border: '1px solid rgba(41, 33, 81, 1)',
    whiteSpace: 'pre-line',
    padding: '15px',
  },
  buttonStyle: {
    backgroundColor: 'rgba(41, 33, 81, 1)',
    color: '#fff',
    borderRadius: '10px',
    height: '25px',
  },
  httpSampleSection: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const DocEndpointDelete = ({database, endpoint, url}) => {
  const classes = useStyle();
  // Response Info
  const var500 = `{
    \u00a0 \u00a0 "id": "Server Error",
    \u00a0 \u00a0 "message": "the server encountered an unexpected condition that prevented it from fulfilling the request"
  }    
  `;
  const var200 = `{
    \u00a0 \u00a0 "id": "Success",
    \u00a0 \u00a0 "message": "The request was successful."
  }    
  `;

  const var401 = `{
    \u00a0 \u00a0 "id": "Request Error",
    \u00a0 \u00a0 "message": "Error in processing a request."
  }    
  `;

  const var404 = `{
    \u00a0 \u00a0 "id": "Not Found",
    \u00a0 \u00a0 "message": "Page or file not found."
  }    
  `;

  const var429 = `{
    \u00a0 \u00a0 "id": "Request Overload",
    \u00a0 \u00a0 "message": "Too many requests were made."
  }    
  `;
  const [showResponseInfo, setShowResponseInfo] = useState('');
  let responseRender = `curl -X DELETE ' \\
  -H "Content-Type: application/json" \\
  -H "API-Key: apiKeyLoftyApps \\ \n`;
  if (endpoint?.isAuthentication) {
    responseRender += '-H "Authorization: $AuthorizationApp" \\ \n';
  }
  responseRender += `Params: {id} \\
  ${url}/api/simulator/database/delete/${endpoint._id}/:_id \\`;
  return (
    <div className="mb-5">
      <hr />
      <h3>
        <b>
          {database.label}
        </b>
        {' '}
        |
        {' '}
        Eliminar
      </h3>
      <Box component="div" className={classes.mainTitleSection}>
        <Box className={classes.headerStyle}>
          Elimina un dato
        </Box>
        <Box component="div" className={classes.iconTitle}>
          DELETE
        </Box>
      </Box>
      <p className="my-4">
        {`${url}/api/simulator/database/delete/${endpoint._id}/:_id`}
      </p>
      <Box>
        <Table aria-label="simple table">
          <Box className={classes.sectionHeaderContainer}>
            <Box component="div" className={classes.sectionAlignmentAdjuster}>
              <Box style={{color: 'white', textAlign: 'left', width: '25%'}}>
                Campo
              </Box>
              <Box style={{color: 'white', textAlign: 'left', width: '25%'}}>
                Tipo
              </Box>
              <Box style={{color: 'white', textAlign: 'left', width: '25%'}}>
                Requerido
              </Box>
              <Box style={{color: 'white', textAlign: 'left', width: '25%'}}>
                Descripción
              </Box>
            </Box>
          </Box>
          <Box component="div" className={classes.sectionDataContainer}>
            <Box className={classes.sectionRowDataContainer}>
              <Box component="div" className={classes.stringContainer}>
                apiKeyLoftyApps
              </Box>
              <Box component="div" className={classes.stringContainer}>
                string
              </Box>
              <Box component="div" className={classes.stringContainer}>
                Si
              </Box>
              <Box component="div" className={classes.stringContainer}>
                API key para aplicaciones externas
              </Box>
            </Box>
            {
              endpoint?.isAuthentication && (
                <Box className={classes.sectionRowDataContainer}>
                  <Box component="div" className={classes.stringContainer}>
                    AuthorizationApp
                  </Box>
                  <Box component="div" className={classes.stringContainer}>
                    string
                  </Box>
                  <Box component="div" className={classes.stringContainer}>
                    Si
                  </Box>
                  <Box component="div" className={classes.stringContainer}>
                    Token de autenticacion del usuario
                  </Box>
                </Box>
              )
            }
          </Box>
        </Table>
      </Box>
      <Box>
        <Box component="div" className={classes.queryAnswerSection}>
          <Box>
            Respuesta
          </Box>
          <Button className={classes.buttonStyle} onClick={() => setShowResponseInfo(var200)}>
            200
          </Button>
          <Button className={classes.buttonStyle} onClick={() => setShowResponseInfo(var401)}>
            401
          </Button>
          <Button className={classes.buttonStyle} onClick={() => setShowResponseInfo(var404)}>
            404
          </Button>
          <Button className={classes.buttonStyle} onClick={() => setShowResponseInfo(var429)}>
            429
          </Button>
          <Button className={classes.buttonStyle} onClick={() => setShowResponseInfo(var500)}>
            500
          </Button>
        </Box>
        <Box component="div" className={classes.querySampleContainer}>
          {showResponseInfo}
        </Box>
      </Box>
      <Box component="div" className={classes.httpSampleSection}>
        <Box>
          Sample Query
        </Box>
        <Box component="div" className={classes.querySampleContainer}>
          {responseRender}
        </Box>
      </Box>
      <hr />
    </div>
  );
};

export default DocEndpointDelete;
