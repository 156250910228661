import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import {listDatabaseWithFields, addPluginDatabaseFieldChange} from 'api-lofty';
import {isNormalTypeFields} from '../util/typesFields';
// import {listDatabaseWithFields} from '../api/database.api';
import typeEvent from '../util/typesEvents';
// import {addPluginDatabaseFieldChange} from '../api/pluginsDatabaseFieldChange.api';

const AddEventChangeField = ({
  open, onClose, successFunction, errorFunction,
  idProject, token, idPluginProject,
}) => {
  const [loading, setLoading] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [type, setType] = useState('');
  const [dataDatabase, setDataDatabase] = useState();
  const [dataField, setDataField] = useState();
  const [booleanValue, setBooleanValue] = useState(false);
  useEffect(async () => {
    try {
      const resDatabase = await listDatabaseWithFields({token, idProject});
      setDatabases(resDatabase.data);
    } catch (error) {
      //
    }
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Agregar Evento
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await addPluginDatabaseFieldChange({
                token,
                idDatabase: dataDatabase._id,
                idFieldDatabase: dataField._id,
                idPluginProject,
                idProject,
                label: e.target.label.value,
                type,
                value: dataField.type === 'boolean' ? booleanValue : e.target.value.value,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField onChange={(e) => setType(e.target.value)} select fullWidth name="type" type="text" label="Tipo" variant="standard">
              {
                typeEvent.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="idDatabase"
              type="text"
              label="Base de Datos"
              variant="standard"
              onChange={(e) => {
                const data = databases.find(({_id}) => _id === e.target.value);
                setDataDatabase(data);
              }}
            >
              {
                databases.map(({_id, label}) => (
                  <MenuItem value={_id} key={_id}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="idField"
              type="text"
              label="Campo"
              variant="standard"
              onChange={(e) => {
                const data = dataDatabase?.dataFields.find(({_id}) => _id === e.target.value);
                setDataField(data);
              }}
            >
              {
                dataDatabase && dataDatabase.dataFields.map(({_id, label, type}) => {
                  if (isNormalTypeFields(type)) {
                    return (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    );
                  }
                  return <></>;
                })
              }
            </TextField>
          </div>
          <div className="my-4">
            {
              dataField?.type === 'boolean' ? (
                <FormControlLabel
                  control={(
                    <Switch
                      checked={booleanValue}
                      onChange={() => setBooleanValue((prev) => !prev)}
                    />
                  )}
                  label="Valor"
                />
              ) : (
                <TextField
                  fullWidth
                  name="value"
                  type={dataField?.type === 'number' ? 'number' : 'text'}
                  label="Valor"
                  variant="standard"
                />
              )
            }
          </div>
          <div className="text-center my-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddEventChangeField;
