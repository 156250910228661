/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import PlusOneIcon from '@material-ui/icons/Add';
import Container from '@material-ui/core/Container';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import {
  getOneProject, getOneUserProject, deleteUserProject, addUpdateUsernameProject,
  removeUsernameProject, getDataFacebook, getDataGoogle,
} from 'api-lofty';
import toastr from 'toastr';
import Layout from '../layouts/UserProjectDashboard';
import LoadingPage from './LoadingPage';
import {labelUserProject} from '../util/typeUserProject';
import AddDataGoogle from '../components/addDataGoogle';
import AddDataFacebook from '../components/AddDataFacebook';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const UserProjectSetting = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idUserProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataUserProject, setDataUserProject] = useState();
  const [credentialGoogle, setCredentialGoogle] = useState();
  const [open, setOpen] = useState(false);
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resUserProject = await getOneUserProject({token, _id: idUserProject});
      setDataUserProject(resUserProject.data);
      setDataProject(resProject.project);
      setLabelUrl(`proyectos/${resProject.project.name}/Usuarios/${resUserProject.data.type}/Configuraciones`);
      if (resUserProject.data.type === 'google') {
        const resDataGoogle = await getDataGoogle({token, idProject, idUserProject});
        setCredentialGoogle(resDataGoogle);
      } else if (resUserProject.data.type === 'facebook') {
        const resDataFacebook = await getDataFacebook({token, idProject, idUserProject});
        setCredentialGoogle(resDataFacebook);
      }
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  const handleUpdateData = async () => {
    const resProject = await getOneProject({token, idProject});
    const resUserProject = await getOneUserProject({token, _id: idUserProject});
    setDataUserProject(resUserProject.data);
    setDataProject(resProject.project);
    if (resUserProject.data.type === 'google') {
      const resDataGoogle = await getDataGoogle({token, idProject, idUserProject});
      setCredentialGoogle(resDataGoogle);
    } else if (resUserProject.data.type === 'facebook') {
      const resDataFacebook = await getDataFacebook({token, idProject, idUserProject});
      setCredentialGoogle(resDataFacebook);
    }
  };

  const handleSuccess = async () => {
    setOpen(false);
    /*
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Se registro los credenciales de manera exitosa',
      showConfirmButton: false,
      timer: 1500,
    });
    */
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 6000,
    };
    toastr.clear();
    setTimeout(() => toastr.success('Se registro las credenciales de manera exitosa'), 300);
    const resDataGoogle = await getDataGoogle({token, idProject, idUserProject});
    setCredentialGoogle(resDataGoogle);
  };

  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idUserProject={idUserProject}
      userProjectType={labelUserProject(dataUserProject?.type)}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Configuraciones de Usuarios
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        {/* Google */}
        {
        dataUserProject?.type === 'google' && (
          <>
            <div className="text-right">
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<PlusOneIcon />}
                onClick={() => setOpen(!open)}
              >
                Agregar
              </Button>
            </div>
            <AddDataGoogle
              idProject={idProject}
              idUserProject={idUserProject}
              open={open}
              token={token}
              onClose={() => setOpen(!open)}
              successFunction={handleSuccess}
              errorFunction={(error) => {
                if (error.info) {
                  /*
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error.info,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  */
                  toastr.options = {
                    positionClass: 'toast-top-right',
                    hideDuration: 300,
                    timeOut: 6000,
                  };
                  toastr.clear();
                  setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
                } else {
                  /*
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Error en el registro de las credenciales',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  */
                  toastr.options = {
                    positionClass: 'toast-top-right',
                    hideDuration: 300,
                    timeOut: 6000,
                  };
                  toastr.clear();
                  setTimeout(() => toastr.error('Error en el registro de las credenciales'), 300);
                }
              }}
            />
            {
              credentialGoogle?.exists && (
                <Paper className="py-3 mt-2">
                  <Container>
                    <p>
                      <b>Cliente Id:</b>
                      {' '}
                      {credentialGoogle?.clientId}
                    </p>
                  </Container>
                  <div className="mt-4 text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={async () => {
                        try {
                          await deleteUserProject({token, _id: idUserProject});
                          Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: `Se elimino el sistema de usuario de ${labelUserProject(dataUserProject?.type)}`,
                            showConfirmButton: false,
                            timer: 1500,
                          });
                          history.push(`/dashboard/project/${idProject}/users`);
                        } catch (error) {
                          if (error.info) {
                            Swal.fire({
                              position: 'top-end',
                              icon: 'error',
                              title: error.info,
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          } else {
                            Swal.fire({
                              position: 'top-end',
                              icon: 'error',
                              title: 'Error al eliminar el sistema de usuario',
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          }
                        }
                      }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </Paper>
              )
            }
          </>
        )
      }
        {/* Facebook */}
        {
        dataUserProject?.type === 'facebook' && (
          <>
            <div className="text-right">
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<PlusOneIcon />}
                onClick={() => setOpen(!open)}
              >
                Agregar
              </Button>
            </div>
            <AddDataFacebook
              idProject={idProject}
              idUserProject={idUserProject}
              open={open}
              token={token}
              onClose={() => setOpen(!open)}
              successFunction={handleSuccess}
              errorFunction={(error) => {
                if (error.info) {
                  /*
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error.info,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  */
                  toastr.options = {
                    positionClass: 'toast-top-right',
                    hideDuration: 300,
                    timeOut: 6000,
                  };
                  toastr.clear();
                  setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
                } else {
                  /*
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Error en el registro de las credenciales',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  */
                  toastr.options = {
                    positionClass: 'toast-top-right',
                    hideDuration: 300,
                    timeOut: 6000,
                  };
                  toastr.clear();
                  setTimeout(() => toastr.error('Error en el registro de las credenciales'), 300);
                }
              }}
            />
            {
              credentialGoogle?.exists && (
                <Paper className="py-3 mt-2">
                  <Container>
                    <p>
                      <b>App Id:</b>
                      {' '}
                      {credentialGoogle?.appId}
                    </p>
                  </Container>
                  <div className="mt-4 text-center">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={async () => {
                        try {
                          await deleteUserProject({token, _id: idUserProject});
                          Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: `Se elimino el sistema de usuario de ${labelUserProject(dataUserProject?.type)}`,
                            showConfirmButton: false,
                            timer: 1500,
                          });
                          history.push(`/dashboard/project/${idProject}/users`);
                        } catch (error) {
                          if (error.info) {
                            Swal.fire({
                              position: 'top-end',
                              icon: 'error',
                              title: error.info,
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          } else {
                            Swal.fire({
                              position: 'top-end',
                              icon: 'error',
                              title: 'Error al eliminar el sistema de usuario',
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          }
                        }
                      }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </Paper>
              )
            }
          </>
        )
      }
        {/* Correo y contraseña */}
        {
        dataUserProject?.type === 'email' && (
          <Paper className="py-3 mt-2">
            <Container>
              <FormGroup row>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={dataUserProject?.haveUserName}
                      onChange={async () => {
                        if (!dataUserProject?.haveUserName) {
                          await addUpdateUsernameProject({
                            token, idProject, _id: dataUserProject?._id,
                          });
                        } else {
                          await removeUsernameProject({
                            token, idProject, _id: dataUserProject?._id,
                          });
                        }
                        await handleUpdateData();
                      }}
                      color="primary"
                    />
                  )}
                  label={!dataUserProject?.haveUserName ? 'Activar campo usuario' : 'Desactivar campo usuario'}
                />
              </FormGroup>
            </Container>
          </Paper>
        )
      }
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(UserProjectSetting);
