/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  getOneProject, getUrlApi, getOneUserProject, listFieldUserProject,
} from 'api-lofty';
import Layout from '../layouts/UserProjectDashboard';
import LoadingPage from './LoadingPage';
import {labelUserProject} from '../util/typeUserProject';
import DocApiEmail from '../components/DocApiUsers/DocApiEmail';
import DocApiGoogle from '../components/DocApiUsers/DocApiGoogle';
import DocApiFacebook from '../components/DocApiUsers/DocApiFacebook';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const DocumentationUserProject = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idUserProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [fields, setFields] = useState([]);
  const [dataUserProject, setDataUserProject] = useState();
  const [domainApi, setDomainApi] = useState('');
  const classes = useStyle();
  const [labelUrl, setLabelUrl] = useState('');
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resFields = await listFieldUserProject({token, idProject, idUserProject});
      const resUserProject = await getOneUserProject({token, _id: idUserProject});
      const resDomainApi = await getUrlApi({token});
      setLabelUrl(`proyectos/${resProject.project.name}/Usuarios/${resUserProject.data.type}/Documentacion`);
      setDataUserProject(resUserProject.data);
      setFields(resFields.data);
      setDataProject(resProject.project);
      setDomainApi(resDomainApi.url);
    } catch (error) {
      // C
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idUserProject={idUserProject}
      userProjectType={labelUserProject(dataUserProject?.type)}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Documentación Usuarios
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
          overflow: 'auto',
        }}
      >
        {
        dataUserProject?.type === 'email' && (
          <DocApiEmail
            data={dataUserProject}
            url={domainApi}
            fields={fields}
            idProject={idProject}
            loading={loading}
          />
        )
      }
        {
        dataUserProject?.type === 'google' && (
          <DocApiGoogle url={domainApi} fields={fields} idProject={idProject} loading={loading} />
        )
      }
        {
        dataUserProject?.type === 'facebook' && (
          <DocApiFacebook url={domainApi} fields={fields} idProject={idProject} loading={loading} />
        )
      }
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(DocumentationUserProject);
