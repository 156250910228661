/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {Typography, Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Container from '@material-ui/core/Container';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {getOneProject, havePaymentProject, listSuscription} from 'api-lofty';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import Deployment from '../components/Deployment/Deployment';
import ErrorDeployment from '../components/Deployment/ErrorDeployment';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const DeploymentProject = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [suscriptions, setSuscription] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const [havePayment, setHavePayment] = useState(false);
  const [info, setInfo] = useState('');
  const classes = useStyle();

  const getData = async () => {
    try {
      const now = new Date();
      const response = await getOneProject({token, idProject});
      setProject(response.project);
      setLabelUrl(`proyectos/${response.project.name}/Despliegue`);
      const resSuscription = await listSuscription({
        token,
      });
      const resPayment = await havePaymentProject({idProject, token});
      if (resPayment.data.havePayment && !response.project.deployed) {
        setHavePayment(resPayment.data.havePayment);
      }
      let listSuscriptions = resSuscription.data;
      if (resPayment.data.payment) {
        const endDate = new Date(resPayment.data.payment.endDate);
        if (now < endDate) {
          setInfo('Si realizas un cambio de plan, el plan anterior se cancelará y se aplicará el nuevo plan.');
        }
        listSuscriptions = resSuscription.data.filter((item) => (
          item?.type !== resPayment.data.payment.suscription.type
        ));
      }
      setSuscription(listSuscriptions);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={classes.descriptionSection}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          {
            info || 'El despliegue permite que el proyecto se lanze al internet en vivo al que los visitantes pueden acceder.'
          }
        </Typography>
      </Box>
      <Container>
        {
          havePayment ? (
            <ErrorDeployment />
          ) : (
            <Deployment
              suscriptions={suscriptions}
              idProject={idProject}
              token={token}
              project={project}
              handleEnd={() => {
                getData();
              }}
            />
          )
        }
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(DeploymentProject);
