/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, Button} from '@material-ui/core';
import PlusOneIcon from '@material-ui/icons/Add';
import {requestListBackoffice} from 'api-lofty';
import Tabular from '../../components/Tabular';
import Layout from '../../layouts/BackofficeDashboard';
import LoadingPage from '../../pages/LoadingPage';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '18px'},
  },
}));

const UserPage = ({
  token, backoffice, history, idProject,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const classes = useStyle();

  const handleDatabaseData = async () => {
    const columnData = [
      {title: 'Correo', field: 'loftyEmail'},
      {title: 'Usuario', field: 'loftyUsername'},
      {title: 'Nombre Completo', field: 'loftyName'},
      {
        title: 'Fecha de creación',
        field: 'loftyCreatedAt',
        render: (rowData) => (
          <span>
            {rowData.loftyCreatedAt ? moment(rowData.loftyCreatedAt).format('DD/MM/YYYY') : 'Fecha no disponible'}
          </span>
        ),
      },
    ];
    setColumns(columnData);

    try {
      setData([]);
      const resDatabaseBackoffice = await requestListBackoffice({
        token,
        url: `${backoffice.domain}/api/user/list`,
      });
      setData(resDatabaseBackoffice.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleDatabaseData();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Layout idProject={idProject} history={history}>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: '60px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography className={classes.miniNavbarMenuTitle}>
            Usuarios
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled
          startIcon={<PlusOneIcon />}
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
        >
          Agregar
        </Button>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Tabular
          title="Usuarios"
          columns={columns}
          data={data}
          ready={loading}
        />
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  backoffice: state.backoffice.data,
});

export default connect(mapStateToProps)(UserPage);
