/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {getOneDatabase, getOneProject, listActions} from 'api-lofty';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Layout from '../layouts/DatabaseDashboard';
import LoadingPage from './LoadingPage';
// import Working from '../components/Working';
import ListActivitiesDBSection from '../components/ListActivitiesDBSection';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const ActivitiesDatabase = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idDatabase} = params;
  const [loading, setLoading] = useState(true);
  const [pageActual, setPageActual] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [limitPage] = useState(20);
  const [dataProject, setDataProject] = useState({});
  const [dataDatabase, setDataDatabase] = useState({});
  const [logDatabase, setLogDatabase] = useState([]);
  const [labelUrl, setLabelUrl] = useState('');

  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resDatabase = await getOneDatabase({token, _id: idDatabase});
      const resLogs = await listActions({
        token,
        idProject,
        limit: limitPage,
        page: pageActual,
        idDatabase,
        idPage: 'none',
        idFieldDatabase: 'none',
        idFieldUserProject: 'none',
        typeFilter: 'database',
      });
      setLogDatabase(resLogs.data);
      setLabelUrl(`proyectos/${resProject.project.name}/Colección de Datos/${resDatabase.data.label}/Actividades`);
      setDataProject(resProject.project);
      setDataDatabase(resDatabase.data);
      // TODO agregar el listado de actividades
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  const handleNextPage = async () => {
    const resLogs = await listActions({
      token,
      idProject,
      limit: limitPage,
      page: pageActual + limitPage,
      idDatabase,
      idPage: 'none',
      idFieldDatabase: 'none',
      idFieldUserProject: 'none',
      typeFilter: 'database',
    });
    setLogDatabase(resLogs.data);
    setPageActual(pageActual + limitPage);
    setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = async () => {
    const resLogs = await listActions({
      token,
      idProject,
      limit: limitPage,
      page: pageActual - limitPage,
      idDatabase,
      idPage: 'none',
      idFieldDatabase: 'none',
      idFieldUserProject: 'none',
      typeFilter: 'database',
    });
    setLogDatabase(resLogs.data);
    setPageActual(pageActual - limitPage);
    setPageNumber(pageNumber - 1);
  };

  return (
    <Layout
      history={history}
      databaseName={dataDatabase?.label}
      projectName={dataProject?.name}
      idDatabase={idDatabase}
      idProject={idProject}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <ListActivitiesDBSection
        data={logDatabase.logs}
        actualPage={pageNumber}
        total={logDatabase.total}
        limitNumber={limitPage}
        previousPage={handlePreviousPage}
        nextPage={handleNextPage}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ActivitiesDatabase);
