export const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function onloadbase64() {
    resolve(reader.result);
  };
  reader.onerror = function onerrorbase64(error) {
    reject(error);
  };
});
