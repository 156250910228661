import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box, Button} from '@material-ui/core';
import TileProjectDep from './tileProjectDep';

const useStyles = makeStyles(() => ({
  mainBox: {
    margin: '5px',
    padding: '5px',
  },
  rootTitle: {
    display: 'flex',
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  titleOneContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px',
    width: '50%',
  },
  timeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px',
    width: '40%',
  },
  rowTitleStyle: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '19px',
    fontWeight: 'bold',
  },
  pageControllerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

export default function ListActivitesProjectSection({
  data, previousPage, nextPage, actualPage, total, limitNumber,
}) {
  const classes = useStyles();
  const count = actualPage;
  const limitPage = total / limitNumber;

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.rootTitle}>
        <Box className={classes.titleOneContainer}>
          <Typography className={classes.rowTitleStyle}>
            Actividad
          </Typography>
        </Box>
        <Box className={classes.timeContainer}>
          <Typography className={classes.rowTitleStyle}>
            Hora
          </Typography>
        </Box>
      </Box>
      {data && data.length > 0
        ? (
          <Box>
            {data.map((index) => {
              let message = '';
              let titleValue = '';
              if (index.type === 'createFieldDatabase') {
                message = `En la base de datos ${index.meta.nameDatabase} se agrego el campo ${index.meta.nameFieldDatabase}`;
                titleValue = 'Agregar Campo Base de Datos';
              } else if (index.type === 'updateFieldDatabase') {
                message = `En la base de datos ${index.meta.nameDatabase} se edito el campo ${index.meta.nameFieldDatabase}`;
                titleValue = 'Actualización de Campo Base de Datos';
              } else if (index.type === 'deleteFieldDatabase') {
                message = `En la base de datos ${index.meta.nameDatabase} se elimino el campo ${index.meta.nameFieldDatabase}`;
                titleValue = 'Eliminación de Campo Base de Datos';
              } else if (index.type === 'createDatabase') {
                message = `Se agrego la base de datos ${index.meta.nameDatabase} en el proyecto ${index.meta.nameProject}`;
                titleValue = 'Agregar Base de Datos';
              } else if (index.type === 'updateDatabase') {
                message = `Se actualizo la base de datos ${index.meta.nameDatabase} en el proyecto ${index.meta.nameProject}`;
                titleValue = 'Actualización de Base de Datos';
              } else if (index.type === 'deleteDatabase') {
                message = `Se elimino la base de datos ${index.meta.nameDatabase} en el proyecto ${index.meta.nameProject}`;
                titleValue = 'Eliminación de Base de Datos';
              } else if (index.type === 'createFieldUserProject') {
                message = `Se agrego el campo ${index.meta.nameFieldUserProject} en los usuarios`;
                titleValue = 'Agregar Campo Usuarios';
              } else if (index.type === 'updateFieldUserproject') {
                message = `Se edito el campo ${index.meta.nameFieldUserProject} en los usuarios`;
                titleValue = 'Actualización de Campo Usuarios';
              } else if (index.type === 'deleteFieldUserProject') {
                message = `Se elimino el campo ${index.meta.nameFieldUserProject} de los usuarios`;
                titleValue = 'Eliminación de Campo Usuarios';
              } else if (index.type === 'createPage') {
                message = 'Se agrego una Página en el proyecto';
                titleValue = 'Agregar Página';
              } else if (index.type === 'updatePage') {
                message = 'Actualizacion de Página en el proyecto';
                titleValue = 'Actualización de Página';
              } else if (index.type === 'deletePage') {
                message = 'Se elimino una Página en el proyecto';
                titleValue = 'Eliminación de Página';
              }
              return (
                <TileProjectDep
                  date={index.createdAt.split('T')[0]}
                  title={titleValue}
                  description={message}
                  time={`${index.createdAt.substring(index.createdAt.indexOf('T') + 1,
                    index.createdAt.lastIndexOf('.'))} UTC`}
                />
              );
            })}
          </Box>
        ) : (
          <Box>
            No Data
          </Box>
        )}
      <Box className={classes.pageControllerContainer}>
        <Box>
          {count > 1
            && (
            <Button onClick={() => previousPage()}>
              Prev
            </Button>
            )}
          {`Pag: ${count} of ${Math.ceil(limitPage)}` }
          {count < limitPage && limitPage > 1
          && (
            <Button onClick={() => nextPage()}>
              Next
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
