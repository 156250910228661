import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import {addPluginsProject, listPlugins} from 'api-lofty';
import DialogFullScreen from './DialogFullScreen';
import pluginImage from '../assets/plugins/plugin.png';
import CardServices from './CardProject';

const AddPluginsDialog = ({
  open, handleClose, token, types, successFunction, errorFunction, idProject,
}) => {
  const [pluginsProject, setPluginProject] = useState([]);
  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await listPlugins({token});
        setPluginProject(res.data.map((item) => ({
          type: item.name,
          name: item.label,
          description: item.description,
          image: pluginImage,
        })));
      } catch (error) {
        // code
      }
    };
    handleData();
  }, []);
  return (
    <DialogFullScreen open={open} handleClose={handleClose} title="Agregando Plugins">
      <Grid container>
        {
          pluginsProject.map((item) => {
            if (!types.find((type) => type === item.type)) {
              return (
                <Grid key={item.type} className="p-2" item md={4}>
                  <CardServices
                    name={item.name}
                    idProject={idProject}
                    token={token}
                    description={item.description}
                    image={item.image}
                    textButton="Agregar"
                    onClick={async () => {
                      try {
                        await addPluginsProject({
                          idProject,
                          token,
                          type: item.type,
                        });
                        if (successFunction) {
                          successFunction();
                        }
                      } catch (error) {
                        if (errorFunction) {
                          errorFunction(error);
                        }
                      }
                    }}
                  />
                </Grid>
              );
            }
            return undefined;
          })
        }
      </Grid>
    </DialogFullScreen>
  );
};

export default AddPluginsDialog;
