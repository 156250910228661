import React, {useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxUpdateBackoffice = ({
  field, docUpdate,
}) => {
  const [value, setValue] = useState(docUpdate ? docUpdate[field.name] : false);
  return (
    <FormControl key={field._id} className="my-4">
      <FormControlLabel
        control={(
          <Checkbox
            name={field.name}
            color="primary"
            checked={value}
            onChange={() => {
              setValue(!value);
            }}
          />
                      )}
        label={field.label}
      />
    </FormControl>
  );
};

export default CheckboxUpdateBackoffice;
