/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, IconButton} from '@material-ui/core';
import {
  getOneProject, listRoleProject, deleteRoleProject,
  getOneUserProject,
} from 'api-lofty';
import toastr from 'toastr';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusOneIcon from '@material-ui/icons/Add';
import Tabular from '../components/Tabular';
import Layout from '../layouts/UserProjectDashboard';
import LoadingPage from './LoadingPage';
import AddRoleUserProject from '../components/AddRoleUserProject';
import {labelUserProject} from '../util/typeUserProject';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const ListRolesUserProject = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idUserProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataFields, setDataFields] = useState([]);
  const [openAddField, setOpenAddField] = useState(false);
  const [dataUserProject, setDataUserProject] = useState();
  const classes = useStyle();
  const [labelUrl, setLabelUrl] = useState('');
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resFields = await listRoleProject({idProject, token});
      const resUserProject = await getOneUserProject({token, _id: idUserProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Usuarios/${resUserProject.data.type}/Roles`);
      setDataProject(resProject.project);
      setDataUserProject(resUserProject.data);
      setDataFields(resFields.data.map((item) => ({
        id: item._id,
        name: item.label,
      })));
    } catch (error) {
      // C
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const handleOpenAddField = () => {
    setOpenAddField((prev) => !prev);
  };
  const updateInforFields = async () => {
    const resFields = await listRoleProject({idProject, token});
    setDataFields(resFields.data.map((item) => ({
      id: item._id,
      name: item.label,
    })));
  };
  const handleAddSuccess = () => {
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 6000,
    };
    toastr.clear();
    setTimeout(() => toastr.success('Se agrego el campo'), 300);
    handleOpenAddField();
    updateInforFields();
  };
  const handleAddError = (error) => {
    if (error.info) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Lo sentimos no se pudo agregar el campo'), 300);
    }
  };
  const colums = [
    {
      title: 'Nombre',
      field: 'name',
    },
    {
      title: 'Acciones',
      field: 'id',
      render: (rowData) => {
        const {id} = rowData;
        return (
          <IconButton
            onClick={async () => {
              try {
                await deleteRoleProject({token, _id: id});
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Se elimino el dato',
                  showConfirmButton: false,
                  timer: 1500,
                });
                updateInforFields();
              } catch (error) {
                if (error.info) {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error.info,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Error no se pudo eliminar',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idUserProject={idUserProject}
      userProjectType={labelUserProject(dataUserProject?.type)}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Campos de Usuario
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<PlusOneIcon />}
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          onClick={() => handleOpenAddField()}
          className={classes.textButton}
        >
          Agregar
        </Button>
      </Box>
      <div className="text-right mb-4">
        <AddRoleUserProject
          idUserProject={idUserProject}
          idProject={idProject}
          token={token}
          open={openAddField}
          onClose={handleOpenAddField}
          successFunction={handleAddSuccess}
          errorFunction={handleAddError}
        />
      </div>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Tabular
          title="Roles"
          columns={colums}
          data={dataFields}
          ready={loading}
        />
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ListRolesUserProject);
