import React, {useState, useEffect} from 'react';
import toastr from 'toastr';
import {getOneConfigGoogleAnalitycs} from 'api-lofty';
import {Grid} from '@material-ui/core';
import FormConfigGoogleAnalytics from './Settings/FormConfigGoogleAnalytics';

const SettingsGoogleAnalytics = ({
  token, idPluginProject, idProject,
}) => {
  const [googleAnalyticsConfig, setGoogleAnalyticsConfig] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handle = async () => {
      try {
        const res = await getOneConfigGoogleAnalitycs({
          idPluginProject, idProject, token,
        });
        setGoogleAnalyticsConfig(res.data);
      } catch (error) {
        if (error.info) {
          toastr.error(error.info);
        } else {
          toastr.error('Error al procesar la petición. Por favor contacte con soporte técnico');
        }
      }
      setLoading(false);
    };
    handle();
  }, []);
  if (loading) {
    return (
      <div className="text-center">
        <h1 className="text-black">Cargando ...</h1>
      </div>
    );
  }
  return (
    <Grid className="my-4 mx-2" container>
      <Grid className="p-2" item md={6} xs={12}>
        <FormConfigGoogleAnalytics
          idPluginProject={idPluginProject}
          idProject={idProject}
          setGoogle={(value) => setGoogleAnalyticsConfig(value)}
          token={token}
          dataGoogleAnalytics={googleAnalyticsConfig}
        />
      </Grid>
    </Grid>
  );
};

export default SettingsGoogleAnalytics;
