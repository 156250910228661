import React, {useState} from 'react';
import Swal from 'sweetalert2';
import {Redirect, Link} from 'react-router-dom';
import {
  TextField, MenuItem, Box, Typography, Button, Hidden,
  Checkbox,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {createUser} from 'api-lofty';
import {connect} from 'react-redux';
import {addToken} from '../actions/auth.actions';
import AuthLayout from '../layouts/AuthLayout';
import containedImage from '../img/bg_register/bg-2r_1.png';
import imgLogo from '../img/logo.png';
import imgSubcontainer from '../img/bg_register/register_1.png';
import imgTablet from '../img/bg_register/bg-tablet_1.png';
import imgMobile from '../img/bg_register/mobile-bg_1.png';
import {isValidName, isValidEmail} from '../util/verifyRegex';

const useStyle = makeStyles((theme) => ({
  header1: {
    fontWeight: 'bold',
    fontSize: '37px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    color: '#ffffff',
    position: 'absolute',
    right: '100px',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]: {fontSize: '28px'},
  },
  header2: {
    fontSize: '37px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    color: '#ffffff',
    position: 'absolute',
    right: '100px',
    marginBottom: '30px',
    [theme.breakpoints.down('lg')]: {fontSize: '28px'},
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {flexDirection: 'column'},
  },
  boxContainerImage: {
    flex: 2,
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('sm')]: {height: 'auto', flex: 1},
  },
  imgStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  imgStyle2: {
    width: '100%',
    height: 'auto',
    zIndex: 1,
  },
  imgStyle3: {
    width: '100%',
    height: 'auto',
    zIndex: 1,
  },
  textAreaImage: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {display: 'none'},
  },
  textComponents: {
    fontWeight: 'bold',
  },
  logoStyle: {
    width: '30%',
    position: 'absolute',
    marginLeft: '2%',
    top: 1,
    marginTop: '10%',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {marginTop: '18%'},
  },
  termsPrivacyText: {
    fontSize: '13px',
    [theme.breakpoints.down('lg')]: {fontSize: '13px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
    [theme.breakpoints.down('sm')]: {fontSize: '14px'},
  },
  fontTitleRegister: {
    textAlign: 'left',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '40px',
    [theme.breakpoints.down('lg')]: {marginBottom: '20px'},
  },
}));

const RegisterPageMain = ({token, addTokenApp, history}) => {
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const classes = useStyle();

  const handleChangeBox = () => {
    setAcceptTerms(!acceptTerms);
  };
  if (token) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <AuthLayout>
      <Box className={classes.containerMain}>
        <Box className={classes.boxContainerImage}>
          <Hidden smDown>
            <Box
              component="img"
              src={containedImage}
              alt="ESE"
              style={{
                position: 'absolute', width: '100%', height: '100%', zIndex: 1,
              }}
            />
            <Box component="img" src={imgLogo} alt="ESE" className={classes.logoStyle} />
            <Box
              component="div"
              style={{
                position: 'absolute', zIndex: 2, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '30px',
              }}
            >
              <Box
                component="img"
                src={imgSubcontainer}
                alt="ESE"
                style={{
                  position: 'absolute', width: '28%', height: '25%', zIndex: 3, marginTop: '80px',
                }}
              />
              <Box component="div" className={classes.textAreaImage}>
                <Typography variant="h2" className={classes.header2}>
                  Ya estas cerca de crear
                </Typography>
                <Typography variant="h1" className={classes.header1}>
                  tu app
                </Typography>
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp xsDown>
            <Box component="img" src={imgTablet} alt="ESE" className={classes.imgStyle2} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={imgMobile} alt="ESE" className={classes.imgStyle3} />
          </Hidden>
        </Box>
        <Box style={{height: 'auto'}}>
          <Box
            component="div"
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box component="div" className="lofty-container-centered-login">
              <Box
                component="div"
                className={classes.fontTitleRegister}
              >
                Registro
              </Box>
              <Box
                component="form"
                onSubmit={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  try {
                    if (!isValidName(e.target.firstName.value)) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Nombre no valido',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setLoading(false);
                      return;
                    }
                    if (!isValidName(e.target.lastName.value)) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Apellido no valido',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setLoading(false);
                      return;
                    }
                    if (!isValidEmail(e.target.email.value)) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'El formato del correo no es correcto',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setLoading(false);
                      return;
                    }
                    const res = await createUser({
                      email: e.target.email.value,
                      firstName: e.target.firstName.value,
                      lastName: e.target.lastName.value,
                      company: e.target.company.value,
                      gender: e.target.gender.value,
                      password: e.target.password.value,
                      acceptTerms,
                    });
                    addTokenApp({
                      token: res.token,
                      user: res.user,
                    });
                    history.push('/dashboard');
                  } catch (error) {
                    if (error.info) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: error.info,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Lo sentimos no se pudo registrar el usuario',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  }
                  setLoading(false);
                }}
                className="wpcf7-form init demo"
              >
                <Box component="div" className="grid grid-flow-col grid-cols-2 grid-rows-1 mt-4">
                  <Box component="div" className="pr-2">
                    <TextField fullWidth name="firstName" type="text" label="Nombre" variant="standard" />
                  </Box>
                  <Box component="div" className="pl-2">
                    <TextField fullWidth name="lastName" type="text" label="Apellido" variant="standard" />
                  </Box>
                </Box>
                <Box component="div" className="mt-4">
                  <TextField select fullWidth name="gender" type="text" label="Genero" variant="standard">
                    <MenuItem value="female">
                      Femenino
                    </MenuItem>
                    <MenuItem value="male">
                      Masculino
                    </MenuItem>
                    <MenuItem value="other">
                      Otros
                    </MenuItem>
                  </TextField>
                </Box>
                <Box component="div" className="mt-4">
                  <TextField fullWidth name="company" type="text" label="Empresa (Opcional)" variant="standard" />
                </Box>
                <Box component="div" className="mt-4">
                  <TextField fullWidth name="email" type="email" label="Correo" variant="standard" />
                </Box>
                <Box component="div" className="mt-4">
                  <TextField fullWidth name="password" type="password" label="Contraseña" variant="standard" />
                </Box>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  <Checkbox
                    checked={acceptTerms}
                    onChange={handleChangeBox}
                  />
                  <Typography className={classes.termsPrivacyText}>
                    Acepto los
                    <Box component="a" href="https://loftyapps.com/terms" target="_blank" style={{fontWeight: 'bold'}}> Terminos & Condiciones </Box>
                    /
                    <Box component="a" href="https://loftyapps.com/privacy" target="_blank" style={{fontWeight: 'bold'}}> Política de Privacidad</Box>
                  </Typography>
                </Box>
                <Box component="div" className="text-center mt-4">
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    className={classes.textComponents}
                  >
                    {
                      loading ? (
                        <>
                          Procesando ...
                        </>
                      ) : (
                        <>
                          Registrarse
                        </>
                      )
                    }
                  </Button>
                </Box>
              </Box>
              <Box component="div" style={{marginTop: '40px', marginBottom: '10px'}}>
                <p>
                  <span style={{color: '#201549', fontSize: '12px', marginRight: '20px'}}>
                    Ya tengo cuenta
                  </span>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    component={Link}
                    to="/"
                    className={classes.textComponents}
                  >
                    Ingresar
                  </Button>
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addTokenApp: (doc) => dispatch(addToken(doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPageMain);
