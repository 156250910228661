import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  mainBox: {
    margin: '10px',
    border: '1px solid',
    borderColor: 'rgba(200, 210, 237, 1)',
    borderRadius: '5px',
  },
  boxContainerMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  avatarSection: {
    margin: '20px',
  },
  textContainer: {
    margin: '10px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  textAreaSection: {
    color: 'rgba(41, 33, 81, 1)',
  },
  textContent: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  buttonStyle: {
    backgroundColor: 'rgba(217, 217, 217, 1)',
    borderRadius: '15px',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontWeight: 'bold',
    fontSize: '13px',
    marginBottom: '20px',
  },
}));

const CardUser = ({
  text, name, permission, isAdmin, status,
  handleRemove, handleAccept,
}) => {
  const classes = useStyle();
  return (
    <Box className={classes.mainBox}>
      <Paper elevation={2}>
        <Container className="py-2">
          <Box className={classes.boxContainerMain}>
            <Box component="div" className={classes.avatarSection}>
              <Avatar>
                {text}
              </Avatar>
            </Box>
            <Box component="div" className={classes.textContainer}>
              <Box className={classes.textContent}>
                <h4>
                  <b className={classes.textAreaSection}>
                    {name}
                  </b>
                </h4>
              </Box>
              <Box className={classes.textContent}>
                <p style={{fontSize: '0.8rem'}}>
                  {permission}
                </p>
              </Box>
              <Box className={classes.textContent}>
                <hr style={{border: '1px solid rgba(229, 229, 229, 1)', minWidth: '150px'}} />
              </Box>
            </Box>
          </Box>
          {
        isAdmin ? (
          <>
            <div className="text-center">
              <Button onClick={handleRemove} color="secondary" className={classes.buttonStyle}>
                Eliminar
              </Button>
            </div>
          </>
        ) : (
          <div>
            {
              status === 'waiting' ? (
                <Grid container>
                  <Grid className="text-center" item xs={12} md={6}>
                    <Button onClick={handleRemove} color="secondary">
                      Eliminar
                    </Button>
                  </Grid>
                  <Grid className="text-center" item xs={12} md={6}>
                    <Button onClick={handleAccept} color="primary">
                      Aceptar
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <div className="text-center">
                  <Button onClick={handleRemove} color="secondary">
                    Eliminar
                  </Button>
                </div>
              )
            }
          </div>
        )
      }
        </Container>
      </Paper>
    </Box>
  );
};

export default CardUser;
