/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import {connect} from 'react-redux';
import Layout from '../../layouts/BackofficeDashboard';
import 'toastr/build/toastr.min.css';
// import DatabaseContainer from './DatabaseContainer';
import V2DatabaseContainer from './v2DatabaseContainer';

const DatabasePage = ({
  token, database, backoffice, history, idProject,
}) => (
  <Layout idProject={idProject} history={history}>
    <V2DatabaseContainer
      database={database}
      backoffice={backoffice}
      token={token}
    />
  </Layout>
);

const mapStateToProps = (state) => ({
  token: state.auth.token,
  backoffice: state.backoffice.data,
});

export default connect(mapStateToProps)(DatabasePage);
