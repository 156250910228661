/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import {
  getOneProject, listFieldUserProject, deleteFieldUserProject,
  getOneUserProject,
} from 'api-lofty';
import toastr from 'toastr';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusOneIcon from '@material-ui/icons/Add';
import Tabular from '../components/Tabular';
import Layout from '../layouts/UserProjectDashboard';
import LoadingPage from './LoadingPage';
import {fieldsLabelValue} from '../util/typesFields';
import AddUserProjectField from '../components/AddUserProjectField';
import {labelUserProject} from '../util/typeUserProject';
import RemoveFieldUserModal from '../components/RemoveFieldUserModal';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const UserProjetFieldsList = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idUserProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataFields, setDataFields] = useState([]);
  const [openAddField, setOpenAddField] = useState(false);
  const [dataUserProject, setDataUserProject] = useState(undefined);
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [labelUrl, setLabelUrl] = useState('');

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resFields = await listFieldUserProject({idUserProject, idProject, token});
      const resUserProject = await getOneUserProject({token, _id: idUserProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Usuarios/${resUserProject.data.type}/Campos`);
      setDataProject(resProject.project);
      setDataUserProject(resUserProject.data);
      const newDataFields = [
        {
          label: 'Correo',
          name: 'loftyEmail',
          type: 'Cadena de Texto',
        },
        {
          label: 'Verificacion de usuario',
          name: 'loftyVerified',
          type: 'Booleano',
        },
        {
          label: 'Tipo de registro',
          name: 'loftyTypeRegister',
          type: 'Cadena de Texto',
        },
        {
          label: 'Contraseña',
          name: 'loftyPassword',
          type: 'Cadena de Texto',
        },
        {
          label: 'Nombre',
          name: 'loftyName',
          type: 'Cadena de Texto',
        },
        {
          label: 'Rol',
          name: 'loftyRole',
          type: 'Cadena de Texto',
        },
        {
          label: 'Fecha de creación',
          name: 'loftyCreatedAt',
          type: 'Fecha',
        },
      ];
      if (resUserProject.data.haveUserName) {
        newDataFields.push({
          label: 'Usuario',
          name: 'loftyUsername',
          type: 'Cadena de Texto',
        });
      }
      resFields.data.forEach((item) => {
        newDataFields.push({
          id: item._id,
          label: item.label,
          name: item.name,
          type: fieldsLabelValue.find(({value}) => item.type === value).label,
        });
      });
      setDataFields(newDataFields);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const handleOpenAddField = () => {
    setOpenAddField((prev) => !prev);
  };
  const updateInforFields = async () => {
    const resFields = await listFieldUserProject({idUserProject, idProject, token});
    const newDataFields = [
      {
        label: 'Correo',
        name: 'loftyEmail',
        type: 'Cadena de Texto',
      },
      {
        label: 'Verificacion de usuario',
        name: 'loftyVerified',
        type: 'Booleano',
      },
      {
        label: 'Tipo de registro',
        name: 'loftyTypeRegister',
        type: 'Cadena de Texto',
      },
      {
        label: 'Contraseña',
        name: 'loftyPassword',
        type: 'Cadena de Texto',
      },
      {
        label: 'Nombre',
        name: 'loftyName',
        type: 'Cadena de Texto',
      },
      {
        label: 'Rol',
        name: 'loftyRole',
        type: 'Cadena de Texto',
      },
    ];
    if (dataUserProject.haveUserName) {
      newDataFields.push({
        label: 'Usuario',
        name: 'loftyUsername',
        type: 'Cadena de Texto',
      });
    }
    resFields.data.forEach((item) => {
      newDataFields.push({
        id: item._id,
        label: item.label,
        name: item.name,
        type: fieldsLabelValue.find(({value}) => item.type === value).label,
      });
    });
    setDataFields(newDataFields);
  };
  const handleAddSuccess = () => {
    // e.preventDefault();
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 6000,
    };
    toastr.clear();
    setTimeout(() => toastr.success('Se agrego el campo'), 300);
    handleOpenAddField();
    updateInforFields();
  };
  const handleAddError = (error) => {
    if (error.info) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Lo sentimos no se pudo agregar el campo'), 300);
    }
  };
  const colums = [
    {
      title: 'Nombre',
      field: 'name',
    },
    {
      title: 'Tipo',
      field: 'type',
    },
    {
      title: 'Acciones',
      field: 'id',
      render: (rowData) => {
        const {id} = rowData;
        if (!id) {
          return <></>;
        }
        return (
          <IconButton
            onClick={() => handleOpenDeleteDialog(id)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idUserProject={idUserProject}
      userProjectType={labelUserProject(dataUserProject?.type)}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Campos de Usuario
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<PlusOneIcon />}
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          className={classes.textButton}
          onClick={() => handleOpenAddField()}
        >
          Agregar
        </Button>
      </Box>
      <div className="text-right mb-4">
        <AddUserProjectField
          idUserProject={idUserProject}
          idProject={idProject}
          token={token}
          open={openAddField}
          onClose={handleOpenAddField}
          successFunction={handleAddSuccess}
          errorFunction={handleAddError}
          fieldData={dataFields}
        />
      </div>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Tabular
          title="Campos"
          columns={colums}
          data={dataFields}
          ready={loading}
        />
      </Box>
      <div>
        <RemoveFieldUserModal
          open={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          undoDelete={() => setOpenDialog(!openDialog)}
          handleDeletion={async () => {
            try {
              await deleteFieldUserProject({token, _id: idDeletion});
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              setOpenDialog(!openDialog);
              updateInforFields();
              toastr.clear();
              setTimeout(() => toastr.success('Se elimino exitosamente el dato'), 300);
            } catch (error) {
              if (error.info) {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
              } else {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n No se elimino el dato'), 300);
              }
            }
          }}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(UserProjetFieldsList);
