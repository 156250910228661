/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {getOneProject, getOnePluginProject} from 'api-lofty';
import {connect} from 'react-redux';
import LoadingPage from './LoadingPage';
import SimulatorPluginExpo from '../components/PluginSimulator/SimulatorPluginExpo';
import SimulatorPdf from '../components/PluginSimulator/SimulatorPdf';
import Error404 from './Error404';

const SimulatorPluginPage = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idPluginsProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataPluginProject, setDataPluginProject] = useState();
  const [labelUrl, setLabelUrl] = useState('');
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resPluginProject = await getOnePluginProject({token, _id: idPluginsProject, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Plugins/${resPluginProject.data.type}/Datos`);
      setDataProject(resProject.project);
      setDataPluginProject(resPluginProject.data);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  if (dataPluginProject?.type === 'pushNotificationExpo') {
    return (
      <SimulatorPluginExpo
        dataPluginProject={dataPluginProject}
        dataProject={dataProject}
        history={history}
        match={match}
        token={token}
        labelUrl={labelUrl}
      />
    );
  } if (dataPluginProject?.type === 'pdfFile') {
    return (
      <SimulatorPdf
        dataPluginProject={dataPluginProject}
        dataProject={dataProject}
        history={history}
        match={match}
        token={token}
        labelUrl={labelUrl}
      />
    );
  }
  return <Error404 />;
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(SimulatorPluginPage);
