import React from 'react';
import Container from '@material-ui/core/Container';
import {Redirect} from 'react-router-dom';
import {Box} from '@material-ui/core';
import {connect} from 'react-redux';
import DashboardNavbar from '../components/DashboardNavbar';

const Dashboard = ({
  children, token, data, toTutorial,
}) => {
  if (!token) {
    return <Redirect to="/" />;
  }
  return (
    <div style={{background: '#F5F5F5'}}>
      <DashboardNavbar props={data} handleTutorial={toTutorial} />
      <br />
      <Box
        component="div"
        style={{
          overflowY: 'scroll',
          height: '80vh',
        }}
      >
        <Container>
          {children}
        </Container>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Dashboard);
