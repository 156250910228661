import React from 'react';
import {
  Dialog, Box, IconButton,
  Button, Typography,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const useStyles = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(243, 243, 243, 1)',
    margin: '10px',
    padding: '15px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '5px',
  },
  buttonAreaContainer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    width: '100px',
    fontWeight: 'bold',
    backgroundColor: '#382f5d',
    color: 'white',
    '&:hover': {
      backgroundColor: '#2e274d',
    },
  },
}));

const RemoveNotificationModal = ({
  open, onClose, notificationId, token, successFunction, errorFunction,
}) => {
  const classes = useStyles();

  const confirmDeletion = async () => {
    try {
      // eslint-disable-next-line no-undef
      await deleteNotification({token, notificationId});
      if (successFunction) {
        successFunction();
      }
      toastr.success('Notificación eliminada correctamente');
      onClose();
    } catch (error) {
      if (errorFunction) {
        errorFunction(error);
      }
      toastr.error('Error al eliminar la notificación');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.titleStyle}>
            Eliminar Notificación
          </Typography>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{margin: '15px', width: '300px', padding: '5px'}}>
          <Box className={classes.messageContainer}>
            <WarningOutlinedIcon style={{width: '50px', height: '50px', color: 'rgba(234, 232, 178, 1)'}} />
            <Typography style={{position: 'relative', bottom: '10px'}}>
              ¿Estás seguro de que deseas eliminar esta notificación?
            </Typography>
          </Box>
          <Box className={classes.buttonAreaContainer}>
            <Button
              variant="contained"
              className={classes.buttonStyle}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              className={classes.buttonStyle}
              onClick={confirmDeletion}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RemoveNotificationModal;
