/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Dialog, Box, IconButton,
  Button,
} from '@material-ui/core';
import toastr from 'toastr';
import {createDomainController, verifyDomainProject} from 'api-lofty';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import ListDomainData from './ListDomainData';
import ListVerifyDomain from './ListVerifyDomain';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  descriptionArea: {
    backgroundColor: 'rgba(200, 210, 237, 1)',
    borderRadius: '5px',
    padding: '20px 15px',
    color: 'rgba(32, 21, 73, 1)',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
  buttonBoxArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '15px',
  },
}));

const DomainAddDialog = ({
  handleClose, open, handleForm, dataInfo,
  token, idProject, infoVerify,
}) => {
  const classes = useStyle();
  const [domainValue, setDomainValue] = useState('');
  const [hasVerified, setHasVerified] = useState(false);
  function handleAddNewDomain() {
    handleForm(domainValue);
  }

  async function handleVerify() {
    try {
      for (const domainInfo of dataInfo) {
        await verifyDomainProject({domain: domainInfo.domain, idProject, token});
      }
      setHasVerified(true);
    } catch (error) {
      //
    }
  }

  return (
    <Dialog open={open} handleClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Dominio
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { handleClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{padding: '10px', margin: '5px'}}>
          <Box>
            {!hasVerified && (
              <div className="mt-4">
                <Box component="div" className={classes.descriptionArea}>
                  El nombre de dominio exacto que el público usará para visitar tu sitio.
                  Puede ser un dominio (tudominio.com) o un subdominio (app.tudominio.com)
                </Box>
              </div>
            )}
            {
              dataInfo?.length < 3 && !hasVerified ? (
                <form
                  style={{display: 'flex'}}
                  className="mt-4"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    try {
                      await createDomainController({
                        token, idProject, domain: e.target.domain.value,
                      });
                      handleAddNewDomain();
                    } catch (error) {
                      if (error.info) {
                        toastr.error(error.info);
                      } else {
                        toastr.error('Error al procesar la petición');
                      }
                    }
                  }}
                >
                  <TextField
                    fullWidth
                    name="domain"
                    type="text"
                    label="Dominion"
                    onChange={(e) => setDomainValue(e.target.value)}
                    defaultValue={domainValue}
                    placeholder="tudominio.com"
                    variant="outlined"
                    inputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                    }}
                  />
                  <Button type="submit" className="ml-4" variant="contained" color="primary">
                    Agregar
                  </Button>
                </form>
              ) : <></>
            }
            <div className="mt-4">
              {hasVerified ? (
                <Box component="div" className={classes.descriptionArea}>
                  Asegurate de actualizar la configuración DNS para apuntar tu dominio a GetResponse
                </Box>
              ) : (
                <Box component="div" className={classes.descriptionArea}>
                  Agrega los siguientes registros TXT a tu proveedor de DNS
                  para verificar que eres el propietario de dasdasdasd.com.
                  No borres tus registros TXT.
                </Box>
              )}
            </div>
            <div className="mt-4">
              {hasVerified ? (
                <ListVerifyDomain
                  data={infoVerify}
                />
              ) : (
                <ListDomainData
                  data={dataInfo}
                />
              )}
            </div>
            {hasVerified && (
              <div className="mt-4">
                <Box component="div" className={classes.descriptionArea}>
                  Quitar los registros A y AAAA anteriores a tu proveedor de DNS
                </Box>
              </div>
            )}
            {!hasVerified ? (
              <Box component="div" className={classes.buttonBoxArea}>
                <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle} onClick={() => handleVerify()}>
                  Verificar
                </Button>
                <Button type="submit" color="secondary" variant="contained" className={classes.buttonStyle} onClick={handleClose}>
                  Cancelar
                </Button>
              </Box>
            ) : (
              <Box component="div" className={classes.buttonBoxArea}>
                <Button type="submit" color="secondary" variant="contained" className={classes.buttonStyle} onClick={handleClose}>
                  Finalizar
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DomainAddDialog;
