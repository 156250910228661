/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {
  Box, Typography, Paper, TextField,
  IconButton,
} from '@material-ui/core';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
// import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  getOneProject, deleteProject, insertCredentialEmail,
  listCredentialEmail, deleteCredentialEmail, isInvalidSlug,
  updateInfoProject, updateImageProject, addKeyword, listKeywords,
  deleteKeywords,
} from 'api-lofty';
import {isInvalidNameProject} from 'api-lofty/lib/utils/slugValidation';
/* import JsZip from 'jszip'; */
import {connect} from 'react-redux';
import toastr from 'toastr';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import CopyProject from '../components/CopyProject';
import AddCredentialEmail from '../components/AddCredentialEmail';
import 'toastr/build/toastr.min.css';
// import ListCredentialEmails from '../components/ListCredentialEmails';
import RemoveEmailModal from '../components/RemoveEmailModal';
import TableProjectCredentialList from '../components/TableProjectCredentialList';

import KeywordsTable from '../components/KeywordsTable';
import RemoveKeywordModal from '../components/RemoveKeywordModal';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  boxInitialContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: 'auto',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
  },
  imageSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: '150px',
    padding: '5px',
    backgroundColor: '#fff',
    marginBottom: '20px',
  },
  imageButtonOptionsSection: {
    display: 'flex',
    width: '55%',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
  },
  imageProjectSection: {
    display: 'flex',
    width: '45%',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignContent: 'center',
    // alignItems: 'center',
  },
  projectInfo: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '5px',
    backgroundColor: '#fff',
    marginBottom: '20px',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  titleStyle: {
    color: 'rgba(41, 33, 81, 1)',
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: 'Arial',
  },
  projectSlugContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '5px',
    backgroundColor: '#fff',
  },
  iconPlaceholder: {
    width: '100px',
    height: '100px',
    border: '1px solid rgba(0, 0, 0, 1)',
    background: 'rgba(217, 217, 217, 1)',
    marginTop: '20px',
    marginBottom: '20px',
  },
  fieldSectionContainer: {
    width: 'auto',
    margin: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  keywordsAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '150px',
    padding: '5px',
    backgroundColor: '#fff',
    marginTop: '20px',
  },
}));
const AddPhotoButton = withStyles(() => ({
  root: {
    border: '2px solid rgba(41, 178, 239, 1)',
    fontWeight: '700',
    color: 'rgba(41, 178, 239, 1)',
    padding: '10px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(217, 217, 217, 1)',
    },
  },
}))(Button);

/*
const RemovePhotoButton = withStyles(() => ({
  root: {
    textDecoration: 'underline',
    fontWeight: '700',
    color: 'rgba(255, 121, 121, 1)',
    backgroundColor: 'transparent',
    padding: '10px',
    '&:hover': {
      backgroundColor: 'rgba(217, 217, 217, 1)',
    },
  },
}))(Button);
*/

const ConfigProjectDashboard = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState('');
  const [openCopy, setOpenCopy] = useState(false);
  const [project, setProject] = useState();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [credentialData, setCredentialData] = useState([]);
  const [loadedData, setLoadedData] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [openDeletionDialog, setOpenDeletionDialog] = useState(false);
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectSlug, setProjectSlug] = useState('');
  const [onEditName, setOnEditName] = useState(false);
  const [onEditDesc, setOnEditDesc] = useState(false);
  const [restorePrevName, setRestorePrevName] = useState('');
  const [restorePrevDesc, setRestorePrevDesc] = useState('');
  const [keywordData, setKeyWordData] = useState([]);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [keywordID, setKeywordID] = useState('');
  const inputRef = React.useRef(null);

  const updateInfo = async () => {
    const getCredentialData = await listCredentialEmail({token, idProject});
    setCredentialData(getCredentialData.data);
  };

  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    try {
      await insertCredentialEmail({
        email: e.target.email.value,
        isPrimary: e.target.isPrimary.value,
        description: e.target.description.value,
        idProject,
        token,
        value: e.target.value.value,
        type: e.target.type.value,
      });
      updateInfo();
      setOpenAddDialog(false);
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se ha agregado la credencial de forma exitosa'), 300);
    } catch (error) {
      // CR
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Error. No se guardo la credencial'), 300);
      }
    }
  };

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDeletionDialog(true);
  };

  const handleUndoDelete = () => {
    setOpenDeletionDialog(false);
  };

  const handleUpdateDeletion = async () => {
    setOpenDeletionDialog(false);
    const getCredentialData = await listCredentialEmail({token, idProject});
    setCredentialData(getCredentialData.data);
  };

  const verifyCase = (e) => {
    const newValue = e.target.value;
    setProjectSlug(e.target.value);
    if (isInvalidSlug(newValue)) {
      setErrorText('Solo letras minusculas. Evitar palabras reservadas');
      setHasError(true);
    } else if (isInvalidNameProject(newValue)) {
      setErrorText('Solo letras minusculas');
      setHasError(true);
    } else {
      setErrorText('');
      setHasError(false);
    }
  };

  const handleEdition = (type) => {
    switch (type) {
      case 'name':
        setOnEditName(true);
        setRestorePrevName(projectName);
        break;
      case 'desc':
        setOnEditDesc(true);
        setRestorePrevDesc(projectDescription);
        break;
      default:
        break;
    }
  };

  const undoEdit = (type) => {
    if (type === 'name') {
      setProjectName(restorePrevName);
      setOnEditName(false);
    } else if (type === 'desc') {
      setProjectDescription(restorePrevDesc);
      setOnEditDesc(false);
    }
  };

  async function handleUpdateProjectInfo(type) {
    try {
      if (type === 'name') {
        const name = projectName;
        const description = projectDescription;
        // console.log('Name: ', name, '\n desc:', description);
        // console.log(idProject, token);
        await updateInfoProject({
          idProject, name, description, token,
        });
        setRestorePrevName('');
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.success('Se actualizo el nombre'), 300);
        setOnEditName(false);
      } else {
        const name = projectName;
        const description = projectDescription;
        // console.log('Name: ', name, '\n desc:', description);
        await updateInfoProject({
          idProject, name, description, token,
        });
        setRestorePrevDesc('');
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.success('Se actualizo la descripcion'), 300);
        setOnEditDesc(false);
      }
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error(`Error: No se logro actualizar la información. ${error.info}`), 300);
    }
  }

  async function toUpload(files) {
    // console.log(files[0]);
    try {
      await updateImageProject({file: files[0], token, idProject});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se actualizo la foto exitosamente'), 300);
      // handleInfoData();
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se logro actualizar la informacion'), 300);
      }
    }
  }

  function handleChange(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      toUpload(e.target.files);
    }
  }

  const clickUpload = () => {
    inputRef.current.click();
  };

  /*
    -- Cancel Deletion --
    In case of canceling or closing the delete keyword dialog
    the id is set to empty and closes the dialog prompt
    --
  */
  const undoKeywordRemoval = () => {
    setKeywordID('');
    setRemoveDialogOpen(false);
  };

  /*
    -- Refresh keyword data --
    Call to update the keyword data in case of deletion or addition
    --
  */
  const updateKeywords = async () => {
    const keywordRes = await listKeywords({token, idProject});
    setKeyWordData(keywordRes.data);
  };

  /*
    -- Add new keyword --
    Captures the string from the textfield
    then calls the API for adding a new keyword
    --
  */
  const handleAddNewKeyword = async (data) => {
    try {
      await addKeyword({
        idProject,
        token,
        word: data,
      });
      // updateInfo();
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se ha agregado la palabra clave de forma exitosa'), 300);
      updateKeywords();
    } catch (error) {
      // CR
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Error. No se logro agregar la palabra clave'), 300);
      }
    }
  };

  /*
    -- Handler for removal --
    Captures the keyword ID and opens the dialog for delete confirmation
    --
  */
  const handleKeywordRemoval = (data) => {
    setKeywordID(data);
    setRemoveDialogOpen(true);
  };

  /*
    -- Delete Keyword call --
    Verifies captured ID for deletion
    If success, send confirmation and dialog closes
    --
  */
  const removeKeyword = async () => {
    try {
      await deleteKeywords({token, _id: keywordID});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Se elimino exitosamente la palabra clave'), 300);
      setRemoveDialogOpen(false);
      updateKeywords();
    } catch (error) {
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('error: \n No se elimino la palabra clave'), 300);
      }
    }
  };

  useEffect(async () => {
    try {
      const getCredentialData = await listCredentialEmail({token, idProject});
      setCredentialData(getCredentialData.data);
      const keywordRes = await listKeywords({token, idProject});
      setKeyWordData(keywordRes.data);
      getOneProject({token, idProject}).then((response) => {
        // console.log(response);
        setProject(response.project);
        setLabelUrl(`proyectos/${response.project.name}/Configuraciones`);
        setImgSrc(response.project.logoUrl);
        // TODO seccion para obtener el listado del equipo
        setProjectName(response.project.name);
        setProjectDescription(response.project.description);
        setProjectSlug(response.project.slug);
        setLoading(false);
        setLoadedData(true);
      }).catch(() => {
        setLoading(false);
      });
    } catch (err) {
      //
    }
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <RemoveKeywordModal
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(!removeDialogOpen)}
        undoDelete={undoKeywordRemoval}
        handleDeletion={removeKeyword}
      />
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <CopyProject
        token={token}
        idProject={idProject}
        onClose={() => setOpenCopy(false)}
        open={openCopy}
        successFunction={() => {
          history.push('/dashboard');
        }}
        errorFunction={(error) => {
          toastr.options = {
            positionClass: 'toast-top-right',
            hideDuration: 300,
            timeOut: 6000,
          };
          if (error.info) {
            // alert(error.info);
            toastr.clear();
            setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
          } else {
            // alert('Error al procesar la peticion');
            toastr.clear();
            setTimeout(() => toastr.error('error al procesar la peticion'), 300);
          }
        }}
      />
      <Box component="div" className={classes.boxInitialContainer}>
        <Box component="div" className={classes.columnContainer}>
          <Paper className="p-2">
            <Box>
              <Box className={classes.titleStyle} style={{textAlign: 'end', marginRight: '15px', fontSize: '18px'}}>
                Credenciales de Correo
              </Box>
              <AddCredentialEmail
                open={openAddDialog}
                handleClose={() => setOpenAddDialog(false)}
                handleForm={(e) => handleSubmitAdd(e)}
              />
            </Box>
            <Box>
              {/* <Box>
                {credentialData.map((index) => (
                  <CredentialCard
                    email={index.email}
                    value={index.value}
                    isPrimary={index.isPrimary}
                    description={index.description}
                  />
                ))}
              </Box> */}
              {loadedData
                && (
                  <TableProjectCredentialList
                    data={credentialData}
                    deleteCredential={(idData) => handleOpenDeleteDialog(idData)}
                    addCredential={() => setOpenAddDialog(true)}
                  />
                )}
              <div>
                <RemoveEmailModal
                  open={openDeletionDialog}
                  onClose={() => setOpenDeletionDialog(!openDeletionDialog)}
                  undoDelete={handleUndoDelete}
                  handleDeletion={async () => {
                    try {
                      await deleteCredentialEmail({token, _id: idDeletion});
                      toastr.options = {
                        positionClass: 'toast-top-right',
                        hideDuration: 300,
                        timeOut: 6000,
                      };
                      handleUpdateDeletion();
                      toastr.clear();
                      setTimeout(() => toastr.success('Se elimino exitosamente el E-mail'), 300);
                    } catch (error) {
                      if (error.info) {
                        toastr.options = {
                          positionClass: 'toast-top-right',
                          hideDuration: 300,
                          timeOut: 6000,
                        };
                        toastr.clear();
                        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
                      } else {
                        toastr.options = {
                          positionClass: 'toast-top-right',
                          hideDuration: 300,
                          timeOut: 6000,
                        };
                        toastr.clear();
                        setTimeout(() => toastr.error('error: \n No se elimino el E-mail'), 300);
                      }
                    }
                  }}
                />
              </div>
            </Box>
          </Paper>
          <Box component="div" className={classes.keywordsAreaContainer}>
            <Box>
              <Box className={classes.titleStyle} style={{textAlign: 'end', marginRight: '15px', fontSize: '21px'}}>
                Palabras clave
              </Box>
              <KeywordsTable
                data={keywordData}
                deletionHandler={(e) => handleKeywordRemoval(e)}
                keywordAddition={(e) => handleAddNewKeyword(e)}
              />
            </Box>
          </Box>
        </Box>
        <Box component="div" className={classes.columnContainer}>
          <Box component="div" className={classes.imageSectionContainer}>
            <Box component="div" className={classes.imageButtonOptionsSection}>
              <input ref={inputRef} type="file" style={{display: 'none'}} multiple={false} onChange={handleChange} />
              <AddPhotoButton variant="outlined" onClick={clickUpload}>
                <InsertPhotoOutlinedIcon />
                Subir Imagen
              </AddPhotoButton>
              {/* <RemovePhotoButton>
                <DeleteOutlinedIcon />
                Eliminar Imagen
              </RemovePhotoButton> */}
            </Box>
            <Box component="div" className={classes.imageProjectSection}>
              <Box className={classes.titleStyle} style={{textAlign: 'right'}}>Imagen del Proyecto</Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                {(imgSrc && (imgSrc !== '' || imgSrc !== undefined)) ? (
                  <Box
                    component="img"
                    style={{
                      width: '100px', height: '100px', marginTop: '20px', marginBottom: '20px',
                    }}
                    src={imgSrc}
                    alt="Logo"
                  />
                ) : (
                  <Box className={classes.iconPlaceholder} />
                )}
              </Box>
            </Box>
          </Box>
          <Box component="div" className={classes.projectInfo}>
            <Box className={classes.titleStyle} style={{textAlign: 'end', marginRight: '15px'}}>
              Editar Datos del Proyecto
            </Box>
            <Box>
              <Box className={classes.fieldSectionContainer}>
                <TextField
                  fullWidth
                  name="name"
                  type="text"
                  label="Nombre"
                  variant="outlined"
                  value={projectName}
                  disabled={!onEditName}
                  onChange={(e) => setProjectName(e.target.value)}
                  inputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                {!onEditName
                  ? (
                    <IconButton onClick={() => handleEdition('name')}>
                      <BorderColorOutlinedIcon />
                    </IconButton>
                  ) : (
                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                      <IconButton>
                        <CancelOutlinedIcon onClick={() => undoEdit('name')} />
                      </IconButton>
                      <IconButton>
                        <DoneOutlineIcon onClick={() => handleUpdateProjectInfo('name')} />
                      </IconButton>
                    </Box>
                  )}
              </Box>
              <Box className={classes.fieldSectionContainer}>
                <TextField
                  fullWidth
                  multiline
                  value={projectDescription}
                  onChange={(e) => setProjectDescription(e.target.value)}
                  rows={4}
                  disabled={!onEditDesc}
                  name="description"
                  type="text"
                  label="Descripción"
                  variant="outlined"
                  inputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
                {!onEditDesc
                  ? (
                    <IconButton onClick={() => handleEdition('desc')}>
                      <BorderColorOutlinedIcon />
                    </IconButton>
                  ) : (
                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                      <IconButton>
                        <CancelOutlinedIcon onClick={() => undoEdit('desc')} />
                      </IconButton>
                      <IconButton>
                        <DoneOutlineIcon onClick={() => handleUpdateProjectInfo('desc')} />
                      </IconButton>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.projectSlugContainer}>
            <Box className={classes.titleStyle} style={{textAlign: 'end', marginRight: '15px'}}>
              Slug
            </Box>
            <Box className={classes.fieldSectionContainer}>
              <TextField
                fullWidth
                name="slug"
                type="text"
                label="Slug"
                variant="outlined"
                helperText={errorText}
                onChange={verifyCase}
                error={hasError}
                value={projectSlug}
                disabled
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '50%',
          }}
        >
          <Button
            onClick={() => history.push(`/dashboard/project/${idProject}/exportcode`)}
            style={{
              backgroundColor: 'rgba(166, 206, 227, 1)',
              color: 'rgba(31, 20, 72, 1)',
              fontWeight: '700',
              fontSize: '13px',
              margin: '10px',
            }}
            variant="contained"
          >
            Exportar Proyecto
          </Button>
          <Button
            className="mx-auto text-white font-semibold rounded-full"
            variant="contained"
            color="secondary"
            style={{fontWeight: '700', margin: '10px', fontSize: '13px'}}
            onClick={async () => {
              const resConfirm = await Swal.fire({
                title: `¿Estás seguro de eliminar el proyecto de ${project.name}?`,
                icon: 'warning',
                confirmButtonText: 'Si',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
              });
              if (resConfirm.isConfirmed) {
                try {
                  await deleteProject({_id: idProject, token});
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Se elimino el proyecto',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  history.push('/dashboard');
                } catch (error) {
                  if (error.info) {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: error.info,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: 'Lo sentimos no se pudo eliminar el proyecto',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                }
              }
            }}
          >
            Eliminar proyecto
          </Button>
          <Button
            onClick={() => setOpenCopy(true)}
            variant="contained"
            color="primary"
            style={{
              color: '#fff', fontWeight: '700', margin: '10px', fontSize: '13px',
            }}
          >
            Copiar Proyecto
          </Button>
        </Box>

      </Box>
      <Grid className="mt-3" container>
        {/* <Grid item xs={6}>
          <div className="text-center">
            <button
              style={{
                background: '#201549',
              }}
              type="button"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loadingExportBackend}
              onClick={async () => {
                setLoadingExportBackend(true);
                try {
                  const res = await generateCodeAll({
                    token, idProject,
                  });
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Se genero el codigo backend',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  const zip = new JsZip();
                  await zip.loadAsync(res.dataBackend, {base64: true});
                  const blob = await zip.generateAsync({type: 'blob'});
                  const element = document.createElement('a');
                  element.setAttribute('href', window.URL.createObjectURL(blob));
                  element.setAttribute('download', `${project?.name}Backend.zip`);
                  element.style.display = 'none';
                  document.body.appendChild(element);
                  element.click();
                } catch (error) {
                  console.log(error);
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error al generar el codigo backend',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
                setLoadingExportBackend(false);
              }}
            >
              {
            loadingExportBackend ? 'Procesando' : 'Generar Codigo Backend'
          }
            </button>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-center">
            <button
              style={{
                background: '#201549',
              }}
              type="button"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loadingExportFrontendAdmin}
              onClick={async () => {
                setLoadingExportFrontendAdmin(true);
                try {
                  const res = await generateCodeAll({
                    token, idProject,
                  });
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Se genero el codigo backend',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  const zip = new JsZip();
                  await zip.loadAsync(res.dataFrontendOffice, {base64: true});
                  const blob = await zip.generateAsync({type: 'blob'});
                  const element = document.createElement('a');
                  element.setAttribute('href', window.URL.createObjectURL(blob));
                  element.setAttribute('download', `${project?.name}Frontend Admin.zip`);
                  element.style.display = 'none';
                  document.body.appendChild(element);
                  element.click();
                } catch (error) {
                  console.log(error);
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error al generar el codigo backend',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
                setLoadingExportFrontendAdmin(false);
              }}
            >
              {
            loadingExportBackend ? 'Procesando' : 'Generar Codigo Frontend Admin'
          }
            </button>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-center">
            <button
              style={{
                background: '#201549',
              }}
              type="button"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loadingExportBackendAdmin}
              onClick={async () => {
                setLoadingExportBackendAdmin(true);
                try {
                  const res = await generateCodeAll({
                    token, idProject,
                  });
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Se genero el codigo backend',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  const zip = new JsZip();
                  await zip.loadAsync(res.dataBackendOffice, {base64: true});
                  const blob = await zip.generateAsync({type: 'blob'});
                  const element = document.createElement('a');
                  element.setAttribute('href', window.URL.createObjectURL(blob));
                  element.setAttribute('download', `${project?.name}BackendAdmin.zip`);
                  element.style.display = 'none';
                  document.body.appendChild(element);
                  element.click();
                } catch (error) {
                  console.log(error);
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error al generar el codigo backend',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
                setLoadingExportBackendAdmin(false);
              }}
            >
              {
            loadingExportBackend ? 'Procesando' : 'Generar Codigo Backend Admin'
          }
            </button>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="text-center">
            <button
              style={{
                background: '#201549',
              }}
              type="button"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loadingExportBackendAdmin}
              onClick={async () => {
                setLoadingExportBackendAdmin(true);
                try {
                  const res = await generateCodeAll({
                    token, idProject,
                  });
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Se genero el codigo frontend',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  const zip = new JsZip();
                  await zip.loadAsync(res.dataFrontend, {base64: true});
                  const blob = await zip.generateAsync({type: 'blob'});
                  const element = document.createElement('a');
                  element.setAttribute('href', window.URL.createObjectURL(blob));
                  element.setAttribute('download', `${project?.name}frontend.zip`);
                  element.style.display = 'none';
                  document.body.appendChild(element);
                  element.click();
                } catch (error) {
                  console.log(error);
                  Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Error al generar el codigo backend',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
                setLoadingExportBackendAdmin(false);
              }}
            >
              {
            loadingExportBackend ? 'Procesando' : 'Generar Codigo Frontend'
          }
            </button>
          </div>
        </Grid> */}
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ConfigProjectDashboard);
