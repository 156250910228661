import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import PrincipalDashboard from '../../layouts/BackofficeDashboard';
import StorageChart from '../../components/StorageChart';
import WeeklyVisitsChart from '../../components/WeeklyVisitsChart';
import UserStatistics from '../../components/UserStatistics';

const DashboardPage = ({history, idProject}) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  return (
    <PrincipalDashboard history={history} idProject={idProject}>
      <Grid container spacing={3}>
        <Grid container item spacing={3} xs={12}>
          <Grid item xs={12} sm={6} md={4}>
            <StorageChart />
          </Grid>

          <Grid item xs={12} sm={6} md={4} style={{marginLeft: '400px'}}>
            {token && <UserStatistics token={token} idProject={idProject} />}
          </Grid>
        </Grid>

        {/* Fila para WeeklyVisitsChart */}
        <Grid item xs={12} md={8}>
          {token && <WeeklyVisitsChart projectId={idProject} />}
        </Grid>
      </Grid>
    </PrincipalDashboard>
  );
};

export default DashboardPage;
