import React from 'react';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
/* import {getCreditCard} from 'api-lofty'; */

const useStyle = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    border: '1px solid',
    borderColor: 'rgba(206, 206, 206, 1)',
    borderRadius: '5px',
  },
  titleArea: {
    padding: '10px',
    backgroundColor: 'rgba(217, 217, 217, 1)',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  titleTextStyle: {
    color: 'rgba(32, 21, 73, 1)',
    fontSize: '18px',
    fontWeight: 500,
  },
  listDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
    marginTop: '10px',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
  listTitleStyle: {
    color: 'rgba(32, 21, 73, 1)',
    fontSize: '16px',
    fontWeight: 500,
  },
  listTypeArea: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  listInfoDisplayArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: 'rgba(117, 117, 117, 1)',
    marginLeft: '25px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
    padding: '5px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonCreditCardSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '15px',
  },
  singleButtonBox: {
    margin: '10px',
  },
}));

const ConfigProject = ({
  suscription, handleNext, handlePrevious, /* token, */
  project,
}) => {
  const classes = useStyle();
  /* const [credicards, setCreditCards] = useState([]); */
  /* const handleData = async () => {
    const res = await getCreditCard({token});
    setCreditCards(res.data);
  }; */
  /* useEffect(() => {
    handleData();
  }, []); */
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.dialogContainer}>
        <Box className={classes.titleArea}>
          <p className={classes.titleTextStyle}>
            {`Resumen: ${project.slug}.loftyapps.live`}
          </p>
        </Box>
        <Box className={classes.listDescriptionContainer}>
          <Box className={classes.listTypeArea}>
            <CheckIcon style={{color: 'green'}} />
            <p className={classes.listTitleStyle}>
              Tipo de Suscripcion
            </p>
          </Box>
          <Box className={classes.listInfoDisplayArea}>
            {suscription?.type}
          </Box>
        </Box>
        <Box className={classes.listDescriptionContainer}>
          <Box className={classes.listTypeArea}>
            <CheckIcon style={{color: 'green'}} />
            <p className={classes.listTitleStyle}>
              Nombre del Subdominio
            </p>
          </Box>
          <Box className={classes.listInfoDisplayArea}>
            {`${project.slug}.loftyapps.live`}
          </Box>
        </Box>
        {/* <Box className={classes.listDescriptionContainer}>
          <Box className={classes.listTypeArea}>
            <CheckIcon style={{color: 'green'}} />
            <p className={classes.listTitleStyle}>
              Método de pago
            </p>
          </Box>
          {
            credicards.length === 0 ? (
              <Box className={classes.listInfoDisplayArea}>No hay registros</Box>
            ) : (
              credicards.map((item) => (
                <Box className={classes.listInfoDisplayArea}>
                  {`Tarjeta de Credito **** **** **** *${item.lastNumberCreditCard}`}
                </Box>
              ))
            )
          }
        </Box> */}
        <Box className={classes.buttonContainer}>
          {/* <Box className={classes.buttonCreditCardSectionContainer}>
            <Box className={classes.singleButtonBox}>
              <Button variant="contained" color="primary" className={classes.buttonStyle}>
                Agregar Tarjeta
              </Button>
            </Box>
            <Box className={classes.singleButtonBox}>
              <Button variant="contained" color="inherited" className={classes.buttonStyle}>
                Modificar Tarjeta
              </Button>
            </Box>
          </Box> */}
          <Box className={classes.buttonCreditCardSectionContainer}>
            <Box className={classes.singleButtonBox}>
              <Button
                onClick={() => handleNext()}
                variant="contained"
                color="primary"
                style={{backgroundColor: 'rgba(32, 21, 73, 1)', color: '#fff'}}
                className={classes.buttonStyle}
              >
                Publicar
              </Button>
            </Box>
            <Box className={classes.singleButtonBox}>
              <Button onClick={() => handlePrevious()} variant="contained" color="inherited" className={classes.buttonStyle}>
                Atras
              </Button>
            </Box>
          </Box>
          <div className="mt-3">
            <img
              src="https://placetopay-static-prod-bucket.s3.us-east-2.amazonaws.com/bancatlan-hn/header.svg"
              alt="Banco Atlantida"
              style={{
                width: 200,
              }}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfigProject;
