import React from 'react';
import Swal from 'sweetalert2';
import {
  TextField, Box, Typography, Button, Hidden,
} from '@material-ui/core';
import {createResetToken} from 'api-lofty';
import {connect} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
// import {createResetToken} from '../api/reset.api';
import AuthLayout from '../layouts/AuthLayout';
import containedImage from '../img/bg_register/bg-2r_1.png';
import imgLogo from '../img/logo.png';
import imgSubcontainer from '../img/bg_pw/forgot_1.png';
import imgTablet from '../img/bg_pw/bg-tablet_1_forgot.png';
import imgMobile from '../img/bg_pw/mobile-bg_1_forgot.png';

const useStyle = makeStyles((theme) => ({
  header1: {
    fontWeight: 'bold',
    fontSize: '42px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    color: '#ffffff',
    position: 'absolute',
    right: '100px',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]: {fontSize: '24px', right: '80px'},
  },
  header2: {
    fontSize: '39px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    color: '#ffffff',
    position: 'absolute',
    right: '200px',
    marginBottom: '50px',
    [theme.breakpoints.down('lg')]: {fontSize: '24px', right: '130px'},
  },
  textBasic1: {
    fontWeight: 'bold',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    marginTop: '30px',
    marginBottom: '40px',
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {flexDirection: 'column'},
  },
  boxContainerImage: {
    flex: 2,
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('sm')]: {height: 'auto'},
  },
  imgStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  imgStyle2: {
    width: '100%',
    height: 'auto',
    zIndex: 1,
  },
  imgStyle3: {
    width: '100%',
    height: 'auto',
    zIndex: 1,
  },
  textAreaImage: {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {display: 'none'},
  },
  textComponents: {
    fontWeight: 'bold',
  },
  logoStyle: {
    width: '30%',
    position: 'absolute',
    marginLeft: '2%',
    top: 1,
    marginTop: '10%',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {marginTop: '18%'},
  },
}));

const ForgotPageMain = ({token, history}) => {
  const classes = useStyle();
  if (token) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <AuthLayout>
      <Box className={classes.containerMain}>
        <Box className={classes.boxContainerImage}>
          <Hidden smDown>
            <Box
              component="img"
              src={containedImage}
              alt="ESE"
              style={{
                position: 'absolute', width: '100%', height: '100%', zIndex: 1,
              }}
            />
            <Box component="img" src={imgLogo} alt="ESE" className={classes.logoStyle} />
            <Box
              component="div"
              style={{
                position: 'absolute', zIndex: 2, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '30px',
              }}
            >
              <Box
                component="img"
                src={imgSubcontainer}
                alt="ESE"
                style={{
                  position: 'absolute', width: '28%', height: '25%', zIndex: 3, marginTop: '80px', objectFit: 'contain',
                }}
              />
              <Box component="div" className={classes.textAreaImage}>
                <Typography variant="h2" className={classes.header2}>
                  Problemas para entrar
                </Typography>
                <Typography variant="h1" className={classes.header1}>
                  Olvidaste tu contraseña?
                </Typography>
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp xsDown>
            <Box component="img" src={imgTablet} alt="ESE" className={classes.imgStyle2} />
          </Hidden>
          <Hidden smUp>
            <Box component="img" src={imgMobile} alt="ESE" className={classes.imgStyle3} />
          </Hidden>
        </Box>
        <Box style={{height: '100%', flex: 1}}>
          <Box
            component="div"
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box component="div" className="lofty-container-centered-password">
              <Typography variant="body1" className={classes.textBasic1}>
                Ingresa tu email y enviaremos
                a tu correo un link para
                resetear tu contraseña
              </Typography>
              <Box
                component="form"
                className="wpcf7-form init demo"
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    await createResetToken({
                      email: e.target.email.value,
                    });
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Se envio el correo',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    history.push('/');
                  } catch (error) {
                    if (error.info) {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: error.info,
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } else {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Lo sentimos no se pudo enviar el correo',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  }
                }}
              >
                <Box component="div" style={{marginBottom: '40px'}}>
                  <TextField fullWidth name="email" type="email" label="Correo" variant="standard" />
                </Box>
                <Box component="div" className="text-center mt-4">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.textComponents}
                  >
                    Resetear Contraseña
                  </Button>
                </Box>
                <Box component="div" style={{marginTop: '80px', marginBottom: '20px'}} className="text-center mt-4">
                  <Box component="p" style={{marginBottom: '10px', fontFamily: ['Nunito', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(',')}}>
                    O
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    component={Link}
                    className={classes.textComponents}
                    to="/register"
                  >
                    Crear Nueva Cuenta
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ForgotPageMain);
