import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    backgroundColor: '#fff',
    position: 'relative',
    width: '95%',
    height: 'auto',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'rgba(166, 206, 227, 1)',
  },
  content: {
    flex: 1,
  },
  descriptionSegment: {
    width: '60%',
    padding: '10px',
    maxHeight: '40px',
  },
  userSegment: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  timeDateSegment: {
    width: '20%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  descriptionText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '14px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  userText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '14px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  dateText: {
    color: 'rgba(41, 178, 239, 1)',
    fontSize: '14px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  timeText: {
    color: 'rgba(178, 223, 138, 1)',
    fontSize: '14px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
}));

export default function HistoryProjectDashboard({
  description, user, date, time,
}) {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.descriptionSegment}>
        <Typography component="h5" variant="h5" className={classes.descriptionText}>
          {description}
        </Typography>
      </Box>
      <Box component="div" className={classes.userSegment}>
        <Typography component="h5" variant="h5" className={classes.userText}>
          {user}
        </Typography>
      </Box>
      <Box component="div" className={classes.timeDateSegment}>
        <Typography component="h5" variant="h5" className={classes.dateText}>
          {date}
        </Typography>
        <Typography component="h5" variant="h5" className={classes.timeText}>
          {time}
        </Typography>
      </Box>
    </Box>
  );
}
