import React, {useState} from 'react';
import toastr from 'toastr';
import {Paper, TextField} from '@material-ui/core';
import {addUpdatePaypalPlugin, getOnePaypalPlugin} from 'api-lofty';

const FormConfigPaypal = ({
  token, idProject, idPluginProject, setPaypal,
  dataPaypal,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Paper className="p-3">
      <h2 className="text-center my-2">
        <b>Configuración Paypal</b>
      </h2>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            await addUpdatePaypalPlugin({
              idPluginProject,
              idProject,
              token,
              productionClientId: e.target.productionClientId.value,
              productionSecret: e.target.productionSecret.value,
              sandBoxClientId: e.target.sandBoxClientId.value,
              sandBoxSecret: e.target.sandBoxSecret.value,
            });
            if (setPaypal) {
              const res = await getOnePaypalPlugin({
                idPluginProject, idProject, token,
              });
              setPaypal(res.data);
            }
          } catch (error) {
            if (error.info) {
              toastr.error(error.info);
            } else {
              toastr.error('Contacte con soporte técnico');
            }
          }
          setLoading(false);
        }}
      >
        <div className="my-4">
          <TextField
            fullWidth
            name="sandBoxClientId"
            type="password"
            label="Clave Sandbox Cliente"
            variant="standard"
            defaultValue={dataPaypal?.sandBoxClientId}
          />
        </div>
        <div className="my-4">
          <TextField
            fullWidth
            name="sandBoxSecret"
            type="password"
            label="Clave Sandbox Secreto"
            variant="standard"
            defaultValue={dataPaypal?.sandBoxSecret}
          />
        </div>
        <div className="my-4">
          <TextField
            fullWidth
            name="productionClientId"
            type="password"
            label="Clave Producción Cliente"
            variant="standard"
            defaultValue={dataPaypal?.productionClientId}
          />
        </div>
        <div className="my-4">
          <TextField
            fullWidth
            name="productionSecret"
            type="password"
            label="Clave Producción Secreta"
            variant="standard"
            defaultValue={dataPaypal?.productionSecret}
          />
        </div>
        <div className="text-center my-4">
          <button
            style={{
              background: '#201549',
            }}
            type="submit"
            className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
            disabled={loading}
          >
            {
              loading ? (
                <>
                  Procesando ...
                </>
              ) : (
                <>
                  Guardar
                </>
              )
            }
          </button>
        </div>
      </form>
    </Paper>
  );
};

export default FormConfigPaypal;
