import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Dialog, Box, IconButton,
  Button, MenuItem,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
}));

const AddSupportRequestModal = ({
  handleClose, open, handleForm, projects,
}) => {
  const classes = useStyle();
  const [sectorValueString, setSectorValueString] = useState('');
  const [sectionSector, setSectionSector] = useState('');
  const [mapSection, setMapSection] = useState([{sectionName: 'otros', sectionType: 'other'}]);
  const [projectValue, setProjectValue] = useState('');

  const sectors = [
    {sectorName: 'Despliegue', sectorType: 'deploy'},
    {sectorName: 'Proyecto', sectorType: 'project'},
    {sectorName: 'Editor', sectorType: 'editor'},
    {sectorName: 'Base de datos', sectorType: 'database'},
    {sectorName: 'Paginas', sectorType: 'pages'},
    {sectorName: 'Plugins', sectorType: 'plugins'},
    {sectorName: 'Equipo', sectorType: 'teams'},
    {sectorName: 'Usuarios', sectorType: 'users'},
    {sectorName: 'Otros', sectorType: 'others'},
  ];
  const mainSection = [
    {sectionName: 'Creacion', sectionType: 'create'},
    {sectionName: 'Lectura', sectionType: 'get'},
    {sectionName: 'Modificacion', sectionType: 'modify'},
    {sectionName: 'Eliminacion', sectionType: 'delete'},
  ];

  const secondarySection = [
    {sectionName: 'Agregar Datos', sectionType: 'addData'},
    {sectionName: 'Modificar Datos', sectionType: 'updateData'},
    {sectionName: 'Eliminar Datos', sectionType: 'removeData'},
    {sectionName: 'Capturar Datos', sectionType: 'getData'},
  ];

  const teamSection = [
    {sectionName: 'Agregar Equipo', sectionType: 'addTeam'},
    {sectionName: 'Eliminar Equipo', sectionType: 'removeTeam'},
  ];

  const serviceSection = [
    {sectionName: 'Agregar Servicio', sectionType: 'addService'},
    {sectionName: 'Modificar Servicio', sectionType: 'modifyService'},
    {sectionName: 'Eliminar Servicio', sectionType: 'deleteService'},
    {sectionName: 'Capturar Servicio', sectionType: 'getService'},
  ];

  const pageSection = [
    {sectionName: 'Crear Pagina', sectionType: 'addPage'},
    {sectionName: 'Copiar Pagina', sectionType: 'copyPage'},
    {sectionName: 'Modificar Pagina', sectionType: 'modifyPage'},
    {sectionName: 'Eliminar Pagina', sectionType: 'deletePage'},
  ];

  const editorSection = [
    {sectionName: 'Funcionamiento de Componente', sectionType: 'functionComponentPage'},
    {sectionName: 'Interfaz Grafica', sectionType: 'uiPage'},
  ];

  const mapperData = (e) => {
    setSectorValueString(e);
    const retVal = [];
    if (e === 'project' || e === 'users') {
      mainSection.map((index) => retVal.push(index));
      retVal.push({sectionName: 'otros', sectionType: 'other'});
      setMapSection(retVal);
    } else if (e === 'database') {
      mainSection.map((index) => retVal.push(index));
      secondarySection.map((index) => retVal.push(index));
      retVal.push({sectionName: 'otros', sectionType: 'other'});
      setMapSection(retVal);
    } else if (e === 'teams') {
      teamSection.map((index) => retVal.push(index));
      retVal.push({sectionName: 'otros', sectionType: 'other'});
      setMapSection(retVal);
    } else if (e === 'pages') {
      pageSection.map((index) => retVal.push(index));
      retVal.push({sectionName: 'otros', sectionType: 'other'});
      setMapSection(retVal);
    } else if (e === 'plugins') {
      serviceSection.map((index) => retVal.push(index));
      retVal.push({sectionName: 'otros', sectionType: 'other'});
      setMapSection(retVal);
    } else if (e === 'editor') {
      editorSection.map((index) => retVal.push(index));
      retVal.push({sectionName: 'otros', sectionType: 'other'});
      setMapSection(retVal);
    } else {
      retVal.push({sectionName: 'otros', sectionType: 'other'});
      setMapSection(retVal);
    }
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Necesita ayuda?
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { handleClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{padding: '10px', margin: '5px', width: '500px'}}>
          <form onSubmit={handleForm}>
            {projects && projects.length > 0
            && (
            <div className="mt-4">
              <TextField
                variant="outlined"
                value={projectValue}
                onChange={(e) => setProjectValue(e.target.value)}
                select
                fullWidth
                name="idProject"
                type="text"
                label="Proyecto"
              >
                {projects.map((index) => (
                  <MenuItem value={index._id}>{index.name}</MenuItem>
                ))}
              </TextField>
            </div>
            )}
            <div className="mt-4">
              <TextField
                fullWidth
                name="title"
                type="text"
                label="Titulo"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <TextField
                variant="outlined"
                value={sectorValueString}
                onChange={(e) => mapperData(e.target.value)}
                select
                fullWidth
                name="sector"
                type="text"
                label="Sector"
              >
                {sectors.map((index) => (
                  <MenuItem value={index.sectorType}>{index.sectorName}</MenuItem>
                ))}
              </TextField>
            </div>
            {sectorValueString && sectorValueString !== 'deploy'
            && (
            <div className="mt-4">
              <TextField
                variant="outlined"
                value={sectionSector}
                onChange={(e) => setSectionSector(e.target.value)}
                select
                fullWidth
                name="sectionPerSector"
                type="text"
                label="Seccion del Sector"
              >
                {mapSection.map((index) => (
                  <MenuItem value={index.sectionType}>{index.sectionName}</MenuItem>
                ))}
              </TextField>
            </div>
            )}
            <div className="mt-4">
              <TextField
                fullWidth
                multiline
                rows={4}
                name="description"
                type="text"
                label="Descripción"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4 text-center">
              <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle}>
                Guardar
              </Button>
            </div>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddSupportRequestModal;
