import React, {useState} from 'react';
import {
  // eslint-disable-next-line max-len
  Button, TextField, Select, MenuItem, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    backgroundColor: '#382f5d',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#2e274d',
    },
    minWidth: '120px',
  },
}));

const AddNotificationDialog = ({
  open, onClose, successFunction, errorFunction, token,
}) => {
  const classes = useStyles();
  const [notificationType, setNotificationType] = useState('all');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [specificUser, setSpecificUser] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendNotification = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-undef
      await sendNotification({
        token,
        notificationType,
        notificationMessage,
        specificUser: notificationType === 'specific' ? specificUser : null,
      });

      if (successFunction) {
        successFunction();
      }
      toastr.success('Notificación enviada correctamente');
      onClose();
    } catch (error) {
      if (errorFunction) {
        errorFunction(error);
      }
      toastr.error('Error al enviar la notificación');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Crear Notificación</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="notification-type-label">Tipo de Notificación</InputLabel>
          <Select
            labelId="notification-type-label"
            id="notification-type"
            value={notificationType}
            onChange={(e) => setNotificationType(e.target.value)}
          >
            <MenuItem value="all">Todos</MenuItem>
            <MenuItem value="specific">Usuario Específico</MenuItem>
          </Select>
        </FormControl>

        {notificationType === 'specific' && (
          <TextField
            id="specific-user"
            label="Usuario Específico"
            value={specificUser}
            onChange={(e) => setSpecificUser(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}

        <TextField
          id="notification-message"
          label="Mensaje de la Notificación"
          multiline
          rows={4}
          value={notificationMessage}
          onChange={(e) => setNotificationMessage(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className={classes.button} disabled={loading}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<SendIcon />}
          onClick={handleSendNotification}
          disabled={loading}
        >
          {loading ? 'Enviando...' : 'Enviar Notificación'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNotificationDialog;
