import {
  Button, Card, CardContent, Box,
} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
/* import CheckCircleIcon from '@material-ui/icons/CheckCircle'; */
import '../../styles/cardStyle.css';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    width: '300px',
    fontFamily: 'arial',
    fontStyle: 'normal',
    color: '#201549',
    boxShadow: '0px 4px 6px #1345A6!important',
    borderRadius: '5px!important',
  },
  itemTitle: {
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '42px',
    letterSpacing: '0em',
    textAlign: 'center',
    margin: '10px',
    color: '#fff',
  },
  boxTwo: {
    display: 'flex',
    alignItems: 'rigth',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingLeft: '2rem',
  },
  boxOne: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'row',
  },
  boxZero: {
    paddingTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  colorCardW: {
    color: '#201549',
  },
  boxTree: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '2rem',
  },
  priceContainerBox: {
    padding: '5px',
    margin: '5px',
  },
  priceCardWhite: {
    fontSize: '20px',
    lineHeight: '15px',
    textAlign: 'center',
    margin: '15px',
    color: '#fff',
  },
  btnInfo: {
    width: '195px',
    height: '30px',
    borderRadius: '5px',
    fontSize: '13px',
    lineHeight: '15px',
  },
  boxBtn: {
    paddingTop: '2rem',
    color: 'rgba(41, 178, 239, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  showBtn: {
    display: 'none',
  },
  hiddenBtn: {
    display: 'show',
  },
  recomienda: {
    padding: '0px',
    margin: '0px',
    fontSize: '20px!important',
    lineHeight: '23px',
  },
  containerTitle: {
    width: '250px',
    marginBottom: '10px',
    backgroundColor: '#201549',
    /*     display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center', */
  },
  itemBoxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemComponentTitle: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
  },
  itemDeployContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemDeployTextArea: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontWeight: 'bold',
  },
}));

const Item = ({itemName, items /* classStyle */}) => {
  const classes = useStyles();
  let ramData = '';
  let storageData = '';
  let vcpuData = '';
  if (items.ram === 0) {
    ramData = 'Compartida';
  } else {
    ramData = `${items.ram} GB`;
  }

  if (items.storage === 0) {
    storageData = 'Compartida';
  } else {
    storageData = `${items.storage} GB`;
  }

  if (items.vcpu === 0) {
    vcpuData = 'Compartida';
  } else {
    vcpuData = items.vcpu;
  }

  let itemData = [];

  if (itemName === 'Base de datos') {
    itemData = [
      /* {
        label: 'RAM',
        value: ramData,
      }, */
      {
        label: 'Almacenamiento de Datos',
        value: storageData,
      },
      /* {
        label: 'VCPU',
        value: vcpuData,
      }, */
    ];
  } else {
    itemData = [
      {
        label: 'RAM',
        value: ramData,
      },
      {
        label: 'VCPU',
        value: vcpuData,
      },
    ];
  }
  return (
    <Box>
      {/* <Box className={classStyle}>
        <CheckCircleIcon />
        {' '}
        {itemName}
      </Box> */}
      <ul>
        {itemData?.map((item) => (
          <li key={item.value}>
            <Box className={classes.boxZero}>
              <Box className={classes.boxOne}>
                {item.label}
              </Box>
              <Box className={classes.boxTwo}>
                <span>
                  {item.value}
                </span>
              </Box>
            </Box>
          </li>
        ))}
      </ul>
    </Box>
  );
};

const CardPlan = ({
  type,
  price,
  /* appweb,
  backoffice, */
  database,
  collabs,
  dataTest,
  /* verPage, */
  assetStorage,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.colorCardW}>
        <Box className={classes.containerTitle}>
          <p className={classes.itemTitle}>
            {type}
          </p>
          <Box className={classes.priceContainerBox}>
            <p className={classes.priceCardWhite}>
              {`HNL ${price.toLocaleString()}.00`}
            </p>
          </Box>
        </Box>
        <Box classname={classes.boxTree}>
          <Box className={classes.itemBoxContainer}>
            <p>Colaboradores</p>
            <p>{collabs}</p>
          </Box>
          <Box className={classes.itemBoxContainer}>
            <p>Data Test</p>
            <p>{dataTest}</p>
          </Box>
          {/* <Box className={classes.itemBoxContainer}>
            <p>Versiones de Páginas</p>
            <p>{verPage}</p>
          </Box> */}
          <Box className={classes.itemBoxContainer}>
            <p>Asset Storage</p>
            <p>{`${assetStorage} GB`}</p>
          </Box>
          {/* <Item
            itemName="App Web"
            classStyle={classes.itemComponentTitle}
            items={appweb}
          /> */}
          {/* <Item
            itemName="Backoffice"
            classStyle={classes.itemComponentTitle}
            items={backoffice}
          /> */}
          <Item
            itemName="Base de datos"
            classStyle={classes.itemComponentTitle}
            items={database}
          />
        </Box>
        <Box className={classes.boxBtn}>
          <Button onClick={onClick} variant="contained" size="small" className={classes.btnInfo}>Seleccionar</Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardPlan;
