/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import moment from 'moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import {
  getOneProject, getLogPaymentProject,
} from 'api-lofty';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import Tabular from '../components/Tabular';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const labelStatusPayment = (status) => {
  if (status === 'waiting') {
    return 'En proceso';
  } if (status === 'success') {
    return 'Exitoso';
  } if (status === 'cancel') {
    return 'cancelado';
  }
  return 'Ocurro un error';
};

const LogPaymentProject = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const [labelUrl, setLabelUrl] = useState('');
  const {idProject} = params;
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resLog = await getLogPaymentProject({
        token, _id: idProject,
      });
      setLabelUrl(`proyectos/${resProject.project.name}/Historial de Pago`);
      setData(resLog.data.map((item) => ({
        suscriptionName: item.suscription.label.toUpperCase(),
        referencePayment: item.objectAtlantida._id,
        datePayment: moment(item.createdAt).format('DD/MM/YYYY'),
        status: labelStatusPayment(item.status),
      })));
      setProject(resProject.project);
    } catch (error) {
      // code
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push('/dashboard')}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Historial de Pago
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          component="div"
          sx={{
            backgroundColor: '#fff',
            width: '100%',
            height: 'auto',
            p: 1,
            m: 1,
          }}
        >
          {
          data.length === 0 ? (
            <Grid item xs={12}>
              <h3 className="text-center">
                No hay datos
              </h3>
            </Grid>
          ) : (
            <div className="mt-2">
              <Tabular
                columns={[
                  {
                    title: 'Referencia de Pago',
                    field: 'referencePayment',
                  },
                  {
                    title: 'Suscripción',
                    field: 'suscriptionName',
                  },
                  {
                    title: 'Estatus',
                    field: 'status',
                  },
                  {
                    title: 'Fecha',
                    field: 'datePayment',
                  },
                ]}
                title=""
                data={data}
              />
            </div>
          )
        }
        </Box>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(LogPaymentProject);
