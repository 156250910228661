import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import toastr from 'toastr';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import {addFieldsDatabase, listDatabases, isInvalidSlug} from 'api-lofty';
// import {addFieldsDatabase} from '../api/fields.database.api';
import {fieldsLabelValue, stringTypeLabelValue} from '../util/typesFields';
// import imageCreate from '../assets/fields/create.svg';
// import {listDatabases} from '../api/database.api';
import 'toastr/build/toastr.min.css';
import {generateSlugSample} from '../util/generateSuggestionSlug';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    // overflow: 'auto',
    // maxHeight: 100,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  buttonSubmitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}));

const AddFieldDialog = ({
  open, onClose, successFunction, errorFunction,
  idDatabase, idProject, token, fieldData,
}) => {
  const classes = useStyles();
  const [checkedUnique, setCheckedUnique] = useState(false);
  const [checkedIndex, setCheckedIndex] = useState(false);
  const [checkedRequired, setCheckedRequired] = useState(false);
  const [checkedIsLabel, setCheckedIsLabel] = useState(false);
  const [checkedDefaultValues, setCheckedDefaultValues] = useState(false);
  const [loading, setLoading] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [typeField, setTypeField] = useState('');
  const [defaultValuesData, setDefaultValueData] = useState([]);
  const [defaultValueString, setDefaultValueString] = useState('');
  const [defaultValueName, setDefaultValueName] = useState('');
  const [continueAdding, setContinueAdding] = useState(false);
  const [typeString, setTypeString] = useState('standard');
  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [suggestionSlug, setSuggestionSlug] = useState('');
  const [errorInSlug, setErrorInSlug] = useState(false);
  const [errorMessageSlug, setErrorMessageSlug] = useState('');

  // FOR SLUG IN Default String values
  const [hasError, setHasError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  useEffect(async () => {
    try {
      const resDatabase = await listDatabases({token, idProject});
      setDatabases(resDatabase.data);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  function findData() {
    let retVal = false;
    if (defaultValuesData.find((element) => element.slug === defaultValueString)) {
      retVal = true;
    }
    return retVal;
  }

  const addDefaultValueChecker = () => {
    if (!findData() && !hasError) {
      const obj = {label: defaultValueName, slug: defaultValueString};
      setDefaultValueData((prevArray) => [...prevArray, obj]);
      setDefaultValueString('');
      setDefaultValueName('');
    } else if (hasError) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('error: \n El Slug no es valido'), 300);
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('error: \n Ya existe la variable. \n Verifique el slug'), 300);
    }
  };

  const removeDefaultValue = (value) => {
    setDefaultValueData((current) => current.filter((data) => data.value !== value));
  };

  const verifySlugDefaultValue = (e) => {
    const newValue = e.target.value;
    setDefaultValueString(e.target.value);
    if (isInvalidSlug(newValue)) {
      setErrorText('Solo letras minusculas y numeros son aceptados');
      setHasError(true);
    } else {
      setErrorText('');
      setHasError(false);
    }
  };

  const verifySlugValue = (value) => {
    if (isInvalidSlug(value)) {
      setErrorMessageSlug('Verifique que no tenga espacios/palabras reservadas');
      setErrorInSlug(true);
    } else {
      setErrorInSlug(false);
      setErrorMessageSlug('');
    }
  };

  function generateSuggestionSlug(value) {
    if (isEditingLabel) {
      const res = generateSlugSample(fieldData, value);
      setSuggestionSlug(res);
      setIsEditingLabel(false);
    }
  }

  function onCloseRefresh() {
    setErrorInSlug(false);
    setErrorMessageSlug('');
    setSuggestionSlug('');
    onClose();
  }

  return (
    <Dialog fullWidth open={open} onClose={() => onCloseRefresh()}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Campo
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onCloseRefresh(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          id="form-add-field-to-collection-lofty"
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await addFieldsDatabase({
                token,
                idDatabase,
                idProject,
                description: e.target.description.value,
                name: suggestionSlug,
                type: e.target.type.value,
                index: checkedIndex,
                required: checkedRequired,
                unique: checkedUnique,
                relationDatabase: e.target.relation?.value,
                label: e.target.label.value,
                isLabel: checkedIsLabel,
                arrayValueStringField: defaultValuesData,
                typeString,
              });
              if (successFunction) {
                setCheckedDefaultValues(false);
                setDefaultValueData([]);
                successFunction(continueAdding);
                if (continueAdding) {
                  document.getElementById('form-add-field-to-collection-lofty').reset();
                  setCheckedDefaultValues(false);
                  setCheckedUnique(false);
                  setCheckedIndex(false);
                  setCheckedRequired(false);
                  setCheckedIsLabel(false);
                  setTypeField('');
                  setDefaultValueData([]);
                  setDefaultValueString('');
                  setDefaultValueName('');
                  setSuggestionSlug('');
                }
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="standard"
              onFocus={() => setIsEditingLabel(true)}
              onBlur={(e) => generateSuggestionSlug(e.target.value)}
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              name="name"
              type="text"
              label="Slug (Identificador Unico)"
              variant="standard"
              value={suggestionSlug}
              onChange={(e) => setSuggestionSlug(e.target.value)}
              helperText={errorMessageSlug}
              error={errorInSlug}
              onBlur={(e) => verifySlugValue(e.target.value)}
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              multiline
              rows={4}
              name="description"
              type="text"
              label="Descripción"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField onChange={(e) => setTypeField(e.target.value)} value={typeField} select fullWidth name="type" type="text" label="Tipo" variant="standard">
              {
                fieldsLabelValue.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          {typeField === 'string' && (
            <div className="my-4">
              <TextField
                onChange={(e) => setTypeString(e.target.value)}
                value={typeString}
                select
                fullWidth
                name="typeString"
                type="text"
                label="Tipo de String"
                variant="standard"
              >
                {
                stringTypeLabelValue.map(({label, value}) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))
              }
              </TextField>
            </div>
          )}
          {typeField === 'string' && (
            <div className="my-4">
              <FormControlLabel
                control={
                  <Checkbox checked={checkedDefaultValues} onClick={() => setCheckedDefaultValues(!checkedDefaultValues)} name="checkedB" color="primary" />
                }
                label="Valores Por defecto"
              />
            </div>
          )}
          <Grid container className="my-4">
            <Grid item md={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedIndex}
                    onChange={() => setCheckedIndex((prev) => !prev)}
                  />
                )}
                label="Indexar"
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedUnique}
                    onChange={() => setCheckedUnique((prev) => !prev)}
                  />
                )}
                label="Unico"
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedRequired}
                    onChange={() => setCheckedRequired((prev) => !prev)}
                  />
                )}
                label="Requerido"
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={checkedIsLabel}
                    onChange={() => setCheckedIsLabel((prev) => !prev)}
                  />
                )}
                label="Etiqueta"
              />
            </Grid>
          </Grid>
          {
            typeField === 'relation' && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="relation"
                  type="text"
                  label="Relación de Colección de Datos"
                  variant="standard"
                >
                  {
                    databases.map(({_id, label}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {checkedDefaultValues && typeField === 'string' && (
            <div className="my-4">
              <div className="my-4">
                <b>Campos por defecto</b>
              </div>
              <div className="my-4">
                <TextField
                  fullWidth
                  type="text"
                  label="Etiqueta"
                  variant="standard"
                  value={defaultValueName}
                  onChange={(e) => setDefaultValueName(e.target.value)}
                />
              </div>
              <div className="my-4">
                <TextField
                  fullWidth
                  type="text"
                  label="Slug"
                  variant="standard"
                  value={defaultValueString}
                  onChange={verifySlugDefaultValue}
                  helperText={errorText}
                  error={hasError}
                />
              </div>
              <Box component="div" className="my-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addDefaultValueChecker}
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    backgroundColor: '#29B2EF',
                    fontFamily: [
                      'Nunito',
                      'Roboto',
                      'Helvetica Neue',
                      'Arial',
                      'sans-serif',
                    ].join(','),
                    textTransform: 'none',
                  }}
                >
                  Agregar Valor Por defecto
                </Button>
              </Box>
              <Box component="div" className="my-4">
                <List className={classes.root}>
                  {defaultValuesData.map((option) => (
                    <ListItem>
                      <ListItemText
                        primary={option.label}
                        secondary={option.slug}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => removeDefaultValue(option.value)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </div>
          )}
          <Box component="div" className={classes.buttonSubmitContainer}>
            <div className="text-center my-4">
              <button
                style={{
                  background: '#201549',
                }}
                type="submit"
                className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                disabled={loading}
                onClick={() => setContinueAdding(false)}
              >
                {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar y Cerrar
                    </>
                  )
                }
              </button>
            </div>
            <div className="text-center my-4">
              <button
                style={{
                  background: '#201549',
                }}
                type="submit"
                className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                disabled={loading}
                onClick={() => setContinueAdding(true)}
              >
                {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar y Continuar
                    </>
                  )
                }
              </button>
            </div>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddFieldDialog;
