import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Dialog, Box, IconButton,
  Button,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
}));

const AddNewPromotionCode = ({
  handleClose, open, handleForm,
}) => {
  const classes = useStyle();

  return (
    <Dialog open={open} handleClose={handleClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Nuevo Codigo Promocional
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { handleClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{padding: '10px', margin: '5px', width: '500px'}}>
          <form onSubmit={(e) => handleForm(e)}>
            <div className="mt-4">
              <TextField
                fullWidth
                name="code"
                type="text"
                label="Codigo"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <TextField
                fullWidth
                name="amount"
                type="number"
                label="Cantidad"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <Box>
                Fecha Inicio
              </Box>
              <TextField
                fullWidth
                name="initialDate"
                type="date"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <Box>
                Fecha Terminacion
              </Box>
              <TextField
                fullWidth
                name="endDate"
                type="date"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4 text-center">
              <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle}>
                Agregar
              </Button>
            </div>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewPromotionCode;
