import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Dialog, Box, IconButton,
  Button, MenuItem,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

const useStyle = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(15, 183, 208, 1) !important',
  },
  buttonStyle: {
    width: '150px',
    fontWeight: 'bold',
  },
}));

const AddSuggestionModal = ({
  handleClose, open, handleForm,
}) => {
  const classes = useStyle();
  const [sectorValueString, setSectorValueString] = useState('');
  /*
    'editor' | 'pages' | 'database' |
  'plugins' | 'teams' | 'users' | 'others'
  */
  const sectors = [
    {sectorName: 'Editor', sectorType: 'editor'},
    {sectorName: 'Base de datos', sectorType: 'database'},
    {sectorName: 'Paginas', sectorType: 'pages'},
    {sectorName: 'Plugins', sectorType: 'plugins'},
    {sectorName: 'Equipo', sectorType: 'teams'},
    {sectorName: 'Usuarios', sectorType: 'users'},
    {sectorName: 'Otros', sectorType: 'others'},
  ];
  return (
    <Dialog open={open} handleClose={handleClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Tiene alguna sugerencia para Lofty Apps?
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (handleClose) { handleClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" style={{padding: '10px', margin: '5px', width: '500px'}}>
          <form onSubmit={handleForm}>
            <div className="mt-4">
              <TextField
                fullWidth
                name="title"
                type="text"
                label="Titulo"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4">
              <TextField
                variant="outlined"
                value={sectorValueString}
                onChange={(e) => setSectorValueString(e.target.value)}
                select
                fullWidth
                name="sector"
                type="text"
                label="Sector"
              >
                {sectors.map((index) => (
                  <MenuItem value={index.sectorType}>{index.sectorName}</MenuItem>
                ))}
              </TextField>
            </div>
            <div className="mt-4">
              <TextField
                fullWidth
                multiline
                rows={4}
                name="description"
                type="text"
                label="Descripción"
                variant="outlined"
                inputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </div>
            <div className="mt-4 text-center">
              <Button type="submit" color="primary" variant="contained" className={classes.buttonStyle}>
                Guardar
              </Button>
            </div>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddSuggestionModal;
