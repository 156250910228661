import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import toastr from 'toastr';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import {
  createEndpointPayment, listMethodPaymentProject,
} from 'api-lofty';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    // overflow: 'auto',
    // maxHeight: 100,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  buttonSubmitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}));

const AddEndpointPayment = ({
  open, onClose, successFunction, errorFunction,
  idProject, token,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [methdoPayments, setMethodPayment] = useState([]);

  const handleMethodPayment = async () => {
    try {
      const res = await listMethodPaymentProject({
        token, project: idProject,
      });
      setMethodPayment(res.data);
    } catch (error) {
      if (error.info) {
        toastr.error(error.info);
      } else {
        toastr.error('Error al procesar la petición');
      }
    }
  };

  useEffect(() => {
    handleMethodPayment();
  }, []);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Servicio
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          id="form-add-field-to-collection-lofty"
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await createEndpointPayment({
                token,
                idMethodPayment: e.target.idMethodPayment.value,
                idProject,
                label: e.target.label.value,
                slug: e.target.slug.value,
                typeFunction: e.target.typeFunction.value,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Etiqueta"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField
              fullWidth
              name="slug"
              type="text"
              label="Identificador o Slug"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <TextField select fullWidth name="typeFunction" type="text" label="Tipo" variant="standard">
              <MenuItem value="one">
                Obtener un dato
              </MenuItem>
            </TextField>
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="idMethodPayment"
              type="text"
              label="Relación de Colección de Datos"
              variant="standard"
              onChange={(e) => setSelectedDatabase(e.target.value)}
              value={selectedDatabase}
            >
              {
                methdoPayments.map(({_id, label}) => (
                  <MenuItem value={_id} key={_id}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <Box component="div" className={classes.buttonSubmitContainer}>
            <div className="text-center my-4">
              <button
                style={{
                  background: '#201549',
                }}
                type="submit"
                className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                disabled={loading}
              >
                {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
              </button>
            </div>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddEndpointPayment;
