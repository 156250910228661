import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {listTeamUser, acceptTeam, removeTeam} from 'api-lofty';
import Layout from '../layouts/PrincipalDasboard';
// import {listTeamUser, acceptTeam, removeTeam} from '../api/team.api';
import LoadingPage from './LoadingPage';
import Card from '../components/CardUser';
import {labelPermission} from '../util/permissionTeam';

const InvitationPage = ({history, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(async () => {
    try {
      const response = await listTeamUser({
        token,
      });
      setData(response.data);
    } catch (error) {
      // C
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const handleUpdate = async () => {
    const response = await listTeamUser({
      token,
    });
    setData(response.data);
  };
  return (
    <Layout history={history} token={token}>
      <h2 style={{fontSize: '2rem'}} className="mb-4 text-center">
        <b>
          Invitaciones
        </b>
      </h2>
      <Grid container className="mt-2">
        {
          data.length === 0 ? (
            <Grid item xs={12}>
              <h4 className="text-center">
                No hay invitaciones
              </h4>
            </Grid>
          ) : (
            data.map((doc) => (
              <Grid key={doc._id} item xs={12} md={4}>
                <Card
                  name={doc.dataProject[0].name}
                  text={doc.dataProject[0].name[0]}
                  status={doc.status}
                  permission={labelPermission(doc.permission)}
                  handleAccept={async () => {
                    try {
                      await acceptTeam({_id: doc._id, token});
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Se Acepto la invitacion',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      handleUpdate();
                    } catch (error) {
                      if (error.info) {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: error.info,
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: 'Error no se pudo eliminar',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    }
                  }}
                  handleRemove={async () => {
                    try {
                      await removeTeam({
                        token,
                        _id: doc._id,
                        userId: doc.userId,
                      });
                      handleUpdate();
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Se cancelo el colaborador',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    } catch (error) {
                      if (error.info) {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: error.info,
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: 'Error no se pudo eliminar',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    }
                  }}
                />
              </Grid>
            ))
          )
        }
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(InvitationPage);
