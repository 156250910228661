import React, {useEffect, useState} from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {listSimulatorUserProject} from 'api-lofty';
import userChartIcon from '../img/usercharticon.png';

const UserStatistics = ({token, idProject}) => {
  const [userCount, setUserCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserStatistics = async () => {
      if (!token) {
        setError('Token no definido');
        return;
      }

      if (!idProject) {
        setError('ID del proyecto no definido');
        return;
      }

      try {
        const response = await listSimulatorUserProject({token, idProject});
        setUserCount(response.data.length);
      } catch (error) {
        setError('Error al cargar los datos de usuarios');
      }
    };

    fetchUserStatistics();
  }, [token, idProject]);

  return (
    <Box
      sx={{
        p: 3,
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        backgroundColor: '#fff',
        position: 'relative',
        width: '300px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '16px',
          left: '16px',
        }}
      >
        <img src={userChartIcon} alt="Icono de Usuarios" style={{width: '40px', height: '40px'}} />
      </Box>
      <Typography
        variant="h6"
        component="div"
        sx={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          color: '#372f5c',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        }}
      >
        Usuarios Registrados
      </Typography>
      <Box
        sx={{
          mt: 2,
          pt: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 1,
          }}
        >
          <Typography
            variant="body1"
            component="div"
            sx={{color: '#758095', fontWeight: 'normal', fontSize: '1.2rem'}}
          >
            Usuarios
          </Typography>
          <Typography
            variant="body1"
            component="div"
            sx={{color: '#758095', fontWeight: 'normal', fontSize: '1.2rem'}}
          >
            Total
          </Typography>
        </Box>
        <Divider sx={{my: 1}} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            component="div"
            sx={{color: '#6fcbf4', fontWeight: 'normal', fontSize: '1.2rem'}}
          >
            Registrados
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{color: '#6fcbf4', fontWeight: 'normal', fontSize: '1.2rem'}}
          >
            {error ? 'N/A' : userCount}
          </Typography>
        </Box>
        {error && (
          <Typography
            variant="body2"
            component="div"
            sx={{color: 'red', mt: 2, textAlign: 'center'}}
          >
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default UserStatistics;
