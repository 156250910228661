import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Button} from '@material-ui/core';
import Layout from '../layouts/PrincipalDasboard';
import LoadingPage from './LoadingPage';
import 'toastr/build/toastr.min.css';
import imgsuccess from '../assets/success.png';
import error from '../assets/error.png';

const useStyle = makeStyles((theme) => ({
  boxCard: {
    border: '1px solid #E2E2E2',
    backgroundColor: '#FCFCFC',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    minHeight: '60vh',
    [theme.breakpoints.down('550')]: {
      display: 'block',
    },
  },
  container: {
    width: '75%',
  },
  title: {
    fontWeight: 700,
    fontSize: 32,
    color: 'rgba(0, 0, 0, 1)',
    textAlign: 'center',
  },
  description: {
    fontWeight: 400,
    fontSize: 20,
    textAlign: 'center',
  },
  containerImage: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ListTemplates = ({history, token}) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(true);
  useEffect(async () => {
    try {
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setSuccess(false);
    }
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout token={token} history={history}>
      <Box>
        <Box className={classes.boxCard}>
          <div className={classes.container}>
            <div className={classes.containerImage}>
              {
                success ? (
                  <img alt="success" src={imgsuccess} />
                ) : (
                  <img alt="success" src={error} />
                )
              }
            </div>
            <h2 className={classes.title}>
              {
                success ? (
                  'Tu Compra se esta procesando'
                ) : (
                  'Tu Compra no se completo'
                )
              }
            </h2>
            <p className={classes.description}>
              {
                success ? (
                  'Recibiras un corre electrónico de confirmación del pago y puedes usar la plantilla para tu producto digital'
                ) : (
                  'La transacción no se pudo realizar'
                )
              }
            </p>
            <br />
            <div className={classes.containerImage}>
              <Button
                variant="contained"
                style={{
                  color: '#201549',
                  fontSize: '13px',
                  fontWeight: 500,
                  backgroundColor: '#29B2EF',
                  fontFamily: [
                    'Nunito',
                    'Roboto',
                    'Helvetica Neue',
                    'Arial',
                    'sans-serif',
                  ].join(','),
                  textTransform: 'none',
                }}
                onClick={() => {
                  history.push('/dashboard');
                }}
              >
                Ir a inicio
              </Button>
            </div>
          </div>
        </Box>
      </Box>
      {/* <Working /> */}
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ListTemplates);
