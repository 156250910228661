/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
// import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Box, Button,
} from '@material-ui/core';
import {
  verifyEmail,
} from 'api-lofty';
import {makeStyles} from '@material-ui/core/styles';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import AuthLayout from '../layouts/AuthLayout';

const useStyle = makeStyles((theme) => ({
  mainBoxContainer: {
    width: '100%',
    borderRadius: '20px',
    backgroundColor: '#F1F1F1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleVerifyEmailAccount: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),

    fontSize: '32px',
    fontWeight: 'bold',
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  buttonStyle: {
    width: '200px',
    fontWeight: 'bold',
  },
}));

const VerifyEmail = ({history, match}) => {
  const {params} = match;
  const {idVerify} = params;
  const [textDialog, setTextDialog] = useState('Se esta verificando el correo');
  const [successful, setSuccesful] = useState(false);
  const classes = useStyle();
  useEffect(async () => {
    try {
      await verifyEmail({token: idVerify});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 1000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Cuenta Activada'), 300);
      setTextDialog('Correo Verificado! Cuenta activada.');
      setSuccesful(true);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 5000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Error en activacion de cuenta.'), 300);
      setTextDialog('ERROR');
    }
  }, []);

  return (
    <AuthLayout>
      <Box component="div" className={classes.mainBoxContainer}>
        <Box component="div" className={classes.titleVerifyEmailAccount}>
          {textDialog}
        </Box>
        {successful && (
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={() => history.push('/')}
          >
            Ir a Plataforma
          </Button>
        )}
      </Box>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(VerifyEmail);
