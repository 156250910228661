/* eslint-disable no-underscore-dangle */
import React, {useEffect, useState} from 'react';
// import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import JoyRide from 'react-joyride';
import {
  Box,
  Button,
} from '@material-ui/core';
import {
  tutorialStatus, listCodePromotionforPartner, addCodePromotion,
  getTotalUsersLogPromotions, listPartner,
} from 'api-lofty';
import toastr from 'toastr';
import {deletetoken} from '../actions/auth.actions';
import DashboardLayout from '../layouts/PrincipalDasboard';
import ModalShowTutorial from '../components/ModalShowTutorial';
import {mainDashboardTutorial} from '../util/tutorialData';
import 'toastr/build/toastr.min.css';
import AddNewPromotionCode from '../components/AddNewPromotionCode';

const AffiliateDashboard = ({history, token}) => {
  const [openNewInPlatform, setOpenNewInPlatform] = useState(false);
  const [withTutorial, setWithTutorial] = useState(false);
  const [listPromotionCodes, setListPromotionCodes] = useState([]);
  const [logPromotionCodes, setLogPromotionCodes] = useState([]);
  const [openAddNewCode, setOpenAddNew] = useState(false);
  const [totalUser, setTotalUser] = useState(0);

  async function handleModalRes() {
    setOpenNewInPlatform(false);
    setWithTutorial(true);
    await tutorialStatus({token, typeTutorialUpdate: 'init'});
    window.localStorage.setItem('newInPlatformValue', 'true');
  }

  async function handleNoTutorial() {
    setOpenNewInPlatform(false);
    await tutorialStatus({token, typeTutorialUpdate: 'init'});
    window.localStorage.setItem('newInPlatformValue', 'true');
  }

  function handleRedoTutorial() {
    setWithTutorial(true);
  }

  async function loadPromotionCodes() {
    try {
      const partnerList = await listPartner({
        token,
      });
      const getAllPromotionCodes = await listCodePromotionforPartner({token});
      setListPromotionCodes(getAllPromotionCodes.data);
      setLogPromotionCodes([]);
      const partner = partnerList.data.find((item) => item.active === true);
      const resTotalUser = await getTotalUsersLogPromotions({
        token, partner: partner?._id,
      });
      setTotalUser(resTotalUser.data.total);
    } catch (error) {
      toastr.error(`${error?.info}`);
    }
  }

  async function generateCode(e) {
    e.preventDefault();
    try {
      await addCodePromotion({
        amount: e.target.amount.value,
        code: e.target.code.value,
        token,
        endDate: e?.target?.endDate?.value,
        initialDate: e?.target?.initialDate?.value,
      });
      toastr.success('Exito al agregar');
      loadPromotionCodes();
    } catch (error) {
      toastr.error('No se logro agregar el codigo');
    }
  }

  useEffect(() => {
    loadPromotionCodes();
  }, []);

  return (
    <DashboardLayout history={history} handleTutorial={() => handleRedoTutorial()} token={token}>
      <ModalShowTutorial
        open={openNewInPlatform}
        handleClose={() => setOpenNewInPlatform(false)}
        handleOpenTutorial={() => handleModalRes()}
        handleWithoutTutorial={() => handleNoTutorial()}
        textinfo="Desea ver un tutorial corto del inicio de la plataforma?"
      />
      <AddNewPromotionCode
        open={openAddNewCode}
        handleClose={() => setOpenAddNew(false)}
        handleForm={(e) => generateCode(e)}
      />
      <Box>
        {withTutorial
          && (
            <JoyRide
              continuous
              hideCloseButton
              scrollToFirstStep
              showProgress
              showSkipButton
              steps={mainDashboardTutorial}
              locale={{
                back: 'Anterior', close: 'Cerrar', last: 'Ultimo', next: 'Siguiente', open: 'Abrir dialogo', skip: 'Saltar',
              }}
              styles={{
                buttonNext: {
                  backgroundColor: 'rgba(41, 33, 81, 1)',
                },
                buttonBack: {
                  backgroundColor: 'rgba(41, 178, 239, 1)',
                  borderRadius: 4,
                  color: '#fff',
                },
              }}
            />
          )}
      </Box>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: '#00000000', width: '100%', minHeight: 500, p: 1, m: 1,
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          sx={{backgroundColor: '#fff'}}
          paddingX="3rem"
          paddingY="2rem"
          marginBottom="2rem"
          width="100%"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src="/icons/checkgreen.svg" alt="check mark" style={{marginRight: '1rem'}} />
            <span className="approved-affiliation-text">
              Tu solicitud de afiliación ha sido aprobada!
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2rem',
            width: '100%',
          }}
        >
          <Box
            width="65%"
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                color: '#fff',
                backgroundColor: '#201549',
                fontSize: '1.2rem',
                padding: '1rem',
              }}
            >
              GENERAR CÓDIGO DE AFILIACIÓN
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingX="2rem"
              paddingY="1.2rem"
              marginBottom="2rem"
              flexGrow="1"
              height="12rem"
              maxHeight="12rem"
              sx={{backgroundColor: '#fff'}}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="50%"
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{backgroundColor: '#F5F3F3', paddingX: '3rem', paddingY: '2rem'}}
                  >
                    <Button
                      variant="contained"
                      style={{
                        color: 'black',
                        fontSize: '13px',
                        backgroundColor: '#29B2EF',
                        fontWeight: 'bold',
                        fontFamily: [
                          'Nunito',
                          'Roboto',
                          'Helvetica Neue',
                          'Arial',
                          'sans-serif',
                        ].join(','),
                        textTransform: 'none',
                      }}
                      onClick={() => setOpenAddNew(true)}
                    >
                      Generar Código
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="50%"
                height="100%"
                overflow="auto"
                paddingTop="2rem"
                sx={{
                  scroll: '2rem',
                }}
              >
                {
                  listPromotionCodes.map((index) => (
                    <Box display="flex" height="100%" width="100%" paddingLeft="2rem">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        sx={{borderBottom: '2px solid #E6E6E6'}}
                        height="2rem"
                      >
                        <Box sx={{fontWeight: 'bold'}}>
                          {index.code}
                        </Box>
                        <Button syle={{borderBottom: '1px solid #E6E6E6', padding: '0'}}>
                          <img src="/icons/copy.svg" alt="copy icon" style={{maxHeight: '2rem'}} />
                        </Button>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                color: '#fff', backgroundColor: '#201549', fontSize: '1.2rem', padding: '1rem',
              }}
            >
              CANTIDAD DE USUARIOS REFERIDOS
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              paddingX="5rem"
              paddingY="1.2rem"
              marginBottom="2rem"
              flexGrow="1"
              sx={{backgroundColor: '#fff'}}
            >
              <Box display="flex" flexDirection="column" justifyContent="center" width="100%" marginBottom="0.5rem">
                <Box
                  className="affiliate-count-number"
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  sx={{width: '100%', marginBottom: '0.4rem'}}
                >
                  {totalUser}
                </Box>
                <Box
                  className="affiliate-count-label"
                  display="flex"
                  justifyContent="center"
                  width="100%"
                >
                  Usuarios
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{width: '100%'}}
        >
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              color: '#fff',
              backgroundColor: '#201549',
              fontSize: '1.2rem',
              padding: '1rem',
            }}
          >
            HISTORIAL DE CÓDIGOS DE AFILIACIÓN
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            paddingX="5rem"
            paddingY="1.2rem"
            sx={{backgroundColor: '#fff'}}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
              marginBottom="0.5rem"
              sx={{gap: '1rem'}}
            >
              <Box
                className="affiliate-date-label"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  marginBottom: '0.4rem', fontSize: '1rem', marginRight: '0rem',
                }}
              >
                Fecha desde:
              </Box>
              <input className="affiliate-date-input" type="date" />
              <Box
                className="affiliate-date-label"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  marginBottom: '0.4rem', fontSize: '1rem', marginRight: '0rem',
                }}
              >
                Hasta:
              </Box>
              <input className="affiliate-date-input" type="date" />
              <Button
                variant="contained"
                style={{
                  color: 'black',
                  fontSize: '13px',
                  backgroundColor: '#29B2EF',
                  fontWeight: 'bold',
                  fontFamily: [
                    'Nunito',
                    'Roboto',
                    'Helvetica Neue',
                    'Arial',
                    'sans-serif',
                  ].join(','),
                  textTransform: 'none',
                  height: '1.5rem',
                  width: '2rem',
                  maxWidth: '2rem',
                }}
              >
                <img src="/icons/search.svg" alt="search icon" style={{padding: '0.5rem'}} />
              </Button>
            </Box>

            <table className="referrals-table">
              <thead>
                <tr className="referrals-header">
                  <th className="referrals-table-header">Códigos Promocionales</th>
                  <th className="referrals-table-header">Tipo de Comisión</th>
                  <th className="referrals-table-header">Comisiones</th>
                  <th className="referrals-table-header">Usuario</th>
                  <th className="referrals-table-header">Rango de Fechas</th>
                </tr>
              </thead>
              <tbody className="referrals-table-body">
                {logPromotionCodes.map((index) => (
                  <tr className="referrals-row">
                    <td className="referrals-table-code">{index?.code}</td>
                    <td className="referrals-table-other">{index?.active}</td>
                    <td className="referrals-table-other">{index?.amount}</td>
                    <td className="referrals-table-other">{index?.partner}</td>
                    <td className="referrals-table-other">{index?.date}</td>
                  </tr>
                ))}
                {/* <tr className="referrals-row">
                  <td className="referrals-table-code">Promo123</td>
                  <td className="referrals-table-other">Descuento 10%</td>
                  <td className="referrals-table-other">$100</td>
                  <td className="referrals-table-other">Juan</td>
                  <td className="referrals-table-other">01/01/2024 - 31/01/2024</td>
                </tr>
                <tr className="referrals-row">
                  <td className="referrals-table-code">Promo456</td>
                  <td className="referrals-table-other">Descuento 15%</td>
                  <td className="referrals-table-other">$200</td>
                  <td className="referrals-table-other">Maria</td>
                  <td className="referrals-table-other">01/02/2024 - 28/02/2024</td>
                </tr>
                <tr className="referrals-row">
                  <td className="referrals-table-code">Promo456</td>
                  <td className="referrals-table-other">Descuento 15%</td>
                  <td className="referrals-table-other">$200</td>
                  <td className="referrals-table-other">Maria</td>
                  <td className="referrals-table-other">01/02/2024 - 28/02/2024</td>
                </tr>
                <tr className="referrals-row">
                  <td className="referrals-table-code">Promo456</td>
                  <td className="referrals-table-other">Descuento 15%</td>
                  <td className="referrals-table-other">$200</td>
                  <td className="referrals-table-other">Maria</td>
                  <td className="referrals-table-other">01/02/2024 - 28/02/2024</td>
                </tr>
                <tr className="referrals-row">
                  <td className="referrals-table-code">Promo456</td>
                  <td className="referrals-table-other">Descuento 15%</td>
                  <td className="referrals-table-other">$200</td>
                  <td className="referrals-table-other">Maria</td>
                  <td className="referrals-table-other">01/02/2024 - 28/02/2024</td>
                </tr>
                <tr className="referrals-row">
                  <td className="referrals-table-code">Promo456</td>
                  <td className="referrals-table-other">Descuento 15%</td>
                  <td className="referrals-table-other">$200</td>
                  <td className="referrals-table-other">Maria</td>
                  <td className="referrals-table-other">01/02/2024 - 28/02/2024</td>
                </tr>
                <tr className="referrals-row">
                  <td className="referrals-table-code">Promo456</td>
                  <td className="referrals-table-other">Descuento 15%</td>
                  <td className="referrals-table-other">$200</td>
                  <td className="referrals-table-other">Maria</td>
                  <td className="referrals-table-other">01/02/2024 - 28/02/2024</td>
                </tr>
                <tr className="referrals-row">
                  <td className="referrals-table-code">Promo456</td>
                  <td className="referrals-table-other">Descuento 15%</td>
                  <td className="referrals-table-other">$200</td>
                  <td className="referrals-table-other">Maria</td>
                  <td className="referrals-table-other">01/02/2024 - 28/02/2024</td>
                </tr>
                <tr className="referrals-row">
                  <td className="referrals-table-code">Promo456</td>
                  <td className="referrals-table-other">Descuento 15%</td>
                  <td className="referrals-table-other">$200</td>
                  <td className="referrals-table-other">Maria</td>
                  <td className="referrals-table-other">01/02/2024 - 28/02/2024</td>
                </tr> */}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateDashboard);
