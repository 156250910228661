/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
  createSessionPaymentBancoAtlantida,
} from 'api-lofty';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StorageIcon from '@material-ui/icons/Storage';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListMembership from './ListMembership';
import ResumeProject from './ResumeProject';
import VerifySuccess from './verifySuccess';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const {active, completed} = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgba(41, 178, 239, 1) 0%,rgba(19, 69, 166, 1) 50%,rgba(41, 178, 239, 1) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgba(41, 178, 239, 1) 0%,rgba(19, 69, 166, 1) 50%,rgba(41, 178, 239, 1) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgba(41, 178, 239, 1) 0%, rgba(19, 69, 166, 1) 50%, rgba(41, 178, 239, 1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgba(41, 178, 239, 1) 0%, rgba(19, 69, 166, 1) 50%, rgba(41, 178, 239, 1) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const {active, completed} = props;

  const icons = {
    1: <StorageIcon />,
    2: <CreditCardIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    'Aplicación Web',
    'Resumen',
  ];
}

export default function CustomizedSteppers({
  token, suscriptions, idProject, project,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [showVerifiedSucces, setShowVerifiedSuccess] = React.useState(false);
  const [suscription, setSuscription] = useState({});
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextSuscription = async (doc) => {
    try {
      setSuscription(doc);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (error) {
      if (error.info) {
        Swal.fire({
          icon: 'error',
          text: error.info,
        });
      } else if (error.response) {
        Swal.fire({
          icon: 'error',
          text: error.response.data.info,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Lo sentimos no puedes continuar con el proceso de despliegue, por favor contacto con soporte técnico',
        });
      }
    }
  };

  const handleNextEnd = async () => {
    try {
      const res = await createSessionPaymentBancoAtlantida({
        idProject,
        token,
        idSuscription: suscription._id,
      });
      if (!res.free) {
        window.location.href = res.data.processUrl;
      } else {
        alert('Se esta procesando la publicación de tu producto digital');
        window.location.href = `/dashboard/project/${idProject}/process/${res.idDeployment}`;
      }
    } catch (error) {
      // console.log(error);
      if (error.info) {
        Swal.fire({
          icon: 'error',
          text: error.info,
        });
      } else if (error.response) {
        Swal.fire({
          icon: 'error',
          text: error.response.data.info,
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Lo sentimos no puedes continuar con el proceso de despliegue, por favor contacto con soporte técnico',
        });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent() {
    switch (activeStep) {
      case 0:
        return <ListMembership handleNext={handleNextSuscription} items={suscriptions} />;
      case 1:
        return (
          <ResumeProject
            suscription={suscription}
            handleNext={handleNextEnd}
            handlePrevious={handleBack}
            project={project}
            token={token}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      <div>
        <VerifySuccess
          open={showVerifiedSucces}
          handleClose={() => setShowVerifiedSuccess(!showVerifiedSucces)}
        />
      </div>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>{getStepContent()}</div>
            <div className="text-center">
              {
                (activeStep > 3 && activeStep !== 4) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Final' : 'Siguiente'}
                  </Button>
                )
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
