/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {
  Typography, IconButton, Grid,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  listSimulatorUserProject, listProjectSimulatorGeneratePdf,
  deleteProjectSimulatorGeneratePdf,
} from 'api-lofty';
import Tabular from '../Tabular';
import Layout from '../../layouts/PluginsProjectDashboard';
import LoadingPage from '../../pages/LoadingPage';

const useStyle = makeStyles((theme) => ({
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const SimulatorPdf = ({
  history, match, token, dataProject, dataPluginProject, labelUrl,
}) => {
  const {params} = match;
  const {idProject, idPluginsProject} = params;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [colums, setColums] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const classes = useStyle();
  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };
  const updateInfo = async () => {
    const resData = await listSimulatorUserProject({token, idProject});
    const resSimulatorPlugin = await listProjectSimulatorGeneratePdf({
      idProject, token,
    });
    const newData = [];
    resSimulatorPlugin.data.forEach((item) => {
      const exists = resData.data.find((item2) => item._id === item2.loftyUserId);
      if (exists) {
        const doc = {
          dataUserLoftyEmail: exists.loftyEmail,
        };
        newData.push({...item, ...doc});
      }
    });
    setData(newData);
  };
  useEffect(async () => {
    try {
      const resData = await listSimulatorUserProject({token, idProject});
      const resSimulatorPlugin = await listProjectSimulatorGeneratePdf({
        token, idProject,
      });
      // AGREGANDO COLUM
      const newColumn = [];
      newColumn.push({
        title: 'Version',
        field: 'namePluginVersion',
      });
      newColumn.push({
        title: 'Archivo',
        field: 'loftyOriginalName',
      });
      newColumn.push({
        title: 'Usuario',
        field: 'dataUserLoftyEmail',
      });
      newColumn.push({
        title: 'Acciones',
        field: '_id',
        render: (rowData) => (
          <IconButton
            onClick={() => handleOpenDeleteDialog(rowData._id)}
          >
            <DeleteIcon />
          </IconButton>
        ),
      });
      const newData = [];
      resSimulatorPlugin.data.forEach((item) => {
        const exists = resData.data.find((item2) => item._id === item2.loftyUserId);
        if (exists) {
          const doc = {
            dataUserLoftyEmail: exists.loftyEmail,
          };
          newData.push({...item, ...doc});
        }
      });
      setData(newData);
      setColums(newColumn);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idPluginProject={idPluginsProject}
      pluginProjectType={dataPluginProject?.type}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <div className="text-right mb-2">
        <Button onClick={updateInfo} variant="contained" color="secondary">
          Actualizar Datos
        </Button>
      </div>
      <Tabular
        title="Archivos"
        columns={colums}
        data={data}
        ready={loading}
      />
      <div>
        <Dialog open={openDialog}>
          <DialogTitle>
            Eliminar
          </DialogTitle>
          <DialogContent>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              ¿Estás seguro de eliminar este campo?
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    try {
                      await deleteProjectSimulatorGeneratePdf({
                        _id: idDeletion,
                        idProject,
                        token,
                      });
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Se elimino el dato',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setOpenDialog(false);
                      updateInfo();
                    } catch (error) {
                      if (error.info) {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: error.info,
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: 'Error no se pudo eliminar',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    }
                  }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(SimulatorPdf);
