/* eslint-disable no-alert */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {listUserProject, getOneProject, listTeamUser} from 'api-lofty';
import {
  Grid, MenuItem,
  ListItemIcon, Typography,
  MenuList, Box, Hidden,
} from '@material-ui/core';
/* import ReceiptIcon from '@material-ui/icons/Receipt'; */
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Layout from './Dashboard';
import AddFieldSlugProject from '../components/AddFieldSlugProject';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import exitImg from '../img/icons/salir.svg';
import imgDashboard1 from '../img/icons/dasboard_w.svg';
import imgDashboard2 from '../img/icons/dasboard_b.svg';
import imgDatabase1 from '../img/icons/basedatos_w.svg';
import imgDatabase2 from '../img/icons/basedatos_b.svg';
import imgPages1 from '../img/icons/paginas_w.svg';
import imgPages2 from '../img/icons/paginas_b.svg';
import imgTeam1 from '../img/icons/equipo_w.svg';
import imgTeam2 from '../img/icons/equipo_b.svg';
import imgUser1 from '../img/icons/usuarios_w.svg';
import imgUser2 from '../img/icons/usuarios_b.svg';
import imgPlugin1 from '../img/icons/plugin_w.svg';
import imgPlugin2 from '../img/icons/plugin_b.svg';
import imgApi1 from '../img/icons/api_w.svg';
import imgApi2 from '../img/icons/api_b.svg';
import imgMulti1 from '../img/icons/multimedia_w.svg';
import imgMulti2 from '../img/icons/multimedia_b.svg';
import imgActivity1 from '../img/icons/actividad_w.svg';
import imgActivity2 from '../img/icons/actividad_b.svg';
import imgConfig1 from '../img/icons/configuracion_w.svg';
import imgConfig2 from '../img/icons/configuracion_b.svg';
import imgDeploy1 from '../img/icons/despliegue_w.svg';
import imgDeploy2 from '../img/icons/despliegue_b.svg';

const StyledMenuDashboard = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgDashboard1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgDashboard2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgDashboard2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuDatabase = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgDatabase1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgDatabase2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgDatabase2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuPages = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgPages1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgPages2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgPages2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuTeam = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgTeam1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgTeam2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgTeam2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuUser = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgUser1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgUser2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgUser2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuPlugin = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgPlugin1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgPlugin2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgPlugin2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuApi = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgApi1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgApi2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgApi2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuMultimedia = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgMulti1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgMulti2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgMulti2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuActivity = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgActivity1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgActivity2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgActivity2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuConfig = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgConfig1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgConfig2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgConfig2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuDeploy = withStyles((theme) => ({
  root: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgDeploy1})`,
      width: '30px',
      height: '30px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgDeploy2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgDeploy2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const useStyle = makeStyles((theme) => ({
  exitItemTypo: {
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '18px',
    marginLeft: '6%',
    marginTop: '10%',
    [theme.breakpoints.down('lg')]: {fontSize: '13px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  fontItemsStyle: {
    fontSize: '21px',
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('1280')]: {fontSize: '11px'},
    [theme.breakpoints.down('1100')]: {fontSize: '9px'},
    [theme.breakpoints.down('950')]: {fontSize: '12px'},
  },
  exitIconStyle: {
    zIndex: 1,
    marginTop: '40px',
    marginBottom: '10%',
    marginLeft: '15%',
    width: '50px',
    height: 'auto',
    [theme.breakpoints.down('lg')]: {width: '30px'},
  },
}));

const PrincipalDashboard = ({
  children, history,
  idProject, token, user,
  handleTutorial,
}) => {
  // console.log(projectName); WAIT UNTIL USE
  // eslint-disable-next-line no-unused-vars
  const [isUserProject, setIsUserProject] = useState(false);
  const [belonger, setBelonger] = useState(false);
  const [openSlug, setOpenSlug] = useState(false);
  const [adminUser, setAdminUser] = useState(false);
  const [project, setProject] = useState({});
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resUserProject = await listUserProject({idProject, token});
      const resTeam = await listTeamUser({token});
      resTeam.data.forEach((index) => {
        if (index.dataProject[0]._id === idProject && index.permission === 'admin') {
          setAdminUser(true);
        }
      });
      getOneProject({token, idProject}).then((res) => {
        const {project} = res;
        if (!project.slug) {
          setOpenSlug(true);
        }
        setBelonger(project.userId === user._id);
        setProject(project);
      });
      if (resUserProject.data.length > 0) {
        setIsUserProject(true);
      } else {
        setIsUserProject(false);
      }
    } catch (error) {
      setIsUserProject(false);
    }
  }, []);

  const DrawerMenu = (
    <Box>
      <Link to="/dashboard">
        <Box
          component="img"
          src={imgLogo}
          alt="logo"
          style={{
            zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
          }}
        />
      </Link>
      <MenuList
        autoFocusItem
        style={{
          marginLeft: '3%',
          // overflowY: 'scroll',
        }}
      >
        <StyledMenuDashboard
          selected={window.location.pathname === `/dashboard/project/${idProject}`}
          onClick={() => history.push(`/dashboard/project/${idProject}`)}
          id="dashboard_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Dashboard
          </Typography>
        </StyledMenuDashboard>
        <StyledMenuDatabase
          selected={window.location.pathname === `/dashboard/project/${idProject}/database`}
          onClick={() => history.push(`/dashboard/project/${idProject}/database`)}
          id="database_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Colección de Datos
          </Typography>
        </StyledMenuDatabase>
        <StyledMenuPages
          selected={window.location.pathname === `/dashboard/project/${idProject}/page`}
          onClick={() => history.push(`/dashboard/project/${idProject}/page`)}
          id="pages_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Páginas
          </Typography>
        </StyledMenuPages>
        <StyledMenuUser
          selected={window.location.pathname === `/dashboard/project/${idProject}/users`}
          onClick={() => history.push(`/dashboard/project/${idProject}/users`)}
          id="users_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Usuarios
          </Typography>
        </StyledMenuUser>
        {
          belonger && (
            <StyledMenuTeam
              selected={window.location.pathname === `/dashboard/project/${idProject}/team`}
              onClick={() => history.push(`/dashboard/project/${idProject}/team`)}
              id="teams_platform"
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Equipo
              </Typography>
            </StyledMenuTeam>
          )
        }
        <StyledMenuPlugin
          // Commit
          selected={window.location.pathname === `/dashboard/project/${idProject}/plugins` || window.location.pathname === `/dashboard/project/${idProject}/plugins/addplugins`}
          onClick={() => history.push(`/dashboard/project/${idProject}/plugins`)}
          id="plugins_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Plugins
          </Typography>
        </StyledMenuPlugin>
        <StyledMenuDashboard
          selected={window.location.pathname === `/dashboard/project/${idProject}/methodpayment`}
          onClick={() => history.push(`/dashboard/project/${idProject}/methodpayment`)}
          id="payment_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Metodo de Pago
          </Typography>
        </StyledMenuDashboard>
        <StyledMenuDashboard
          selected={window.location.pathname === `/dashboard/project/${idProject}/cronjobs`}
          onClick={() => history.push(`/dashboard/project/${idProject}/cronjobs`)}
          id="payment_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Cron Jobs
          </Typography>
        </StyledMenuDashboard>
        <StyledMenuApi
          selected={window.location.pathname === `/dashboard/project/${idProject}/apikeytesting`}
          onClick={() => history.push(`/dashboard/project/${idProject}/apikeytesting`)}
          id="api_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            API Key Test
          </Typography>
        </StyledMenuApi>
        <StyledMenuMultimedia
          selected={window.location.pathname === `/dashboard/project/${idProject}/assets`}
          onClick={() => history.push(`/dashboard/project/${idProject}/assets`)}
          id="media_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Multimedia
          </Typography>
        </StyledMenuMultimedia>
        <StyledMenuActivity
          selected={window.location.pathname === `/dashboard/project/${idProject}/activities`}
          onClick={() => history.push(`/dashboard/project/${idProject}/activities`)}
          id="logsproject_platform"
        >
          <ListItemIcon />
          <Typography className={classes.fontItemsStyle}>
            Actividad
          </Typography>
        </StyledMenuActivity>
        {
          (belonger || adminUser) && (
            <StyledMenuConfig
              selected={window.location.pathname === `/dashboard/project/${idProject}/config`}
              onClick={() => history.push(`/dashboard/project/${idProject}/config`)}
              id="config_platform"
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Configuración
              </Typography>
            </StyledMenuConfig>
          )
        }
        {
          belonger && (
            <StyledMenuConfig
              selected={window.location.pathname === `/dashboard/project/${idProject}/logpayment`}
              onClick={() => history.push(`/dashboard/project/${idProject}/logpayment`)}
              id="log_payment"
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Historial de Pago
              </Typography>
            </StyledMenuConfig>
          )
        }
        {
          project.deployed ? (
            <StyledMenuDeploy
              selected={window.location.pathname === `/dashboard/project/${idProject}/deployed`}
              onClick={() => history.push(`/dashboard/project/${idProject}/deployed`)}
              id="deploy_platform"
            >
              <ListItemIcon />
              <Typography className={classes.fontItemsStyle}>
                Producción
              </Typography>
            </StyledMenuDeploy>
          ) : <></>
        }
        {
          belonger && (
            <>
              {
                project.deployed ? (
                  <></>
                ) : (
                  <StyledMenuDeploy
                    selected={window.location.pathname === `/dashboard/project/${idProject}/deployment`}
                    onClick={() => history.push(`/dashboard/project/${idProject}/deployment`)}
                    id="deploy_platform"
                  >
                    <ListItemIcon />
                    <Typography className={classes.fontItemsStyle}>
                      Despliegue
                    </Typography>
                  </StyledMenuDeploy>
                )
              }
            </>
          )
        }
      </MenuList>
      <Box
        component="div"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          alignItems: 'flex-end',
          minHeight: '100px',
        }}
      >
        <Link to="/dashboard">
          <Box
            component="img"
            src={exitImg}
            alt="logo"
            className={classes.exitIconStyle}
          />
        </Link>
        <Typography className={classes.exitItemTypo}>
          Salir del Proyecto
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      {/* Validacion para el proyecto */}
      <AddFieldSlugProject
        idProject={idProject}
        token={token}
        open={openSlug}
        successFunction={() => {
          setOpenSlug(false);
        }}
        errorFunction={(error) => {
          if (error.info) {
            alert(error.info);
          } else {
            alert('Lo sentimos no se puedo guardar el dato, por favor contacte con soporte tecnico');
          }
        }}
      />
      <Grid container>
        <Hidden smDown>
          <Grid item md={2} style={{backgroundColor: '#144999', position: 'relative'}}>
            {DrawerMenu}
          </Grid>
        </Hidden>
        <Grid className="p-3" item sm={12} md={10} xs={12} style={{backgroundColor: '#F5F5F5'}}>
          <Layout data={DrawerMenu} toTutorial={handleTutorial}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
});

export default connect(mapStateToProps)(PrincipalDashboard);
