/* eslint-disable consistent-return */

export const blocklyVariableSeeker = (
  block, listDatabases, fieldData, originPoint, listVariables, setListVariables,
) => {
  if (block.type === 'main_block') {
    if (block.inputs?.main_block?.block) {
      blocklyVariableSeeker(
        block.inputs.main_block.block,
        listDatabases,
        fieldData,
        originPoint,
        listVariables,
        setListVariables,
      );
      return listVariables;
    }
  }
  // if (block.type === 'list_database_block') {
  //   const getSelectedCollection = block?.fields?.listCollection;
  //   if (block?.inputs?.listFieldDatabaseAdd?.block) {
  //     const seekIssue = blocklyAnalyzer(
  //       block?.inputs?.listFieldDatabaseAdd.block,
  //       listDatabases,
  //       fieldData,
  //       getSelectedCollection,
  //       listVariables,
  //       setListVariables,
  //     );
  //     if (seekIssue) {
  //       return seekIssue;
  //     }
  //   }
  // }
  if (block.type === 'object_database_block') {
    const getSelectedCollection = block?.fields?.getOne;
    if (block?.inputs?.objectDatabaseList?.block) {
      blocklyVariableSeeker(
        block?.inputs?.objectDatabaseList.block,
        listDatabases,
        fieldData,
        getSelectedCollection,
        listVariables,
        setListVariables,
      );
    }
  }
  if (block.type === 'variables_get') {
    const getReferenceDatabase = listDatabases.find((index) => (index._id === originPoint));
    if (getReferenceDatabase) {
      const seekCurrentFields = fieldData.find(
        (index) => (index.collection.id === getReferenceDatabase.name),
      );
      listVariables.push({id: block.fields?.VAR?.id, fieldinfo: seekCurrentFields?.fields});
    }
  }

  if (block.inputs?.then) {
    blocklyVariableSeeker(
      block.inputs?.then.block,
      listDatabases,
      fieldData,
      originPoint,
      listVariables,
      setListVariables,
    );
  }

  if (block.next?.block) {
    blocklyVariableSeeker(
      block.next.block,
      listDatabases,
      fieldData,
      originPoint,
      listVariables,
      setListVariables,
    );
  }
};
