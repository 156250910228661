import React, {useState} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {TextField, IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {addDatabase} from 'api-lofty';
import Layout from '../layouts/Dashboard';
import imageDatabse from '../assets/database/create.svg';
// import {addDatabase} from '../api/database.api';

const CreateProject = ({history, token, match}) => {
  const [loading, setLoading] = useState(false);
  const [isBucket, setIsBucket] = useState(false);
  const {params} = match;
  const {idProject} = params;
  return (
    <Layout history={history}>
      <div>
        <IconButton onClick={() => history.push(`/dashboard/project/${idProject}`)}>
          <ArrowBackIcon style={{fontSize: '4rem', color: 'black'}} />
        </IconButton>
      </div>
      <div className="text-center mb-2">
        <img
          alt="Crear"
          src={imageDatabse}
          style={{
            margin: 'auto',
            width: '350px',
          }}
        />
        <h2 style={{fontSize: '3rem'}}>
          <b>
            Crea tu Colección de Datos
          </b>
        </h2>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          addDatabase({
            token,
            idProject,
            name: e.target.name.value,
            description: e.target.description.value,
            production: false,
            label: e.target.label.value,
            isBucket,
          }).then(() => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Se creo la base de datos',
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
            history.push(`/dashboard/project/${idProject}`);
          }).catch((error) => {
            if (error.info) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.info,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Lo sentimos no se pudo crear la base de datos',
                showConfirmButton: false,
                timer: 1500,
              });
            }
            setLoading(false);
          });
        }}
      >
        <div>
          <TextField
            fullWidth
            name="label"
            type="text"
            label="Etiqueta"
            variant="standard"
          />
        </div>
        <div className="mt-4">
          <TextField
            fullWidth
            name="name"
            type="text"
            label="Slug"
            variant="standard"
          />
        </div>
        <div className="mt-4">
          <FormControlLabel
            control={(
              <Switch
                checked={isBucket}
                onChange={() => setIsBucket((prev) => !prev)}
              />
                )}
            label="Almacenador de Archivos"
          />
        </div>
        <div className="mt-4">
          <TextField
            multiline
            fullWidth
            name="description"
            type="text"
            label="Descripción"
            rows={3}
            variant="standard"
          />
        </div>
        <div className="text-center mt-4 mb-4">
          <button
            style={{
              background: '#201549',
            }}
            type="submit"
            className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
            disabled={loading}
          >
            {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
          </button>
        </div>
      </form>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(CreateProject);
