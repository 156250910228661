import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import imgBackground from '../img/bg_login/image_1.png';

const useStyle = makeStyles((theme) => ({
  containerMain: {
    display: 'flex',
    backgroundColor:
    '#ffffff',
    [theme.breakpoints.up('xs')]: {width: '80%', height: '80%'},
    [theme.breakpoints.down('xs')]: {width: '100%', height: '100%'},
  },
  containerImage: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${imgBackground})`,
    backgroundSize: 'cover',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {height: 'auto', minHeight: '100vh'},
  },
}));

const AuthLayout = ({children}) => {
  const classes = useStyle();
  return (
    <Box
      component="div"
      className={classes.containerImage}
    >
      <Box component="div" className={classes.containerMain}>
        {children}
      </Box>
    </Box>
  );
};

export default AuthLayout;
