import React, {useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
// eslint-disable-next-line object-curly-newline
import {addLogPromotion} from 'api-lofty';
import toastr from 'toastr';
import {
  Box, Button, TextField, Typography,
} from '@material-ui/core';
import DashboardLayout from '../layouts/PrincipalDasboard';
import promocodeImage from '../img/promocode.png';
import 'toastr/build/toastr.min.css';

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: 'Arial, sans-serif',
  },
  promoTitle: {
    fontWeight: 'bold',
    color: 'black',
  },
  promoSubtitle: {
    color: 'gray',
    marginBottom: theme.spacing(3),
    fontSize: '1.5rem',
  },
  promoInputTitle: {
    fontSize: '0.875rem',
    color: '#2b2f42',
  },
  textField: {
    width: '400px',
    height: '55px',
    marginBottom: '20px',
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#2196f3',
      },
      '&:hover fieldset': {
        borderColor: '#1976d2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0d47a1',
      },
    },
  },
  box: {
    backgroundColor: '#fff',
    width: '100%',
    minHeight: '550px',
    padding: theme.spacing(4),
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
  },
  innerBox: {
    backgroundColor: '#f5f5f5',
    width: '70%',
    height: '70vh',
    padding: theme.spacing(3),
    border: '5px solid #2196f3',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
  promoImage: {
    marginBottom: theme.spacing(3),
    maxWidth: '100%',
  },
}));

const RedeemCreditsPage = ({history, token}) => {
  const classes = useStyles();
  const [promoCode, setPromoCode] = useState('');

  const handleInputChange = (event) => {
    setPromoCode(event.target.value);
  };

  const handleRedeem = async () => {
    try {
      await addLogPromotion({token, code: promoCode});
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.success('Codigo Promocional Canjeado'), 300);
    } catch (error) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error(`Error \n ${error?.info}`), 300);
    }
  };

  return (
    <DashboardLayout history={history} token={token}>
      <Box component="div" className={classes.box}>
        <Box component="div" className={classes.innerBox}>
          <img src={promocodeImage} alt="Promocode" className={classes.promoImage} />
          <Typography variant="h4" gutterBottom className={`${classes.text} ${classes.promoTitle}`}>
            ¿Tienes un código promocional?
          </Typography>
          <Typography variant="body2" gutterBottom className={`${classes.text} ${classes.promoSubtitle}`}>
            ¡Ingresa aquí para aprovecharlo al máximo!
          </Typography>
          <Typography variant="h6" gutterBottom className={`${classes.text} ${classes.promoInputTitle}`}>
            Ingresar el código promocional
          </Typography>
          <TextField
            variant="outlined"
            value={promoCode}
            onChange={handleInputChange}
            className={classes.textField}
          />
          <Button variant="contained" color="primary" onClick={handleRedeem} className={classes.button}>
            Canjear
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(RedeemCreditsPage);
