import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {copyProject} from 'api-lofty';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
// import {copyProject} from '../api/project.api';

const AddFieldDialog = ({
  open, onClose, successFunction,
  errorFunction, idProject, token,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        Copiando Proyecto
      </DialogTitle>
      <DialogContent>
        {/* <div className="text-center">
          <img alt="Crear" src={imageCreate} className="mx-auto w-75" />
        </div> */}
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            // TODO api de copy
            try {
              const resProject = await copyProject({
                token,
                idProject,
                description: e.target.description.value,
                name: e.target.name.value,
                slug: e.target.slug.value,
              });
              if (successFunction) {
                successFunction(resProject);
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div>
            <TextField
              fullWidth
              name="name"
              type="text"
              label="Nombre"
              variant="standard"
            />
          </div>
          <div className="mt-4">
            <TextField
              fullWidth
              name="slug"
              type="text"
              label="Slug"
              variant="standard"
            />
          </div>
          <div className="mt-4">
            <TextField
              multiline
              fullWidth
              name="description"
              type="text"
              label="Descripción"
              rows={3}
              variant="standard"
            />
          </div>
          <div className="text-center mt-4">
            <button
              style={{
                background: '#201549',
              }}
              type="submit"
              className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
              disabled={loading}
            >
              {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddFieldDialog;
