/* eslint-disable no-alert */
import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {
  addVariableActionPluginDatabase, listVariableActionPluginDatabase,
  removeVariableActionPluginDatabase,
  listFieldsDatabase,
} from 'api-lofty';
import DialogFullScreen from '../DialogFullScreen';
import CardProject from '../CardProject';

const addVariableMailChange = ({
  open, onClose, successFunction, errorFunction, idDatabase,
  idProject, token, idPluginProject, idActionPluginsDatabase,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataField, setDataField] = useState();
  const [fields, setFields] = useState([]);
  const [required, setRequired] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    const handleAsync = async () => {
      try {
        const resField = await listFieldsDatabase({
          token,
          idDatabase,
          idProject,
        });
        const resVariables = await listVariableActionPluginDatabase({
          idActionPluginsDatabase,
          idPluginProject,
          idProject,
          token,
        });
        setFields(resField.data);
        setData(resVariables.data);
      } catch (error) {
        // code
      }
    };
    handleAsync();
  }, [loading]);
  return (
    <DialogFullScreen title="Agregar Acción" open={open} handleClose={onClose}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            await addVariableActionPluginDatabase({
              idActionPluginsDatabase,
              idDatabase,
              idFieldDatabase: dataField._id,
              idPluginProject,
              idProject,
              required,
              token,
              slugContentHtml: e.target.slugContentHtml.value,
            });
            if (successFunction) {
              successFunction();
            }
          } catch (error) {
            if (errorFunction) {
              errorFunction(error);
            }
          }
          setLoading(false);
        }}
      >
        <Grid spacing={3} container>
          <Grid item md={6}>
            <div className="my-4">
              <TextField
                fullWidth
                name="slugContentHtml"
                type="text"
                label="Slug"
                variant="outlined"
              />
            </div>
            <div className="my-4">
              <TextField
                select
                fullWidth
                name="idFieldUserDatabase"
                type="text"
                label="Campo de usuario"
                variant="outlined"
                onChange={(e) => {
                  const data = fields.find(({_id}) => _id === e.target.value);
                  setDataField(data);
                }}
              >
                {
                  fields.map(({_id, label}) => (
                    <MenuItem value={_id} key={_id}>
                      {label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>
            <div className="my-4">
              <FormControlLabel
                control={(
                  <Switch
                    checked={required}
                    onChange={() => setRequired(!required)}
                  />
                  )}
                label="Requerido"
              />
            </div>
            <div className="text-center my-4">
              <button
                style={{
                  background: '#201549',
                }}
                type="submit"
                className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                disabled={loading}
              >
                {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
              </button>
            </div>
          </Grid>
          <Grid item md={6}>
            {
              data.map((item) => (
                <CardProject
                  name={item.slugContentHtml}
                  description=""
                  textButton="Eliminar"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      removeVariableActionPluginDatabase({
                        token,
                        _id: item._id,
                      });
                      alert('Se elimino de manera exitosa');
                    } catch (error) {
                      if (error.info) {
                        alert(error.info);
                      } else {
                        alert('Error al eliminar el dato');
                      }
                    }
                  }}
                />
              ))
            }
          </Grid>
        </Grid>
      </form>
    </DialogFullScreen>
  );
};

export default addVariableMailChange;
