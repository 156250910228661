import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const limitText = (text) => {
  const limit = 25;
  if (text.length > 25) {
    return `${text.substr(0, limit)}...`;
  }
  return text;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // background: '',
    height: '300px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  titleText: {
    color: '#14569E',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px', /* 150% */
    letterSpacing: '0.1px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
}));

export default function PluginCard({
  name, description, image, onClick,
  textButton, secondButton, onClickSecondButton, textSecondButton,
  index,
}) {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      style={{
        background: (index % 2) ? 'linear-gradient(180deg, #E8F7FE 0%, #FFF 0.01%, #F3FBFF 66.67%, #94D8F6 100%)' : '#fff',
        border: (index % 2) ? '' : '1px solid #92DDFF',
      }}
    >
      <div className={classes.details}>
        {
        image && (
          <Box
            component="img"
            className={classes.cover}
            src={image}
          />
        )
      }
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.titleText}>
            {name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {limitText(description)}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          <Button
            style={{
              margin: '5px',
              backgroundColor: '#292151',
              color: '#fff',
            }}
            variant="contained"
            onClick={onClick}
          >
            {
              textButton || 'Ingresar'
            }
          </Button>
          {
            secondButton && (
              <Button style={{margin: '5px'}} onClick={onClickSecondButton}>
                {
                  textSecondButton || 'Otra funcion'
                }
              </Button>
            )
          }
        </div>
      </div>
    </Box>
  );
}
