import googleImage from '../assets/userProject/google.png';
import facebookImage from '../assets/userProject/facebook.png';
import imageProject from '../assets/userProject/user.svg';
import imageApple from '../assets/userProject/apple.jpg';

export default [
  {
    type: 'google',
    name: 'Google',
    description: 'Autenticación por medio de la cuenta de Google',
    image: googleImage,
  },
  {
    type: 'facebook',
    name: 'Facebook',
    description: 'Autenticación por medio de la cuenta de Facebook',
    image: facebookImage,
  },
  {
    type: 'email',
    name: 'Correo y contraseña',
    description: 'Autenticación por correo y contraseña',
    image: imageProject,
  },
  {
    type: 'apple',
    name: 'Apple',
    description: 'Autentiacción por medio de la cuenta de Apple',
    image: imageApple,
  },
];
